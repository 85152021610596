<template>
  <div class="row">
    <div class="col">
      <label for="role_code">Код ролі:</label>
      <input
        type="text"
        class="form-control"
        id="role_code"
        v-model.trim="v$.user_role_obj.role_code.$model"
      />
      <div v-if="v$.user_role_obj.role_code.$error" class="text-danger">
        Введіть код ролі
      </div>
    </div>
    <div class="col">
      <label for="role_title">Назва ролі:</label>
      <input
        type="text"
        class="form-control"
        id="role_title"
        v-model.trim="v$.user_role_obj.role_title.$model"
      />
      <div v-if="v$.user_role_obj.role_title.$error" class="text-danger">
        Введіть назву ролі
      </div>
    </div>
    <div class="col">
      <label for="description">Опис:</label>
      <textarea
        class="form-control"
        id="description"
        rows="3"
        v-model.trim="v$.user_role_obj.description.$model"
      ></textarea>
      <div v-if="v$.user_role_obj.description.$error" class="text-danger">
        Введіть опис ролі
      </div>
    </div>
    <div class="col-auto">
      <div class="d-flex justify-content-center pt-4">
        <button type="button" class="btn btn-danger" @click="remove(indexF)">
          Видалити
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "TrnSetupConfigUserRole",
  setup() {
    return { v$: useVuelidate() };
  },
  props: {
    user_role_obj: { type: Object, required: true },
    indexF: { type: Number, required: true },
  },
  emits: ["deleteUserRole", "updateUserRole"],
  validations() {
    return {
      user_role_obj: {
        role_code: { required },
        role_title: { required },
        description: { required },
      },
    };
  },
  methods: {
    remove(index) {
      // this.currencies.splice(index, 1);
      this.$emit("deleteUserRole", index);
    },
    updateValueForm(event, inputName) {
      // Відправляємо подію з оновленим значенням
      let tempObj = {
        ...this.currency,
      };
      tempObj[inputName] = event.target.value;
      this.$emit("updateUserRole", tempObj);
    },
  },
};
</script>

<style scoped></style>
