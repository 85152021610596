<template>
  <div class="input-group input-group-sm mb-2">
    <span class="input-group-text fw-bold border-0">{{
      $t(`RegularReports.form.period`)
    }}</span>
    <Datepicker
      class=""
      v-model="dateRange"
      v-if="dateRange"
      range
      autoApply
      :clearable="false"
      locale="ru"
      :format="dateFormat"
      :previewFormat="dateFormat"
    />
    <span class="input-group-text fw-bold border-0">{{
      $t(`Terminal.table.merchantId`)
    }}</span>
    <input
      type="text"
      class="form-control"
      v-model="filters.merchant_id"
      required
    />
  </div>

  <div
    class="table-responsive table-block overflow-auto"
    id="table-block"
    @scroll="scrollTable"
    v-if="dataResp"
  >
    <table
      class="table table-striped table-hover table-sm table-light table-ll"
    >
      <thead>
        <tr class="text-center">
          <th
            scope="col"
            data-col-sort="id"
            @click="clickSortCol($event, 'id')"
          >
            {{ $t(`Settings.TerminalFacility.table.id`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="fieldName"
            @click="clickSortCol($event, 'fieldName')"
          >
            {{ $t(`RegularReports.lifecycle.table.fieldName`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="oldValue"
            @click="clickSortCol($event, 'oldValue')"
          >
            {{ $t(`RegularReports.lifecycle.table.oldValue`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="newValue"
            @click="clickSortCol($event, 'newValue')"
          >
            {{ $t(`RegularReports.lifecycle.table.newValue`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="date"
            @click="clickSortCol($event, 'date')"
          >
            {{ $t(`Analytics.charts.date`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="username"
            @click="clickSortCol($event, 'username')"
          >
            {{ $t(`Users.table.username`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="entityName"
            @click="clickSortCol($event, 'entityName')"
          >
            {{ $t(`EntityAudit.table.entityName`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="entityId"
            @click="clickSortCol($event, 'entityId')"
          >
            {{ $t(`EntityAudit.table.entityId`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="type"
            @click="clickSortCol($event, 'type')"
          >
            {{ $t(`EntityAudit.table.type`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>
        </tr>
      </thead>
      <tbody class="text-nowrap">
        <template v-if="isLoading">
          <tr>
            <td colspan="16">
              <div class="loading text-center">
                <trn-loading></trn-loading>
              </div>
            </td>
          </tr>
        </template>
        <template v-if="dataResp?.content.length === 0">
          <tr>
            <td colspan="16">
              <div class="text-center fw-bold bg-info text-black">
                {{ $t(`uiElements.text.noData`) }}
              </div>
            </td>
          </tr>
        </template>
        <template v-if="dataResp">
          <tr v-for="item in dataResp.content" :key="item" class="">
            <td>{{ item.id }}</td>
            <td>{{ item.fieldName }}</td>
            <td>{{ item.oldValue }}</td>
            <td>{{ item.newValue }}</td>
            <td>{{ item.entityAudit.date }}</td>
            <td>{{ item.entityAudit.username }}</td>
            <td>{{ item.entityAudit.entityName }}</td>
            <td>{{ item.entityAudit.entityId }}</td>
            <td>{{ item.entityAudit.type }}</td>
          </tr>
          <template v-if="isLoadingScroll">
            <tr>
              <td colspan="16">
                <div class="loading text-center">
                  <trn-loading></trn-loading>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>
  <template v-if="dataResp === null">
    <div class="alert alert-warning" role="alert">
      {{ $t(`RegularReports.text.toStartGenReport`)
      }}{{ $t(`Terminal.table.merchantId`) }}
    </div>
  </template>

  <div class="count-items" v-if="dataResp">
    <div class="text-center">
      <span class="fst-italic"
        >{{ $t(`uiElements.text.countItems`) }}:
        {{ dataResp?.content.length }}
        <span v-if="dataResp?.content.length > 0">
          {{ $t(`uiElements.text.of`) }} {{ dataResp?.totalElements }}
        </span>
      </span>
    </div>
  </div>

  <div class="toast-container position-fixed p-3 top-0 end-0" v-if="showToast">
    <bs-toast v-for="toast in toastPropsList" :key="toast" :="toast"></bs-toast>
  </div>
</template>

<script>
import TrnLoading from "@/components/Loading";
import useVuelidate from "@vuelidate/core";
import { stringify } from "query-string";
import regularReportsApi from "@/api/regular-reports";
import Datepicker from "vue3-date-time-picker";
import BsToast from "@/components/UI-elements/BsToast";

export default {
  name: "TrnRegularReportsTerminalLifecycle",
  components: {
    TrnLoading,
    Datepicker,
    BsToast,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  props: {
    fileFormatType: {
      type: String,
      default: "PDF",
      required: true,
    },
    downloadFile: {
      type: Boolean,
      default: false,
      required: true,
    },
    nameReports: {
      type: String,
      required: true,
    },
  },
  emits: ["disable-download"],
  data() {
    return {
      page: 0,
      size: 40,
      sort: {
        col: "id",
        order: "desc",
      },
      dataResp: null,
      isLoading: false,
      isLoadingScroll: false,
      filters: {
        date_update_start: "",
        date_update_end: "",
        merchant_id: "",
      },
      dateFormat: "dd/MM/yyyy",
      dateRange: [],
      showToast: false,
      toastPropsList: [],
    };
  },
  computed: {},
  methods: {
    fetchDataReport() {
      this.isLoading = true;
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
        ...this.filters,
      });
      regularReportsApi
        .getTerminalLifecycle(stringifiedParams)
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          // console.log("result", result);
          if (result.status === 200) {
            this.dataResp = result.data;
            this.isLoading = false;
            this.$emit("disable-download", false);
          } else {
            console.log("err");
            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.err`)}`,
              bgColor: "bg-danger",
              textColor: "text-white",
            });
            this.showToast = true;
            this.isLoading = false;
            this.$emit("disable-download", false);
          }
        });
      if (this.dataResp) {
        this.changeArrowIcon();
      }
    },
    scrollTable(event) {
      const currentEl = event.target;
      let checkScrollEnd =
        currentEl.scrollTop + currentEl.clientHeight + 3 >=
        currentEl.scrollHeight;
      if (checkScrollEnd && this.isLoadingScroll === false) {
        const currentPage = this.page + 1;
        const totalPages = this.dataResp.totalPages;
        if (currentPage < totalPages) this.loadMoreData();
      }
    },
    loadMoreData() {
      this.isLoadingScroll = true;
      this.page += 1;
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
        ...this.filters,
      });
      regularReportsApi
        .getTerminalLifecycle(stringifiedParams)
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          if (result.status === 200) {
            for (let item in result.data.content) {
              this.dataResp.content.push(result.data.content[item]);
            }
            this.isLoadingScroll = false;
          } else {
            console.log("err");
            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.err`)}`,
              bgColor: "bg-danger",
              textColor: "text-white",
            });
            this.showToast = true;
            this.isLoadingScroll = false;
          }
        });
    },
    clickSortCol(event, newSortItem) {
      const newSortItemData = newSortItem;
      newSortItem = newSortItem.replaceAll(".", "-");
      const scrollTableEl = document.getElementById("table-block");
      const beforeSortKey = this.sort.col.replaceAll(".", "-");
      const beforeSortDirection = this.sort.order;

      const beforeEl = document.querySelectorAll(
        `[data-col-sort~=${beforeSortKey}]`
      );
      beforeEl[0].children[0].innerHTML = '<i class="bi bi-arrow-down-up"></i>';

      // let newSortVal = [newSortItem, ""];

      if (beforeSortDirection === "desc" && beforeSortKey === newSortItem) {
        this.sort.order = "asc";
      } else {
        this.sort.order = "desc";
      }
      this.page = 0;
      this.sort.col = newSortItemData;

      scrollTableEl.scrollTo(0, 0);
      this.fetchDataReport();
    },
    changeArrowIcon(sortKey = this.sort.col, sortDirection = this.sort.order) {
      sortKey = sortKey.replaceAll(".", "-");
      const targetEl = document.querySelectorAll(`[data-col-sort~=${sortKey}]`);
      if (sortDirection === "asc") {
        targetEl[0].children[0].innerHTML =
          '<i class="bi bi-arrow-up-square"></i>';
      } else {
        targetEl[0].children[0].innerHTML =
          '<i class="bi bi-arrow-down-square"></i>';
      }
    },
    downloadReport() {
      this.$emit("disable-download", true);
      const stringifiedParams = stringify({
        ...this.filters,
        fileFormatType: this.fileFormatType,
      });
      regularReportsApi
        .getTerminalLifecycleAsFile(stringifiedParams)
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          if (result.status === 200) {
            const blob = new Blob([result.data], {
              type: result.headers["content-type"],
            });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = `${this.nameReports.toLowerCase()}.${this.fileFormatType.toLowerCase()}`;
            link.click();
            URL.revokeObjectURL(link.href);
            this.$emit("disable-download", false);
          } else {
            console.log("err");
            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.err`)}`,
              bgColor: "bg-danger",
              textColor: "text-white",
            });
            this.showToast = true;
            this.$emit("disable-download", false);
          }
        });
    },
    setDefaultDate() {
      const endDate = new Date();
      const startDate = new Date(new Date().setDate(endDate.getDate() - 30));

      this.dateRange = [startDate, endDate];
      this.setFiltersDate();
    },
    setFiltersDate() {
      let startDate = new Date(this.dateRange[0]);
      startDate.setHours(0,0,0,0);
      this.filters.date_update_start = startDate.toISOString();
      let endDate = new Date(this.dateRange[1]);
      endDate.setHours(23,59,59,59);
      this.filters.date_update_end = endDate.toISOString();
    },
  },
  watch: {
    dateRange() {
      const nowDate = new Date();
      if (this.dateRange[0] > nowDate || this.dateRange[1] > nowDate) {
        this.setDefaultDate();
      }
      this.setFiltersDate();
    },
    downloadFile() {
      this.downloadReport();
    },
  },
  mounted() {
    this.$emit("disable-download", true);
    this.setDefaultDate();
    for (const key in this.filters) {
      this.$watch(
        () => this.filters[key],
        () => {
          if (this.filters.merchant_id.length >= 5) {
            this.fetchDataReport();
          }
        }
      );
    }
  },
};
</script>

<style scoped>
.table-ll {
  table-layout: auto;
}
.table-block {
  height: calc(100vh - 235px);
}
</style>
