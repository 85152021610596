import attestationSequenceThreatsApi from "@/api/settings-api/attestation";

const state = {
  dataResp: null,
  dataModalResp: null,

  isLoading: false,
  isLoadingScroll: false,
  isLoadingModal: false,

  errorResp: null,
};

export const mutationTypes = {
  getAttestationSequenceThreatsStart:
    "[AttestationSequenceThreats] getAttestationSequenceThreats start",
  getAttestationSequenceThreatsSuccess:
    "[AttestationSequenceThreats] getAttestationSequenceThreats success",
  getAttestationSequenceThreatsFailure:
    "[AttestationSequenceThreats] getAttestationSequenceThreats failure",

  getAttestationSequenceThreatsScrollPushStart:
    "[AttestationSequenceThreats] getAttestationSequenceThreatsScrollPush start",
  getAttestationSequenceThreatsScrollPushSuccess:
    "[AttestationSequenceThreats] getAttestationSequenceThreatsScrollPushSuccess success",
  getAttestationSequenceThreatsScrollPushFailure:
    "[AttestationSequenceThreats] getAttestationSequenceThreatsScrollPush failure",
};

export const actionTypes = {
  getAttestationSequenceThreats:
    "[AttestationSequenceThreats] getAttestationSequenceThreats",
  getAttestationSequenceThreatsScrollPush:
    "[AttestationSequenceThreats] getAttestationSequenceThreatsScrollPush",
};

const mutations = {
  [mutationTypes.getAttestationSequenceThreatsStart](state) {
    state.isLoading = true;
    state.data = null;
  },
  [mutationTypes.getAttestationSequenceThreatsSuccess](state, payload) {
    state.isLoading = false;
    state.dataResp = payload;
  },
  [mutationTypes.getAttestationSequenceThreatsFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
  [mutationTypes.getAttestationSequenceThreatsScrollPushStart](state) {
    state.isLoadingScroll = true;
  },
  [mutationTypes.getAttestationSequenceThreatsScrollPushSuccess](
    state,
    payload
  ) {
    for (let item in payload.content) {
      state.dataResp.content.push(payload.content[item]);
    }
    state.isLoadingScroll = false;
  },
  [mutationTypes.getAttestationSequenceThreatsScrollPushFailure](
    state,
    payload
  ) {
    state.isLoading = false;
    state.errorResp = payload;
  },
};

const actions = {
  [actionTypes.getAttestationSequenceThreats](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(
        mutationTypes.getAttestationSequenceThreatsStart,
        apiParams
      );
      attestationSequenceThreatsApi
        .getAttestationThreatSequences(apiParams)
        .then((response) => {
          context.commit(
            mutationTypes.getAttestationSequenceThreatsSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getAttestationSequenceThreatsFailure,
            result.response.data.error
          );
        });
    });
  },
  [actionTypes.getAttestationSequenceThreatsScrollPush](
    context,
    { apiParams }
  ) {
    return new Promise((resolve) => {
      context.commit(
        mutationTypes.getAttestationSequenceThreatsScrollPushStart,
        apiParams
      );
      attestationSequenceThreatsApi
        .getAttestationThreatSequences(apiParams)
        .then((response) => {
          context.commit(
            mutationTypes.getAttestationSequenceThreatsScrollPushSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getAttestationSequenceThreatsScrollPushFailure,
            result.response.data
          );
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
