import authApi from "@/api/auth";
import { setItemLocalStorage } from "@/helpers/persistanceStorage";

const state = {
  isSubmitting: false,
  isLoggedIn: null,
  isLoading: false,
  currentUser: null,
  validationErrors: null,
};

export const mutationTypes = {
  loginStart: "[auth] Login start",
  loginSuccess: "[auth] Login success",
  loginFailure: "[auth] Login failure",

  getCurrentUserStart: "[auth] Get current user start",
  getCurrentUserSuccess: "[auth] Get current user success",
  getCurrentUserFailure: "[auth] Get current user failure",
};

export const actionTypes = {
  login: "[auth] Login",
  getCurrentUser: "[auth] Get current user",
};

export const getterTypes = {
  currentUser: "[auth] currentUser",
  isLoggedIn: "[auth] isLoggedIn",
  isAnonymous: "[auth] isAnonymous",
};

const getters = {
  [getterTypes.currentUser]: (state) => {
    return state.currentUser;
  },
  [getterTypes.isLoggedIn]: (state) => {
    return Boolean(state.isLoggedIn);
  },
  [getterTypes.isAnonymous]: (state) => {
    return state.isLoggedIn === false;
  },
};

const mutations = {
  [mutationTypes.loginStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.loginSuccess](state, payload) {
    state.isSubmitting = false;
    state.isLoggedIn = true;
    state.currentUser = payload;
  },
  [mutationTypes.loginFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },
  [mutationTypes.getCurrentUserStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getCurrentUserSuccess](state, payload) {
    state.isLoading = false;
    state.isLoggedIn = true;
    state.currentUser = payload;
  },
  [mutationTypes.getCurrentUserFailure](state) {
    state.isLoading = false;
    state.isLoggedIn = false;
    state.currentUser = null;
  },
};

const actions = {
  [actionTypes.login](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.loginStart);
      authApi
        .login(credentials)
        .then((result) => {
          if (result.status === 200) {
            context.commit(mutationTypes.loginSuccess, result.data.user);
            setItemLocalStorage("accessToken", result.data.token);
            setItemLocalStorage("authorities", result.data.authorities);
            setItemLocalStorage("userName", credentials.username);
            setItemLocalStorage(
              "isIncludeServiceOfFata",
              result.data.isIncludeServiceOfFata
            );
            resolve(result.data);
          } else {
            context.commit(
              mutationTypes.loginFailure,
              result.response.data.error
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.loginFailure, err.response.data.error);
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
