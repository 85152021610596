import axios from "@/api/axios";

const getTerminalGroups = (apiParams) => {
  return axios.get("/service-groups/with-dictionaries?" + apiParams);
};

const updateTerminalGroups = (groupId, groupObj) => {
  return axios.put("/service-groups/" + groupId, { ...groupObj });
};

const addTerminalGroups = (groupObj) => {
  return axios.post("/service-groups", { ...groupObj });
};

const transferTerminalsToNewServiceGroup = (
  fromGroupNumberToChangeTerminals,
  toGroupNumberToChangeTerminals
) => {
  return axios.post(
    `/service-groups/change/${fromGroupNumberToChangeTerminals}/to/${toGroupNumberToChangeTerminals}`
  );
};

const deleteTerminalGroup = (groupId) => {
  return axios.delete("/service-groups/" + groupId);
};

export default {
  getTerminalGroups,
  updateTerminalGroups,
  transferTerminalsToNewServiceGroup,
  deleteTerminalGroup,
  addTerminalGroups,
};
