import transactionsApi from "@/api/transaciton";

const state = {
  dataResp: null,
  dataModalResp: null,

  isLoading: false,
  isLoadingScroll: false,
  isLoadingModal: false,

  errorResp: null,
};

export const mutationTypes = {
  getTransactionsStart: "[Transactions] getTransactions start",
  getTransactionsSuccess: "[Transactions] getTransactions success",
  getTransactionsFailure: "[Transactions] getTransactions failure",

  getTransactionsScrollPushStart:
    "[Transactions] getTransactionsScrollPush start",
  getTransactionsScrollPushSuccess:
    "[Transactions] getTransactionsScrollPushSuccess success",
  getTransactionsScrollPushFailure:
    "[Transactions] getTransactionsScrollPush failure",

  getTerminalIdStart: "[Transactions] getTerminalId start",
  getTerminalIdSuccess: "[Transactions] getTerminalId success",
  getTerminalIdFailure: "[Transactions] getTerminalId failure",

  getReceiptNumberStart: "[Transactions] getReceiptNumber start",
  getReceiptNumberSuccess: "[Transactions] getReceiptNumber success",
  getReceiptNumberFailure: "[Transactions] getReceiptNumber failure",

  reversalTransactionStart: "[Transactions] reversalTransaction start",
  reversalTransactionSuccess: "[Transactions] reversalTransaction success",
  reversalTransactionFailure: "[Transactions] reversalTransaction failure",
};

export const actionTypes = {
  getTransactions: "[Transactions] getTransactions",
  getTransactionsScrollPush: "[Transactions] getTransactionsScrollPush",
  getTerminalId: "[Transactions] getTerminalId",
  getReceiptNumber: "[Transactions] getReceiptNumber",
  reversalTransaction: "[Transactions] reversalTransaction",
};

const mutations = {
  [mutationTypes.getTransactionsStart](state) {
    state.isLoading = true;
    state.data = null;
  },
  [mutationTypes.getTransactionsSuccess](state, payload) {
    state.isLoading = false;
    state.dataResp = payload;
  },
  [mutationTypes.getTransactionsFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
  [mutationTypes.getTransactionsScrollPushStart](state) {
    state.isLoadingScroll = true;
  },
  [mutationTypes.getTransactionsScrollPushSuccess](state, payload) {
    for (let item in payload.content) {
      state.dataResp.content.push(payload.content[item]);
    }
    state.isLoadingScroll = false;
  },
  [mutationTypes.getTransactionsScrollPushFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },

  [mutationTypes.getTerminalIdStart](state) {
    state.isLoadingModal = true;
    state.dataModalResp = null;
  },
  [mutationTypes.getTerminalIdSuccess](state, payload) {
    state.isLoadingModal = false;
    state.dataModalResp = payload;
  },
  [mutationTypes.getTerminalIdFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },

  [mutationTypes.getReceiptNumberStart](state) {
    state.isLoadingModal = true;
    state.dataModalResp = null;
  },
  [mutationTypes.getReceiptNumberSuccess](state, payload) {
    state.isLoadingModal = false;
    state.dataModalResp = payload;
  },
  [mutationTypes.getReceiptNumberFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },

  [mutationTypes.reversalTransactionStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.reversalTransactionSuccess](state, payload) {
    state.isLoading = false;
    state.dataResp = payload;
  },
  [mutationTypes.reversalTransactionFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
};

const actions = {
  [actionTypes.getTransactions](context, { apiUrl }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getTransactionsStart, apiUrl);
      transactionsApi
        .transactions(apiUrl)
        .then((response) => {
          context.commit(mutationTypes.getTransactionsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getTransactionsFailure,
            result.response.data.error
          );
        });
    });
  },
  [actionTypes.getTransactionsScrollPush](context, { apiUrl }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getTransactionsScrollPushStart, apiUrl);
      transactionsApi
        .transactions(apiUrl)
        .then((response) => {
          context.commit(
            mutationTypes.getTransactionsScrollPushSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getTransactionsScrollPushFailure,
            result.response.data
          );
        });
    });
  },
  [actionTypes.getTerminalId](context, { value }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getTerminalIdStart, value);
      transactionsApi
        .terminalId(value)
        .then((response) => {
          context.commit(mutationTypes.getTerminalIdSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getTerminalIdFailure,
            result.response.data
          );
        });
    });
  },
  [actionTypes.getReceiptNumber](context, { value }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getReceiptNumberStart, value);
      transactionsApi
        .receiptNumber(value)
        .then((response) => {
          context.commit(mutationTypes.getReceiptNumberSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getReceiptNumberFailure,
            result.response.data
          );
        });
    });
  },
  [actionTypes.reversalTransaction](context, { value }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.reversalTransactionStart, value);
      transactionsApi
        .reversal(value)
        .then((response) => {
          context.commit(
            mutationTypes.reversalTransactionSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.reversalTransactionFailure,
            result.response.data
          );
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
