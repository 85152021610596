<template>
  <ul class="nav nav-tabs mb-3" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <button
        class="nav-link active"
        id="terminal-tab"
        data-bs-toggle="tab"
        data-bs-target="#terminal"
        type="button"
        role="tab"
        aria-controls="home"
        aria-selected="true"
      >
        <i class="bi bi-view-list"></i>
        {{ $t("Attestation.tab.actionsThreats") }}
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="nav-link"
        id="transaction-tab"
        data-bs-toggle="tab"
        data-bs-target="#transaction"
        type="button"
        role="tab"
        aria-controls="profile"
        aria-selected="false"
      >
        <i class="bi bi-view-list"></i>
        {{ $t("Attestation.tab.sequenceThreats") }}
      </button>
    </li>
  </ul>
  <div class="tab-content" id="myTabContent">
    <div
      class="tab-pane fade active show"
      id="terminal"
      role="tab-panel"
      aria-labelledby="terminal-tab"
    >
      <trn-attestation-threat-tab></trn-attestation-threat-tab>
      <div class="row">
        <div class="col-md-6 text-center">
          <hr />
        </div>
      </div>

      <trn-attestation-action-tab></trn-attestation-action-tab>
    </div>
    <div
      class="tab-pane fade"
      id="transaction"
      role="tab-panel"
      aria-labelledby="transaction-tab"
    >
      <trn-attestation-sequence-threats-tab></trn-attestation-sequence-threats-tab>
    </div>
  </div>
</template>

<script>
import TrnAttestationThreatTab from "@/components/block-pages/AttestationThreatTab";
import TrnAttestationActionTab from "@/components/block-pages/AttestationActionTab";
import TrnAttestationSequenceThreatsTab from "@/components/block-pages/AttestationSequenceThreatsTab";
export default {
  name: "TrnAttestation",
  components: {
    TrnAttestationSequenceThreatsTab,
    TrnAttestationActionTab,
    TrnAttestationThreatTab,
  },
};
</script>

<style scoped></style>
