import merchantRegistrationApi from "@/api/merchant-registration";

const state = {
  dataResp: null,
  dataModalResp: null,

  isLoading: false,
  isLoadingScroll: false,
  isLoadingModal: false,

  errorResp: null,

  isReqSendSms: false,
  sendSmsResp: null,
};

export const mutationTypes = {
  getAllRegistrationStart: "[Transactions] getAllRegistration start",
  getAllRegistrationSuccess: "[Transactions] getAllRegistration success",
  getAllRegistrationFailure: "[Transactions] getAllRegistration failure",

  getAllRegistrationScrollPageStart:
    "[Transactions] getAllRegistrationScrollPage start",
  getAllRegistrationScrollPageSuccess:
    "[Transactions] getAllRegistrationScrollPage success",
  getAllRegistrationScrollPageFailure:
    "[Transactions] getAllRegistrationScrollPage failure",

  setSendSmsSuccess: "[Transactions] setSendSmsSuccess success",
  setSendSmsFailure: "[Transactions] setSendSmsFailure failure",
};

export const actionTypes = {
  getAllRegistration: "[Transactions] getAllRegistration",
  getAllRegistrationScrollPage: "[Transactions] getAllRegistrationScrollPage",
  sendSms: "[Transactions] sendSms",
};

const mutations = {
  [mutationTypes.getAllRegistrationStart](state) {
    state.isLoading = true;
    state.data = null;
  },
  [mutationTypes.getAllRegistrationSuccess](state, payload) {
    state.isLoading = false;
    state.dataResp = payload;
  },
  [mutationTypes.getAllRegistrationFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
  [mutationTypes.getAllRegistrationScrollPageStart](state) {
    state.isLoadingScroll = true;
  },
  [mutationTypes.getAllRegistrationScrollPageSuccess](state, payload) {
    for (let item in payload.page.content) {
      state.dataResp.page.content.push(payload.page.content[item]);
    }
    state.isLoadingScroll = false;
  },
  [mutationTypes.getAllRegistrationScrollPageFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },

  [mutationTypes.setSendSmsSuccess](state) {
    state.isReqSendSms = true;
  },
  [mutationTypes.setSendSmsFailure](state, payload) {
    state.isReqSendSms = true;
    state.errorResp = payload;
  },
  //
  // [mutationTypes.getTerminalIdStart](state) {
  //   state.isLoadingModal = true;
  //   state.dataModalResp = null;
  // },
  // [mutationTypes.getTerminalIdSuccess](state, payload) {
  //   state.isLoadingModal = false;
  //   state.dataModalResp = payload;
  // },
  // [mutationTypes.getTerminalIdFailure](state, payload) {
  //   state.isLoading = false;
  //   state.errorResp = payload;
  // },
};

const actions = {
  [actionTypes.getAllRegistration](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getAllRegistrationStart);
      merchantRegistrationApi
        .registration(apiParams)
        .then((response) => {
          context.commit(
            mutationTypes.getAllRegistrationSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getAllRegistrationFailure,
            result.response.data.error
          );
        });
    });
  },
  [actionTypes.getAllRegistrationScrollPage](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getAllRegistrationScrollPageStart);
      merchantRegistrationApi
        .registration(apiParams)
        .then((response) => {
          context.commit(
            mutationTypes.getAllRegistrationScrollPageSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getAllRegistrationScrollPageFailure,
            result.response.data.error
          );
        });
    });
  },
  [actionTypes.sendSms](context, { loginsString }) {
    return new Promise((resolve) => {
      merchantRegistrationApi
        .sendSms(loginsString)
        .then((response) => {
          context.commit(mutationTypes.setSendSmsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.setSendSmsFailure,
            result.response.data.error
          );
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
