<template>
  <div
    class="toast"
    :class="[bgColor, textColor]"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
    ref="toast"
  >
    <div class="toast-header" v-if="title">
      <strong class="me-auto">{{ title }}</strong>
      <small></small>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="toast"
        aria-label="Close"
      ></button>
    </div>
    <div class="d-flex">
      <div class="toast-body overflow-auto" v-html="content"></div>
      <button
        type="button"
        class="btn-close btn-close-white me-2 m-auto"
        data-bs-dismiss="toast"
        aria-label="Close"
        v-if="!title"
      ></button>
    </div>
  </div>
</template>

<script>
import { Toast } from "bootstrap";

export default {
  name: "BsToast",
  props: {
    content: {
      required: false,
      default: "",
    },
    title: {
      default: "",
    },
    delay: {
      default: 0,
    },
    bgColor: {
      default: "bg-light",
    },
    textColor: {
      default: "text-black",
    },
  },
  mounted() {
    let toast = new Toast(this.$refs.toast);
    toast.show();
  },
};
</script>

<style scoped></style>
