import axios from "@/api/axios";

const getProducts = (apiParams) => {
  return axios.get("/products/with-dictionaries?" + apiParams);
};

const updateProducts = (id, reqObj) => {
  return axios.put("/products/" + id, {
    ...reqObj,
  });
};

const addProducts = (reqObj) => {
  return axios.post("/products", {
    ...reqObj,
  });
};

export default {
  getProducts,
  updateProducts,
  addProducts,
};
