<template>
  <div class="row">
    <div class="col-md-6">
      <div class="mb-3">
        <label for="group_name" class="form-label">Назва групи</label>
        <input
          type="text"
          class="form-control"
          id="group_name"
          v-model.trim="v$.service_group.group_name.$model"
        />
        <div v-if="v$.service_group.group_name.$error" class="text-danger">
          Поле обов'язкове для заповнення
        </div>
      </div>
      <div class="mb-3">
        <label for="no_pin_limit" class="form-label">Ліміт без ПІН-коду</label>
        <input
          type="number"
          class="form-control"
          id="no_pin_limit"
          v-model.trim="v$.service_group.no_pin_limit.$model"
          :min="0"
        />
        <div
          v-if="v$.service_group.no_pin_limit.required.$valid"
          class="text-danger"
        >
          Поле обов'язкове для заповнення
        </div>
      </div>
      <div class="mb-3">
        <label for="one_transaction_limit" class="form-label"
          >Ліміт однієї транзакції</label
        >
        <input
          type="number"
          class="form-control"
          id="one_transaction_limit"
          v-model.trim="v$.service_group.one_transaction_limit.$model"
        />
      </div>
      <div class="mb-3">
        <label for="total_amount_limit" class="form-label"
          >Загальний ліміт суми транзакцій за період</label
        >
        <input
          type="number"
          class="form-control"
          id="total_amount_limit"
          v-model.trim="v$.service_group.total_amount_limit.$model"
        />
        <div
          v-if="v$.service_group.total_amount_limit.$error"
          class="text-danger"
        >
          Загальний ліміт суми транзакцій за період повинен бути заповнений.
        </div>
      </div>
      <div class="mb-3">
        <label for="total_count_limit" class="form-label"
          >Загальний ліміт кількості транзакцій за період</label
        >
        <input
          type="number"
          class="form-control"
          id="total_count_limit"
          v-model.trim="v$.service_group.total_count_limit.$model"
        />
        <div
          v-if="v$.service_group.total_count_limit.$error"
          class="text-danger"
        >
          Загальний ліміт кількості транзакцій за період повинен бути
          заповнений.
        </div>
      </div>
      <div class="mb-3">
        <label for="total_limit_period" class="form-label"
          >Період загального ліміту транзакцій</label
        >
        <input
          type="number"
          class="form-control"
          id="total_limit_period"
          v-model.trim="v$.service_group.total_limit_period.$model"
        />
        <div
          v-if="v$.service_group.total_limit_period.$error"
          class="text-danger"
        >
          Період загального ліміту транзакцій повинен бути заповнений.
        </div>
      </div>
      <div class="mb-3">
        <label for="velocity_count" class="form-label"
          >Кількість транзакцій на період</label
        >
        <input
          type="number"
          class="form-control"
          id="velocity_count"
          v-model.trim="v$.service_group.velocity_count.$model"
        />
        <div v-if="v$.service_group.velocity_count.$error" class="text-danger">
          Кількість транзакцій на період повинна бути заповнена.
        </div>
      </div>
      <div class="mb-3">
        <label for="velocity_period" class="form-label"
          >Період часу для ліміту транзакцій</label
        >
        <input
          type="number"
          class="form-control"
          id="velocity_period"
          v-model.trim="v$.service_group.velocity_period.$model"
        />
        <div v-if="v$.service_group.velocity_period.$error" class="text-danger">
          Період часу для ліміту транзакцій повинен бути заповнений.
        </div>
      </div>
      <div class="mb-3">
        <label for="currency_code" class="form-label">Код валюти</label>
        <select
          class="form-select"
          id="currency_code"
          v-model.trim="v$.service_group.currency_code.$model"
        >
          <option value="">-- Оберіть код валюти --</option>
          <option
            v-for="currency in currencies"
            :key="currency.code"
            :value="currency.code"
          >
            {{ currency.letter_code }}
          </option>
        </select>
        <div v-if="v$.service_group.currency_code.$error" class="text-danger">
          Код валюти повинен бути обраний.
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="mb-3">
        <label for="receipt_template_id" class="form-label"
          >Ідентифікатор шаблону чека</label
        >
        <input
          type="number"
          class="form-control"
          id="receipt_template_id"
          v-model.trim="v$.service_group.receipt_template_id.$model"
        />
        <div
          v-if="v$.service_group.receipt_template_id.$error"
          class="text-danger"
        >
          Ідентифікатор шаблону чека повинен бути заповнений.
        </div>
      </div>
      <div class="mb-3">
        <label for="service_group_has_receipt_send_channel" class="form-label"
          >Канал надсилання чека</label
        >
        <select
          class="form-select"
          id="service_group_has_receipt_send_channel"
          multiple
          v-model.trim="
            v$.service_group.service_group_has_receipt_send_channel.$model
          "
        >
          <option value="" disabled>-- Оберіть канал надсилання чека --</option>
          <option v-for="channel in receiptSendChannels" :key="channel">
            {{ channel }}
          </option>
        </select>
        <div
          v-if="v$.service_group.service_group_has_receipt_send_channel.$error"
          class="text-danger"
        >
          Канал надсилання чека повинен бути обраний.
        </div>
      </div>
      <div class="mb-3">
        <label for="service_group_has_ips_card_group" class="form-label"
          >Група карток IPS</label
        >
        <select
          class="form-select"
          id="service_group_has_ips_card_group"
          multiple
          v-model.trim="
            v$.service_group.service_group_has_ips_card_group.$model
          "
        >
          <option value="" disabled>-- Оберіть групу карток IPS --</option>
          <option
            v-for="group in ipsCardGroups"
            :key="group"
            :value="group.ips_card_group"
            :class="{ invisible: group.ips_card_group === '' }"
          >
            {{ group.ips_card_group }} : {{ group.ips_name }}
          </option>
        </select>
        <div
          v-if="v$.service_group.service_group_has_ips_card_group.$error"
          class="text-danger"
        >
          Група карток IPS повинна бути обрана.
        </div>
      </div>
      <div class="mb-3">
        <label for="op_create_subordinate_terminal" class="form-label"
          >Можливість створювати підлеглі термінали</label
        >
        <select
          class="form-select"
          id="op_create_subordinate_terminal"
          v-model.trim="v$.service_group.op_create_subordinate_terminal.$model"
        >
          <option value="N">Ні</option>
          <option value="Y">Так</option>
        </select>
      </div>
      <div
        v-if="v$.service_group.op_create_subordinate_terminal.$model === 'Y'"
        class="mb-3"
      >
        <label for="num_sec_term" class="form-label"
          >Кількість підлеглих терміналів</label
        >
        <input
          type="number"
          class="form-control"
          id="num_sec_term"
          v-model.trim="v$.service_group.num_sec_term.$model"
        />
      </div>
      <div class="mb-3 form-check">
        <input
          type="checkbox"
          class="form-check-input"
          id="op_manual"
          v-model.trim="v$.service_group.op_manual.$model"
        />
        <label class="form-check-label" for="op_manual"
          >Операція "Вручну"</label
        >
      </div>
      <div class="mb-3 form-check">
        <input
          type="checkbox"
          class="form-check-input"
          id="op_nfc"
          v-model.trim="v$.service_group.op_nfc.$model"
        />
        <label class="form-check-label" for="op_nfc">Операція з NFC</label>
      </div>
      <div class="mb-3 form-check">
        <input
          type="checkbox"
          class="form-check-input"
          id="op_purchase"
          v-model.trim="v$.service_group.op_purchase.$model"
        />
        <label class="form-check-label" for="op_purchase"
          >Операція "Покупка"</label
        >
      </div>
      <div class="mb-3 form-check">
        <input
          type="checkbox"
          class="form-check-input"
          id="op_qr"
          v-model.trim="v$.service_group.op_qr.$model"
        />
        <label class="form-check-label" for="op_qr">Операція з QR-кодом</label>
      </div>
      <div class="mb-3 form-check">
        <input
          type="checkbox"
          class="form-check-input"
          id="op_refund"
          v-model.trim="v$.service_group.op_refund.$model"
        />
        <label class="form-check-label" for="op_refund"
          >Операція "Повернення"</label
        >
      </div>
      <div class="mb-3 form-check">
        <input
          type="checkbox"
          class="form-check-input"
          id="op_reversal"
          v-model.trim="v$.service_group.op_reversal.$model"
        />
        <label class="form-check-label" for="op_reversal"
          >Операція "Скасування"</label
        >
      </div>
      <div class="mb-3 form-check">
        <input
          type="checkbox"
          class="form-check-input"
          id="op_tips"
          v-model.trim="v$.service_group.op_tips.$model"
        />
        <label class="form-check-label" for="op_tips">Операція "Тіпс"</label>
      </div>
      <div class="mb-3 form-check">
        <input
          type="checkbox"
          class="form-check-input"
          id="op_auto_refund"
          v-model.trim="v$.service_group.op_auto_refund.$model"
        />
        <label class="form-check-label" for="op_auto_refund"
          >Автоматичне повернення</label
        >
      </div>
      <div class="mb-3 form-check">
        <input
          type="checkbox"
          class="form-check-input"
          id="op_auto_reversal"
          v-model.trim="v$.service_group.op_auto_reversal.$model"
        />
        <label class="form-check-label" for="op_auto_reversal"
          >Автоматичне скасування</label
        >
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { minValue, required } from "@vuelidate/validators";

export default {
  name: "TrnSetupConfigServiceGroup",
  setup() {
    return { v$: useVuelidate() };
  },
  props: {
    service_group: {
      type: Object,
      required: true,
    },
    currencies: {
      type: Object,
      required: true,
    },
    receiptSendChannels: {
      type: Array,
      required: true,
    },
    ipsCardGroups: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      minimumNoPinLimit: 5,
    };
  },
  validations() {
    return {
      service_group: {
        geo_position: {},
        group_name: {
          required,
        },
        no_pin_limit: {
          required,
        },
        one_transaction_limit: {
          required,
          minValue: minValue(1),
        },
        op_manual: {},
        op_nfc: {},
        op_purchase: {},
        op_qr: {},
        op_refund: {},
        op_reversal: {},
        op_tips: {},
        total_amount_limit: {
          required,
          minValue: minValue(1),
        },
        total_count_limit: {
          required,
          minValue: minValue(1),
        },
        total_limit_period: {
          required,
          minValue: minValue(1),
        },
        velocity_count: {
          required,
          minValue: minValue(1),
        },
        velocity_period: {
          required,
          minValue: minValue(1),
        },
        velocity_time_unit: {},
        currency_code: {
          required,
        },
        receipt_template_id: {
          required,
        },
        service_group_has_receipt_send_channel: {
          required,
        },
        service_group_has_ips_card_group: {
          required,
        },
        op_create_subordinate_terminal: {},
        num_sec_term: {},
        op_auto_refund: {},
        op_auto_reversal: {},
      },
    };
  },
  methods: {},
};
</script>

<style scoped></style>
