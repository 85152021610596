import tmsKeyApi from "@/api/settings-api/tms-key";

const state = {
  dataResp: null,
  dataModalResp: null,

  isLoading: false,
  isLoadingScroll: false,
  isLoadingModal: false,

  errorResp: null,
};

export const mutationTypes = {
  getTmsKeyStart: "[TmsKey] getTmsKey start",
  getTmsKeySuccess: "[TmsKey] getTmsKey success",
  getTmsKeyFailure: "[TmsKey] getTmsKey failure",

  getTmsKeyScrollPushStart: "[TmsKey] getTmsKeyScrollPush start",
  getTmsKeyScrollPushSuccess: "[TmsKey] getTmsKeyScrollPushSuccess success",
  getTmsKeyScrollPushFailure: "[TmsKey] getTmsKeyScrollPush failure",
};

export const actionTypes = {
  getTmsKey: "[TmsKey] getTmsKey",
  getTmsKeyScrollPush: "[TmsKey] getTmsKeyScrollPush",
};

const mutations = {
  [mutationTypes.getTmsKeyStart](state) {
    state.isLoading = true;
    state.data = null;
  },
  [mutationTypes.getTmsKeySuccess](state, payload) {
    state.isLoading = false;
    state.dataResp = payload;
  },
  [mutationTypes.getTmsKeyFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
  [mutationTypes.getTmsKeyScrollPushStart](state) {
    state.isLoadingScroll = true;
  },
  [mutationTypes.getTmsKeyScrollPushSuccess](state, payload) {
    for (let item in payload.content) {
      state.dataResp.content.push(payload.content[item]);
    }
    state.isLoadingScroll = false;
  },
  [mutationTypes.getTmsKeyScrollPushFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
};

const actions = {
  [actionTypes.getTmsKey](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getTmsKeyStart, apiParams);
      tmsKeyApi
        .getTmsKey(apiParams)
        .then((response) => {
          context.commit(mutationTypes.getTmsKeySuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getTmsKeyFailure,
            result.response.data.error
          );
        });
    });
  },
  [actionTypes.getTmsKeyScrollPush](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getTmsKeyScrollPushStart, apiParams);
      tmsKeyApi
        .getTmsKey(apiParams)
        .then((response) => {
          context.commit(
            mutationTypes.getTmsKeyScrollPushSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getTmsKeyScrollPushFailure,
            result.response.data
          );
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
