<template>
  <!-- ToolBar -->
  <div class="toolbar">
    <div class="btn-group mb-2">
      <button
        class="btn btn-outline-primary btn-sm"
        type="button"
        @click="clickChangeGroupObj('add')"
      >
        <i class="bi bi-file-earmark-plus"></i>
        <span class="ms-1">{{ $t(`uiElements.btn.add`) }}</span>
      </button>
      <button
        class="btn btn-outline-primary btn-sm"
        :class="{ disabled: !selectedTableRow?.id }"
        type="button"
        @click="clickChangeGroupObj('update')"
      >
        <i class="bi bi-pencil-square"></i>
        <span class="ms-1">{{ $t(`uiElements.btn.change`) }}</span>
      </button>
    </div>
  </div>

  <!-- ToolBar кінець -->
  <div
    class="table-responsive table-block overflow-auto"
    id="table-block"
    @scroll="scrollTable"
  >
    <table class="table table-sm table-light">
      <thead>
        <tr class="text-center">
          <th scope="col">
            {{ $t(`Attestation.sequenceThreats.table.id`) }}
          </th>
          <th scope="col">
            {{ $t(`Attestation.sequenceThreats.table.attestationActions`) }}
          </th>
          <th scope="col">
            {{ $t(`Attestation.threat.table.DEBUG`) }}
          </th>
          <th scope="col">
            {{ $t(`Attestation.threat.table.INTEGRITY`) }}
          </th>
          <th scope="col">
            {{ $t(`Attestation.threat.table.EMULATOR`) }}
          </th>
          <th scope="col">
            {{ $t(`Attestation.threat.table.ROOT`) }}
          </th>
          <th scope="col">
            {{ $t(`Attestation.threat.table.VELOCITY_CHECK`) }}
          </th>
          <th scope="col">
            {{ $t(`Attestation.threat.table.CHANNEL_INTEGRITY`) }}
          </th>
          <th scope="col">
            {{ $t(`Attestation.threat.table.GEO_POSITION`) }}
          </th>
          <th scope="col">
            {{ $t(`Attestation.threat.table.INSTALL_SOURCE`) }}
          </th>
          <th scope="col">
            {{ $t(`Attestation.threat.table.KEY_ATTESTATION`) }}
          </th>
          <th scope="col">
            {{ $t(`Attestation.sequenceThreats.table.enabled`) }}
          </th>
        </tr>
      </thead>
      <tbody class="text-nowrap">
        <template v-if="isLoading">
          <tr>
            <td colspan="12">
              <div class="loading text-center">
                <trn-loading></trn-loading>
              </div>
            </td>
          </tr>
        </template>
        <template v-if="dataResp">
          <tr
            v-for="item in dataResp.content"
            :key="item"
            class="text-center cursor-pointer"
            :class="{
              'select-td': item.id === selectedTableRow?.id,
            }"
            @click="clickTableRow(item)"
          >
            <td>{{ item.id }}</td>
            <td>
              <template
                v-for="aAction in item.attestationActions"
                :key="aAction"
              >
                {{ aAction.shortName }},
              </template>
            </td>
            <td>{{ item.debug }}</td>
            <td>{{ item.integrity }}</td>
            <td>{{ item.emulator }}</td>
            <td>{{ item.root }}</td>
            <td>{{ item.velocity }}</td>
            <td>{{ item.channelIntegrity }}</td>
            <td>{{ item.geoPosition }}</td>
            <td>{{ item.installSource }}</td>
            <td>{{ item.keyAttestationChallengeCheck }}</td>
            <td>{{ item.enabled }}</td>
          </tr>
          <template v-if="isLoadingScroll">
            <tr>
              <td colspan="16">
                <div class="loading text-center">
                  <trn-loading></trn-loading>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>
  <bs-modal
    :modal-toggle-emit="showModal"
    :title="titleModal"
    @hideMainModal="showModal = false"
  >
    <template v-if="showFormGroup">
      <form
        class="needs-validation"
        @submit.prevent="submitChangeGroupNumber"
        novalidate
      >
        <!--  input-id  -->
        <div class="row mb-3" v-if="selectedTableRow">
          <label for="input-id" class="col-sm-6 col-form-label">
            {{ $t(`Attestation.sequenceThreats.table.id`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              id="input-id"
              v-model="selectedTableRow.id"
              disabled
            />
          </div>
        </div>

        <!--  input-attestationActionsIdList  -->
        <div class="row mb-3">
          <label
            for="input-attestationActionsIdList"
            class="col-sm-6 col-form-label"
          >
            {{ $t(`Attestation.sequenceThreats.table.attestationActions`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <multiselect
              v-model="
                v$.formAttestationSequenceThreats.attestationActionsIdList
                  .$model
              "
              :options="multiSelectOptions.attestationActionsIdList"
              class="multiselect-blue"
              :class="addClassValidInput('attestationActionsIdList')"
              id="input-ipsCardGroupIdList"
              mode="multiple"
              :close-on-select="false"
              :hide-selected="false"
            >
            </multiselect>
            <div class="invalid-feedback">
              <div
                v-if="
                  v$.formAttestationSequenceThreats.attestationActionsIdList
                    .required.$invalid
                "
              >
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-channelIntegrity  -->
        <div class="row mb-3">
          <label for="input-channelIntegrity" class="col-sm-6 col-form-label">
            {{ $t(`Attestation.threat.table.CHANNEL_INTEGRITY`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              :class="addClassValidInput('channelIntegrity')"
              id="input-channelIntegrity"
              v-model="
                v$.formAttestationSequenceThreats.channelIntegrity.$model
              "
            >
              <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
              <option value="N">{{ $t(`uiElements.text.no`) }}</option>
            </select>
            <div class="invalid-feedback">
              <div
                v-if="
                  v$.formAttestationSequenceThreats.channelIntegrity.required
                    .$invalid
                "
              >
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-debug  -->
        <div class="row mb-3">
          <label for="input-debug" class="col-sm-6 col-form-label">
            {{ $t(`Attestation.threat.table.DEBUG`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              :class="addClassValidInput('debug')"
              id="input-debug"
              v-model="v$.formAttestationSequenceThreats.debug.$model"
            >
              <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
              <option value="N">{{ $t(`uiElements.text.no`) }}</option>
            </select>
            <div class="invalid-feedback">
              <div
                v-if="v$.formAttestationSequenceThreats.debug.required.$invalid"
              >
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-emulator  -->
        <div class="row mb-3">
          <label for="input-emulator" class="col-sm-6 col-form-label">
            {{ $t(`Attestation.threat.table.EMULATOR`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              :class="addClassValidInput('emulator')"
              id="input-emulator"
              v-model="v$.formAttestationSequenceThreats.emulator.$model"
            >
              <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
              <option value="N">{{ $t(`uiElements.text.no`) }}</option>
            </select>
            <div class="invalid-feedback">
              <div
                v-if="
                  v$.formAttestationSequenceThreats.emulator.required.$invalid
                "
              >
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-enabled  -->
        <div class="row mb-3">
          <label for="input-enabled" class="col-sm-6 col-form-label">
            {{ $t(`Attestation.sequenceThreats.table.enabled`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              :class="addClassValidInput('enabled')"
              id="input-enabled"
              v-model="v$.formAttestationSequenceThreats.enabled.$model"
            >
              <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
              <option value="N">{{ $t(`uiElements.text.no`) }}</option>
            </select>
            <div class="invalid-feedback">
              <div
                v-if="
                  v$.formAttestationSequenceThreats.enabled.required.$invalid
                "
              >
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-geoPosition  -->
        <div class="row mb-3">
          <label for="input-geoPosition" class="col-sm-6 col-form-label">
            {{ $t(`Attestation.threat.table.GEO_POSITION`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              :class="addClassValidInput('geoPosition')"
              id="input-geoPosition"
              v-model="v$.formAttestationSequenceThreats.geoPosition.$model"
            >
              <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
              <option value="N">{{ $t(`uiElements.text.no`) }}</option>
            </select>
            <div class="invalid-feedback">
              <div
                v-if="
                  v$.formAttestationSequenceThreats.geoPosition.required
                    .$invalid
                "
              >
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-integrity  -->
        <div class="row mb-3">
          <label for="input-integrity" class="col-sm-6 col-form-label">
            {{ $t(`Attestation.threat.table.INTEGRITY`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              :class="addClassValidInput('integrity')"
              id="input-integrity"
              v-model="v$.formAttestationSequenceThreats.integrity.$model"
            >
              <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
              <option value="N">{{ $t(`uiElements.text.no`) }}</option>
            </select>
            <div class="invalid-feedback">
              <div
                v-if="
                  v$.formAttestationSequenceThreats.integrity.required.$invalid
                "
              >
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-root  -->
        <div class="row mb-3">
          <label for="input-root" class="col-sm-6 col-form-label">
            {{ $t(`Attestation.threat.table.ROOT`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              :class="addClassValidInput('root')"
              id="input-root"
              v-model="v$.formAttestationSequenceThreats.root.$model"
            >
              <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
              <option value="N">{{ $t(`uiElements.text.no`) }}</option>
            </select>
            <div class="invalid-feedback">
              <div
                v-if="v$.formAttestationSequenceThreats.root.required.$invalid"
              >
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-velocity  -->
        <div class="row mb-3">
          <label for="input-velocity" class="col-sm-6 col-form-label">
            {{ $t(`Attestation.threat.table.VELOCITY_CHECK`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              :class="addClassValidInput('velocity')"
              id="input-velocity"
              v-model="v$.formAttestationSequenceThreats.velocity.$model"
            >
              <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
              <option value="N">{{ $t(`uiElements.text.no`) }}</option>
            </select>
            <div class="invalid-feedback">
              <div
                v-if="
                  v$.formAttestationSequenceThreats.velocity.required.$invalid
                "
              >
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-installSource  -->
        <div class="row mb-3">
          <label for="input-installSource" class="col-sm-6 col-form-label">
            {{ $t(`Attestation.threat.table.INSTALL_SOURCE`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              :class="addClassValidInput('installSource')"
              id="input-installSource"
              v-model="v$.formAttestationSequenceThreats.installSource.$model"
            >
              <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
              <option value="N">{{ $t(`uiElements.text.no`) }}</option>
            </select>
            <div class="invalid-feedback">
              <div
                v-if="
                  v$.formAttestationSequenceThreats.installSource.required
                    .$invalid
                "
              >
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-keyAttestationChallengeCheck  -->
        <div class="row mb-3">
          <label
            for="input-keyAttestationChallengeCheck"
            class="col-sm-6 col-form-label"
          >
            {{ $t(`Attestation.threat.table.KEY_ATTESTATION`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              :class="addClassValidInput('keyAttestationChallengeCheck')"
              id="input-keyAttestationChallengeCheck"
              v-model="
                v$.formAttestationSequenceThreats.keyAttestationChallengeCheck
                  .$model
              "
            >
              <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
              <option value="N">{{ $t(`uiElements.text.no`) }}</option>
            </select>
            <div class="invalid-feedback">
              <div
                v-if="
                  v$.formAttestationSequenceThreats.keyAttestationChallengeCheck
                    .required.$invalid
                "
              >
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  Buttons form  -->
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-outline-secondary me-3"
            @click="showModal = false"
          >
            {{ $t(`uiElements.btn.close`) }}
          </button>
          <button
            type="submit"
            class="btn btn-outline-primary"
            :disabled="v$.$invalid && v$.$dirty ? true : false"
          >
            {{ $t(`uiElements.btn.save`) }}
          </button>
        </div>
      </form>
    </template>
  </bs-modal>
  <div
    class="toast-container position-absolute p-3 top-0 end-0"
    v-if="showToast"
  >
    <bs-toast v-for="toast in toastPropsList" :key="toast" :="toast"></bs-toast>
  </div>
</template>

<script>
import TrnLoading from "@/components/Loading";
import BsModal from "@/components/UI-elements/BsModal";
import BsToast from "@/components/UI-elements/BsToast";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapState } from "vuex";
import { stringify } from "query-string";
import { actionTypes } from "@/store/modules/settings-store/attestation-sequence-threats";
import attestationSequenceThreatsApi from "@/api/settings-api/attestation";
import Multiselect from "@vueform/multiselect";
import attestationThreatsApi from "@/api/settings-api/attestation";

export default {
  name: "TrnAttestationSequenceThreatsTab",
  components: {
    TrnLoading,
    BsModal,
    BsToast,
    Multiselect,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      page: 0,
      size: 100,
      sort: {
        col: "id",
        order: "asc",
      },
      showModal: false,
      titleModal: "",
      showFormGroup: false,
      formAttestationSequenceThreats: {},
      formAttestationSequenceThreatsType: "",
      showToast: false,
      toastPropsList: [],
      selectedTableRow: null,
      multiSelectOptions: {
        attestationActionsIdList: [],
      },
    };
  },
  validations() {
    return {
      formAttestationSequenceThreats: {
        attestationActionsIdList: {
          required,
        },
        channelIntegrity: {
          required,
        },
        debug: {
          required,
        },
        emulator: {
          required,
        },
        enabled: {
          required,
        },
        geoPosition: {
          required,
        },
        integrity: {
          required,
        },
        root: {
          required,
        },
        velocity: {
          required,
        },
        installSource: {
          required,
        },
        keyAttestationChallengeCheck: {
          required,
        },
      },
    };
  },
  computed: {
    dataResp: {
      get() {
        return this.$store.state.attestationSequenceThreats.dataResp;
      },
      set(newValue) {
        this.$store.state.attestationSequenceThreats.dataResp = newValue;
      },
    },
    ...mapState({
      isLoading: (state) => state.attestationSequenceThreats.isLoading,
      isLoadingScroll: (state) =>
        state.attestationSequenceThreats.isLoadingScroll,
      dataModalResp: (state) => state.attestationSequenceThreats.dataModalResp,
      errorResp: (state) => state.attestationSequenceThreats.errorResp,
    }),
  },
  methods: {
    fetchAttestationSequenceThreats() {
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
      });
      this.$store.dispatch(actionTypes.getAttestationSequenceThreats, {
        apiParams: stringifiedParams,
      });
      attestationThreatsApi
        .getAttestationAction()
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          // console.log("result", result);
          if (result.status === 200) {
            // attestationActionsIdList
            for (const key in result.data) {
              const obj = {
                value: result.data[key].action,
                label: this.$t(
                  `Attestation.action.select.option.${result.data[key].action}`
                ),
              };
              this.multiSelectOptions.attestationActionsIdList.push(obj);
            }
          } else {
            console.log("err");
            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.err`)}`,
              bgColor: "bg-danger",
              textColor: "text-white",
            });
            this.showToast = true;
            this.showModal = false;
          }
        });
    },
    scrollTable(event) {
      const currentEl = event.target;
      let checkScrollEnd =
        currentEl.scrollTop + currentEl.clientHeight + 3 >= currentEl.scrollHeight;
      if (checkScrollEnd && this.isLoadingScroll === false) {
        const currentPage = this.page + 1;
        const totalPages = this.dataResp.totalPages;
        if (currentPage < totalPages) this.loadMoreData();
      }
    },
    loadMoreData() {
      this.page += 1;
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
      });
      this.$store.dispatch(
        actionTypes.getAttestationSequenceThreatsScrollPush,
        {
          apiParams: stringifiedParams,
        }
      );
    },
    reloadPage() {
      this.page = 0;
      this.fetchAttestationSequenceThreats();
    },
    addClassValidInput(inputName) {
      return {
        "is-invalid":
          this.v$.formAttestationSequenceThreats[inputName].$error === true &&
          this.v$.formAttestationSequenceThreats[inputName].$dirty === true,
        "is-valid":
          this.v$.formAttestationSequenceThreats[inputName].$error === false &&
          this.v$.formAttestationSequenceThreats[inputName].$dirty === true,
      };
    },
    clickTableRow(rowItem) {
      this.selectedTableRow = rowItem;
    },
    clickChangeGroupObj(type) {
      this.formAttestationSequenceThreatsType = type;
      this.showModal = true;
      this.showFormGroup = true;
      if (type === "add") {
        this.selectedTableRow = null;
        this.formAttestationSequenceThreats = {};
        this.titleModal = this.$t(`uiElements.text.add`);
      } else {
        this.titleModal = this.selectedTableRow.id;

        this.formAttestationSequenceThreats.attestationActionsIdList = [];
        for (const key in this.selectedTableRow.attestationActions) {
          this.formAttestationSequenceThreats.attestationActionsIdList.push(
            this.selectedTableRow.attestationActions[key].action
          );
        }

        this.formAttestationSequenceThreats.channelIntegrity =
          this.selectedTableRow.channelIntegrity;
        this.formAttestationSequenceThreats.debug = this.selectedTableRow.debug;
        this.formAttestationSequenceThreats.emulator =
          this.selectedTableRow.emulator;
        this.formAttestationSequenceThreats.enabled =
          this.selectedTableRow.enabled;
        this.formAttestationSequenceThreats.geoPosition =
          this.selectedTableRow.geoPosition;
        this.formAttestationSequenceThreats.integrity =
          this.selectedTableRow.integrity;
        this.formAttestationSequenceThreats.root = this.selectedTableRow.root;
        this.formAttestationSequenceThreats.velocity =
          this.selectedTableRow.velocity;
        this.formAttestationSequenceThreats.installSource =
          this.selectedTableRow.installSource;
        this.formAttestationSequenceThreats.keyAttestationChallengeCheck =
          this.selectedTableRow.keyAttestationChallengeCheck;
      }
    },
    submitChangeGroupNumber() {
      this.v$.formAttestationSequenceThreats.$touch();
      if (!this.v$.formAttestationSequenceThreats.$error) {
        this.formAttestationSequenceThreats.keyExpDate =
          this.$filters.formatDateTime(
            this.formAttestationSequenceThreats.keyExpDate,
            "yyyy-MM-dd HH:mm:ss"
          );
        if (this.formAttestationSequenceThreatsType === "add") {
          attestationSequenceThreatsApi
            .addAttestationThreatSequences({
              ...this.formAttestationSequenceThreats,
            })
            .then(function (result) {
              return result;
            })
            .catch(function (result) {
              return result;
            })
            .then((result) => {
              // console.log("result", result);
              if (result.status === 200) {
                this.showModal = false;
                this.selectedTableRow = null;
                this.formAttestationSequenceThreats = {};

                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.successSave`)}`,
                  bgColor: "bg-success",
                  textColor: "text-white",
                });
                this.showToast = true;

                this.fetchAttestationSequenceThreats();
              } else {
                console.log("err");
                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.errSave`)}`,
                  bgColor: "bg-danger",
                  textColor: "text-white",
                });
                this.showToast = true;
              }
            });
        } else {
          attestationSequenceThreatsApi
            .updateAttestationThreatSequences(this.selectedTableRow.id, {
              ...this.formAttestationSequenceThreats,
            })
            .then(function (result) {
              return result;
            })
            .catch(function (result) {
              return result;
            })
            .then((result) => {
              // console.log("result", result);
              if (result.status === 200) {
                this.showModal = false;

                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.successSave`)}`,
                  bgColor: "bg-success",
                  textColor: "text-white",
                });
                this.showToast = true;

                this.fetchAttestationSequenceThreats();
              } else {
                console.log("err");
                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.errSave`)}`,
                  bgColor: "bg-danger",
                  textColor: "text-white",
                });
                this.showToast = true;
              }
            });
        }
      }
    },
  },
  mounted() {
    this.fetchAttestationSequenceThreats();
  },
};
</script>

<style scoped>
.select-td > td {
  background-color: #0d6efd !important;
  color: white !important;
}
</style>
