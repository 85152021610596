import versionApi from "@/api/settings-api/version";

const state = {
  dataResp: null,
  dataModalResp: null,

  isLoading: false,
  isLoadingScroll: false,
  isLoadingModal: false,

  errorResp: null,
};

export const mutationTypes = {
  getVersionStart: "[Version] getVersion start",
  getVersionSuccess: "[Version] getVersion success",
  getVersionFailure: "[Version] getVersion failure",

  getVersionScrollPushStart: "[Version] getVersionScrollPush start",
  getVersionScrollPushSuccess: "[Version] getVersionScrollPushSuccess success",
  getVersionScrollPushFailure: "[Version] getVersionScrollPush failure",
};

export const actionTypes = {
  getVersion: "[Version] getVersion",
  getVersionScrollPush: "[Version] getVersionScrollPush",
};

const mutations = {
  [mutationTypes.getVersionStart](state) {
    state.isLoading = true;
    state.data = null;
  },
  [mutationTypes.getVersionSuccess](state, payload) {
    state.isLoading = false;
    state.dataResp = payload;
  },
  [mutationTypes.getVersionFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
  [mutationTypes.getVersionScrollPushStart](state) {
    state.isLoadingScroll = true;
  },
  [mutationTypes.getVersionScrollPushSuccess](state, payload) {
    for (let item in payload.content) {
      state.dataResp.content.push(payload.content[item]);
    }
    state.isLoadingScroll = false;
  },
  [mutationTypes.getVersionScrollPushFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
};

const actions = {
  [actionTypes.getVersion](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getVersionStart, apiParams);
      versionApi
        .getVersion(apiParams)
        .then((response) => {
          context.commit(mutationTypes.getVersionSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getVersionFailure,
            result.response.data.error
          );
        });
    });
  },
  [actionTypes.getVersionScrollPush](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getVersionScrollPushStart, apiParams);
      versionApi
        .getVersion(apiParams)
        .then((response) => {
          context.commit(
            mutationTypes.getVersionScrollPushSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getVersionScrollPushFailure,
            result.response.data
          );
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
