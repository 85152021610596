import axios from "@/api/axios";

const getTerminalFacility = () => {
  return axios.get("/terminal-facility/with-dictionaries");
};

const getTerminalFacilityParams = (apiParams) => {
  return axios.get("/terminal-facility/with-dictionaries?" + apiParams);
};

const addTerminalFacility = (fObj) => {
  return axios.post("/terminal-facility", { ...fObj });
};

const updateTerminalFacility = (id, fObj) => {
  return axios.put("/terminal-facility/" + id, { ...fObj });
};

const getTerminalGroupFacility = () => {
  return axios.get("terminal-group-facility/with-dictionaries");
};

const addTerminalGroupFacility = (fObj) => {
  return axios.post("/terminal-group-facility", { ...fObj });
};

const updateTerminalGroupFacility = (id, fObj) => {
  return axios.put("/terminal-group-facility/" + id, { ...fObj });
};

const getTransferTerminalGroupFacility = (id) => {
  return axios.get(
    "/terminal-group-facility/available-transfer-facility/" + id
  );
};

const deleteTerminalGroupFacility = (deleteId, transferId) => {
  return axios.delete(`/terminal-group-facility/${deleteId}/${transferId}`);
};

export default {
  getTerminalFacility,
  addTerminalFacility,
  updateTerminalFacility,
  getTerminalGroupFacility,
  getTerminalFacilityParams,
  addTerminalGroupFacility,
  updateTerminalGroupFacility,
  deleteTerminalGroupFacility,
  getTransferTerminalGroupFacility,
};
