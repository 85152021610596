import axios from "@/api/axios";

const getTerminalKeys = (apiParams) => {
  return axios.get("/term-keys?" + apiParams);
};

const getFindAllPosKeys = (apiParams) => {
  return axios.get("/term-keys/find-all-pos-keys?" + apiParams);
};

const updateTerminalKeys = (termKeyId, termKeysObj) => {
  return axios.put("/term-keys/" + termKeyId, { ...termKeysObj });
};

const runOneKeyManager = (termKeyId) => {
  return axios.get(
    "/keymanagement/check-terminal-keys?terminalId=" + termKeyId
  );
};

const runAllKeyManager = () => {
  return axios.get("/keymanagement/check-terminal-keys");
};

export default {
  getTerminalKeys,
  updateTerminalKeys,
  getFindAllPosKeys,
  runOneKeyManager,
  runAllKeyManager,
};
