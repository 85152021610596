import scheduleApi from "@/api/settings-api/schedule";

const state = {
  dataResp: null,
  dataModalResp: null,

  isLoading: false,
  isLoadingScroll: false,
  isLoadingModal: false,

  errorResp: null,
};

export const mutationTypes = {
  getScheduleStart: "[Schedule] getSchedule start",
  getScheduleSuccess: "[Schedule] getSchedule success",
  getScheduleFailure: "[Schedule] getSchedule failure",

  getScheduleScrollPushStart: "[Schedule] getScheduleScrollPush start",
  getScheduleScrollPushSuccess:
    "[Schedule] getScheduleScrollPushSuccess success",
  getScheduleScrollPushFailure: "[Schedule] getScheduleScrollPush failure",
};

export const actionTypes = {
  getSchedule: "[Schedule] getSchedule",
  getScheduleScrollPush: "[Schedule] getScheduleScrollPush",
};

const mutations = {
  [mutationTypes.getScheduleStart](state) {
    state.isLoading = true;
    state.data = null;
  },
  [mutationTypes.getScheduleSuccess](state, payload) {
    state.isLoading = false;
    state.dataResp = payload;
  },
  [mutationTypes.getScheduleFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
  [mutationTypes.getScheduleScrollPushStart](state) {
    state.isLoadingScroll = true;
  },
  [mutationTypes.getScheduleScrollPushSuccess](state, payload) {
    for (let item in payload.content) {
      state.dataResp.content.push(payload.content[item]);
    }
    state.isLoadingScroll = false;
  },
  [mutationTypes.getScheduleScrollPushFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
};

const actions = {
  [actionTypes.getSchedule](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getScheduleStart, apiParams);
      scheduleApi
        .getSchedule(apiParams)
        .then((response) => {
          context.commit(mutationTypes.getScheduleSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getScheduleFailure,
            result.response.data.error
          );
        });
    });
  },
  [actionTypes.getScheduleScrollPush](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getScheduleScrollPushStart, apiParams);
      scheduleApi
        .getSchedule(apiParams)
        .then((response) => {
          context.commit(
            mutationTypes.getScheduleScrollPushSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getScheduleScrollPushFailure,
            result.response.data
          );
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
