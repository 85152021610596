import axios from "@/api/axios";

const getAttestationThreats = (apiParams = "") => {
  return axios.get("/attestation-threats?" + apiParams);
};

const updateAttestationThreats = (reqObj) => {
  return axios.put("/attestation-threats/", reqObj);
};

const getAttestationAction = (apiParams = "") => {
  return axios.get("/attestation-actions?" + apiParams);
};

const updateAttestationAction = (reqObj) => {
  return axios.put("/attestation-actions/", reqObj);
};

const getAttestationThreatSequences = (apiParams = "") => {
  return axios.get("/attestation-threat-sequences?" + apiParams);
};

const updateAttestationThreatSequences = (id, reqObj) => {
  return axios.put("/attestation-threat-sequences/" + id, {
    ...reqObj,
  });
};

const addAttestationThreatSequences = (reqObj) => {
  return axios.post("/attestation-threat-sequences", {
    ...reqObj,
  });
};

export default {
  getAttestationThreats,
  updateAttestationThreats,
  getAttestationAction,
  updateAttestationAction,
  getAttestationThreatSequences,
  updateAttestationThreatSequences,
  addAttestationThreatSequences,
};
