<template>
  <ul class="navbar-nav mb-2 mb-lg-0">
    <li class="nav-item dropdown">
      <a
        class="nav-link dropdown-toggle"
        href="#"
        id="navbarDropdown"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <i class="bi bi-translate pe-1"></i>
        <span>{{ $t("lang." + currentLocale) }}</span>
      </a>
      <ul
        class="dropdown-menu dropdown-menu-end"
        aria-labelledby="navbarDropdown"
      >
        <li></li>
        <li
          v-for="locale in supportedLocales"
          :key="locale"
          @click="switchLocale(locale)"
        >
          <a class="dropdown-item" href="#">{{ $t("lang." + locale) }}</a>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import { setItemLocalStorage } from "@/helpers/persistanceStorage";
import langApi from "@/api/settings-api/installations";

export default {
  name: "TrnLocaleSwitcher",
  computed: {
    supportedLocales() {
      return process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(",");
    },
    currentLocale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
      }
      setItemLocalStorage("locale", locale);

      let langCode = "";
      switch (locale) {
        case "en":
          langCode = "ENG";
          break;
        case "uk":
          langCode = "UKR";
          break;
        case "ru":
          langCode = "RUS";
          break;
      }

      langApi
        .updateUserLanguage(langCode)
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          // console.log("result", result);
          if (result.status === 200) {
            console.log("success update lang");
          } else {
            console.log("err update lang");
          }
        });
    },
  },
};
</script>

<style scoped>
li {
  text-decoration: underline;
  color: #459ce7;
  cursor: pointer;
}
</style>
