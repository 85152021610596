<template>
  <div class="row g-0">
    <div class="col-md-4 my-2">
      <div class="row">
        <span class="text-black fw-bold border-0 col-md-6 pt-2 w-auto"
          >{{ $t(`RegulatoryCommissions.text.searchMerchant`) }}
          {{ $t(`Terminal.table.merchantId`) }}</span
        >
        <multiselect
          id="selectedMerchant"
          v-model="selectedMerchant"
          class="col-md-6 multiselect-blue"
          :mode="'single'"
          :placeholder="$t(`RegulatoryCommissions.text.typeText`)"
          open-direction="bottom"
          :options="dataRespListMerchant"
          :multiple="false"
          :searchable="true"
          :loading="isLoadingSelect"
          :internal-search="false"
          :clear-on-select="false"
          :close-on-select="true"
          :options-limit="300"
          :max-height="300"
          :show-no-results="false"
          :hide-selected="false"
          @search-change="changeInputMID"
          :noResultsText="$t(`uiElements.text.noDataSearch`)"
          :noOptionText="$t(`uiElements.text.listEmpty`)"
        >
        </multiselect>
      </div>
    </div>
  </div>

  <div
    class="merchant-row d-flex justify-content-between my-3 bg-info text-dark px-3 rounded-2 fw-bold"
    v-if="dataResp"
  >
    <div class="left-block">
      <span>{{ dataResp.data.balance.merchantId }} | </span>
      <span>{{ dataResp.data.balance.merchantName }}</span>
    </div>
    <div class="right-block">
      <span
        >{{ $t(`RegulatoryCommissions.table.balance.title`) }}
        {{ dataResp.data.balance.balance }}
        {{
          $t(
            `uiElements.text.currencyCode.${dataResp.data.balance.currencyCode}`
          )
        }}
      </span>
    </div>
  </div>

  <div
    class="table-responsive table-block overflow-auto"
    id="table-block"
    @scroll="scrollTable"
    v-show="dataResp"
  >
    <table
      class="table table-striped table-hover table-sm table-light table-ll"
    >
      <thead>
        <tr class="text-center">
          <th
            scope="col"
            data-col-sort="transactionId"
            @click="clickSortCol($event, 'transactionId')"
          >
            <span v-html="$t(`Transaction.table.transactionId`)"></span>
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="merchant-merchantId"
            @click="clickSortCol($event, 'merchant.merchantId')"
          >
            {{ $t(`Terminal.table.merchantId`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="shipmentReportId"
            @click="clickSortCol($event, 'shipmentReportId')"
          >
            {{ $t(`RegulatoryCommissions.table.accrual.shipmentReportId`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="total"
            @click="clickSortCol($event, 'total')"
          >
            {{ $t(`RegulatoryCommissions.table.transaction.total`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="paymentDetails"
            @click="clickSortCol($event, 'paymentDetails')"
          >
            {{ $t(`RegulatoryCommissions.table.balance.paymentDetails`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="ibanPayer"
            @click="clickSortCol($event, 'ibanPayer')"
          >
            {{ $t(`RegulatoryCommissions.table.balance.ibanPayer`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="ibanReceiver"
            @click="clickSortCol($event, 'ibanReceiver')"
          >
            {{ $t(`RegulatoryCommissions.table.balance.ibanReceiver`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="status"
            @click="clickSortCol($event, 'status')"
          >
            {{ $t(`RegularReports.activeTerminals.table.status`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="operationType"
            @click="clickSortCol($event, 'operationType')"
          >
            {{ $t(`RegulatoryCommissions.table.balance.operationType`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="dateTransferred"
            @click="clickSortCol($event, 'dateTransferred')"
          >
            {{ $t(`RegulatoryCommissions.table.transaction.dateTransferred`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>
        </tr>
      </thead>
      <tbody class="text-nowrap">
        <template v-if="isLoading">
          <tr>
            <td colspan="16">
              <div class="loading text-center">
                <trn-loading></trn-loading>
              </div>
            </td>
          </tr>
        </template>
        <template
          v-if="dataResp?.data.outcomingTransactions.content.length === 0"
        >
          <tr>
            <td colspan="16">
              <div class="text-center fw-bold bg-info text-black">
                {{ $t(`uiElements.text.noData`) }}
              </div>
            </td>
          </tr>
        </template>
        <template v-if="dataResp">
          <tr
            v-for="item in dataResp.data.outcomingTransactions.content"
            :key="item"
            class=""
          >
            <td class="text-center">{{ item.transactionId }}</td>
            <td>{{ item.merchantId }}</td>
            <td>{{ item.generalFinBookId }}</td>
            <td class="text-end">
              {{ item.total }}
              {{ $t(`uiElements.text.currencyCode.${item.currencyCode}`) }}
            </td>
            <td>{{ item.paymentDetails }}</td>
            <td>{{ item.ibanPayer }}</td>
            <td>{{ item.ibanReceiver }}</td>
            <td>{{ item.status }}</td>
            <td>{{ item.operationType }}</td>
            <td>{{ $filters.formatDateTime(item.dateTransferred) }}</td>
          </tr>
          <template v-if="isLoadingScroll">
            <tr>
              <td colspan="16">
                <div class="loading text-center">
                  <trn-loading></trn-loading>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>

  <div class="toast-container position-fixed p-3 top-0 end-0" v-if="showToast">
    <bs-toast v-for="toast in toastPropsList" :key="toast" :="toast"></bs-toast>
  </div>
</template>

<script>
import TrnLoading from "@/components/Loading";
import BsToast from "@/components/UI-elements/BsToast";
import { stringify } from "query-string";
import regulatoryCommissionsApi from "@/api/regulatory-commissions";
import Multiselect from "@vueform/multiselect";

export default {
  name: "TrnRegulatoryCommissionsBalance",
  components: {
    TrnLoading,
    BsToast,
    Multiselect,
  },
  data() {
    return {
      page: 0,
      size: 50,
      sort: {
        col: "dateTransferred",
        order: "asc",
      },
      dataResp: null,
      isLoading: false,
      isLoadingScroll: false,
      filters: {
        merchantId: [],
      },
      showToast: false,
      toastPropsList: [],

      selectedMerchant: [],
      dataRespListMerchant: [],
      isLoadingSelect: false,
      inputClearTimeoutID: null,
    };
  },
  methods: {
    fetchBalanceByMerchant() {
      this.isLoading = true;
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
      });
      regulatoryCommissionsApi
        .getBalanceByMerchant(this.filters.merchantId, stringifiedParams)
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          // console.log("result", result);
          if (result.status === 200) {
            this.dataResp = result.data;
            this.isLoading = false;
            this.changeArrowIcon();
          } else {
            console.log("err");
            this.isLoading = false;
            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.err`)}`,
              bgColor: "bg-danger",
              textColor: "text-white",
            });
            this.showToast = true;
          }
        });
    },
    scrollTable(event) {
      const currentEl = event.target;
      let checkScrollEnd =
        currentEl.scrollTop + currentEl.clientHeight + 3 >= currentEl.scrollHeight;
      if (checkScrollEnd && this.isLoadingScroll === false) {
        const currentPage = this.page + 1;
        const totalPages = this.dataResp.totalPages;
        if (currentPage < totalPages) this.loadMoreData();
      }
    },
    loadMoreData() {
      this.isLoadingScroll = true;
      this.page += 1;
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
        ...this.filters,
      });
      regulatoryCommissionsApi
        .getBalanceByMerchant(stringifiedParams)
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          if (result.status === 200) {
            for (let item in result.data.data.content) {
              this.dataResp.content.push(result.data.data.content[item]);
            }
            this.isLoadingScroll = false;
          } else {
            console.log("err");
            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.err`)}`,
              bgColor: "bg-danger",
              textColor: "text-white",
            });
            this.showToast = true;
            this.isLoadingScroll = false;
          }
        });
    },
    clickSortCol(event, newSortItem) {
      const newSortItemData = newSortItem;
      newSortItem = newSortItem.replaceAll(".", "-");
      const scrollTableEl = document.getElementById("table-block");
      const beforeSortKey = this.sort.col.replaceAll(".", "-");
      const beforeSortDirection = this.sort.order;

      const beforeEl = document.querySelectorAll(
        `[data-col-sort~=${beforeSortKey}]`
      );

      let child_index = 0;
      if (beforeEl[0].children.length > 1) {
        child_index = 1;
      }
      beforeEl[0].children[child_index].innerHTML =
        '<i class="bi bi-arrow-down-up"></i>';

      // let newSortVal = [newSortItem, ""];

      if (beforeSortDirection === "desc" && beforeSortKey === newSortItem) {
        this.sort.order = "asc";
      } else {
        this.sort.order = "desc";
      }
      this.page = 0;
      this.sort.col = newSortItemData;

      scrollTableEl.scrollTo(0, 0);
      this.fetchDataReport();
    },
    changeArrowIcon(sortKey = this.sort.col, sortDirection = this.sort.order) {
      sortKey = sortKey.replaceAll(".", "-");
      const targetEl = document.querySelectorAll(`[data-col-sort~=${sortKey}]`);
      let child_index = 0;
      if (targetEl[0].children.length > 1) {
        child_index = 1;
      }
      if (sortDirection === "asc") {
        targetEl[0].children[child_index].innerHTML =
          '<i class="bi bi-arrow-up-square"></i>';
      } else {
        targetEl[0].children[child_index].innerHTML =
          '<i class="bi bi-arrow-down-square"></i>';
      }
    },
    isTransferredIcon(val) {
      if (val) {
        return '<i class="bi bi-check fs-3 text-success"></i>';
      } else {
        return "is waiting";
      }
    },
    changeInputMID(inputVal) {
      if (inputVal) {
        this.dataResp = null;
        this.isLoadingSelect = true;
        this.dataRespListMerchant = [];
        if (this.inputClearTimeoutID) {
          clearTimeout(this.inputClearTimeoutID);
        }
        this.inputClearTimeoutID = setTimeout(() => {
          regulatoryCommissionsApi
            .searchMerchantByBalance(inputVal)
            .then(function (result) {
              return result;
            })
            .catch(function (result) {
              return result;
            })
            .then((result) => {
              // console.log("result", result);
              if (result.status === 200) {
                if (result.data.length > 0) {
                  for (let index in result.data) {
                    // const optObj = {
                    //   name: result.data[index],
                    //   id: result.data[index],
                    // };
                    this.dataRespListMerchant.push(result.data[index]);
                  }
                }

                this.isLoadingSelect = false;
              } else {
                // console.log("err_");
                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.err`)}`,
                  bgColor: "bg-danger",
                  textColor: "text-white",
                });
                this.showToast = true;
                this.isLoadingSelect = false;
              }
            });
        }, 1000);
      }
    },
  },
  watch: {
    selectedMerchant() {
      if (this.selectedMerchant) {
        this.filters.merchantId = this.selectedMerchant;
        this.fetchBalanceByMerchant();
      }
    },
  },
  mounted() {},
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect-blue {
  --ms-option-bg-pointed: #ffffff;
  --ms-option-color-pointed: #1f2937;
  --ms-option-bg-selected: #0d6efd;
  --ms-option-color-selected: #ffffff;
  --ms-option-bg-selected-pointed: #2f6fcd;
  --ms-option-color-selected-pointed: #ffffff;
}
</style>
