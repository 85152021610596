import axios from "@/api/axios";

const getAnalyticsMonthly = (apiParams) => {
  return axios.get("/transactions/analytics/admin/monthly?" + apiParams);
};

const getAnalyticsDaily = (apiParams) => {
  return axios.get("/transactions/analytics/admin/daily?" + apiParams );
};

export default {
  getAnalyticsMonthly,
  getAnalyticsDaily,
};
