<template>
  <div class="title-table mb-2 ps-3 py-1 fw-bold">
    {{ $t(`left-menu.message`) }}
  </div>
  <div class="container-fluid msg-container">
    <div class="row">
      <!--   col merchant   -->
      <div class="col-md-6 my-3">
        <div class="row">
          <!--    merchant table      -->
          <div class="col-md-8">
            <div class="border">
              <div class="w-100 p-2 mb-2 bg-primary text-white">
                <span class="">{{
                  $t(`Message.blockTitle.searchMerchant`)
                }}</span>
              </div>
              <div
                class="table-responsive table-block overflow-auto"
                @scroll="scrollTableMerchant"
              >
                <table
                  class="table table-striped table-hover table-sm table-light table-bordered table-sm"
                >
                  <thead>
                    <tr class="text-center">
                      <th scope="col">
                        {{ $t(`Merchant.table.merchantId`) }}
                        <input
                          type="text"
                          class="form-control my-1 form-control-sm"
                          form="frm-merchant"
                          id="frm-merchantId"
                          v-model="merchantReq.filters.merchantId"
                          @input="fetchMerchant"
                        />
                      </th>

                      <th scope="col">
                        {{ $t(`Merchant.table.merchantName`) }}
                        <input
                          type="text"
                          class="form-control my-1 form-control-sm"
                          form="frm-merchant"
                          id="frm-merchantName"
                          v-model="merchantReq.filters.merchantName"
                          @input="fetchMerchant"
                        />
                      </th>

                      <th scope="col">
                        {{ $t(`Merchant.table.mcc`) }}
                        <input
                          type="text"
                          class="form-control my-1 form-control-sm"
                          form="frm-merchant"
                          id="frm-mcc"
                          v-model="merchantReq.filters.mcc"
                          @input="fetchMerchant"
                        />
                      </th>

                      <th scope="col">
                        {{ $t(`Merchant.table.merchantLocation`) }}
                        <input
                          type="text"
                          class="form-control my-1 form-control-sm"
                          form="frm-merchant"
                          id="frm-merchantLocation"
                          v-model="merchantReq.filters.merchantLocation"
                          @input="fetchMerchant"
                        />
                      </th>

                      <th scope="col">
                        {{ $t(`Merchant.table.bankName`) }}
                        <input
                          type="text"
                          class="form-control my-1 form-control-sm"
                          form="frm-merchant"
                          id="frm-bankName"
                          v-model="merchantReq.filters['bank.name']"
                          @input="fetchMerchant"
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="isLoadingMerchant">
                      <tr>
                        <td colspan="5">
                          <div class="loading text-center">
                            <trn-loading></trn-loading>
                          </div>
                        </td>
                      </tr>
                    </template>
                    <template v-if="dataRespMerchant">
                      <tr
                        v-for="item in dataRespMerchant.content"
                        :key="item"
                        class="text-center cursor-pointer"
                        @click="clickRowMerchantId(item)"
                      >
                        <td>{{ item.merchantId }}</td>
                        <td>{{ item.merchantName }}</td>
                        <td>{{ item.mcc }}</td>
                        <td>{{ item.merchantLocation }}</td>
                        <td>{{ item.bank.name }}</td>
                      </tr>
                      <template v-if="isLoadingMerchantScroll">
                        <tr>
                          <td colspan="3">
                            <div class="loading text-center">
                              <trn-loading></trn-loading>
                            </div>
                          </td>
                        </tr>
                      </template>
                    </template>
                  </tbody>
                </table>
              </div>
              <div class="d-flex justify-content-end m-2">
                <button
                  class="btn btn-secondary"
                  @click="clickSelectAllMerchant"
                >
                  {{ $t(`Message.btn.selectAll`) }}
                </button>
              </div>
            </div>
          </div>
          <!--    merchant selected      -->
          <div class="col-md-4">
            <div class="selected-block">
              <div class="w-100 p-2 mb-2 bg-primary text-white">
                <span class="">{{
                  $t(`Message.blockTitle.selectedMerchant`)
                }}</span>
              </div>
              <div class="border selected-btn-group overflow-scroll h-100">
                <div
                  class="btn-group w-100 my-1 px-2"
                  role="group"
                  v-for="item in dataSelectMerchantList"
                  :key="item"
                >
                  <button
                    type="button"
                    class="btn btn-primary col-10 text-start"
                  >
                    {{ item.merchantId }}
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger px-1 col-2"
                    @click="removeSelectedMerchantItem(item)"
                  >
                    <i class="bi bi-x-circle"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--   col terminal   -->
      <div class="col-md-6 my-3">
        <div class="row">
          <!--    terminal table      -->
          <div class="col-md-8">
            <div class="border">
              <div class="w-100 p-2 mb-2 bg-primary text-white">
                <span class="">{{
                  $t(`Message.blockTitle.searchTerminal`)
                }}</span>
              </div>
              <div class="table-responsive table-block overflow-auto">
                <table
                  class="table table-striped table-hover table-sm table-light table-bordered table-sm"
                >
                  <thead>
                    <tr class="text-center">
                      <th scope="col">
                        {{ $t(`Terminal.table.terminalId`) }}
                        <input
                          type="text"
                          class="form-control"
                          form="frm-terminal"
                          id="frm-terminalId"
                          v-model="terminalReq.filters.terminalId"
                          @input="fetchMerchant"
                        />
                      </th>

                      <th scope="col">
                        {{ $t(`Terminal.table.status`) }}
                        <input
                          type="text"
                          class="form-control"
                          form="frm-terminal"
                          id="frm-terminalId"
                          v-model="terminalReq.filters.status"
                          @input="fetchMerchant"
                        />
                      </th>

                      <th scope="col">
                        {{ $t(`Terminal.table.groupNumber`) }}
                        <input
                          type="text"
                          class="form-control"
                          form="frm-terminal"
                          id="frm-terminalId"
                          v-model="terminalReq.filters.groupNumber"
                          @input="fetchMerchant"
                        />
                      </th>

                      <th scope="col">
                        {{ $t(`Merchant.table.merchantLocation`) }}
                        <input
                          type="text"
                          class="form-control"
                          form="frm-terminal"
                          id="frm-terminalId"
                          v-model="
                            terminalReq.filters['merchant.merchantLocation']
                          "
                          @input="fetchMerchant"
                        />
                      </th>

                      <th scope="col">
                        {{ $t(`Terminal.table.currencyCode`) }}
                        <input
                          type="text"
                          class="form-control"
                          form="frm-terminal"
                          id="frm-terminalId"
                          v-model="terminalReq.filters['currency.letterCode']"
                          @input="fetchMerchant"
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="isLoadingTerminal">
                      <tr>
                        <td colspan="5">
                          <div class="loading text-center">
                            <trn-loading></trn-loading>
                          </div>
                        </td>
                      </tr>
                    </template>
                    <template v-if="dataRespTerminal">
                      <tr
                        v-for="item in dataRespTerminalFilteredItems"
                        :key="item"
                        class="text-center cursor-pointer"
                        @click="clickRowTerminalId(item)"
                      >
                        <td>{{ item.terminalId }}</td>
                        <td>{{ item.status }}</td>
                        <td>{{ item.groupNumber }}</td>
                        <td>{{ item.merchant.merchantLocation }}</td>
                        <td>{{ item.currency.letterCode }}</td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
              <div class="d-flex justify-content-end m-2">
                <button
                  class="btn btn-secondary"
                  @click="clickSelectAllTerminal"
                  :disabled="dataRespTerminal.length === 0"
                >
                  {{ $t(`Message.btn.selectAll`) }}
                </button>
              </div>
            </div>
          </div>
          <!--    terminal selected      -->
          <div class="col-md-4">
            <div class="selected-block">
              <div class="w-100 p-2 mb-2 bg-primary text-white">
                <span class="">{{
                  $t(`Message.blockTitle.selectedTerminal`)
                }}</span>
              </div>
              <div class="border selected-btn-group overflow-scroll h-100">
                <div
                  class="btn-group w-100 my-1 px-2"
                  role="group"
                  v-for="item in dataSelectTerminalList"
                  :key="item"
                >
                  <button
                    type="button"
                    class="btn btn-primary col-10 text-start"
                  >
                    {{ item.terminalId }}
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger px-1 col-2"
                    @click="removeSelectedTerminalItem(item)"
                  >
                    <i class="bi bi-x-circle"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col text-end">
        <button
          class="btn btn-success"
          :disabled="dataSelectTerminalList.length === 0"
          @click="clickCreateMessage"
        >
          {{ $t(`Message.btn.createMess`) }}
        </button>
      </div>
    </div>
  </div>

  <bs-modal
    :modal-toggle-emit="showModal"
    :title="titleModal"
    modal-dialog-width="modal-xl"
    @hideMainModal="showModal = false"
  >
    <div class="row">
      <!--    template items      -->
      <div class="col-md-6">
        <div class="row">
          <div class="com-12">
            <div
              class="table-responsive table-block overflow-auto"
              @scroll="scrollTableTemplate"
            >
              <table
                class="table table-striped table-hover table-sm table-light table-bordered table-sm"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">
                      {{ $t(`Message.blockTemplate.text`) }}
                    </th>
                    <th scope="col">
                      {{ $t(`Message.blockTemplate.action`) }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="isLoadingTemplate">
                    <tr>
                      <td colspan="3">
                        <div class="loading text-center">
                          <trn-loading></trn-loading>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-if="dataRespTemplate">
                    <tr
                      v-for="(item, index) in dataRespTemplate.content"
                      :key="item"
                      class="cursor-pointer"
                    >
                      <td @click="clickTemplateText(item)">{{ index + 1 }}</td>
                      <td @click="clickTemplateText(item)">{{ item.text }}</td>
                      <td>
                        <button
                          class="btn btn-danger btn-sm"
                          @click="clickDeleteTemplateText(item)"
                        >
                          <i class="bi bi-x-circle"></i>
                        </button>
                      </td>
                    </tr>
                    <template v-if="isLoadingTemplateScroll">
                      <tr>
                        <td colspan="3">
                          <div class="loading text-center">
                            <trn-loading></trn-loading>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!--    template textarea      -->
      <div class="col-md-6">
        <div class="m-1">
          <label for="textTemplate" class="form-label"
            >{{ $t(`Message.form.text`) }} ->
            {{ $t(`Message.form.countText`, { num: countTextAreaTemplate }) }}
          </label>
          <textarea
            class="form-control"
            :class="addClassValidInput('textAreaTemplate')"
            id="textTemplate"
            v-model="v$.textAreaTemplate.$model"
            rows="10"
          ></textarea>
          <div class="invalid-feedback">
            <div v-if="v$.textAreaTemplate.required.$invalid">
              {{ $t(`Validations.required`) }}
            </div>
            <div v-if="v$.textAreaTemplate.maxLength.$invalid">
              {{ $t(`Validations.maxLength`, { num: 255 }) }}
            </div>
            <div v-if="v$.textAreaTemplate.minLength.$invalid">
              {{ $t(`Validations.minLength`, { num: 1 }) }}
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="d-flex justify-content-end my-3">
            <button
              class="btn btn-secondary"
              :disabled="dataSelectTerminalList.length === 0"
              @click="textAreaTemplate = ''"
            >
              {{ $t(`Message.btn.clearText`) }}
            </button>
            <button
              class="btn btn-warning mx-3"
              @click="clickSaveTemplateText"
              ref="saveTemplate"
              :disabled="!!(v$.$invalid && v$.$dirty)"
            >
              {{ $t(`Message.btn.saveText`) }}
            </button>
            <button
              class="btn btn-success"
              ref="sendMess"
              :disabled="dataSelectTerminalList.length === 0"
              @click="sendMessage"
            >
              {{ $t(`Message.btn.send`) }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </bs-modal>

  <div
    class="toast-container position-absolute p-3 end-0 z-index-1500"
    v-if="showToast"
  >
    <bs-toast v-for="toast in toastPropsList" :key="toast" :="toast"></bs-toast>
  </div>
</template>

<script>
import TrnLoading from "@/components/Loading";
import messageApi from "@/api/message";
import merchantApi from "@/api/merchant";
import terminalApi from "@/api/terminal";
import BsToast from "@/components/UI-elements/BsToast";
import { stringify } from "query-string";
import useVuelidate from "@vuelidate/core";
import { maxLength, minLength, required } from "@vuelidate/validators";
import BsModal from "@/components/UI-elements/BsModal";

export default {
  name: "TrnMessage",
  setup: () => ({ v$: useVuelidate() }),
  components: {
    TrnLoading,
    BsToast,
    BsModal,
  },
  validations() {
    return {
      textAreaTemplate: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(255),
      },
    };
  },
  data() {
    return {
      templateReq: {
        page: 0,
        size: 10,
        sort: {
          col: "id",
          order: "desc",
        },
      },
      merchantReq: {
        page: 0,
        size: 100,
        sort: {
          col: "merchantId",
          order: "desc",
        },
        filters: {
          merchantId: "",
          merchantName: "",
          mcc: "",
          merchantLocation: "",
          "bank.name": "",
        },
      },
      terminalReq: {
        list: [],
        filters: {
          terminalId: "",
          status: "",
          groupNumber: "",
          "merchant.merchantLocation": "",
          "currency.letterCode": "",
        },
      },
      showModal: false,
      titleModal: "",
      showToast: false,
      toastPropsList: [],

      isLoadingTemplate: true,
      isLoadingTemplateScroll: false,
      dataRespTemplate: null,

      isLoadingMerchant: true,
      isLoadingMerchantScroll: false,
      dataRespMerchant: null,

      isLoadingTerminal: false,
      dataRespTerminal: [],

      textAreaTemplate: "",

      dataSelectMerchantList: [],
      dataSelectTerminalList: [],
    };
  },
  computed: {
    countTextAreaTemplate: function () {
      return this.textAreaTemplate.length;
    },
    dataRespTerminalFilteredItems() {
      return this.dataRespTerminal.filter((item) => {
        let terminalId, status, groupNumber, merchantLocation, currencyCode;
        if (this.terminalReq.filters.terminalId === "") {
          terminalId = true;
        } else {
          terminalId =
            item.terminalId
              .toLowerCase()
              .indexOf(this.terminalReq.filters.terminalId.toLowerCase()) > -1;
        }

        if (this.terminalReq.filters.status === "") {
          status = true;
        } else {
          status =
            item.status
              .toLowerCase()
              .indexOf(this.terminalReq.filters.status.toLowerCase()) > -1;
        }

        if (this.terminalReq.filters.groupNumber === "") {
          groupNumber = true;
        } else {
          groupNumber =
            item.groupNumber
              .toString()
              .toLowerCase()
              .indexOf(this.terminalReq.filters.groupNumber.toLowerCase()) > -1;
        }

        if (this.terminalReq.filters["merchant.merchantLocation"] === "") {
          merchantLocation = true;
        } else {
          merchantLocation =
            item.merchant.merchantLocation
              .toLowerCase()
              .indexOf(
                this.terminalReq.filters[
                  "merchant.merchantLocation"
                ].toLowerCase()
              ) > -1;
        }

        if (this.terminalReq.filters["currency.letterCode"] === "") {
          currencyCode = true;
        } else {
          currencyCode =
            item.currency.letterCode
              .toLowerCase()
              .indexOf(
                this.terminalReq.filters["currency.letterCode"].toLowerCase()
              ) > -1;
        }
        return (
          terminalId &&
          status &&
          groupNumber &&
          merchantLocation &&
          currencyCode
        );
      });
    },
  },
  methods: {
    addClassValidInput(inputName) {
      return {
        "is-invalid":
          this.v$[inputName].$error === true &&
          this.v$[inputName].$dirty === true,
        "is-valid":
          this.v$[inputName].$error === false &&
          this.v$[inputName].$dirty === true,
      };
    },
    fetchTemplate() {
      const stringifiedParams = stringify({
        page: this.templateReq.page,
        size: this.templateReq.size,
        sort: `${this.templateReq.sort.col},${this.templateReq.sort.order}`,
      });
      messageApi
        .getMessageTemplate(stringifiedParams)
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          // console.log("result", result);
          if (result.status === 200) {
            this.isLoadingTemplate = false;
            this.dataRespTemplate = result.data;
          } else {
            console.log("err");
            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.err`)}`,
              bgColor: "bg-danger",
              textColor: "text-white",
            });
            this.showToast = true;
          }
        });
    },
    fetchMerchant() {
      const stringifiedParams = stringify({
        page: this.merchantReq.page,
        size: this.merchantReq.size,
        sort: `${this.merchantReq.sort.col},${this.merchantReq.sort.order}`,
        ...this.merchantReq.filters,
      });
      merchantApi
        .getMerchant(stringifiedParams)
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          // console.log("result", result);
          if (result.status === 200) {
            this.isLoadingMerchant = false;
            this.dataRespMerchant = null;
            this.dataRespMerchant = result.data;
          } else {
            console.log("err");
            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.err`)}`,
              bgColor: "bg-danger",
              textColor: "text-white",
            });
            this.showToast = true;
          }
        });
    },
    fetchTerminal(selectedMerchant) {
      this.isLoadingTerminal = true;
      terminalApi
        .findTerminalByMerchantIds({
          merchantIds: selectedMerchant,
        })
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          // console.log("result", result);
          if (result.status === 200) {
            if (result.data) {
              for (let index in result.data) {
                this.dataRespTerminal.push(result.data[index]);
              }
            }
            this.isLoadingTerminal = false;
          } else {
            console.log("err");
            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.err`)}`,
              bgColor: "bg-danger",
              textColor: "text-white",
            });
            this.showToast = true;
            this.isLoadingTerminal = false;
          }
        });
    },
    scrollTableTemplate(event) {
      const currentEl = event.target;
      let checkScrollEnd =
        currentEl.scrollTop + currentEl.clientHeight + 3 >= currentEl.scrollHeight;
      if (checkScrollEnd && this.isLoadingTemplateScroll === false) {
        const currentPage = this.templateReq.page + 1;
        const totalPages = this.dataRespTemplate.totalPages;
        if (currentPage < totalPages) this.loadMoreDataTemplate();
      }
    },
    loadMoreDataTemplate() {
      this.page += 1;
      const stringifiedParams = stringify({
        page: this.templateReq.page,
        size: this.templateReq.size,
        sort: `${this.templateReq.sort.col},${this.templateReq.sort.order}`,
      });
      this.isLoadingTemplateScroll = true;
      messageApi
        .getMessageTemplate(stringifiedParams)
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          // console.log("result", result);
          if (result.status === 200) {
            for (let item in result.data.content) {
              this.dataRespTemplate.content.push(result.data.content[item]);
            }
            this.isLoadingTemplateScroll = false;
          } else {
            console.log("err");
            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.err`)}`,
              bgColor: "bg-danger",
              textColor: "text-white",
            });
            this.showToast = true;
            this.isLoadingTemplateScroll = false;
          }
        });
    },
    scrollTableMerchant(event) {
      const currentEl = event.target;
      let checkScrollEnd =
        currentEl.scrollTop + currentEl.clientHeight + 3 >= currentEl.scrollHeight;
      if (checkScrollEnd && this.isLoadingMerchantScroll === false) {
        const currentPage = this.merchantReq.page + 1;
        const totalPages = this.dataRespMerchant.totalPages;
        if (currentPage < totalPages) {
          this.loadMoreDataMerchant();
        }
      }
    },
    loadMoreDataMerchant() {
      this.page += 1;
      const stringifiedParams = stringify({
        page: this.merchantReq.page,
        size: this.merchantReq.size,
        sort: `${this.merchantReq.sort.col},${this.merchantReq.sort.order}`,
        ...this.merchantReq.filters,
      });
      this.isLoadingMerchantScroll = true;
      merchantApi
        .getMerchant(stringifiedParams)
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          // console.log("result", result);
          if (result.status === 200) {
            for (let item in result.data.content) {
              this.dataRespMerchant.content.push(result.data.content[item]);
            }
            this.isLoadingMerchantScroll = false;
          } else {
            console.log("err");
            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.err`)}`,
              bgColor: "bg-danger",
              textColor: "text-white",
            });
            this.showToast = true;
            this.isLoadingMerchantScroll = false;
          }
        });
    },
    clickTemplateText(item) {
      this.textAreaTemplate = item.text;
    },
    clickSaveTemplateText() {
      this.v$.textAreaTemplate.$touch();
      if (!this.v$.textAreaTemplate.$error) {
        this.$refs.saveTemplate.disabled = true;
        messageApi
          .addMessageTemplate({
            text: this.textAreaTemplate,
          })
          .then(function (result) {
            return result;
          })
          .catch(function (result) {
            return result;
          })
          .then((result) => {
            // console.log("result", result);
            if (result.status === 200) {
              this.toastPropsList.push({
                content: `${this.$t(`uiElements.toast.successSave`)}`,
                bgColor: "bg-success",
                textColor: "text-white",
              });
              this.showToast = true;
              this.fetchTemplate();
              this.$refs.saveTemplate.disabled = false;
            } else {
              console.log("err");
              this.toastPropsList.push({
                content: `${this.$t(`uiElements.toast.err`)}`,
                bgColor: "bg-danger",
                textColor: "text-white",
              });
              this.showToast = true;
              this.$refs.saveTemplate.disabled = false;
            }
          });
      }
    },
    clickDeleteTemplateText(item) {
      messageApi
        .deleteMessageTemplate(item.id)
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          // console.log("result", result);
          if (result.status === 200) {
            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.success`)}`,
              bgColor: "bg-success",
              textColor: "text-white",
            });
            this.showToast = true;
            this.page = 0;
            this.fetchTemplate();
          } else {
            console.log("err");
            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.err`)}`,
              bgColor: "bg-danger",
              textColor: "text-white",
            });
            this.showToast = true;
          }
        });
    },
    clickRowMerchantId(merchant) {
      let uItem = true;
      this.dataSelectMerchantList.map(function (ele) {
        if (ele === merchant) {
          uItem = false;
        }
        return ele;
      });
      if (uItem) {
        this.dataSelectMerchantList.push(merchant);
        this.fetchTerminal([merchant.merchantId]);
      } else {
        this.toastPropsList.push({
          content: `${this.$t(`Message.toast.itemUnique`)}`,
          bgColor: "bg-danger",
          textColor: "text-white",
        });
        this.showToast = true;
      }
    },
    clickSelectAllMerchant() {
      let merchantList = [].concat(
        this.dataSelectMerchantList,
        this.dataRespMerchant.content
      );
      let selectedBlockSet = new Set(merchantList);
      this.dataSelectMerchantList = [...selectedBlockSet];

      let fetchTerminalArr = [];
      this.dataSelectMerchantList.forEach(function (item) {
        fetchTerminalArr.push(item.merchantId);
      });
      this.fetchTerminal(fetchTerminalArr);
    },
    clickSelectAllTerminal() {
      let terminalList = [].concat(
        this.dataSelectTerminalList,
        this.dataRespTerminalFilteredItems
      );
      let selectedBlockSet = new Set(terminalList);
      this.dataSelectTerminalList = [...selectedBlockSet];
    },
    clickRowTerminalId(terminal) {
      let uItem = true;
      this.dataSelectTerminalList.map(function (ele) {
        if (ele === terminal) {
          uItem = false;
        }
        return ele;
      });
      if (uItem) {
        this.dataSelectTerminalList.push(terminal);
      } else {
        this.toastPropsList.push({
          content: `${this.$t(`Message.toast.itemUnique`)}`,
          bgColor: "bg-danger",
          textColor: "text-white",
        });
        this.showToast = true;
      }
    },
    removeSelectedMerchantItem(value) {
      this.dataSelectMerchantList = this.dataSelectMerchantList.filter(
        function (ele) {
          return ele !== value;
        }
      );
      this.dataRespTerminal = this.dataRespTerminal.filter(function (ele) {
        return ele.merchant.merchantId !== value.merchantId;
      });
      this.dataSelectTerminalList = this.dataSelectTerminalList.filter(
        function (ele) {
          return ele.merchant.merchantId !== value.merchantId;
        }
      );
    },
    removeSelectedTerminalItem(value) {
      this.dataSelectTerminalList = this.dataSelectTerminalList.filter(
        function (ele) {
          return ele !== value;
        }
      );
    },
    clickCreateMessage() {
      this.showModal = true;
      this.titleModal = this.$t(`Message.btn.createMess`);
    },
    sendMessage() {
      this.v$.textAreaTemplate.$touch();
      if (!this.v$.textAreaTemplate.$error) {
        this.$refs.sendMess.disabled = true;

        let terminalList = [];
        this.dataSelectTerminalList.forEach(function (item) {
          terminalList.push(item.terminalId);
        });

        messageApi
          .sendMessage({
            allTerminal: false,
            terminalIdList: terminalList,
            text: this.textAreaTemplate,
          })
          .then(function (result) {
            return result;
          })
          .catch(function (result) {
            return result;
          })
          .then((result) => {
            // console.log("result", result);
            if (result.status === 200) {
              this.dataRespTerminal = [];
              this.dataSelectTerminalList = [];
              this.dataSelectMerchantList = [];

              this.showModal = false;

              this.toastPropsList.push({
                content: `${this.$t(`uiElements.toast.sendSuccess`)}`,
                bgColor: "bg-success",
                textColor: "text-white",
              });
              this.showToast = true;

              this.$refs.sendMess.disabled = false;
            } else {
              console.log("err");
              this.toastPropsList.push({
                content: `${this.$t(`uiElements.toast.err`)}`,
                bgColor: "bg-danger",
                textColor: "text-white",
              });
              this.showToast = true;
              this.$refs.sendMess.disabled = false;
            }
          });
      }
    },
  },
  mounted() {
    this.fetchTemplate();
    this.fetchMerchant();
  },
};
</script>

<style scoped>
.table-block {
  min-height: 200px;
  max-height: 700px;
}

.selected-btn-group {
  min-height: 200px;
  max-height: 700px;
}

.msg-container {
  height: calc(100vh - 112px);
}
</style>
