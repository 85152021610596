<template>
  <form class="needs-validation" @submit.prevent="submitFormGroup" novalidate>
    <!--  input-groupName  -->
    <div class="row mb-3">
      <label for="input-groupName" class="col-sm-6 col-form-label">
        {{ $t(`TerminalGroups.table.groupName`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <input
          type="text"
          class="form-control"
          :class="addClassValidInput('groupName')"
          id="input-groupName"
          v-model="v$.formGroup.groupName.$model"
        />
        <div class="invalid-feedback">
          <div v-if="v$.formGroup.groupName.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-status  -->
    <div class="row mb-3">
      <label for="input-status" class="col-sm-6 col-form-label">
        {{ $t(`TerminalGroups.table.status`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <select
          class="form-select"
          :class="addClassValidInput('status')"
          id="input-status"
          v-model="v$.formGroup.status.$model"
        >
          <option value="ACTIVE">ACTIVE</option>
          <option value="INACTIVE">INACTIVE</option>
        </select>
        <div class="invalid-feedback">
          <div v-if="v$.formGroup.status.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-currencyCode  -->
    <div class="row mb-3">
      <label for="input-currencycode" class="col-sm-6 col-form-label">
        {{ $t(`TerminalGroups.table.currencyCode`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <multiselect
          v-model="v$.formGroup.currencyCode.$model"
          :options="multiSelectOptions.allCurrencies"
          class="multiselect-blue"
          :class="addClassValidInput('currencyCode')"
          id="input-currencycode"
          mode="single"
          :close-on-select="true"
          :hide-selected="false"
          :disabled="actionType === 'Update'"
          :object="false"
        >
        </multiselect>
        <div class="invalid-feedback">
          <div v-if="v$.formGroup.currencyCode.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-receiptTemplateId  -->
    <div class="row mb-3">
      <label for="input-receiptTemplateId" class="col-sm-6 col-form-label">
        {{ $t(`TerminalGroups.table.receiptTemplateId`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <select
          class="form-select"
          :class="addClassValidInput('receiptTemplateId')"
          id="input-receiptTemplateId"
          v-model="v$.formGroup.receiptTemplateId.$model"
        >
          <option
            :value="item.id"
            v-for="item in this.allReceiptTemplates"
            :key="item.id"
          >
            {{ item.templateName }}
          </option>
        </select>
        <div class="invalid-feedback">
          <div v-if="v$.formGroup.receiptTemplateId.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-opPurchase  -->
    <div class="row mb-3">
      <label for="input-opPurchase" class="col-sm-6 col-form-label">
        {{ $t(`TerminalGroups.table.opPurchase`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <select
          class="form-select"
          :class="addClassValidInput('opPurchase')"
          id="input-opPurchase"
          v-model="v$.formGroup.opPurchase.$model"
        >
          <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
          <option value="N">{{ $t(`uiElements.text.no`) }}</option>
        </select>
        <div class="invalid-feedback">
          <div v-if="v$.formGroup.opPurchase.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-opReversal  -->
    <div class="row mb-3">
      <label for="input-opReversal" class="col-sm-6 col-form-label">
        {{ $t(`TerminalGroups.table.opReversal`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <select
          class="form-select"
          :class="addClassValidInput('opReversal')"
          id="input-opReversal"
          v-model="v$.formGroup.opReversal.$model"
        >
          <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
          <option value="N">{{ $t(`uiElements.text.no`) }}</option>
        </select>
        <div class="invalid-feedback">
          <div v-if="v$.formGroup.opReversal.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-opRefund  -->
    <div class="row mb-3">
      <label for="input-opRefund" class="col-sm-6 col-form-label">
        {{ $t(`TerminalGroups.table.opRefund`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <select
          class="form-select"
          :class="addClassValidInput('opRefund')"
          id="input-opRefund"
          v-model="v$.formGroup.opRefund.$model"
        >
          <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
          <option value="N">{{ $t(`uiElements.text.no`) }}</option>
        </select>
        <div class="invalid-feedback">
          <div v-if="v$.formGroup.opRefund.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-opNfc  -->
    <div class="row mb-3">
      <label for="input-opNfc" class="col-sm-6 col-form-label">
        {{ $t(`TerminalGroups.table.opNfc`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <select
          class="form-select"
          :class="addClassValidInput('opNfc')"
          id="input-opNfc"
          v-model="v$.formGroup.opNfc.$model"
        >
          <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
          <option value="N">{{ $t(`uiElements.text.no`) }}</option>
        </select>
        <div class="invalid-feedback">
          <div v-if="v$.formGroup.opNfc.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-opManual  -->
    <div class="row mb-3">
      <label for="input-opManual" class="col-sm-6 col-form-label">
        {{ $t(`TerminalGroups.table.opManual`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <select
          class="form-select"
          :class="addClassValidInput('opManual')"
          id="input-opManual"
          v-model="v$.formGroup.opManual.$model"
        >
          <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
          <option value="N">{{ $t(`uiElements.text.no`) }}</option>
        </select>
        <div class="invalid-feedback">
          <div v-if="v$.formGroup.opManual.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-opCash  -->
    <div class="row mb-3">
      <label for="input-opCash" class="col-sm-6 col-form-label">
        {{ $t(`TerminalGroups.table.opCash`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <select
          class="form-select"
          :class="addClassValidInput('opCash')"
          id="input-opCash"
          v-model="v$.formGroup.opCash.$model"
        >
          <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
          <option value="N">{{ $t(`uiElements.text.no`) }}</option>
        </select>
        <div class="invalid-feedback">
          <div v-if="v$.formGroup.opCash.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-opPinMastercard  -->
    <div class="row mb-3">
      <label for="input-opPinMastercard" class="col-sm-6 col-form-label">
        {{ $t(`TerminalGroups.table.opPinMastercard`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <select
          class="form-select"
          :class="addClassValidInput('opPinMastercard')"
          id="input-opPinMastercard"
          v-model="v$.formGroup.opPinMastercard.$model"
        >
          <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
          <option value="N">{{ $t(`uiElements.text.no`) }}</option>
        </select>
        <div class="invalid-feedback">
          <div v-if="v$.formGroup.opPinMastercard.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-opPinVisa  -->
    <div class="row mb-3">
      <label for="input-opPinVisa" class="col-sm-6 col-form-label">
        {{ $t(`TerminalGroups.table.opPinVisa`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <select
          class="form-select"
          :class="addClassValidInput('opPinVisa')"
          id="input-opPinVisa"
          v-model="v$.formGroup.opPinVisa.$model"
        >
          <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
          <option value="N">{{ $t(`uiElements.text.no`) }}</option>
        </select>
        <div class="invalid-feedback">
          <div v-if="v$.formGroup.opPinVisa.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-opPinProstir  -->
    <div class="row mb-3">
      <label for="input-opPinProstir" class="col-sm-6 col-form-label">
        {{ $t(`TerminalGroups.table.opPinProstir`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <select
          class="form-select"
          :class="addClassValidInput('opPinProstir')"
          id="input-opPinProstir"
          v-model="v$.formGroup.opPinProstir.$model"
        >
          <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
          <option value="N">{{ $t(`uiElements.text.no`) }}</option>
        </select>
        <div class="invalid-feedback">
          <div v-if="v$.formGroup.opPinProstir.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-opPinAmex  -->
    <div class="row mb-3">
      <label for="input-opPinAmex" class="col-sm-6 col-form-label">
        {{ $t(`TerminalGroups.table.opPinAmex`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <select
          class="form-select"
          :class="addClassValidInput('opPinAmex')"
          id="input-opPinAmex"
          v-model="v$.formGroup.opPinAmex.$model"
        >
          <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
          <option value="N">{{ $t(`uiElements.text.no`) }}</option>
        </select>
        <div class="invalid-feedback">
          <div v-if="v$.formGroup.opPinAmex.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-opPinDiscover  -->
    <div class="row mb-3">
      <label for="input-opPinDiscover" class="col-sm-6 col-form-label">
        {{ $t(`TerminalGroups.table.opPinDiscover`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <select
          class="form-select"
          :class="addClassValidInput('opPinDiscover')"
          id="input-opPinDiscover"
          v-model="v$.formGroup.opPinDiscover.$model"
        >
          <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
          <option value="N">{{ $t(`uiElements.text.no`) }}</option>
        </select>
        <div class="invalid-feedback">
          <div v-if="v$.formGroup.opPinDiscover.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-opSingleTapAndPin  -->
    <div class="row mb-3">
      <label for="input-opPinDiscover" class="col-sm-6 col-form-label">
        {{ $t(`TerminalGroups.table.opSingleTapAndPin`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <select
          class="form-select"
          :class="addClassValidInput('opSingleTapAndPin')"
          id="input-opPinDiscover"
          v-model="v$.formGroup.opSingleTapAndPin.$model"
        >
          <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
          <option value="N">{{ $t(`uiElements.text.no`) }}</option>
        </select>
        <div class="invalid-feedback">
          <div v-if="v$.formGroup.opPinDiscover.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-geoPosition  -->
    <div class="row mb-3">
      <label for="input-geoPosition" class="col-sm-6 col-form-label">
        {{ $t(`TerminalGroups.table.geoPosition`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <select
          class="form-select"
          :class="addClassValidInput('geoPosition')"
          id="input-geoPosition"
          v-model="v$.formGroup.geoPosition.$model"
        >
          <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
          <option value="N">{{ $t(`uiElements.text.no`) }}</option>
        </select>
        <div class="invalid-feedback">
          <div v-if="v$.formGroup.geoPosition.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-oneTransactionLimit  -->
    <div class="row mb-3">
      <label for="input-oneTransactionLimit" class="col-sm-6 col-form-label">
        {{ $t(`TerminalGroups.table.oneTransactionLimit`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <input
          type="number"
          min="0"
          class="form-control"
          :class="addClassValidInput('oneTransactionLimit')"
          id="input-oneTransactionLimit"
          v-model="v$.formGroup.oneTransactionLimit.$model"
        />
        <div class="invalid-feedback">
          <div v-if="v$.formGroup.oneTransactionLimit.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
          <div v-if="v$.formGroup.oneTransactionLimit.maxLength.$invalid">
            {{ $t(`Validations.maxLength`, { num: 9 }) }}
          </div>
          <div v-if="v$.formGroup.oneTransactionLimit.minValue.$invalid">
            {{ $t(`Validations.minValue`, { num: 0 }) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-noPinLimit  -->
    <div class="row mb-3">
      <label for="input-noPinLimit" class="col-sm-6 col-form-label">
        {{ $t(`TerminalGroups.table.noPinLimit`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <input
          type="number"
          min="0"
          class="form-control"
          :class="addClassValidInput('noPinLimit')"
          id="input-noPinLimit"
          v-model="v$.formGroup.noPinLimit.$model"
        />
        <div class="invalid-feedback">
          <div v-if="v$.formGroup.noPinLimit.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
          <div v-if="v$.formGroup.noPinLimit.maxLength.$invalid">
            {{ $t(`Validations.maxLength`, { num: 9 }) }}
          </div>
          <div v-if="v$.formGroup.noPinLimit.minValue.$invalid">
            {{ $t(`Validations.minValue`, { num: 0 }) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-opCreateSubordinationTerminal  -->
    <div class="row mb-3">
      <label
        for="input-opCreateSubordinationTerminal"
        class="col-sm-6 col-form-label"
      >
        {{ $t(`TerminalGroups.table.opCreateSubordinationTerminal`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <select
          class="form-select"
          :class="addClassValidInput('opCreateSubordinationTerminal')"
          id="input-opCreateSubordinationTerminal"
          v-model="v$.formGroup.opCreateSubordinationTerminal.$model"
        >
          <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
          <option value="N">{{ $t(`uiElements.text.no`) }}</option>
        </select>
        <div class="invalid-feedback">
          <div
            v-if="v$.formGroup.opCreateSubordinationTerminal.required.$invalid"
          >
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-numberOfSecondaryTerminals  -->
    <div
      class="row mb-3"
      v-if="v$.formGroup.opCreateSubordinationTerminal.$model === 'Y'"
    >
      <label
        for="input-numberOfSecondaryTerminals"
        class="col-sm-6 col-form-label"
      >
        {{ $t(`TerminalGroups.table.numberOfSecondaryTerminals`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <input
          type="number"
          v-maska
          data-maska="#############"
          class="form-control"
          :class="addClassValidInput('numberOfSecondaryTerminals')"
          id="input-numberOfSecondaryTerminals"
          v-model="v$.formGroup.numberOfSecondaryTerminals.$model"
        />
        <div class="invalid-feedback">
          <div
            v-if="v$.formGroup.numberOfSecondaryTerminals.requiredIf.$invalid"
          >
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-productIdList  -->
    <div class="row mb-3">
      <label for="input-productIds" class="col-sm-6 col-form-label">
        {{ $t(`TerminalGroups.table.productIdList`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <multiselect
          v-model="v$.formGroup.productIdList.$model"
          :options="multiSelectOptions.allProducts"
          class="multiselect-blue"
          :class="addClassValidInput('productIdList')"
          id="input-productIds"
          mode="multiple"
          :close-on-select="false"
          :hide-selected="false"
        >
        </multiselect>
        <div class="invalid-feedback">
          <div v-if="v$.formGroup.productIdList.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-ipsCardGroupIdList  -->
    <div class="row mb-3">
      <label for="input-ipsCardGroupIdList" class="col-sm-6 col-form-label">
        {{ $t(`TerminalGroups.table.ipsCardGroupIdList`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <multiselect
          v-model="v$.formGroup.ipsCardGroupIdList.$model"
          :options="multiSelectOptions.allIpsCardGroups"
          class="multiselect-blue"
          :class="addClassValidInput('ipsCardGroupIdList')"
          id="input-ipsCardGroupIdList"
          mode="multiple"
          :close-on-select="false"
          :hide-selected="false"
        >
        </multiselect>
        <div class="invalid-feedback">
          <div v-if="v$.formGroup.ipsCardGroupIdList.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-menuItemList  -->
    <div class="row mb-3">
      <label for="input-menuItemList1" class="col-sm-6 col-form-label">
        {{ $t(`TerminalGroups.table.menuItemList`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <multiselect
          v-model="v$.formGroup.menuItemList.$model"
          :options="multiSelectOptions.allMenuItems"
          class="multiselect-blue"
          :class="addClassValidInput('menuItemList')"
          id="input-menuItemList1"
          mode="multiple"
          :close-on-select="false"
          :hide-selected="false"
          :can-clear="false"
        >
        </multiselect>
        <div class="invalid-feedback">
          <div v-if="v$.formGroup.menuItemList.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-receiptSendChannelIdList  -->
    <div class="row mb-3">
      <label
        for="input-receiptSendChannelIdList"
        class="col-sm-6 col-form-label"
      >
        {{ $t(`TerminalGroups.table.receiptSendChannelIdList`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <multiselect
          v-model="v$.formGroup.receiptSendChannelIdList.$model"
          :options="multiSelectOptions.allReceiptSendChannels"
          class="multiselect-blue"
          :class="addClassValidInput('receiptSendChannelIdList')"
          id="input-receiptSendChannelIdList"
          mode="multiple"
          :close-on-select="false"
          :hide-selected="false"
        >
        </multiselect>
        <div class="invalid-feedback">
          <div v-if="v$.formGroup.receiptSendChannelIdList.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-opTips  -->
    <div class="row mb-3">
      <label for="input-opTips" class="col-sm-6 col-form-label">
        {{ $t(`TerminalGroups.table.opTips`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <select
          class="form-select"
          :class="addClassValidInput('opTips')"
          id="input-opTips"
          v-model="v$.formGroup.opTips.$model"
        >
          <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
          <option value="N">{{ $t(`uiElements.text.no`) }}</option>
        </select>
        <div class="invalid-feedback">
          <div v-if="v$.formGroup.opTips.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-totalAmountLimit  -->
    <div class="row mb-3">
      <label for="input-totalAmountLimit" class="col-sm-6 col-form-label">
        {{ $t(`TerminalGroups.table.totalAmountLimit`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <input
          type="number"
          class="form-control"
          :class="addClassValidInput('totalAmountLimit')"
          id="input-totalAmountLimit"
          v-model="v$.formGroup.totalAmountLimit.$model"
        />
        <div class="invalid-feedback">
          <div v-if="v$.formGroup.totalAmountLimit.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
          <div v-if="v$.formGroup.totalAmountLimit.maxLength.$invalid">
            {{ $t(`Validations.maxLength`, { num: 9 }) }}
          </div>
          <div v-if="v$.formGroup.totalAmountLimit.minValue.$invalid">
            {{ $t(`Validations.minValue`, { num: 0 }) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-totalCountLimit  -->
    <div class="row mb-3">
      <label for="input-totalCountLimit" class="col-sm-6 col-form-label">
        {{ $t(`TerminalGroups.table.totalCountLimit`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <input
          type="number"
          class="form-control"
          :class="addClassValidInput('totalCountLimit')"
          id="input-totalCountLimit"
          v-model="v$.formGroup.totalCountLimit.$model"
        />
        <div class="invalid-feedback">
          <div v-if="v$.formGroup.totalCountLimit.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
          <div v-if="v$.formGroup.totalCountLimit.maxLength.$invalid">
            {{ $t(`Validations.maxLength`, { num: 9 }) }}
          </div>
          <div v-if="v$.formGroup.totalCountLimit.minValue.$invalid">
            {{ $t(`Validations.minValue`, { num: 0 }) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-totalLimitPeriod  -->
    <div class="row mb-3">
      <label for="input-totalLimitPeriod" class="col-sm-6 col-form-label">
        {{ $t(`TerminalGroups.table.totalLimitPeriod`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <input
          type="number"
          class="form-control"
          :class="addClassValidInput('totalLimitPeriod')"
          id="input-totalLimitPeriod"
          v-model="v$.formGroup.totalLimitPeriod.$model"
        />
        <div class="invalid-feedback">
          <div v-if="v$.formGroup.totalLimitPeriod.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
          <div v-if="v$.formGroup.totalLimitPeriod.maxLength.$invalid">
            {{ $t(`Validations.maxLength`, { num: 9 }) }}
          </div>
          <div v-if="v$.formGroup.totalLimitPeriod.minValue.$invalid">
            {{ $t(`Validations.minValue`, { num: 1 }) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-velocityCount  -->
    <div class="row mb-3">
      <label for="input-velocityCount" class="col-sm-6 col-form-label">
        {{ $t(`TerminalGroups.table.velocityCount`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <input
          type="number"
          class="form-control"
          :class="addClassValidInput('velocityCount')"
          id="input-velocityCount"
          v-model="v$.formGroup.velocityCount.$model"
        />
        <div class="invalid-feedback">
          <div v-if="v$.formGroup.velocityCount.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
          <div v-if="v$.formGroup.velocityCount.maxLength.$invalid">
            {{ $t(`Validations.maxLength`, { num: 9 }) }}
          </div>
          <div v-if="v$.formGroup.velocityCount.minValue.$invalid">
            {{ $t(`Validations.minValue`, { num: 0 }) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-velocityPeriod  -->
    <div class="row mb-3">
      <label for="input-velocityPeriod" class="col-sm-6 col-form-label">
        {{ $t(`TerminalGroups.table.velocityPeriod`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <input
          type="number"
          class="form-control"
          :class="addClassValidInput('velocityPeriod')"
          id="input-velocityPeriod"
          v-model="v$.formGroup.velocityPeriod.$model"
        />
        <div class="invalid-feedback">
          <div v-if="v$.formGroup.velocityPeriod.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
          <div v-if="v$.formGroup.velocityPeriod.maxLength.$invalid">
            {{ $t(`Validations.maxLength`, { num: 9 }) }}
          </div>
          <div v-if="v$.formGroup.velocityPeriod.minValue.$invalid">
            {{ $t(`Validations.minValue`, { num: 0 }) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-velocityTimeUnit  -->
    <div class="row mb-3">
      <label for="input-velocityTimeUnit" class="col-sm-6 col-form-label">
        {{ $t(`TerminalGroups.table.velocityTimeUnit`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <select
          class="form-select"
          :class="addClassValidInput('velocityTimeUnit')"
          id="input-velocityTimeUnit"
          v-model="v$.formGroup.velocityTimeUnit.$model"
        >
          <option value="NANOS">NANOS</option>
          <option value="MICROS">MICROS</option>
          <option value="MILLIS">MILLIS</option>
          <option value="SECONDS">SECONDS</option>
          <option value="MINUTES">MINUTES</option>
          <option value="HOURS">HOURS</option>
          <option value="HALF_DAYS">HALF_DAYS</option>
          <option value="DAYS">DAYS</option>
          <option value="WEEKS">WEEKS</option>
          <option value="MONTHS">MONTHS</option>
          <option value="YEARS">YEARS</option>
        </select>
        <div class="invalid-feedback">
          <div v-if="v$.formGroup.velocityTimeUnit.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-transactionGeoposition  -->
    <div class="row mb-3">
      <label for="input-opTips" class="col-sm-6 col-form-label">
        {{ $t(`TerminalGroups.table.transactionGeoposition`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <select
          class="form-select"
          :class="addClassValidInput('transactionGeoposition')"
          id="input-opTips"
          v-model="v$.formGroup.transactionGeoposition.$model"
        >
          <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
          <option value="N">{{ $t(`uiElements.text.no`) }}</option>
        </select>
        <div class="invalid-feedback">
          <div v-if="v$.formGroup.transactionGeoposition.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-bankId  -->
    <div class="row mb-3">
      <label for="input-bankId" class="col-sm-6 col-form-label">
        {{ $t(`TerminalGroups.table.bankName`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <select
          class="form-select"
          :class="addClassValidInput('bankId')"
          id="input-bankId"
          v-model="v$.formGroup.bankId.$model"
        >
          <option :value="item.id" v-for="item in this.allBanks" :key="item.id">
            {{ item.name }}
          </option>
        </select>
        <div class="invalid-feedback">
          <div v-if="v$.formGroup.bankId.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  Buttons form  -->
    <div class="d-flex justify-content-end">
      <button
        type="button"
        class="btn btn-outline-secondary me-3"
        @click="closeModal"
      >
        {{ $t(`uiElements.btn.close`) }}
      </button>
      <button
        type="submit"
        class="btn btn-outline-primary"
        :disabled="v$.$invalid && v$.$dirty ? true : false"
      >
        {{ $t(`uiElements.btn.save`) }}
      </button>
    </div>
  </form>
  <div
    class="toast-container position-fixed p-3 top-0 end-0 z-index-1500"
    v-if="showToast"
  >
    <bs-toast v-for="toast in toastPropsList" :key="toast" :="toast"></bs-toast>
  </div>
</template>

<script>
import {
  maxLength,
  required,
  minValue,
  requiredIf,
} from "@vuelidate/validators";
import terminalGroupsApi from "@/api/terminal-groups";
import BsToast from "@/components/UI-elements/BsToast";
import useVuelidate from "@vuelidate/core";
import Multiselect from "@vueform/multiselect";

export default {
  name: "TrnTerminalGroupEdit",
  components: {
    BsToast,
    Multiselect,
  },
  emits: ["hideMainModal", "reloadPageTerminalGroup"],
  setup() {
    return { v$: useVuelidate() };
  },
  props: {
    actionType: {
      type: String,
      default: "Add",
    },
    terminalGroupObj: {
      type: Object,
      default: null,
    },
    allReceiptTemplates: {
      type: Object,
      required: true,
    },
    allIpsCardGroups: {
      type: Object,
      required: true,
    },
    allCurrencies: {
      type: Object,
      required: true,
    },
    allMenuItems: {
      type: Object,
      required: true,
    },
    allProducts: {
      type: Object,
      required: true,
    },
    allReceiptSendChannels: {
      type: Object,
      required: true,
    },
    allBanks: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      terminalGroupObjD: this.terminalGroupObj,
      showToast: false,
      toastPropsList: [],
      formGroup: {
        menuItemList: [],
        receiptSendChannelIdList: [],
        currencyCode: [],
        productIdList: [],
        ipsCardGroupIdList: [],
      },
      multiSelectOptions: {
        allReceiptTemplates: [],
        allIpsCardGroups: [],
        allCurrencies: [],
        allMenuItems: [],
        allProducts: [],
        allReceiptSendChannels: [],
        allBanks: [],
      },
    };
  },
  validations() {
    return {
      formGroup: {
        groupName: {
          required,
        },
        status: {
          required,
        },
        currencyCode: {
          required,
        },
        receiptTemplateId: {
          required,
        },
        bankId: {
          required,
        },
        opPurchase: {
          required,
        },
        opReversal: {
          required,
        },
        opRefund: {
          required,
        },
        opNfc: {
          required,
        },
        opManual: {
          required,
        },
        opCash: {
          required,
        },
        opPinMastercard: {
          required,
        },
        opPinVisa: {
          required,
        },
        opPinProstir: {
          required,
        },
        opPinAmex: {
          required,
        },
        opPinDiscover: {
          required,
        },
        opSingleTapAndPin: {
          required,
        },
        geoPosition: {
          required,
        },
        oneTransactionLimit: {
          required,
          maxLength: maxLength(9),
          minValue: minValue(0),
        },
        noPinLimit: {
          required,
          maxLength: maxLength(9),
          minValue: minValue(0),
        },
        productIdList: {
          required,
        },
        ipsCardGroupIdList: {
          required,
        },
        menuItemList: {
          required,
        },
        receiptSendChannelIdList: {
          required,
        },
        opTips: {
          required,
        },
        opCreateSubordinationTerminal: {
          required,
        },
        numberOfSecondaryTerminals: {
          requiredIf: requiredIf(
            this.formGroup.opCreateSubordinationTerminal === "Y"
          ),
        },
        totalAmountLimit: {
          required,
          maxLength: maxLength(9),
          minValue: minValue(0),
        },
        totalCountLimit: {
          required,
          maxLength: maxLength(9),
          minValue: minValue(0),
        },
        totalLimitPeriod: {
          required,
          maxLength: maxLength(9),
          minValue: minValue(1),
        },
        velocityCount: {
          required,
          maxLength: maxLength(9),
          minValue: minValue(0),
        },
        velocityPeriod: {
          required,
          maxLength: maxLength(9),
          minValue: minValue(0),
        },
        velocityTimeUnit: {
          required,
        },
        transactionGeoposition: {
          required,
        },
      },
    };
  },
  methods: {
    submitFormGroup() {
      this.v$.formGroup.$touch();
      if (!this.v$.formGroup.$error) {
        if (this.actionType === "Update") {
          console.log(this.formGroup);
          terminalGroupsApi
            .updateTerminalGroups(this.terminalGroupObj.groupNumber, {
              ...this.formGroup,
            })
            .then(function (result) {
              return result;
            })
            .catch(function (result) {
              return result;
            })
            .then((result) => {
              // console.log("result", result);
              if (result.status === 200) {
                this.showModal = false;

                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.successSave`)}`,
                  bgColor: "bg-success",
                  textColor: "text-white",
                });
                this.showToast = true;

                this.$emit("hideMainModal");
                this.$emit("reloadPageTerminalGroup");
              } else {
                console.log("err");
                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.errSave`)}`,
                  bgColor: "bg-danger",
                  textColor: "text-white",
                });
                this.showToast = true;
              }
            });
        } else {
          console.log("add");
          terminalGroupsApi
            .addTerminalGroups({
              ...this.formGroup,
            })
            .then(function (result) {
              return result;
            })
            .catch(function (result) {
              return result;
            })
            .then((result) => {
              // console.log("result", result);
              if (result.status === 200) {
                this.showModal = false;

                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.successSave`)}`,
                  bgColor: "bg-success",
                  textColor: "text-white",
                });
                this.showToast = true;

                this.$emit("hideMainModal");
                this.$emit("reloadPageTerminalGroup");
              } else {
                console.log("err");
                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.errSave`)}`,
                  bgColor: "bg-danger",
                  textColor: "text-white",
                });
                this.showToast = true;
              }
            });
        }
      }
    },
    addClassValidInput(inputName) {
      return {
        "is-invalid":
          this.v$.formGroup[inputName].$error === true &&
          this.v$.formGroup[inputName].$dirty === true,
        "is-valid":
          this.v$.formGroup[inputName].$error === false &&
          this.v$.formGroup[inputName].$dirty === true,
      };
    },
    closeModal() {
      this.$emit("hideMainModal");
    },
    fillFormFields() {
      if (this.terminalGroupObj !== null) {
        this.formGroup.groupNumber = this.terminalGroupObj.groupNumber;
        this.formGroup.groupName = this.terminalGroupObj.groupName;
        this.formGroup.status = this.terminalGroupObj.status;
        this.formGroup.currencyCode = this.terminalGroupObj.currency.code;
        this.formGroup.receiptTemplateId =
          this.terminalGroupObj.receiptTemplate.id;
        this.formGroup.bankId = this.terminalGroupObj.bank.id;
        this.formGroup.opPurchase = this.terminalGroupObj.opPurchase;
        this.formGroup.opReversal = this.terminalGroupObj.opReversal;
        this.formGroup.opRefund = this.terminalGroupObj.opRefund;
        this.formGroup.opNfc = this.terminalGroupObj.opNfc;
        this.formGroup.opManual = this.terminalGroupObj.opManual;
        this.formGroup.opCash = this.terminalGroupObj.opCash;
        this.formGroup.opPinMastercard = this.terminalGroupObj.opPinMastercard;
        this.formGroup.opPinVisa = this.terminalGroupObj.opPinVisa;
        this.formGroup.opPinProstir = this.terminalGroupObj.opPinProstir;
        this.formGroup.opPinAmex = this.terminalGroupObj.opPinAmex;
        this.formGroup.opPinDiscover = this.terminalGroupObj.opPinDiscover;
        this.formGroup.opSingleTapAndPin =
          this.terminalGroupObj.opSingleTapAndPin;
        this.formGroup.geoPosition = this.terminalGroupObj.geoPosition;
        this.formGroup.oneTransactionLimit =
          this.terminalGroupObj.oneTransactionLimit;
        this.formGroup.noPinLimit = this.terminalGroupObj.noPinLimit;
        this.formGroup.opTips = this.terminalGroupObj.opTips;
        this.formGroup.opCreateSubordinationTerminal =
          this.terminalGroupObj.opCreateSubordinationTerminal;
        this.formGroup.numberOfSecondaryTerminals =
          this.terminalGroupObj.numberOfSecondaryTerminals;
        this.formGroup.totalAmountLimit =
          this.terminalGroupObj.totalAmountLimit;
        this.formGroup.totalCountLimit = this.terminalGroupObj.totalCountLimit;
        this.formGroup.totalLimitPeriod =
          this.terminalGroupObj.totalLimitPeriod;
        this.formGroup.velocityCount = this.terminalGroupObj.velocityCount;
        this.formGroup.velocityPeriod = this.terminalGroupObj.velocityPeriod;
        this.formGroup.velocityTimeUnit =
          this.terminalGroupObj.velocityTimeUnit;
        this.formGroup.transactionGeoposition =
          this.terminalGroupObj.transactionGeoposition;

        this.formGroup.productIdList = [];
        for (const key in this.terminalGroupObj.products) {
          this.formGroup.productIdList.push(
            this.terminalGroupObj.products[key].productId
          );
        }

        this.formGroup.ipsCardGroupIdList = [];
        for (const key in this.terminalGroupObj.allowedIpsCardGroups) {
          this.formGroup.ipsCardGroupIdList.push(
            this.terminalGroupObj.allowedIpsCardGroups[key].ipsCardGroupId
          );
        }

        this.formGroup.menuItemList = [];
        for (const key in this.terminalGroupObj.menuItemList) {
          this.formGroup.menuItemList.push(
            this.terminalGroupObj.menuItemList[key]
          );
        }

        this.formGroup.receiptSendChannelIdList = [];
        for (const key in this.terminalGroupObj.receiptSendChannels) {
          this.formGroup.receiptSendChannelIdList.push(
            this.terminalGroupObj.receiptSendChannels[key].id
          );
        }
      }
    },
    addAllOption() {
      // allProducts
      for (const key in this.allProducts) {
        const obj = {
          value: this.allProducts[key].productId,
          label: this.allProducts[key].productName,
        };
        this.multiSelectOptions.allProducts.push(obj);
      }

      // allReceiptTemplates
      for (const key in this.allReceiptTemplates) {
        const obj = {
          value: this.allReceiptTemplates[key].id,
          label: this.allReceiptTemplates[key].id,
        };
        this.multiSelectOptions.allReceiptTemplates.push(obj);
      }

      // allIpsCardGroups
      for (const key in this.allIpsCardGroups) {
        const obj = {
          value: this.allIpsCardGroups[key].ipsCardGroupId,
          label: this.allIpsCardGroups[key].ipsName,
        };
        this.multiSelectOptions.allIpsCardGroups.push(obj);
      }

      // allCurrencies
      for (const key in this.allCurrencies) {
        const obj = {
          value: this.allCurrencies[key].code,
          label: this.allCurrencies[key].letterCode,
        };
        this.multiSelectOptions.allCurrencies.push(obj);
      }

      // allMenuItems
      this.formGroup.menuItemList = [];
      for (const key in this.allMenuItems) {
        let mandatoryItem = false;
        if (this.allMenuItems[key].mandatory) {
          mandatoryItem = true;
          this.formGroup.menuItemList.push(this.allMenuItems[key].name);
        }
        const obj = {
          value: this.allMenuItems[key].name,
          label: this.allMenuItems[key].name,
          disabled: mandatoryItem,
        };
        this.multiSelectOptions.allMenuItems.push(obj);
      }

      // allReceiptSendChannels
      for (const key in this.allReceiptSendChannels) {
        const obj = {
          value: this.allReceiptSendChannels[key].id,
          label: this.allReceiptSendChannels[key].name,
        };
        this.multiSelectOptions.allReceiptSendChannels.push(obj);
      }

      // allBanks
      for (const key in this.allBanks) {
        const obj = {
          value: this.allBanks[key].id,
          label: this.allBanks[key].id,
        };
        this.multiSelectOptions.allBanks.push(obj);
      }
    },
  },
  mounted() {
    this.addAllOption();
    this.fillFormFields();
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect-blue {
  --ms-option-bg-pointed: #ffffff;
  --ms-option-color-pointed: #1f2937;
  --ms-option-bg-selected: #0d6efd;
  --ms-option-color-selected: #ffffff;
  --ms-option-bg-selected-pointed: #2f6fcd;
  --ms-option-color-selected-pointed: #ffffff;
}
</style>
