import userRoleApi from "@/api/settings-api/user-roles";

const state = {
  dataResp: null,
  dataModalResp: null,

  isLoading: false,
  isLoadingModal: false,

  errorResp: null,
};

export const mutationTypes = {
  getSettingUserRoleStart: "[settingUserRole] getSettingUserRole start",
  getSettingUserRoleSuccess: "[settingUserRole] getSettingUserRole success",
  getSettingUserRoleFailure: "[settingUserRole] getSettingUserRole failure",
};

export const actionTypes = {
  getSettingUserRole: "[settingUserRole] getSettingUserRole",
};

const mutations = {
  [mutationTypes.getSettingUserRoleStart](state) {
    state.isLoading = true;
    state.data = null;
  },
  [mutationTypes.getSettingUserRoleSuccess](state, payload) {
    state.isLoading = false;
    state.dataResp = payload;
  },
  [mutationTypes.getSettingUserRoleFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
};

const actions = {
  [actionTypes.getSettingUserRole](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getSettingUserRoleStart);
      userRoleApi
        .get_user_role()
        .then((response) => {
          context.commit(
            mutationTypes.getSettingUserRoleSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getSettingUserRoleFailure,
            result.response.data.error
          );
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
