<template>
  <form class="needs-validation" @submit.prevent="formUpdateBank" novalidate>
    <!--  input-id  -->
    <div class="row mb-3">
      <label for="input-id" class="col-sm-4 col-form-label">
        {{ $t(`Settings.Banks.table.id`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-8">
        <input
          type="number"
          class="form-control"
          :class="addClassValidInput('id')"
          id="input-id"
          v-model="v$.form.id.$model"
          disabled
        />
        <div class="invalid-feedback">
          <div v-if="v$.form.id.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-name  -->
    <div class="row mb-3">
      <label for="input-name" class="col-sm-4 col-form-label">
        {{ $t(`Settings.Banks.table.name`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-8">
        <input
          type="text"
          class="form-control"
          :class="addClassValidInput('name')"
          id="input-name"
          v-model="v$.form.name.$model"
        />
        <div class="invalid-feedback">
          <div v-if="v$.form.name.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-address  -->
    <div class="row mb-3">
      <label for="input-address" class="col-sm-4 col-form-label">
        {{ $t(`Settings.Banks.table.address`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-8">
        <input
          type="text"
          class="form-control"
          :class="addClassValidInput('address')"
          id="input-address"
          v-model="v$.form.address.$model"
        />
        <div class="invalid-feedback">
          <div v-if="v$.form.address.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-email  -->
    <div class="row mb-3">
      <label for="input-email" class="col-sm-4 col-form-label">
        {{ $t(`Settings.Banks.table.email`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-8">
        <input
          type="text"
          class="form-control"
          :class="addClassValidInput('email')"
          id="input-email"
          v-model="v$.form.email.$model"
        />
        <div class="invalid-feedback">
          <div v-if="v$.form.email.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-phone  -->
    <div class="row mb-3">
      <label for="input-phone" class="col-sm-4 col-form-label">
        {{ $t(`Settings.Banks.table.phone`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-8">
        <input
          type="text"
          class="form-control"
          :class="addClassValidInput('phone')"
          id="input-phone"
          v-model="v$.form.phone.$model"
        />
        <div class="invalid-feedback">
          <div v-if="v$.form.phone.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-taxId  -->
    <div class="row mb-3">
      <label for="input-taxId" class="col-sm-4 col-form-label">
        {{ $t(`Settings.Banks.table.taxId`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-8">
        <input
          type="text"
          class="form-control"
          :class="addClassValidInput('taxId')"
          id="input-taxId"
          v-model="v$.form.taxId.$model"
        />
        <div class="invalid-feedback">
          <div v-if="v$.form.taxId.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-mfo  -->
    <div class="row mb-3">
      <label for="input-mfo" class="col-sm-4 col-form-label">
        {{ $t(`Settings.Banks.table.mfo`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-8">
        <input
          type="text"
          class="form-control"
          :class="addClassValidInput('mfo')"
          id="input-mfo"
          v-model="v$.form.mfo.$model"
        />
        <div class="invalid-feedback">
          <div v-if="v$.form.mfo.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-instructions  -->
    <div class="row mb-3">
      <label for="input-instructions" class="col-sm-4 col-form-label">
        {{ $t(`Settings.Banks.table.instructions`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-8">
        <input
          type="text"
          class="form-control"
          :class="addClassValidInput('instructions')"
          id="input-instructions"
          v-model="v$.form.instructions.$model"
        />
        <div class="invalid-feedback">
          <div v-if="v$.form.instructions.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>
    <!--  Buttons form  -->
    <div class="d-flex justify-content-end">
      <button
        type="button"
        class="btn btn-outline-secondary me-3"
        @click="$emit('hideMainModal')"
      >
        {{ $t(`uiElements.btn.cancel`) }}
      </button>
      <button
        type="submit"
        class="btn btn-outline-primary"
        :disabled="v$.$invalid && v$.$dirty ? true : false"
      >
        {{ $t(`uiElements.btn.save`) }}
      </button>
    </div>
  </form>

  <div class="toast-container position-fixed p-3 start-0 end-0" v-if="showToast">
    <bs-toast v-for="toast in toastPropsList" :key="toast" :="toast"></bs-toast>
  </div>
</template>

<script>
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import banksApi from "@/api/settings-api/settings-banks";
import BsToast from "@/components/UI-elements/BsToast";

export default {
  name: "TrnSettingBanksForm",
  emits: ["hideMainModal"],
  setup() {
    return { v$: useVuelidate() };
  },
  components: { BsToast },
  props: {
    form: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showToast: true,
      toastPropsList: [],
    };
  },
  validations() {
    return {
      form: {
        id: {
          required,
        },
        name: {
          required,
        },
        address: {
          required,
        },
        email: {
          required,
        },
        phone: {
          required,
        },
        taxId: {
          required,
        },
        mfo: {
          required,
        },
        instructions: {
          required,
        },
      },
    };
  },
  methods: {
    formUpdateBank() {
      console.log("formUpdateBank");
      this.v$.form.$touch();
      if (!this.v$.form.$error) {
        banksApi
          .update_bank(this.form.id, {
            ...this.form,
          })
          .then(function (result) {
            return result;
          })
          .catch(function (result) {
            return result;
          })
          .then((result) => {
            // console.log("result", result);
            if (result.status === 200) {
              this.showModal = false;

              this.toastPropsList.push({
                content: `${this.$t(`uiElements.toast.successSave`)}`,
                bgColor: "bg-success",
                textColor: "text-white",
              });
              this.showToast = true;
              this.$emit("hideMainModal");
            } else {
              this.showModal = false;
              this.selectedTableRow = null;
              console.log("err");
              this.toastPropsList.push({
                content: `${this.$t(`uiElements.toast.errSave`)}`,
                bgColor: "bg-danger",
                textColor: "text-white",
              });
              this.showToast = true;
              this.$emit("hideMainModal");
            }
          });
      }
    },
    addClassValidInput(inputName) {
      return {
        "is-invalid":
          this.v$.form[inputName].$error === true &&
          this.v$.form[inputName].$dirty === true,
        "is-valid":
          this.v$.form[inputName].$error === false &&
          this.v$.form[inputName].$dirty === true,
      };
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
