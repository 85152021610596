import { createRouter, createWebHistory, RouterView } from "vue-router";
import TrnLogin from "@/views/auth-page/Login";
// import business-service-pages
import TrnReportsSettlement from "@/views/business-service-pages/ReportsSettlement";
import TrnMerchantRegistration from "@/views/business-service-pages/MerchantRegistration";
import TrnPageNotFound from "@/views/maintenance-pages/pageNotFound";
import TrnTransactions from "@/views/business-service-pages/Transactions";
import TrnMerchant from "@/views/business-service-pages/Merchant";
import TrnTerminal from "@/views/business-service-pages/Terminal";
import TrnDevices from "@/views/business-service-pages/Devices";
import TrnTerminalGroups from "@/views/business-service-pages/TerminalGroups";
import TrnAttestationHistory from "@/views/business-service-pages/AttestationHistory";
import TrnZReportTransactions from "@/views/business-service-pages/ZReportTransactions";
import TrnAnalytics from "@/views/business-service-pages/Analytics";
import TrnMonitoring from "@/views/business-service-pages/Monitoring";
import TrnUser from "@/views/business-service-pages/User";
import TrnMessage from "@/views/business-service-pages/Message";
import TrnRegularReports from "@/views/business-service-pages/RegularReports";
import TrnBusinessDayCalculation from "@/views/business-service-pages/BusinessDayCalculation";
import TrnRegulatoryCommissions from "@/views/business-service-pages/RegulatoryCommissions";

// import settings page
import TrnInstallations from "@/views/setting-pages/Іnstallations";
import TrnBanks from "@/views/setting-pages/Banks";
import TrnUserRoles from "@/views/setting-pages/UserRoles";
import TrnEntityAudit from "@/views/setting-pages/EntityAudit";
import TrnTerminalFacility from "@/views/setting-pages/TerminalFacility";
import TrnReceiptTemplate from "@/views/setting-pages/ReceiptTemplate";
import TrnIpsCardGroup from "@/views/setting-pages/IpsCardGroup";
import TrnProducts from "@/views/setting-pages/Products";
import TrnAttestation from "@/views/setting-pages/Attestation";
import TrnVersion from "@/views/setting-pages/Version";
import TrnSchedule from "@/views/setting-pages/Schedule";
import TrnSetupConfig from "@/views/setting-pages/SetupConfig.vue";
import TrnVendorRSO from "@/views/setting-pages/VendorRSO.vue";

const routes = [
  {
    path: "/",
    name: "home",
    redirect: { name: "login" },
  },
  {
    path: "/setup-config",
    name: "setupConfig",
    component: TrnSetupConfig,
  },
  {
    path: "/login",
    name: "login",
    component: TrnLogin,
    meta: {
      name: "login",
    },
  },
  {
    path: "/reports/settlement",
    name: "reportsSettlement",
    redirect: "/reports/settlement/terminal",
    component: TrnReportsSettlement,
    children: [
      {
        path: "transaction",
        name: "reportsSettlementTransaction",
        component: TrnReportsSettlement,
      },
      {
        path: "terminal",
        name: "reportsSettlementTerminal",
        component: TrnReportsSettlement,
      },
    ],
  },
  {
    path: "/transactions",
    name: "transactions",
    component: TrnTransactions,
  },
  {
    path: "/merchants",
    name: "merchants",
    component: TrnMerchant,
  },
  {
    path: "/terminal",
    name: "terminal",
    component: TrnTerminal,
  },
  {
    path: "/devices",
    name: "devices",
    component: TrnDevices,
  },
  {
    path: "/terminal-groups",
    name: "terminalGroups",
    component: TrnTerminalGroups,
  },
  {
    path: "/merchant-registration",
    name: "merchantRegistration",
    component: TrnMerchantRegistration,
  },
  {
    path: "/attestation-history",
    name: "attestationHistory",
    component: TrnAttestationHistory,
  },
  {
    path: "/z-report-transactions",
    name: "zReportTransactions",
    component: TrnZReportTransactions,
  },
  {
    path: "/analytics",
    name: "analytics",
    component: TrnAnalytics,
  },
  {
    path: "/monitoring",
    name: "monitoring",
    component: TrnMonitoring,
  },
  {
    path: "/user",
    name: "user",
    component: TrnUser,
  },
  {
    path: "/message",
    name: "message",
    component: TrnMessage,
  },
  {
    path: "/regular-reports",
    name: "regularReports",
    component: TrnRegularReports,
  },
  {
    path: "/business-day-calculation",
    name: "businessDayCalculation",
    component: TrnBusinessDayCalculation,
  },
  {
    path: "/regulatory-commissions",
    name: "regulatoryCommissions",
    component: TrnRegulatoryCommissions,
  },

  // Menu SETTINGS
  {
    path: "/settings",
    name: "settings",
    component: RouterView,
    redirect: { name: "error404" },
    children: [
      {
        path: "installations",
        name: "settingsInstallations",
        component: TrnInstallations,
      },
      {
        path: "banks",
        name: "settingsBanks",
        component: TrnBanks,
      },
      {
        path: "user-roles",
        name: "settingsUserRoles",
        component: TrnUserRoles,
      },
      {
        path: "entity-audit",
        name: "settingsEntityAudit",
        component: TrnEntityAudit,
      },
      {
        path: "terminal-facility",
        name: "terminalFacility",
        component: TrnTerminalFacility,
      },
      {
        path: "receipt-template",
        name: "receiptTemplate",
        component: TrnReceiptTemplate,
      },
      {
        path: "ips-card-group",
        name: "ipsCardGroup",
        component: TrnIpsCardGroup,
      },
      {
        path: "products",
        name: "products",
        component: TrnProducts,
      },
      {
        path: "attestation",
        name: "attestation",
        component: TrnAttestation,
      },
      {
        path: "version",
        name: "version",
        component: TrnVersion,
      },
      {
        path: "schedule",
        name: "schedule",
        component: TrnSchedule,
      },
      {
        path: "vendor-rso",
        name: "vendorRSO",
        component: TrnVendorRSO,
      },
    ],
  },
  {
    path: "/404",
    name: "error404",
    component: TrnPageNotFound,
  },
  {
    path: "/:catchAll(.*)",
    component: TrnPageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = "Transenix";
  next();
});

export default router;
