import settlementApi from "@/api/settlement-reports";

const state = {
  dataResp: null,
  isLoading: false,
  errorResp: null,
};

export const mutationTypes = {
  getSettlementReportStart: "[settlementReport] getSettlementReport start",
  getSettlementReportSuccess: "[settlementReport] getSettlementReport success",
  getSettlementReportFailure: "[settlementReport] getSettlementReport failure",
};

export const actionTypes = {
  getSettlementReport: "[settlementTerminal] getSettlementReport",
};

const mutations = {
  [mutationTypes.getSettlementReportStart](state) {
    state.isLoading = true;
    state.data = null;
  },
  [mutationTypes.getSettlementReportSuccess](state, payload) {
    state.isLoading = false;
    state.dataResp = payload;
  },
  [mutationTypes.getSettlementReportFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
};

const actions = {
  [actionTypes.getSettlementReport](context, { apiUrl }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getSettlementReportStart, apiUrl);
      settlementApi
        .reports_settlement(apiUrl)
        .then((response) => {
          context.commit(
            mutationTypes.getSettlementReportSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getSettlementReportFailure,
            result.response.data.error
          );
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
