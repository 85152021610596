import axios from "@/api/axios";

const getFinalBilling = (apiParams) => {
  return axios.get("/fata-proxy-server/shipment-report?" + apiParams);
};

const getMerchant = (apiParams) => {
  return axios.get("/fata-proxy-server/merchant?" + apiParams);
};

const getAccrual = (apiParams) => {
  return axios.get(
    "/fata-proxy-server/account-incoming-transaction?" + apiParams
  );
};

const searchMerchantByBalance = (apiParams) => {
  return axios.get("/fata-proxy-server/merchant-start-with/" + apiParams);
};

const getBalanceByMerchant = (merchantId, apiParams) => {
  return axios.get(`/fata-proxy-server/wiring/${merchantId}?${apiParams}`);
};

const getAllFataCommissionBook = (apiParams) => {
  return axios.get("/fata-proxy-server/fata-commission-book?" + apiParams);
};

const getFataMessage = () => {
  return axios.get("/fata-proxy-server/fata-message");
};

const updateFataMessage = (apiParams) => {
  return axios.put("/fata-proxy-server/fata-message", { ...apiParams });
};

const makeWiring = () => {
  return axios.get("/fata-proxy-server/shipment-report/make-wiring");
};

const getAllGeneralFinBook = (apiParams) => {
  return axios.get("/fata-proxy-server/general-fin-book?" + apiParams);
};

export default {
  getFinalBilling,
  getMerchant,
  getAccrual,
  getBalanceByMerchant,
  searchMerchantByBalance,
  makeWiring,
  getAllFataCommissionBook,
  getFataMessage,
  updateFataMessage,
  getAllGeneralFinBook
};
