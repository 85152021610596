import zReportTransactionsApi from "@/api/z-report-transactions";

const state = {
  dataResp: null,
  dataModalResp: null,

  isLoading: false,
  isLoadingScroll: false,
  isLoadingModal: false,

  errorResp: null,
};

export const mutationTypes = {
  getZReportTransactionsStart:
    "[ZReportTransactions] getZReportTransactions start",
  getZReportTransactionsSuccess:
    "[ZReportTransactions] getZReportTransactions success",
  getZReportTransactionsFailure:
    "[ZReportTransactions] getZReportTransactions failure",

  getZReportTransactionsScrollPushStart:
    "[ZReportTransactions] getZReportTransactionsScrollPush start",
  getZReportTransactionsScrollPushSuccess:
    "[ZReportTransactions] getZReportTransactionsScrollPushSuccess success",
  getZReportTransactionsScrollPushFailure:
    "[ZReportTransactions] getZReportTransactionsScrollPush failure",
};

export const actionTypes = {
  getZReportTransactions: "[ZReportTransactions] getZReportTransactions",
  getZReportTransactionsScrollPush:
    "[ZReportTransactions] getZReportTransactionsScrollPush",
};

const mutations = {
  [mutationTypes.getZReportTransactionsStart](state) {
    state.isLoading = true;
    state.data = null;
  },
  [mutationTypes.getZReportTransactionsSuccess](state, payload) {
    state.isLoading = false;
    state.dataResp = payload;
  },
  [mutationTypes.getZReportTransactionsFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
  [mutationTypes.getZReportTransactionsScrollPushStart](state) {
    state.isLoadingScroll = true;
  },
  [mutationTypes.getZReportTransactionsScrollPushSuccess](state, payload) {
    for (let item in payload.content) {
      state.dataResp.content.push(payload.content[item]);
    }
    state.isLoadingScroll = false;
  },
  [mutationTypes.getZReportTransactionsScrollPushFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
};

const actions = {
  [actionTypes.getZReportTransactions](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getZReportTransactionsStart, apiParams);
      zReportTransactionsApi
        .getZReportTransactions(apiParams)
        .then((response) => {
          context.commit(
            mutationTypes.getZReportTransactionsSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getZReportTransactionsFailure,
            result.response.data.error
          );
        });
    });
  },
  [actionTypes.getZReportTransactionsScrollPush](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(
        mutationTypes.getZReportTransactionsScrollPushStart,
        apiParams
      );
      zReportTransactionsApi
        .getZReportTransactions(apiParams)
        .then((response) => {
          context.commit(
            mutationTypes.getZReportTransactionsScrollPushSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getZReportTransactionsScrollPushFailure,
            result.response.data
          );
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
