import axios from "@/api/axios";

const getIpsCardGroups = (apiParams) => {
  return axios.get("/ips-card-groups?" + apiParams);
};

const updateIpsCardGroup = (id, reqObj) => {
  return axios.put("/ips-card-groups/" + id, {
    ...reqObj,
  });
};

const addIpsCardGroup = (reqObj) => {
  return axios.post("/ips-card-groups", {
    ...reqObj,
  });
};

const deleteIpsCardGroup = (id) => {
  return axios.delete("/ips-card-groups/" + id);
};

export default {
  getIpsCardGroups,
  updateIpsCardGroup,
  addIpsCardGroup,
  deleteIpsCardGroup,
};
