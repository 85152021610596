import axios from "@/api/axios";

const get_user_role = () => {
  return axios.get("/user-roles");
};

const update_role_code = (roleCode, dataBody) => {
  return axios.put("/user-roles/" + roleCode, dataBody);
};

const add_role_code = (dataBody) => {
  return axios.post("/user-roles", dataBody);
};

export default {
  get_user_role,
  update_role_code,
  add_role_code,
};
