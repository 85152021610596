import axios from "@/api/axios";

const registration = (apiParams) => {
  return axios.get("/registration/with-dictionaries?" + apiParams);
};

const sendSms = (loginsString) => {
  return axios.get("/init/send-sms?terminalIdList=" + loginsString);
};

const migrateMerchantToFata = (merchantID) => {
  return axios.get("/fata-proxy-server/migrate-merchant/" + merchantID);
};

const updateMerchant = (merchantObj) => {
  return axios.post("/registration/update", {
    ...merchantObj,
  });
};

const uploadRegFile = (formData) => {
  const formDataObj = formData.get("file");
  return axios.post(
    "/registration/batch?filename=" + formDataObj.name,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

const addNewMerchant = (mObj) => {
  return axios.post("/registration", { ...mObj });
};

export default {
  registration,
  sendSms,
  uploadRegFile,
  addNewMerchant,
  migrateMerchantToFata,
  updateMerchant,
};
