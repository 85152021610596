import axios from "@/api/axios";

const getMessageTemplate = (apiParams) => {
  return axios.get("/message-template?" + apiParams);
};

const addMessageTemplate = (reqObj) => {
  return axios.post("/message-template", { ...reqObj });
};

const deleteMessageTemplate = (id) => {
  return axios.delete("/message-template/" + id);
};

const sendMessage = (reqObj) => {
  return axios.post("/messages", { ...reqObj });
};

export default {
  getMessageTemplate,
  addMessageTemplate,
  deleteMessageTemplate,
  sendMessage,
};
