<template>
  <table class="table">
    <thead>
      <tr>
        <th scope="col" class="title-column-name">
          {{ $t(`uiElements.text.columnName`) }}
        </th>
        <th scope="col" class="text-center">
          {{ $t(`uiElements.text.show`) }}
          <input
            type="checkbox"
            id="changeAllColumns"
            v-model="isAllColumnsVisible"
          />
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in columns" :key="item">
        <td>{{ item.title }}</td>
        <td class="text-center">
          <input
            type="checkbox"
            :id="item.key"
            v-model="item.visible"
            :disabled="item.required"
          />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { setItemLocalStorage } from "@/helpers/persistanceStorage";

export default {
  name: "TrnTableColumnSettings",
  props: {
    columns: {
      type: Array,
      required: true,
    },
    pageTableViewName: {
      type: String,
      required: true,
    },
  },
  computed: {
    isAllColumnsVisible: {
      get() {
        return this.columns.every((item) => item.visible);
      },
      set(value) {
        this.columns.forEach((item) => {
          if (item.required) {
            return;
          }
          item.visible = value;
        });
      },
    },
  },
  emits: ["updateColumns"],
  watch: {
    columns: {
      handler: function (val) {
        let columnsList = [];
        val.forEach((item) => {
          columnsList.push({
            key: item.key,
            visible: item.visible,
          });
        });
        setItemLocalStorage(this.pageTableViewName, columnsList);
        this.$emit("updateColumns");
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.title-column-name {
  vertical-align: top;
}
</style>
