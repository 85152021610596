import axios from "@/api/axios";

const transactions = (apiUrl) => {
  return axios.get(apiUrl);
};

const terminalId = (value) => {
  return axios.get("/terminals?terminalId=" + value);
};

const deviceId = (value) => {
  return axios.get("/devices/terminal/" + value);
};

const receiptNumber = (value) => {
  return axios.get(`/transactions/receipt?lang=RUS&receiptDataId=${value}`);
};

const runStubEcho = () => {
  return axios.get("/transactions/stub_echo");
};

const reversal = (value) => {
  return axios.post("/transactions/reversal-web", value);
};

export default {
  transactions,
  terminalId,
  receiptNumber,
  deviceId,
  runStubEcho,
  reversal,
};
