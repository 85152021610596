import entityAuditApi from "@/api/settings-api/entity-audit";

const state = {
  dataResp: null,
  dataModalResp: null,

  isLoading: false,
  isLoadingScroll: false,

  errorResp: null,
};

export const mutationTypes = {
  getEntityAuditStart: "[EntityAudit] getEntityAudit start",
  getEntityAuditSuccess: "[EntityAudit] getEntityAudit success",
  getEntityAuditFailure: "[EntityAudit] getEntityAudit failure",

  getEntityAuditScrollPushStart: "[EntityAudit] getEntityAuditScrollPush start",
  getEntityAuditScrollPushSuccess:
    "[EntityAudit] getEntityAuditScrollPushSuccess success",
  getEntityAuditScrollPushFailure:
    "[EntityAudit] getEntityAuditScrollPush failure",
};

export const actionTypes = {
  getEntityAudit: "[EntityAudit] getEntityAudit",
  getEntityAuditScrollPush: "[EntityAudit] getEntityAuditScrollPush",
};

const mutations = {
  [mutationTypes.getEntityAuditStart](state) {
    state.isLoading = true;
    state.data = null;
  },
  [mutationTypes.getEntityAuditSuccess](state, payload) {
    state.isLoading = false;
    state.dataResp = payload;
  },
  [mutationTypes.getEntityAuditFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
  [mutationTypes.getEntityAuditScrollPushStart](state) {
    state.isLoadingScroll = true;
  },
  [mutationTypes.getEntityAuditScrollPushSuccess](state, payload) {
    for (let item in payload.content) {
      state.dataResp.content.push(payload.content[item]);
    }
    state.isLoadingScroll = false;
  },
  [mutationTypes.getEntityAuditScrollPushFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
};

const actions = {
  [actionTypes.getEntityAudit](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getEntityAuditStart, apiParams);
      entityAuditApi
        .get_entity_audit(apiParams)
        .then((response) => {
          context.commit(mutationTypes.getEntityAuditSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getEntityAuditFailure,
            result.response.data.error
          );
        });
    });
  },
  [actionTypes.getEntityAuditScrollPush](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getEntityAuditScrollPushStart, apiParams);
      entityAuditApi
        .get_entity_audit(apiParams)
        .then((response) => {
          context.commit(
            mutationTypes.getEntityAuditScrollPushSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getEntityAuditScrollPushFailure,
            result.response.data
          );
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
