import { createStore } from "vuex";
import auth from "@/store/modules/auth";
import settlementReport from "@/store/modules/settlement-report";
import transactions from "@/store/modules/transaction";
import merchant from "@/store/modules/merchant";
import terminalKeys from "@/store/modules/terminal-keys";
import terminal from "@/store/modules/terminal";
import devices from "@/store/modules/devices";
import terminalGroups from "@/store/modules/terminal-groups";
import merchantRegistration from "@/store/modules/merchant-registration";
import attestationHistory from "@/store/modules/attestation-history";
import receiptSendAudits from "@/store/modules/receipt-send-audits";
import zReportTransactions from "@/store/modules/z-report-transactions";
import users from "@/store/modules/users";
import chatHistory from "@/store/modules/chat-history";
import businessDayCalculation from "@/store/modules/business-day-calculation";
import investigation from "@/store/modules/investigation";

import settingBanks from "@/store/modules/settings-store/settings-banks";
import settingUserRole from "@/store/modules/settings-store/user-roles";
import settingEntityAudit from "@/store/modules/settings-store/entity-audit";
import settingTerminalFacility from "@/store/modules/settings-store/terminal-facility";
import receiptTemplate from "@/store/modules/settings-store/receipt-remplate";
import ipsCardGroups from "@/store/modules/settings-store/ips-card-groups";
import products from "@/store/modules/settings-store/products";
import ipsKeys from "@/store/modules/settings-store/ips-keys";
import tmsKeys from "@/store/modules/settings-store/tms-keys";
import attestationSequenceThreats from "@/store/modules/settings-store/attestation-sequence-threats";
import version from "@/store/modules/settings-store/version";
import schedule from "@/store/modules/settings-store/schedule";
import vendorRSO from "@/store/modules/settings-store/vendor-aidl";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    settlementReport,
    transactions,
    merchantRegistration,
    settingBanks,
    settingUserRole,
    settingEntityAudit,
    settingTerminalFacility,
    merchant,
    terminalKeys,
    terminal,
    terminalGroups,
    receiptTemplate,
    devices,
    ipsCardGroups,
    products,
    ipsKeys,
    tmsKeys,
    attestationSequenceThreats,
    attestationHistory,
    receiptSendAudits,
    zReportTransactions,
    users,
    chatHistory,
    version,
    schedule,
    businessDayCalculation,
    investigation,
    vendorRSO,
  },
});
