<template>
  <div class="row">
    <div class="col-md-6">
      <div class="mb-3">
        <label for="bank_name" class="form-label">Назва банку *</label>
        <input
          type="text"
          class="form-control"
          id="bank_name"
          v-model.trim="v$.bank_obj.bank_name.$model"
        />
        <div v-if="v$.bank_obj.bank_name.required.$invalid" class="text-danger">
          Назва банку є обов'язковим полем.
        </div>
      </div>

      <div class="mb-3">
        <label for="address" class="form-label">Адреса *</label>
        <input
          type="text"
          class="form-control"
          id="address"
          v-model.trim="v$.bank_obj.address.$model"
        />
        <div v-if="v$.bank_obj.address.required.$invalid" class="text-danger">
          Адреса є обов'язковим полем.
        </div>
      </div>

      <div class="mb-3">
        <label for="email" class="form-label">Електронна пошта *</label>
        <input
          type="email"
          class="form-control"
          id="email"
          v-model.trim="v$.bank_obj.email.$model"
        />
        <div v-if="v$.bank_obj.email.required.$invalid" class="text-danger">
          Електронна пошта є обов'язковим полем.
        </div>
        <div v-if="v$.bank_obj.email.email.$invalid" class="text-danger">
          Введіть коректну адресу електронної пошти.
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="mb-3">
        <label for="mfo" class="form-label">МФО *</label>
        <input
          type="text"
          class="form-control"
          id="mfo"
          v-model.trim="v$.bank_obj.mfo.$model"
        />
        <div v-if="v$.bank_obj.mfo.required.$invalid" class="text-danger">
          МФО є обов'язковим полем.
        </div>
      </div>

      <div class="mb-3">
        <label for="phone" class="form-label">Телефон *</label>
        <input
          type="text"
          class="form-control"
          id="phone"
          v-model.trim="v$.bank_obj.phone.$model"
        />
        <div v-if="v$.bank_obj.phone.required.$invalid" class="text-danger">
          Телефон є обов'язковим полем.
        </div>
      </div>

      <div class="mb-3">
        <label for="tax_id" class="form-label">Ідентифікаційний код *</label>
        <input
          type="text"
          class="form-control"
          id="tax_id"
          v-model.trim="v$.bank_obj.tax_id.$model"
        />
        <div v-if="v$.bank_obj.tax_id.required.$invalid" class="text-danger">
          Ідентифікаційний код є обов'язковим полем.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";

export default {
  name: "TrnSetupConfigBank",
  setup() {
    return { v$: useVuelidate() };
  },
  props: {
    bank_obj: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      minimumNoPinLimit: 5,
      maximumOneTransactionLimit: 5,
    };
  },
  validations() {
    return {
      bank_obj: {
        bank_name: {
          required,
        },
        address: {
          required,
        },
        email: {
          required,
          email,
        },
        mfo: {
          required,
        },
        phone: {
          required,
        },
        tax_id: {
          required,
        },
      },
    };
  },
  methods: {},
};
</script>

<style scoped></style>
