import axios from "@/api/axios";

const getBusinessDayCalculation = (apiParams) => {
  return axios.get("/terminal-used-facility?" + apiParams);
};

const getBusinessDayCalculationArchive = (apiParams) => {
  return axios.get("/terminal-billing?" + apiParams);
};

const makeBillingForAll = () => {
  return axios.post("/terminal-billing/make-billing-for-all");
};

export default {
  getBusinessDayCalculation,
  getBusinessDayCalculationArchive,
  makeBillingForAll,
};
