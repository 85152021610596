import receiptSendAuditsApi from "@/api/receipt-send-audits";

const state = {
  dataResp: null,
  dataModalResp: null,

  isLoading: false,
  isLoadingScroll: false,
  isLoadingModal: false,

  errorResp: null,
};

export const mutationTypes = {
  getReceiptSendAuditsStart: "[ReceiptSendAudits] getReceiptSendAudits start",
  getReceiptSendAuditsSuccess:
    "[ReceiptSendAudits] getReceiptSendAudits success",
  getReceiptSendAuditsFailure:
    "[ReceiptSendAudits] getReceiptSendAudits failure",

  getReceiptSendAuditsScrollPushStart:
    "[ReceiptSendAudits] getReceiptSendAuditsScrollPush start",
  getReceiptSendAuditsScrollPushSuccess:
    "[ReceiptSendAudits] getReceiptSendAuditsScrollPushSuccess success",
  getReceiptSendAuditsScrollPushFailure:
    "[ReceiptSendAudits] getReceiptSendAuditsScrollPush failure",
};

export const actionTypes = {
  getReceiptSendAudits: "[ReceiptSendAudits] getReceiptSendAudits",
  getReceiptSendAuditsScrollPush:
    "[ReceiptSendAudits] getReceiptSendAuditsScrollPush",
};

const mutations = {
  [mutationTypes.getReceiptSendAuditsStart](state) {
    state.isLoading = true;
    state.data = null;
  },
  [mutationTypes.getReceiptSendAuditsSuccess](state, payload) {
    state.isLoading = false;
    state.dataResp = payload;
  },
  [mutationTypes.getReceiptSendAuditsFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
  [mutationTypes.getReceiptSendAuditsScrollPushStart](state) {
    state.isLoadingScroll = true;
  },
  [mutationTypes.getReceiptSendAuditsScrollPushSuccess](state, payload) {
    for (let item in payload.content) {
      state.dataResp.content.push(payload.content[item]);
    }
    state.isLoadingScroll = false;
  },
  [mutationTypes.getReceiptSendAuditsScrollPushFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
};

const actions = {
  [actionTypes.getReceiptSendAudits](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getReceiptSendAuditsStart, apiParams);
      receiptSendAuditsApi
        .getReceiptSendAudits(apiParams)
        .then((response) => {
          context.commit(
            mutationTypes.getReceiptSendAuditsSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getReceiptSendAuditsFailure,
            result.response.data.error
          );
        });
    });
  },
  [actionTypes.getReceiptSendAuditsScrollPush](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(
        mutationTypes.getReceiptSendAuditsScrollPushStart,
        apiParams
      );
      receiptSendAuditsApi
        .getReceiptSendAudits(apiParams)
        .then((response) => {
          context.commit(
            mutationTypes.getReceiptSendAuditsScrollPushSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getReceiptSendAuditsScrollPushFailure,
            result.response.data
          );
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
