import investigationApi from "@/api/investigation";

const state = {
  dataResp: null,
  dataModalResp: null,

  isLoading: false,
  isLoadingScroll: false,
  isLoadingModal: false,

  errorResp: null,
};

export const mutationTypes = {
  getInvestigationStart: "[Investigation] getInvestigation start",
  getInvestigationSuccess: "[Investigation] getInvestigation success",
  getInvestigationFailure: "[Investigation] getInvestigation failure",

  getInvestigationScrollPushStart:
    "[Investigation] getInvestigationScrollPush start",
  getInvestigationScrollPushSuccess:
    "[Investigation] getInvestigationScrollPushSuccess success",
  getInvestigationScrollPushFailure:
    "[Investigation] getInvestigationScrollPush failure",
};

export const actionTypes = {
  getInvestigation: "[Investigation] getInvestigation",
  getInvestigationScrollPush: "[Investigation] getInvestigationScrollPush",
};

const mutations = {
  [mutationTypes.getInvestigationStart](state) {
    state.isLoading = true;
    state.data = null;
  },
  [mutationTypes.getInvestigationSuccess](state, payload) {
    state.isLoading = false;
    state.dataResp = payload;
  },
  [mutationTypes.getInvestigationFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
  [mutationTypes.getInvestigationScrollPushStart](state) {
    state.isLoadingScroll = true;
  },
  [mutationTypes.getInvestigationScrollPushSuccess](state, payload) {
    for (let item in payload.content) {
      state.dataResp.content.push(payload.content[item]);
    }
    state.isLoadingScroll = false;
  },
  [mutationTypes.getInvestigationScrollPushFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
};

const actions = {
  [actionTypes.getInvestigation](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getInvestigationStart, apiParams);
      investigationApi
        .getInvestigation(apiParams)
        .then((response) => {
          context.commit(mutationTypes.getInvestigationSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getInvestigationFailure,
            result.response.data.error
          );
        });
    });
  },
  [actionTypes.getInvestigationScrollPush](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getInvestigationScrollPushStart, apiParams);
      investigationApi
        .getInvestigation(apiParams)
        .then((response) => {
          context.commit(
            mutationTypes.getInvestigationScrollPushSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getInvestigationScrollPushFailure,
            result.response.data
          );
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
