<template>
  <div class="main">
    <div class="auth-page d-flex align-items-center justify-content-center">
      <div class="border border-secondary p-3 bg-white login-block">
        <h2 class="text-center">{{ $t(`uiElements.text.signInPanel`) }}</h2>

        <ul class="error-messages bg-danger" v-if="validationErrors">
          <li class="text-white">
            {{ validationErrors }}
          </li>
        </ul>
        <form v-if="isPasswordExpired" @submit.prevent="onSubmitChangePassword">
          <!--  input-password  -->
          <div class="mb-3">
            <label for="input-password" class="col-sm-6 col-form-label">
              {{ $t(`uiElements.text.password`) }}
              <span class="text-danger">*</span>
            </label>
            <div class="">
              <input
                type="password"
                class="form-control"
                :class="addClassValidInput('formChangePassword', 'password')"
                id="input-password"
                v-model="v$.formChangePassword.password.$model"
              />
              <div class="invalid-feedback">
                <div v-if="v$.formChangePassword.password.required.$invalid">
                  {{ $t(`Validations.required`) }}
                </div>
                <div
                  v-if="v$.formChangePassword.password.passRule1.$invalid"
                  v-html="
                    $t(`Validations.passRule1`, {
                      specChar: '@,#,$,%,^,&,+,=,!',
                    })
                  "
                ></div>
                <div
                  v-if="v$.formChangePassword.password.passRule2.$invalid"
                  v-html="$t(`Validations.passRule2`)"
                ></div>
              </div>
            </div>
          </div>

          <!--  input-userPasswordRepeatRepeat  -->
          <div class="row mb-3">
            <label for="input-userPasswordRepeat" class="col-form-label">
              {{ $t(`Validations.passwordRepeat`) }}
              <span class="text-danger">*</span>
            </label>
            <div class="">
              <input
                type="password"
                class="form-control"
                :class="
                  addClassValidInput('formChangePassword', 'userPasswordRepeat')
                "
                id="input-userPasswordRepeat"
                v-model="v$.formChangePassword.userPasswordRepeat.$model"
              />
              <div class="invalid-feedback">
                <div
                  v-if="
                    v$.formChangePassword.userPasswordRepeat.required.$invalid
                  "
                >
                  {{ $t(`Validations.required`) }}
                </div>
                <div
                  v-if="
                    v$.formChangePassword.userPasswordRepeat.sameAs.$invalid
                  "
                >
                  {{
                    $t(
                      `MerchantRegistration.validationRegForm.passwordRepeatErr`
                    )
                  }}
                </div>
              </div>
            </div>
          </div>

          <div class="text-center">
            <button
              class="btn btn-lg btn-primary col-12"
              :disabled="
                isSubmitting && v$.$invalid && v$.$dirty ? true : false
              "
            >
              {{ $t(`uiElements.btn.change`) }}
            </button>
          </div>
          <div class="text-center mt-3">© Transenix 2024</div>
        </form>
        <form v-else @submit.prevent="onSubmit">
          <div class="mb-3">
            <label for="username" class="form-label">{{
              $t(`uiElements.text.login`)
            }}</label>
            <input
              type="text"
              class="form-control"
              id="username"
              placeholder=""
              v-model="username"
            />
          </div>
          <div class="mb-3">
            <label for="password" class="form-label">{{
              $t(`uiElements.text.password`)
            }}</label>
            <input
              type="password"
              class="form-control"
              id="password"
              v-model="password"
            />
          </div>
          <div class="text-center">
            <button
              class="btn btn-lg btn-primary col-12"
              :disabled="isSubmitting"
            >
              {{ $t(`uiElements.btn.signIn`) }}
            </button>
          </div>
          <div class="text-center mt-3">© Transenix 2024</div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import authApi from "@/api/auth";
import userApi from "@/api/users";
import { setItemLocalStorage } from "@/helpers/persistanceStorage";
import useVuelidate from "@vuelidate/core";
import { helpers, required, sameAs } from "@vuelidate/validators";

export default {
  name: "TrnLogin",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {},
  data() {
    return {
      username: "",
      password: "",
      isSubmitting: false,
      isLoggedIn: null,
      isLoading: false,
      currentUser: null,
      validationErrors: null,
      isPasswordExpired: false,
      formChangePassword: {},
    };
  },
  validations() {
    return {
      formChangePassword: {
        password: {
          required,
          passRule1: helpers.regex(
            /^(?=.*\d)(?=.*[A-Z])(?=.*[@#$%^&+=!])(?!.*\s).{8,}$/
          ),
          passRule2: helpers.regex(/^(?!.*(.)\1\1\1)[\s\S]*$/),
        },
        userPasswordRepeat: {
          required,
          sameAs: sameAs(this.formChangePassword.password),
        },
      },
    };
  },
  methods: {
    onSubmit() {
      this.isSubmitting = true;
      authApi
        .login({
          username: this.username,
          password: this.password,
        })
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          this.isSubmitting = false;
          // console.log("result", result);
          if (result.status === 200) {
            // console.log("result", result);
            if (result.data?.isForbidToChangePassword === true) {
              this.validationErrors = this.$t(
                "Validations.isForbidToChangePassword"
              );
              return;
            }

            this.isPasswordExpired = result.data?.isPasswordExpired;
            if (this.isPasswordExpired) {
              setItemLocalStorage("accessToken", result.data.token);
              this.validationErrors = this.$t("Validations.passwordExpired");
              return;
            }
            setItemLocalStorage("accessToken", result.data.token);
            setItemLocalStorage("authorities", result.data.authorities);
            setItemLocalStorage("userName", this.username);
            setItemLocalStorage(
              "isIncludeServiceOfFata",
              result.data.isIncludeServiceOfFata
            );
            if (result.data?.isPasswordExpiresSoon === true) {
              alert(this.$t("Validations.passwordExpiresSoon"));
            }
            this.$router.push({ name: "transactions" });
          } else {
            console.log("err");
            this.validationErrors = result.response.data.error.message;
          }
        });
    },
    onSubmitChangePassword() {
      this.isSubmitting = true;
      this.v$.formChangePassword.$touch();
      if (!this.v$.formChangePassword.$error) {
        userApi
          .changeCurrentPasswordUser({
            password: this.formChangePassword.password,
          })
          .then(function (result) {
            return result;
          })
          .catch(function (result) {
            return result;
          })
          .then((result) => {
            this.isSubmitting = false;
            // console.log("result", result);
            if (result.status === 200) {
              // console.log("result", result);
              setItemLocalStorage("accessToken", result.data.token);
              setItemLocalStorage("authorities", result.data.authorities);
              setItemLocalStorage("userName", this.username);
              setItemLocalStorage(
                "isIncludeServiceOfFata",
                result.data.isIncludeServiceOfFata
              );
              this.$router.push({ name: "transactions" });
            } else {
              console.log("err");
              this.validationErrors = result.response.data.error.message;
            }
          });
      }
    },
    addClassValidInput(formName, inputName) {
      return {
        "is-invalid":
          this.v$[formName][inputName].$error === true &&
          this.v$[formName][inputName].$dirty === true,
        "is-valid":
          this.v$[formName][inputName].$error === false &&
          this.v$[formName][inputName].$dirty === true,
      };
    },
  },
};
</script>

<style scoped>
.main {
  background: url("~@/assets/img/bg-login2.webp") no-repeat center center;
  background-size: cover;
}

.auth-page {
  height: 100vh;
}

.login-block {
  width: 350px;
}
</style>
