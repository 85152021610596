<template>
  <div class="title-table mb-2 ps-3 py-1 fw-bold">
    {{ $t(`settings-menu.banks`) }}
  </div>
  <div class="table-responsive table-block overflow-auto">
    <table class="table table-striped table-hover table-sm table-light">
      <thead class="text-center">
        <tr>
          <th scope="col">{{ $t(`Settings.Banks.table.id`) }}</th>
          <th scope="col">{{ $t(`Settings.Banks.table.name`) }}</th>
          <th scope="col">{{ $t(`Settings.Banks.table.address`) }}</th>
          <th scope="col">{{ $t(`Settings.Banks.table.email`) }}</th>
          <th scope="col">{{ $t(`Settings.Banks.table.phone`) }}</th>
          <th scope="col">{{ $t(`Settings.Banks.table.taxId`) }}</th>
          <th scope="col">{{ $t(`Settings.Banks.table.mfo`) }}</th>
          <th scope="col">{{ $t(`Settings.Banks.table.instructions`) }}</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="isLoading">
          <tr>
            <td colspan="8">
              <div class="loading text-center">
                <trn-loading></trn-loading>
              </div>
            </td>
          </tr>
        </template>
        <template v-if="dataResp">
          <tr
            v-for="item in dataResp.content"
            :key="item"
            class="text-center cursor-pointer"
            @click="updateForm(item)"
          >
            <th scope="row">{{ item.id }}</th>
            <td>{{ item.name }}</td>
            <td>{{ item.address }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.phone }}</td>
            <td>{{ item.taxId }}</td>
            <td>{{ item.mfo }}</td>
            <td>{{ item.instructions }}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
  <bs-modal
    :modal-toggle-emit="showModal"
    @hideMainModal="showModal = false"
    :title="$t(`settings-menu.banks`)"
  >
    <trn-setting-banks-form
      :form="formItems"
      @hideMainModal="showModal = false"
    ></trn-setting-banks-form>
  </bs-modal>
</template>

<script>
import TrnLoading from "@/components/Loading";
import { actionTypes } from "@/store/modules/settings-store/settings-banks";
import { mapState } from "vuex";
import BsModal from "@/components/UI-elements/BsModal";
import TrnSettingBanksForm from "@/components/block-pages/SettingBanksForm";

export default {
  name: "TrnBanks",
  components: { TrnSettingBanksForm, BsModal, TrnLoading },
  emits: ["hideMainModal"],
  data() {
    return {
      showModal: false,
      formItems: {},
    };
  },
  methods: {
    fetchBanks() {
      this.$store.dispatch(actionTypes.getSettingsBanks, {});
    },
    updateForm(items) {
      this.formItems = items;
      this.showModal = !this.showModal;
    },
  },
  computed: {
    dataResp: {
      get() {
        return this.$store.state.settingBanks.dataResp;
      },
      set(newValue) {
        this.$store.state.settingBanks.dataResp = newValue;
      },
    },
    ...mapState({
      isLoading: (state) => state.settingBanks.isLoading,
      // isLoadingScroll: (state) => state.transactions.isLoadingScroll,
      // dataResp: (state) => state.transactions.dataResp,
      dataModalResp: (state) => state.settingBanks.dataModalResp,
      errorResp: (state) => state.settingBanks.errorResp,
    }),
  },
  mounted() {
    this.fetchBanks();
  },
};
</script>

<style scoped></style>
