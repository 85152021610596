import ipsCardGroupsApi from "@/api/settings-api/ips-card-groups";

const state = {
  dataResp: null,
  dataModalResp: null,

  isLoading: false,
  isLoadingScroll: false,
  isLoadingModal: false,

  errorResp: null,
};

export const mutationTypes = {
  getIpsCardGroupsStart: "[IpsCardGroups] getIpsCardGroups start",
  getIpsCardGroupsSuccess: "[IpsCardGroups] getIpsCardGroups success",
  getIpsCardGroupsFailure: "[IpsCardGroups] getIpsCardGroups failure",

  getIpsCardGroupsScrollPushStart:
    "[IpsCardGroups] getIpsCardGroupsScrollPush start",
  getIpsCardGroupsScrollPushSuccess:
    "[IpsCardGroups] getIpsCardGroupsScrollPushSuccess success",
  getIpsCardGroupsScrollPushFailure:
    "[IpsCardGroups] getIpsCardGroupsScrollPush failure",
};

export const actionTypes = {
  getIpsCardGroups: "[IpsCardGroups] getIpsCardGroups",
  getIpsCardGroupsScrollPush: "[IpsCardGroups] getIpsCardGroupsScrollPush",
};

const mutations = {
  [mutationTypes.getIpsCardGroupsStart](state) {
    state.isLoading = true;
    state.data = null;
  },
  [mutationTypes.getIpsCardGroupsSuccess](state, payload) {
    state.isLoading = false;
    state.dataResp = payload;
  },
  [mutationTypes.getIpsCardGroupsFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
  [mutationTypes.getIpsCardGroupsScrollPushStart](state) {
    state.isLoadingScroll = true;
  },
  [mutationTypes.getIpsCardGroupsScrollPushSuccess](state, payload) {
    for (let item in payload.content) {
      state.dataResp.content.push(payload.content[item]);
    }
    state.isLoadingScroll = false;
  },
  [mutationTypes.getIpsCardGroupsScrollPushFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
};

const actions = {
  [actionTypes.getIpsCardGroups](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getIpsCardGroupsStart, apiParams);
      ipsCardGroupsApi
        .getIpsCardGroups(apiParams)
        .then((response) => {
          context.commit(mutationTypes.getIpsCardGroupsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getIpsCardGroupsFailure,
            result.response.data.error
          );
        });
    });
  },
  [actionTypes.getIpsCardGroupsScrollPush](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getIpsCardGroupsScrollPushStart, apiParams);
      ipsCardGroupsApi
        .getIpsCardGroups(apiParams)
        .then((response) => {
          context.commit(
            mutationTypes.getIpsCardGroupsScrollPushSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getIpsCardGroupsScrollPushFailure,
            result.response.data
          );
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
