<template>
  <div class="title-table mb-2 ps-3 py-1 fw-bold">
    {{ $t(`left-menu.attestationHistory`) }}
  </div>
  <!-- Фільтр -->
  <div class="toolbar">
    <div class="btn-group mb-2">
      <button
        class="btn btn-outline-primary btn-sm dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        data-bs-auto-close="outside"
        aria-expanded="false"
        ref="dropdownBtnFilter"
      >
        <i class="bi bi-filter-square me-2"></i>
        <span>{{ $t(`uiElements.btn.filters`) }}</span>
      </button>
      <button
        class="btn btn-outline-primary btn-sm"
        type="button"
        @click="resetFilterForm"
      >
        <i class="bi bi-x-square"></i>
      </button>
      <form
        class="dropdown-menu p-4"
        style="width: 500px"
        @submit.prevent="formFilterRm"
        ref="dropdownFormFilter"
      >
        <div class="row mb-3">
          <label
            for="deviceSn-ff"
            class="col-sm-4 col-form-label col-form-label-sm"
            >{{ $t(`AttestationHistory.table.deviceSn`) }}</label
          >
          <div class="col-sm-8">
            <input
              type="text"
              class="form-control form-control-sm"
              id="deviceSn-ff"
              v-model.trim="filters['device.deviceSn']"
              :placeholder="$t(`Validations.notChosen`)"
            />
          </div>
        </div>

        <div class="row mb-3">
          <label
            for="terminalId-ff"
            class="col-sm-4 col-form-label col-form-label-sm"
            >{{ $t(`AttestationHistory.table.terminalId`) }}</label
          >
          <div class="col-sm-8">
            <input
              v-model.trim="filters['device.terminal.terminalId']"
              type="text"
              class="form-control form-control-sm"
              id="terminalId-ff"
              :placeholder="$t(`Validations.notChosen`)"
            />
          </div>
        </div>

        <div class="justify-content-end d-flex">
          <button
            class="btn btn-outline-primary btn-sm me-3"
            type="button"
            @click="resetFilterForm"
          >
            <i class="bi bi-x-square me-1"></i>
            <span>{{ $t(`uiElements.btn.resetFilters`) }}</span>
          </button>
          <button type="submit" class="btn btn-primary btn-sm">
            <i class="bi bi-clipboard-check me-1"></i>
            <span>{{ $t(`uiElements.btn.apply`) }}</span>
          </button>
        </div>
      </form>
    </div>
  </div>

  <!-- Фільтр кінець -->

  <div
    class="table-responsive table-block overflow-auto"
    id="table-block"
    @scroll="scrollTable"
  >
    <table class="table table-striped table-hover table-sm table-light">
      <thead>
        <tr class="text-center">
          <th
            scope="col"
            data-col-sort="device-deviceSn"
            @click="clickSortCol($event, 'device.deviceSn')"
          >
            {{ $t(`AttestationHistory.table.deviceSn`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>
          <th
            scope="col"
            data-col-sort="device-terminal-terminalId"
            @click="clickSortCol($event, 'device.terminal.terminalId')"
          >
            {{ $t(`AttestationHistory.table.terminalId`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>
          <th
            scope="col"
            data-col-sort="attestationPhase"
            @click="clickSortCol($event, 'attestationPhase')"
          >
            {{ $t(`AttestationHistory.table.attestationPhase`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>
          <th
            scope="col"
            data-col-sort="declined"
            @click="clickSortCol($event, 'declined')"
          >
            {{ $t(`AttestationHistory.table.declined`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>
          <th
            scope="col"
            data-col-sort="date"
            @click="clickSortCol($event, 'date')"
          >
            {{ $t(`AttestationHistory.table.date`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>
          <th
            scope="col"
            data-col-sort="attestationActions"
            @click="clickSortCol($event, 'attestationActions')"
          >
            {{ $t(`Attestation.sequenceThreats.table.attestationActions`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>
          <th
            scope="col"
            data-col-sort="integrity"
            @click="clickSortCol($event, 'integrity')"
          >
            {{ $t(`Attestation.threat.table.INTEGRITY`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>
          <th
            scope="col"
            data-col-sort="root"
            @click="clickSortCol($event, 'root')"
          >
            {{ $t(`Attestation.threat.table.ROOT`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>
          <th
            scope="col"
            data-col-sort="debug"
            @click="clickSortCol($event, 'debug')"
          >
            {{ $t(`Attestation.threat.table.DEBUG`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>
          <th
            scope="col"
            data-col-sort="emulator"
            @click="clickSortCol($event, 'emulator')"
          >
            {{ $t(`Attestation.threat.table.EMULATOR`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>
          <th
            scope="col"
            data-col-sort="geoPosition"
            @click="clickSortCol($event, 'geoPosition')"
          >
            {{ $t(`Attestation.threat.table.GEO_POSITION`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>
          <th
            scope="col"
            data-col-sort="velocity"
            @click="clickSortCol($event, 'velocity')"
          >
            {{ $t(`Attestation.threat.table.VELOCITY_CHECK`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>
          <th
            scope="col"
            data-col-sort="channelIntegrity"
            @click="clickSortCol($event, 'channelIntegrity')"
          >
            {{ $t(`Attestation.threat.table.CHANNEL_INTEGRITY`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>
          <th
            scope="col"
            data-col-sort="installSource"
            @click="clickSortCol($event, 'installSource')"
          >
            {{ $t(`Attestation.threat.table.INSTALL_SOURCE`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>
          <th
            scope="col"
            data-col-sort="keyAttestation"
            @click="clickSortCol($event, 'keyAttestation')"
          >
            {{ $t(`Attestation.threat.table.KEY_ATTESTATION`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>
          <th
            scope="col"
            data-col-sort="pinChannelIntegrity"
            @click="clickSortCol($event, 'pinChannelIntegrity')"
          >
            {{ $t(`AttestationHistory.table.pinChannelIntegrity`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>
        </tr>
      </thead>
      <tbody class="text-nowrap">
        <template v-if="isLoading">
          <tr>
            <td colspan="16">
              <div class="loading text-center">
                <trn-loading></trn-loading>
              </div>
            </td>
          </tr>
        </template>
        <template v-if="dataResp">
          <tr v-for="item in dataResp.content" :key="item" class="text-center">
            <td>{{ item.device.deviceSn }}</td>
            <td>{{ item.device.terminal?.terminalId }}</td>
            <td>{{ item.attestationPhase }}</td>
            <td>{{ item.declined }}</td>
            <td>{{ $filters.formatDateTime(item.date) }}</td>
            <td>{{ item.attestationActions[0].shortName }}</td>
            <td>{{ item.integrity }}</td>
            <td>{{ item.root }}</td>
            <td>{{ item.debug }}</td>
            <td>{{ item.emulator }}</td>
            <td>{{ item.geoPosition }}</td>
            <td>{{ item.velocity }}</td>
            <td>{{ item.channelIntegrity }}</td>
            <td>{{ item.installSource }}</td>
            <td>{{ item.keyAttestation }}</td>
            <td>{{ item.pinChannelIntegrity ? item.pinChannelIntegrity : "-" }}</td>
          </tr>
          <template v-if="isLoadingScroll">
            <tr>
              <td colspan="16">
                <div class="loading text-center">
                  <trn-loading></trn-loading>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import TrnLoading from "@/components/Loading";
import { mapState } from "vuex";
import { stringify } from "query-string";
import { actionTypes } from "@/store/modules/attestation-history";
import useVuelidate from "@vuelidate/core";

export default {
  name: "TrnAttestationHistory",
  components: {
    TrnLoading,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      page: 0,
      size: 50,
      sort: {
        col: "device.deviceSn",
        order: "desc",
      },
      filters: {
        "device.deviceSn": "",
        "device.terminal.terminalId": "",
      },
    };
  },
  computed: {
    dataResp: {
      get() {
        return this.$store.state.attestationHistory.dataResp;
      },
      set(newValue) {
        this.$store.state.attestationHistory.dataResp = newValue;
      },
    },
    ...mapState({
      isLoading: (state) => state.attestationHistory.isLoading,
      isLoadingScroll: (state) => state.attestationHistory.isLoadingScroll,
      dataModalResp: (state) => state.attestationHistory.dataModalResp,
      errorResp: (state) => state.attestationHistory.errorResp,
    }),
  },
  methods: {
    fetchAtHistory() {
      this.changeArrowIcon();
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
        ...this.filters,
      });
      this.$store.dispatch(actionTypes.getAttestationHistory, {
        apiParams: stringifiedParams,
      });
    },
    scrollTable(event) {
      const currentEl = event.target;
      let checkScrollEnd =
        currentEl.scrollTop + currentEl.clientHeight + 3 >= currentEl.scrollHeight;
      if (checkScrollEnd && this.isLoadingScroll === false) {
        const currentPage = this.page + 1;
        const totalPages = this.dataResp.totalPages;
        if (currentPage < totalPages) this.loadMoreData();
      }
    },
    loadMoreData() {
      this.page += 1;
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
      });
      this.$store.dispatch(actionTypes.getAttestationHistoryScrollPush, {
        apiParams: stringifiedParams,
      });
    },
    reloadPage() {
      this.page = 0;
      this.fetchAtHistory();
    },
    clickSortCol(event, newSortItem) {
      const newSortItemData = newSortItem;
      newSortItem = newSortItem.replaceAll(".", "-");
      const scrollTableEl = document.getElementById("table-block");
      const beforeSortKey = this.sort.col.replaceAll(".", "-");
      const beforeSortDirection = this.sort.order;

      const beforeEl = document.querySelectorAll(
        `[data-col-sort~=${beforeSortKey}]`
      );
      beforeEl[0].children[0].innerHTML = '<i class="bi bi-arrow-down-up"></i>';

      // let newSortVal = [newSortItem, ""];

      if (beforeSortDirection === "desc" && beforeSortKey === newSortItem) {
        this.sort.order = "asc";
      } else {
        this.sort.order = "desc";
      }
      this.page = 0;
      this.sort.col = newSortItemData;

      scrollTableEl.scrollTo(0, 0);
      this.fetchAtHistory();
    },
    changeArrowIcon(sortKey = this.sort.col, sortDirection = this.sort.order) {
      sortKey = sortKey.replaceAll(".", "-");
      const targetEl = document.querySelectorAll(`[data-col-sort~=${sortKey}]`);
      if (sortDirection === "asc") {
        targetEl[0].children[0].innerHTML =
          '<i class="bi bi-arrow-up-square"></i>';
      } else {
        targetEl[0].children[0].innerHTML =
          '<i class="bi bi-arrow-down-square"></i>';
      }
    },
    formFilterRm() {
      this.$refs.dropdownBtnFilter.click();
      this.fetchAtHistory();
    },
    resetFilterForm() {
      for (let item in this.filters) {
        this.filters[item] = "";
      }
      this.$refs.dropdownBtnFilter.click();
      this.fetchAtHistory();
    },
  },
  mounted() {
    this.fetchAtHistory();
  },
};
</script>

<style scoped></style>
