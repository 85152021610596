<template>
  <div class="iframe-content">
    <iframe :srcdoc="this.htmlData" height="100%" width="100%"></iframe>
  </div>
</template>

<script>
export default {
  name: "TrnLoadIframeScrdoc",
  props: {
    htmlData: {
      required: true,
      type: String,
    },
  },
};
</script>

<style scoped>
.iframe-content {
  height: 80vh;
}
</style>
