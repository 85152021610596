import MerchantApi from "@/api/merchant";

const state = {
  dataResp: null,
  dataModalResp: null,

  isLoading: false,
  isLoadingScroll: false,
  isLoadingModal: false,

  errorResp: null,
};

export const mutationTypes = {
  getMerchantStart: "[Merchant] getMerchant start",
  getMerchantSuccess: "[Merchant] getMerchant success",
  getMerchantFailure: "[Merchant] getMerchant failure",

  getMerchantScrollPushStart: "[Merchant] getMerchantScrollPush start",
  getMerchantScrollPushSuccess:
    "[Merchant] getMerchantScrollPushSuccess success",
  getMerchantScrollPushFailure: "[Merchant] getMerchantScrollPush failure",

  getMerchantIdStart: "[Merchant] getMerchantId start",
  getMerchantIdSuccess: "[Merchant] getMerchantId success",
  getMerchantIdFailure: "[Merchant] getMerchantId failure",

  getReceiptNumberStart: "[Merchant] getReceiptNumber start",
  getReceiptNumberSuccess: "[Merchant] getReceiptNumber success",
  getReceiptNumberFailure: "[Merchant] getReceiptNumber failure",
};

export const actionTypes = {
  getMerchant: "[Merchant] getMerchant",
  getMerchantScrollPush: "[Merchant] getMerchantScrollPush",
  getMerchantId: "[Merchant] getMerchantId",
  getReceiptNumber: "[Merchant] getReceiptNumber",
};

const mutations = {
  [mutationTypes.getMerchantStart](state) {
    state.isLoading = true;
    state.data = null;
  },
  [mutationTypes.getMerchantSuccess](state, payload) {
    state.isLoading = false;
    state.dataResp = payload;
  },
  [mutationTypes.getMerchantFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
  [mutationTypes.getMerchantScrollPushStart](state) {
    state.isLoadingScroll = true;
  },
  [mutationTypes.getMerchantScrollPushSuccess](state, payload) {
    for (let item in payload.content) {
      state.dataResp.content.push(payload.content[item]);
    }
    state.isLoadingScroll = false;
  },
  [mutationTypes.getMerchantScrollPushFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },

  [mutationTypes.getMerchantIdStart](state) {
    state.isLoadingModal = true;
    state.dataModalResp = null;
  },
  [mutationTypes.getMerchantIdSuccess](state, payload) {
    state.isLoadingModal = false;
    state.dataModalResp = payload;
  },
  [mutationTypes.getMerchantIdFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },

  [mutationTypes.getReceiptNumberStart](state) {
    state.isLoadingModal = true;
    state.dataModalResp = null;
  },
  [mutationTypes.getReceiptNumberSuccess](state, payload) {
    state.isLoadingModal = false;
    state.dataModalResp = payload;
  },
  [mutationTypes.getReceiptNumberFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
};

const actions = {
  [actionTypes.getMerchant](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getMerchantStart, apiParams);
      MerchantApi.getMerchant(apiParams)
        .then((response) => {
          context.commit(mutationTypes.getMerchantSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getMerchantFailure,
            result.response.data.error
          );
        });
    });
  },
  [actionTypes.getMerchantScrollPush](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getMerchantScrollPushStart, apiParams);
      MerchantApi.getMerchant(apiParams)
        .then((response) => {
          context.commit(
            mutationTypes.getMerchantScrollPushSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getMerchantScrollPushFailure,
            result.response.data
          );
        });
    });
  },
  // [actionTypes.getMerchantId](context, { value }) {
  //   return new Promise((resolve) => {
  //     context.commit(mutationTypes.getMerchantIdStart, value);
  //     MerchantApi.MerchantId(value)
  //       .then((response) => {
  //         context.commit(mutationTypes.getMerchantIdSuccess, response.data);
  //         resolve(response.data);
  //       })
  //       .catch((result) => {
  //         context.commit(
  //           mutationTypes.getMerchantIdFailure,
  //           result.response.data
  //         );
  //       });
  //   });
  // },
};

export default {
  state,
  actions,
  mutations,
};
