import terminalKeysApi from "@/api/terminal-keys";

const state = {
  dataResp: null,
  dataModalResp: null,

  isLoading: false,
  isLoadingScroll: false,
  isLoadingModal: false,

  errorResp: null,

  dataRespAll: null,

  isLoadingAll: false,
  isLoadingScrollAll: false,

  errorRespAll: null,
};

export const mutationTypes = {
  getTerminalKeysStart: "[TerminalKeys] getTerminalKeys start",
  getTerminalKeysSuccess: "[TerminalKeys] getTerminalKeys success",
  getTerminalKeysFailure: "[TerminalKeys] getTerminalKeys failure",

  getFindAllPosKeysStart: "[TerminalKeys] getFindAllPosKeys start",
  getFindAllPosKeysSuccess: "[TerminalKeys] getFindAllPosKeys success",
  getFindAllPosKeysFailure: "[TerminalKeys] getFindAllPosKeys failure",

  getTerminalKeysScrollPushStart:
    "[TerminalKeys] getTerminalKeysScrollPush start",
  getTerminalKeysScrollPushSuccess:
    "[TerminalKeys] getTerminalKeysScrollPushSuccess success",
  getTerminalKeysScrollPushFailure:
    "[TerminalKeys] getTerminalKeysScrollPush failure",

  getFindAllPosKeysScrollPushStart:
    "[TerminalKeys] getFindAllPosKeysScrollPush start",
  getFindAllPosKeysScrollPushSuccess:
    "[TerminalKeys] getFindAllPosKeysScrollPushSuccess success",
  getFindAllPosKeysScrollPushFailure:
    "[TerminalKeys] getFindAllPosKeysScrollPush failure",
};

export const actionTypes = {
  getTerminalKeys: "[TerminalKeys] getTerminalKeys",
  getFindAllPosKeys: "[TerminalKeys] getFindAllPosKeys",
  getTerminalKeysScrollPush: "[TerminalKeys] getTerminalKeysScrollPush",
};

const mutations = {
  [mutationTypes.getTerminalKeysStart](state) {
    state.isLoading = true;
    state.data = null;
  },
  [mutationTypes.getTerminalKeysSuccess](state, payload) {
    state.isLoading = false;
    state.dataResp = payload;
  },
  [mutationTypes.getTerminalKeysFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },

  [mutationTypes.getFindAllPosKeysStart](state) {
    state.isLoadingAll = true;
    state.data = null;
  },
  [mutationTypes.getFindAllPosKeysSuccess](state, payload) {
    state.isLoadingAll = false;
    state.dataRespAll = payload;
  },
  [mutationTypes.getFindAllPosKeysFailure](state, payload) {
    state.isLoadingAll = false;
    state.errorRespAll = payload;
  },

  [mutationTypes.getTerminalKeysScrollPushStart](state) {
    state.isLoadingScroll = true;
  },
  [mutationTypes.getTerminalKeysScrollPushSuccess](state, payload) {
    for (let item in payload.content) {
      state.dataResp.content.push(payload.content[item]);
    }
    state.isLoadingScroll = false;
  },
  [mutationTypes.getTerminalKeysScrollPushFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },

  [mutationTypes.getFindAllPosKeysScrollPushStart](state) {
    state.isLoadingScrollAll = true;
  },
  [mutationTypes.getFindAllPosKeysScrollPushSuccess](state, payload) {
    for (let item in payload.content) {
      state.dataRespAll.content.push(payload.content[item]);
    }
    state.isLoadingScrollAll = false;
  },
  [mutationTypes.getFindAllPosKeysScrollPushFailure](state, payload) {
    state.isLoadingAll = false;
    state.errorRespAll = payload;
  },
};

const actions = {
  [actionTypes.getTerminalKeys](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getTerminalKeysStart, apiParams);
      terminalKeysApi
        .getTerminalKeys(apiParams)
        .then((response) => {
          context.commit(mutationTypes.getTerminalKeysSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getTerminalKeysFailure,
            result.response.data.error
          );
        });
    });
  },
  [actionTypes.getTerminalKeysScrollPush](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getTerminalKeysScrollPushStart, apiParams);
      terminalKeysApi
        .getTerminalKeys(apiParams)
        .then((response) => {
          context.commit(
            mutationTypes.getTerminalKeysScrollPushSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getTerminalKeysScrollPushFailure,
            result.response.data
          );
        });
    });
  },

  [actionTypes.getFindAllPosKeys](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getFindAllPosKeysStart, apiParams);
      terminalKeysApi
        .getFindAllPosKeys(apiParams)
        .then((response) => {
          context.commit(mutationTypes.getFindAllPosKeysSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getFindAllPosKeysFailure,
            result.response.data.error
          );
        });
    });
  },

  [actionTypes.getTerminalKeysScrollPush](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getFindAllPosKeysScrollPushStart, apiParams);
      terminalKeysApi
        .getFindAllPosKeys(apiParams)
        .then((response) => {
          context.commit(
            mutationTypes.getFindAllPosKeysScrollPushSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getFindAllPosKeysScrollPushFailure,
            result.response.data
          );
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
