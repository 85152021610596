<template>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label for="amount_timeout">Тайм-аут для суми (секунди):</label>
        <input
          type="number"
          v-model.trim="v$.general_config_obj.amount_timeout.$model"
          class="form-control"
          :class="{ 'is-invalid': v$.general_config_obj.amount_timeout.$error }"
          id="amount_timeout"
        />
        <div
          v-if="v$.general_config_obj.amount_timeout.required"
          class="invalid-feedback"
        >
          Поле обов'язкове
        </div>
      </div>
      <div class="form-group">
        <label for="app_active_time"
          >Тайм-аут активності додатку (хвилини):</label
        >
        <input
          type="number"
          v-model.trim="v$.general_config_obj.app_active_time.$model"
          class="form-control"
          :class="{
            'is-invalid': v$.general_config_obj.app_active_time.$error,
          }"
          id="app_active_time"
        />
        <div
          v-if="v$.general_config_obj.app_active_time.required"
          class="invalid-feedback"
        >
          Поле обов'язкове
        </div>
      </div>
      <div class="form-group">
        <label for="attestation_time_max"
          >Максимальний період для випадкової атестації (хвилини):</label
        >
        <input
          type="text"
          v-maska
          data-maska="##:##:##"
          v-model.trim="v$.general_config_obj.attestation_time_max.$model"
          class="form-control"
          :class="{
            'is-invalid': v$.general_config_obj.attestation_time_max.$error,
          }"
          id="attestation_time_max"
        />
        <div
          v-if="v$.general_config_obj.attestation_time_max.regex"
          class="invalid-feedback"
        >
          Не вірно вказаний формат часу (HH:MM:SS)
        </div>
        <div
          v-if="v$.general_config_obj.attestation_time_max.required"
          class="invalid-feedback"
        >
          Поле обов'язкове
        </div>
      </div>
      <div class="form-group">
        <label for="attestation_time_min"
          >Мінімальний період для випадкової атестації (хвилини):</label
        >
        <input
          type="text"
          v-maska
          data-maska="##:##:##"
          v-model.trim="v$.general_config_obj.attestation_time_min.$model"
          class="form-control"
          :class="{
            'is-invalid': v$.general_config_obj.attestation_time_min.$error,
          }"
          id="attestation_time_min"
        />
        <div
          v-if="v$.general_config_obj.attestation_time_min.regex"
          class="invalid-feedback"
        >
          Не вірно вказаний формат часу (HH:MM:SS)
        </div>
        <div
          v-if="v$.general_config_obj.attestation_time_min.required"
          class="invalid-feedback"
        >
          Поле обов'язкове
        </div>
      </div>
      <div class="form-group">
        <label for="card_mask_symbol">Замінювач для цифр картки:</label>
        <input
          type="text"
          v-model.trim="v$.general_config_obj.card_mask_symbol.$model"
          class="form-control"
          :class="{
            'is-invalid': v$.general_config_obj.card_mask_symbol.$error,
          }"
          id="card_mask_symbol"
        />
        <div
          v-if="v$.general_config_obj.card_mask_symbol.required"
          class="invalid-feedback"
        >
          Поле обов'язкове
        </div>
      </div>
      <div class="form-group">
        <label for="manual_timeout"
          >Тайм-аут для введення номеру картки вручну (секунди):</label
        >
        <input
          type="number"
          v-model.trim="v$.general_config_obj.manual_timeout.$model"
          class="form-control"
          :class="{ 'is-invalid': v$.general_config_obj.manual_timeout.$error }"
          id="manual_timeout"
        />
        <div
          v-if="v$.general_config_obj.manual_timeout.required"
          class="invalid-feedback"
        >
          Поле обов'язкове
        </div>
      </div>
      <div class="form-group">
        <label for="max_receipt_number">Максимальний номер квитанції:</label>
        <input
          type="number"
          v-model.trim="v$.general_config_obj.max_receipt_number.$model"
          class="form-control"
          :class="{
            'is-invalid': v$.general_config_obj.max_receipt_number.$error,
          }"
          id="max_receipt_number"
        />
        <div
          v-if="v$.general_config_obj.max_receipt_number.required"
          class="invalid-feedback"
        >
          Поле обов'язкове
        </div>
      </div>
      <div class="form-group">
        <label for="min_receipt_number">Мінімальний номер квитанції:</label>
        <input
          type="number"
          v-model.trim="v$.general_config_obj.min_receipt_number.$model"
          class="form-control"
          :class="{
            'is-invalid': v$.general_config_obj.min_receipt_number.$error,
          }"
          id="min_receipt_number"
        />
        <div
          v-if="v$.general_config_obj.min_receipt_number.required"
          class="invalid-feedback"
        >
          Поле обов'язкове
        </div>
      </div>
      <div class="form-group">
        <label for="nfc_timeout">Тайм-аут для NFC транзакції (секунди):</label>
        <input
          type="number"
          v-model.trim="v$.general_config_obj.nfc_timeout.$model"
          class="form-control"
          :class="{ 'is-invalid': v$.general_config_obj.nfc_timeout.$error }"
          id="nfc_timeout"
        />
        <div
          v-if="v$.general_config_obj.nfc_timeout.required"
          class="invalid-feedback"
        >
          Поле обов'язкове
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="phone_mask">Шаблон для номера телефону:</label>
        <input
          type="text"
          v-maska
          data-maska="W"
          data-maska-tokens="{ 'W': { 'pattern': '[+()-1234567890]', 'repeated' : true }}"
          v-model.trim="v$.general_config_obj.phone_mask.$model"
          maxlength="99"
          class="form-control"
          :class="{ 'is-invalid': v$.general_config_obj.phone_mask.$error }"
          id="phone_mask"
        />
        <div
          v-if="v$.general_config_obj.phone_mask.required"
          class="invalid-feedback"
        >
          Поле обов'язкове
        </div>
      </div>
      <div class="form-group">
        <label for="proposal_email">Email для пропозицій:</label>
        <input
          type="email"
          v-model.trim="v$.general_config_obj.proposal_email.$model"
          class="form-control"
          :class="{ 'is-invalid': v$.general_config_obj.proposal_email.$error }"
          id="proposal_email"
        />
        <div
          v-if="v$.general_config_obj.proposal_email.required"
          class="invalid-feedback"
        >
          Поле обов'язкове
        </div>
        <div
          v-if="v$.general_config_obj.proposal_email.email"
          class="invalid-feedback"
        >
          Не вірний Email
        </div>
      </div>
      <div class="form-group">
        <label for="question_email">Email для запитань:</label>
        <input
          type="email"
          v-model.trim="v$.general_config_obj.question_email.$model"
          class="form-control"
          :class="{ 'is-invalid': v$.general_config_obj.question_email.$error }"
          id="question_email"
        />
        <div
          v-if="v$.general_config_obj.question_email.required"
          class="invalid-feedback"
        >
          Поле обов'язкове
        </div>
        <div
          v-if="v$.general_config_obj.question_email.email"
          class="invalid-feedback"
        >
          Не вірний Email
        </div>
      </div>
      <div class="form-group">
        <label for="receipt_host"
          >Хост сервера для електронних квитанцій:</label
        >
        <input
          type="text"
          v-model.trim="v$.general_config_obj.receipt_host.$model"
          class="form-control"
          :class="{ 'is-invalid': v$.general_config_obj.receipt_host.$error }"
          id="receipt_host"
        />
        <div
          v-if="v$.general_config_obj.receipt_host.required"
          class="invalid-feedback"
        >
          Поле обов'язкове
        </div>
      </div>
      <div class="form-group">
        <label for="time_z_report">Час надсилання Z-звіту:</label>
        <input
          type="time"
          v-model.trim="v$.general_config_obj.time_z_report.$model"
          class="form-control"
          :class="{ 'is-invalid': v$.general_config_obj.time_z_report.$error }"
          id="time_z_report"
        />
        <div
          v-if="v$.general_config_obj.time_z_report.required"
          class="invalid-feedback"
        >
          Поле обов'язкове
        </div>
      </div>
      <div class="form-group">
        <label for="refund_limit_days"
          >Період для повернення коштів (днів):</label
        >
        <input
          type="number"
          v-model.trim="v$.general_config_obj.refund_limit_days.$model"
          class="form-control"
          :class="{
            'is-invalid': v$.general_config_obj.refund_limit_days.$error,
          }"
          id="refund_limit_days"
        />
        <div
          v-if="v$.general_config_obj.refund_limit_days.required"
          class="invalid-feedback"
        >
          Поле обов'язкове
        </div>
      </div>
      <div class="form-group">
        <label for="num_refund_attempts_for_transaction"
          >Кількість спроб повернення коштів для однієї транзакції:</label
        >
        <input
          type="number"
          v-model.trim="
            v$.general_config_obj.num_refund_attempts_for_transaction.$model
          "
          class="form-control"
          :class="{
            'is-invalid':
              v$.general_config_obj.num_refund_attempts_for_transaction.$error,
          }"
          id="num_refund_attempts_for_transaction"
        />
        <div
          v-if="
            v$.general_config_obj.num_refund_attempts_for_transaction.required
          "
          class="invalid-feedback"
        >
          Поле обов'язкове
        </div>
      </div>
      <div class="form-group">
        <label for="technical_merchant_id"
          >Технічний ідентифікатор продавця:</label
        >
        <input
          type="text"
          v-model.trim="v$.general_config_obj.technical_merchant_id.$model"
          class="form-control"
          :class="{
            'is-invalid': v$.general_config_obj.technical_merchant_id.$error,
          }"
          id="technical_merchant_id"
        />
        <div
          v-if="v$.general_config_obj.technical_merchant_id.required"
          class="invalid-feedback"
        >
          Поле обов'язкове
        </div>
      </div>
      <div class="form-group">
        <label for="technical_terminal_id"
          >Технічний ідентифікатор терміналу:</label
        >
        <input
          type="text"
          v-model.trim="v$.general_config_obj.technical_terminal_id.$model"
          class="form-control"
          :class="{
            'is-invalid': v$.general_config_obj.technical_terminal_id.$error,
          }"
          id="technical_terminal_id"
        />
        <div
          v-if="v$.general_config_obj.technical_terminal_id.required"
          class="invalid-feedback"
        >
          Поле обов'язкове
        </div>
      </div>
      <div class="form-group">
        <label for="supervisor_card_number">Номер карти супервайзера:</label>
        <input
          type="text"
          v-model.trim="v$.general_config_obj.supervisor_card_number.$model"
          class="form-control"
          :class="{
            'is-invalid': v$.general_config_obj.supervisor_card_number.$error,
          }"
          id="supervisor_card_number"
        />
        <div
          v-if="v$.general_config_obj.supervisor_card_number.required"
          class="invalid-feedback"
        >
          Поле обов'язкове
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, numeric, email, helpers } from "@vuelidate/validators";

export default {
  name: "TrnSetupConfigGeneralConfiguration",
  setup() {
    return { v$: useVuelidate() };
  },
  props: {
    general_config_obj: {
      type: Object,
      required: true,
    },
  },
  validations() {
    return {
      general_config_obj: {
        amount_timeout: {
          required,
          numeric,
        },
        app_active_time: {
          required,
          numeric,
        },
        attestation_time_max: {
          required,
          regex: helpers.regex(/^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/),
        },
        attestation_time_min: {
          required,
          regex: helpers.regex(/^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/),
        },
        card_mask_symbol: {
          required,
        },
        manual_timeout: {
          required,
          numeric,
        },
        max_receipt_number: {
          required,
          numeric,
        },
        min_receipt_number: {
          required,
          numeric,
        },
        nfc_timeout: {
          required,
          numeric,
        },
        phone_mask: {
          required,
        },
        proposal_email: {
          required,
          email,
        },
        question_email: {
          required,
          email,
        },
        receipt_host: {
          required,
        },
        time_z_report: {
          required,
        },
        refund_limit_days: {
          required,
          numeric,
        },
        num_refund_attempts_for_transaction: {
          required,
          numeric,
        },
        technical_merchant_id: {
          required,
        },
        technical_terminal_id: {
          required,
        },
        supervisor_card_number: {
          required,
        },
      },
    };
  },
  methods: {
    removeCurrency(index) {
      // this.currencies.splice(index, 1);
      this.$emit("deleteCurrency", index);
    },
    updateValueForm(event, inputName) {
      // Відправляємо подію з оновленим значенням
      let tempObj = {
        ...this.currency,
      };
      tempObj[inputName] = event.target.value;
      this.$emit("updateCurrency", tempObj);
    },
  },
};
</script>

<style scoped></style>
