<template>
  <div class="row g-0">
    <div class="col">
      <div
        class="merchant-row d-flex justify-content-between mb-3 bg-light text-dark rounded-2 fw-bold"
      >
        <div class="left-block">
          <div class="input-group">
            <span class="input-group-text fw-bold bg-info border-0">{{
              $t(`RegularReports.form.type`)
            }}</span>
            <select
              class="form-select w-auto"
              id="input-type"
              v-model="form.type"
            >
              <option value="activeTerminals">
                {{ $t(`RegularReports.typeReportList.activeTerminals`) }}
              </option>
              <option value="terminalRegistration">
                {{ $t(`RegularReports.typeReportList.terminalRegistration`) }}
              </option>
              <option value="merchant">
                {{ $t(`RegularReports.typeReportList.merchant`) }}
              </option>
              <option value="transactionsByMerchant">
                {{ $t(`RegularReports.typeReportList.transactionsByMerchant`) }}
              </option>
              <option value="transactionsByTerminal">
                {{ $t(`RegularReports.typeReportList.transactionsByTerminal`) }}
              </option>
              <option value="transactionsByType">
                {{ $t(`RegularReports.typeReportList.transactionsByType`) }}
              </option>
              <option value="transactionsByIps">
                {{ $t(`RegularReports.typeReportList.transactionsByIps`) }}
              </option>
              <option value="transactionsByBlame">
                {{ $t(`RegularReports.typeReportList.transactionsByBlame`) }}
              </option>
              <option value="terminalLifecycle">
                {{ $t(`RegularReports.typeReportList.terminalLifecycle`) }}
              </option>
              <option value="terminalsByGroups">
                {{ $t(`RegularReports.typeReportList.terminalsByGroups`) }}
              </option>
            </select>
            <span class="input-group-text fw-bold bg-info border-0">{{
              $t(`RegularReports.form.export`)
            }}</span>
            <select
              class="form-select w-auto"
              id="input-period"
              v-model="form.fileFormatType"
            >
              <option value="PDF">
                {{ $t(`RegularReports.select.export.PDF`) }}
              </option>
              <option value="CSV">
                {{ $t(`RegularReports.select.export.CSV`) }}
              </option>
              <option value="XLS">
                {{ $t(`RegularReports.select.export.XLS`) }}
              </option>
            </select>
            <button
              class="btn btn-success"
              type="button"
              @click="downloadReport"
              :disabled="btnDisableDownload"
            >
              {{ $t(`uiElements.btn.download`) }}
            </button>
          </div>
        </div>
        <div class="right-block"></div>
      </div>
    </div>
  </div>
  <div class="row g-0">
    <div class="col-auto">
      <trn-regular-reports-active-terminal
        v-if="form.type === 'activeTerminals'"
        :file-format-type="form.fileFormatType"
        :download-file="form.downloadFile"
        :name-reports="$t(`RegularReports.typeReportList.activeTerminals`)"
        @disable-download="downloadDisable"
      ></trn-regular-reports-active-terminal>

      <trn-regular-reports-registration
        v-if="form.type === 'terminalRegistration'"
        :fileFormatType="form.fileFormatType"
        :download-file="form.downloadFile"
        :name-reports="$t(`RegularReports.typeReportList.terminalRegistration`)"
        @disable-download="downloadDisable"
      ></trn-regular-reports-registration>

      <trn-regular-reports-merchant
        v-if="form.type === 'merchant'"
        :fileFormatType="form.fileFormatType"
        :download-file="form.downloadFile"
        :name-reports="$t(`RegularReports.typeReportList.merchant`)"
        @disable-download="downloadDisable"
      ></trn-regular-reports-merchant>

      <trn-regular-reports-transactions-by-merchant
        v-if="form.type === 'transactionsByMerchant'"
        :fileFormatType="form.fileFormatType"
        :download-file="form.downloadFile"
        :name-reports="
          $t(`RegularReports.typeReportList.transactionsByMerchant`)
        "
        @disable-download="downloadDisable"
      ></trn-regular-reports-transactions-by-merchant>

      <trn-regular-reports-transactions-by-terminal
        v-if="form.type === 'transactionsByTerminal'"
        :fileFormatType="form.fileFormatType"
        :download-file="form.downloadFile"
        :name-reports="
          $t(`RegularReports.typeReportList.transactionsByTerminal`)
        "
        @disable-download="downloadDisable"
      ></trn-regular-reports-transactions-by-terminal>

      <trn-regular-reports-transactions-by-type
        v-if="form.type === 'transactionsByType'"
        :fileFormatType="form.fileFormatType"
        :download-file="form.downloadFile"
        :name-reports="$t(`RegularReports.typeReportList.transactionsByType`)"
        @disable-download="downloadDisable"
      ></trn-regular-reports-transactions-by-type>

      <trn-regular-reports-transactions-by-ips
        v-if="form.type === 'transactionsByIps'"
        :fileFormatType="form.fileFormatType"
        :download-file="form.downloadFile"
        :name-reports="$t(`RegularReports.typeReportList.transactionsByIps`)"
        @disable-download="downloadDisable"
      ></trn-regular-reports-transactions-by-ips>

      <trn-regular-reports-transactions-by-blame
        v-if="form.type === 'transactionsByBlame'"
        :fileFormatType="form.fileFormatType"
        :download-file="form.downloadFile"
        :name-reports="$t(`RegularReports.typeReportList.transactionsByBlame`)"
        @disable-download="downloadDisable"
      ></trn-regular-reports-transactions-by-blame>

      <trn-regular-reports-terminal-lifecycle
        v-if="form.type === 'terminalLifecycle'"
        :fileFormatType="form.fileFormatType"
        :download-file="form.downloadFile"
        :name-reports="$t(`RegularReports.typeReportList.terminalLifecycle`)"
        @disable-download="downloadDisable"
      ></trn-regular-reports-terminal-lifecycle>

      <trn-regular-reports-terminals-by-groups
        v-if="form.type === 'terminalsByGroups'"
        :fileFormatType="form.fileFormatType"
        :download-file="form.downloadFile"
        :name-reports="$t(`RegularReports.typeReportList.terminalsByGroups`)"
        @disable-download="downloadDisable"
      ></trn-regular-reports-terminals-by-groups>
    </div>
  </div>
</template>

<script>
import TrnRegularReportsActiveTerminal from "@/components/block-pages/reports/RegularReportsActiveTerminal";
import TrnRegularReportsRegistration from "@/components/block-pages/reports/RegularReportsRegistration";
import TrnRegularReportsMerchant from "@/components/block-pages/reports/RegularReportsReportByMerchant";
import TrnRegularReportsTransactionsByMerchant from "@/components/block-pages/reports/RegularReportsTransactionsByMerchant";
import TrnRegularReportsTransactionsByTerminal from "@/components/block-pages/reports/RegularReportsTransactionsByTerminal";
import TrnRegularReportsTransactionsByType from "@/components/block-pages/reports/RegularReportsTransactionsByType";
import TrnRegularReportsTransactionsByIps from "@/components/block-pages/reports/RegularReportsTransactionsByIps";
import TrnRegularReportsTransactionsByBlame from "@/components/block-pages/reports/RegularReportsTransactionsByBlame";
import TrnRegularReportsTerminalLifecycle from "@/components/block-pages/reports/RegularReportsTerminalLifecycle";
import TrnRegularReportsTerminalsByGroups from "@/components/block-pages/reports/RegularReportsTerminalsByGroups";

export default {
  name: "TrnRegularReports",
  components: {
    TrnRegularReportsTerminalsByGroups,
    TrnRegularReportsTerminalLifecycle,
    TrnRegularReportsTransactionsByBlame,
    TrnRegularReportsTransactionsByIps,
    TrnRegularReportsTransactionsByType,
    TrnRegularReportsTransactionsByTerminal,
    TrnRegularReportsTransactionsByMerchant,
    TrnRegularReportsMerchant,
    TrnRegularReportsRegistration,
    TrnRegularReportsActiveTerminal,
  },
  data() {
    return {
      form: {
        type: "activeTerminals",
        fileFormatType: "PDF",
        downloadFile: false,
      },
      btnDisableDownload: true,
    };
  },
  methods: {
    downloadReport() {
      this.form.downloadFile = !this.form.downloadFile;
    },
    downloadDisable(val) {
      this.btnDisableDownload = !!val;
    },
  },
};
</script>

<style scoped></style>
