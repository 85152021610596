<template>
  <form id="action_form" @submit.prevent="submitForm"></form>
  <div class="row">
    <div class="col-6">
      <div class="table-responsive table-block overflow-auto" id="table-block">
        <table class="table table-sm table-light table-striped">
          <thead>
            <tr class="text-center">
              <th scope="col">
                {{ $t(`Attestation.action.table.action`) }}
              </th>
              <th scope="col">
                {{ $t(`Attestation.action.table.shortName`) }}
              </th>

              <th scope="col">
                {{ $t(`Attestation.action.table.actionWeight`) }}
              </th>
            </tr>
          </thead>
          <tbody class="text-nowrap">
            <tr class="">
              <td>{{ $t(`Attestation.action.select.option.BLOCK_MANUAL`) }}</td>
              <td>
                <input
                  type="text"
                  class="form-control"
                  v-model="formData.BLOCK_MANUAL.shortName"
                />
              </td>
              <td>
                <select
                  class="form-select"
                  id="input-BLOCK_MANUAL"
                  v-model="formData.BLOCK_MANUAL.actionWeight"
                >
                  <option
                    v-for="item in actionWeightOption"
                    :key="item"
                    :value="item.value"
                    :disabled="item.disabled"
                  >
                    {{ item.text }}
                  </option>
                </select>
              </td>
            </tr>

            <tr class="">
              <td>{{ $t(`Attestation.action.select.option.BLOCK_NFC`) }}</td>
              <td>
                <input
                  type="text"
                  class="form-control"
                  v-model="formData.BLOCK_NFC.shortName"
                />
              </td>
              <td>
                <select
                  class="form-select"
                  id="input-BLOCK_NFC"
                  v-model="formData.BLOCK_NFC.actionWeight"
                >
                  <option
                    v-for="item in actionWeightOption"
                    :key="item"
                    :value="item.value"
                    :disabled="item.disabled"
                  >
                    {{ item.text }}
                  </option>
                </select>
              </td>
            </tr>

            <tr class="">
              <td>{{ $t(`Attestation.action.select.option.NO_BLOCK`) }}</td>
              <td>
                <input
                  type="text"
                  class="form-control"
                  v-model="formData.NO_BLOCK.shortName"
                />
              </td>
              <td>
                <select
                  class="form-select"
                  id="input-NO_BLOCK"
                  v-model="formData.NO_BLOCK.actionWeight"
                >
                  <option
                    v-for="item in actionWeightOption"
                    :key="item"
                    :value="item.value"
                    :disabled="item.disabled"
                  >
                    {{ item.text }}
                  </option>
                </select>
              </td>
            </tr>

            <tr class="">
              <td>{{ $t(`Attestation.action.select.option.BLOCK_PIN`) }}</td>
              <td>
                <input
                  type="text"
                  class="form-control"
                  v-model="formData.BLOCK_PIN.shortName"
                />
              </td>
              <td>
                <select
                  class="form-select"
                  id="input-BLOCK_PIN"
                  v-model="formData.BLOCK_PIN.actionWeight"
                >
                  <option
                    v-for="item in actionWeightOption"
                    :key="item"
                    :value="item.value"
                    :disabled="item.disabled"
                  >
                    {{ item.text }}
                  </option>
                </select>
              </td>
            </tr>

            <tr class="">
              <td>{{ $t(`Attestation.action.select.option.BLOCK_QR`) }}</td>
              <td>
                <input
                  type="text"
                  class="form-control"
                  v-model="formData.BLOCK_QR.shortName"
                />
              </td>
              <td>
                <select
                  class="form-select"
                  id="input-BLOCK_QR"
                  v-model="formData.BLOCK_QR.actionWeight"
                >
                  <option
                    v-for="item in actionWeightOption"
                    :key="item"
                    :value="item.value"
                    :disabled="item.disabled"
                  >
                    {{ item.text }}
                  </option>
                </select>
              </td>
            </tr>

            <tr class="">
              <td>{{ $t(`Attestation.action.select.option.BLOCK_DEVICE`) }}</td>
              <td>
                <input
                  type="text"
                  class="form-control"
                  v-model="formData.BLOCK_DEVICE.shortName"
                />
              </td>
              <td>
                <select
                  class="form-select"
                  id="input-BLOCK_DEVICE"
                  v-model="formData.BLOCK_DEVICE.actionWeight"
                >
                  <option
                    v-for="item in actionWeightOption"
                    :key="item"
                    :value="item.value"
                    :disabled="item.disabled"
                  >
                    {{ item.text }}
                  </option>
                </select>
              </td>
            </tr>

            <tr class="">
              <td>
                {{ $t(`Attestation.action.select.option.BLOCK_TRANSACTION`) }}
              </td>
              <td>
                <input
                  type="text"
                  class="form-control"
                  v-model="formData.BLOCK_TRANSACTION.shortName"
                />
              </td>
              <td>
                <select
                  class="form-select"
                  id="input-BLOCK_TRANSACTION"
                  v-model="formData.BLOCK_TRANSACTION.actionWeight"
                >
                  <option
                    v-for="item in actionWeightOption"
                    :key="item"
                    :value="item.value"
                    :disabled="item.disabled"
                  >
                    {{ item.text }}
                  </option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 text-center">
      <button form="action_form" class="btn btn-success">
        {{ $t(`uiElements.btn.save`) }}
      </button>
    </div>
  </div>
  <div
    class="toast-container position-absolute p-3 top-0 end-0"
    v-if="showToast"
  >
    <bs-toast v-for="toast in toastPropsList" :key="toast" :="toast"></bs-toast>
  </div>
</template>

<script>
import BsToast from "@/components/UI-elements/BsToast";
import attestationThreatsApi from "@/api/settings-api/attestation";

export default {
  name: "TrnAttestationThreatTab",
  components: {
    BsToast,
  },
  data() {
    return {
      isLoading: true,
      showToast: false,
      toastPropsList: [],
      formData: {
        BLOCK_MANUAL: { actionWeight: null, shortName: null },
        BLOCK_NFC: { actionWeight: null, shortName: null },
        NO_BLOCK: { actionWeight: null, shortName: null },
        BLOCK_PIN: { actionWeight: null, shortName: null },
        BLOCK_QR: { actionWeight: null, shortName: null },
        BLOCK_DEVICE: { actionWeight: null, shortName: null },
        BLOCK_TRANSACTION: { actionWeight: null, shortName: null },
      },
      actionWeightOption: [
        {
          disabled: true,
          value: null,
          text: `${this.$t(`Validations.notChosen`)}`,
        },
        {
          disabled: false,
          value: "0",
          text: "0",
        },
        {
          disabled: false,
          value: "1",
          text: "1",
        },
        {
          disabled: false,
          value: "2",
          text: "2",
        },
        {
          disabled: false,
          value: "3",
          text: "3",
        },
        {
          disabled: false,
          value: "4",
          text: "4",
        },
        {
          disabled: false,
          value: "5",
          text: "5",
        },
      ],
    };
  },
  methods: {
    fetchData() {
      attestationThreatsApi
        .getAttestationAction()
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          // console.log("result", result);
          if (result.status === 200) {
            for (let item in result.data) {
              this.formData[result.data[item].action] = {
                actionWeight: result.data[item].actionWeight,
                shortName: result.data[item].shortName,
              };
            }
          } else {
            console.log("err");
            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.err`)}`,
              bgColor: "bg-danger",
              textColor: "text-white",
            });
            this.showToast = true;
          }
        });
    },
    submitForm() {
      let updateArr = [];
      for (let key in this.formData) {
        updateArr.push({
          id: key,
          actionWeight: this.formData[key].actionWeight,
          shortName: this.formData[key].shortName,
        });
      }

      attestationThreatsApi
        .updateAttestationAction(updateArr)
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          // console.log("result", result);
          if (result.status === 200) {
            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.successSave`)}`,
              bgColor: "bg-success",
              textColor: "text-white",
            });
            this.showToast = true;
          } else {
            console.log("err");
            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.err`)}`,
              bgColor: "bg-danger",
              textColor: "text-white",
            });
            this.showToast = true;
          }
        });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped></style>
