import usersApi from "@/api/users";

const state = {
  dataResp: null,
  dataModalResp: null,

  isLoading: false,
  isLoadingScroll: false,
  isLoadingModal: false,

  errorResp: null,
};

export const mutationTypes = {
  getUsersStart: "[Users] getUsers start",
  getUsersSuccess: "[Users] getUsers success",
  getUsersFailure: "[Users] getUsers failure",

  getUsersScrollPushStart: "[Users] getUsersScrollPush start",
  getUsersScrollPushSuccess: "[Users] getUsersScrollPushSuccess success",
  getUsersScrollPushFailure: "[Users] getUsersScrollPush failure",
};

export const actionTypes = {
  getUsers: "[Users] getUsers",
  getUsersScrollPush: "[Users] getUsersScrollPush",
};

const mutations = {
  [mutationTypes.getUsersStart](state) {
    state.isLoading = true;
    state.data = null;
  },
  [mutationTypes.getUsersSuccess](state, payload) {
    state.isLoading = false;
    state.dataResp = payload;
  },
  [mutationTypes.getUsersFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
  [mutationTypes.getUsersScrollPushStart](state) {
    state.isLoadingScroll = true;
  },
  [mutationTypes.getUsersScrollPushSuccess](state, payload) {
    for (let item in payload.content) {
      state.dataResp.content.push(payload.content[item]);
    }
    state.isLoadingScroll = false;
  },
  [mutationTypes.getUsersScrollPushFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
};

const actions = {
  [actionTypes.getUsers](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getUsersStart, apiParams);
      usersApi
        .getUsers(apiParams)
        .then((response) => {
          context.commit(mutationTypes.getUsersSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getUsersFailure,
            result.response.data.error
          );
        });
    });
  },
  [actionTypes.getUsersScrollPush](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getUsersScrollPushStart, apiParams);
      usersApi
        .getUsers(apiParams)
        .then((response) => {
          context.commit(
            mutationTypes.getUsersScrollPushSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getUsersScrollPushFailure,
            result.response.data
          );
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
