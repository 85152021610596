<template>
  <div class="title-table mb-2 ps-3 py-1 fw-bold">
    {{ $t(`settings-menu.terminalFacility`) }}
  </div>

  <div class="fluid-container">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="tariffs-tab"
          data-bs-toggle="tab"
          data-bs-target="#tariffs"
          type="button"
          role="tab"
          aria-controls="tariffs"
          aria-selected="true"
        >
          <i class="bi bi-calculator"></i>
          {{ $t(`Settings.TerminalFacility.tab.tariffs`) }}
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="profile-tab"
          data-bs-toggle="tab"
          data-bs-target="#bdc-profile"
          type="button"
          role="tab"
          aria-controls="bdc-profile"
          aria-selected="false"
        >
          <i class="bi bi-card-list"></i>
          {{ $t(`Settings.TerminalFacility.tab.tariffsProfile`) }}
        </button>
      </li>
    </ul>
    <div class="tab-content" id="tabContent">
      <!--   tab TARIFFS   -->
      <div
        class="tab-pane fade show active"
        id="tariffs"
        role="tabpanel"
        aria-labelledby="tariffs-tab"
      >
        <trn-terminal-facility-tab-tariffs></trn-terminal-facility-tab-tariffs>
      </div>
      <!--   tab PROFILE TARIFFS   -->
      <div
        class="tab-pane fade"
        id="bdc-profile"
        role="tabpanel"
        aria-labelledby="profile-tab"
      >
        <trn-terminal-facility-tab-profiles></trn-terminal-facility-tab-profiles>
      </div>
    </div>
  </div>
  <div
    class="toast-container position-absolute p-3 end-0 z-index-1500"
    v-if="showToast"
  >
    <bs-toast v-for="toast in toastPropsList" :key="toast" :="toast"></bs-toast>
  </div>
</template>

<script>
import BsToast from "@/components/UI-elements/BsToast";
import TrnTerminalFacilityTabTariffs from "@/components/block-pages/tariffs/TabTariffs";
import TrnTerminalFacilityTabProfiles from "@/components/block-pages/tariffs/TabProfiles";

export default {
  name: "TrnTerminalFacility",
  components: {
    TrnTerminalFacilityTabProfiles,
    TrnTerminalFacilityTabTariffs,
    BsToast,
  },
  data() {
    return {
      showToast: false,
      toastPropsList: [],
    };
  },
  computed: {},
  watch: {},
  methods: {},
  mounted() {},
};
</script>

<style scoped></style>
