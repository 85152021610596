<template>
  <div class="title-table mb-2 ps-3 py-1 fw-bold">
    {{ $t(`settings-menu.products`) }}
  </div>

  <!-- ToolBar -->
  <div class="toolbar">
    <div class="btn-group mb-2">
      <button
        class="btn btn-outline-primary btn-sm"
        type="button"
        @click="clickChangeGroupObj('add')"
      >
        <i class="bi bi-file-earmark-plus"></i>
        <span class="ms-1">{{ $t(`uiElements.btn.add`) }}</span>
      </button>
      <button
        class="btn btn-outline-primary btn-sm"
        :class="{ disabled: !selectedTableRow?.productId }"
        type="button"
        @click="clickChangeGroupObj('update')"
      >
        <i class="bi bi-pencil-square"></i>
        <span class="ms-1">{{ $t(`uiElements.btn.change`) }}</span>
      </button>
    </div>
  </div>

  <!-- ToolBar кінець -->

  <div
    class="table-responsive table-block overflow-auto"
    id="table-block"
    @scroll="scrollTable"
  >
    <table class="table table-sm table-light">
      <thead>
        <tr class="text-center">
          <th scope="col">
            {{ $t(`Products.table.productId`) }}
          </th>
          <th scope="col">
            {{ $t(`Products.table.productName`) }}
          </th>
          <th scope="col">
            {{ $t(`Products.table.ipsCardGroupId`) }}
          </th>
          <th scope="col">
            {{ $t(`Products.table.ipsSymbol`) }}
          </th>
          <th scope="col">
            {{ $t(`Products.table.rangeBegin`) }}
          </th>
          <th scope="col">
            {{ $t(`Products.table.rangeEnd`) }}
          </th>
        </tr>
      </thead>
      <tbody class="text-nowrap">
        <template v-if="isLoading">
          <tr>
            <td colspan="16">
              <div class="loading text-center">
                <trn-loading></trn-loading>
              </div>
            </td>
          </tr>
        </template>
        <template v-if="dataResp">
          <tr
            v-for="item in dataResp.page.content"
            :key="item"
            class="text-center cursor-pointer"
            :class="{
              'select-td': item.productId === selectedTableRow?.productId,
            }"
            @click="clickTableRow(item)"
          >
            <td>{{ item.productId }}</td>
            <td>{{ item.productName }}</td>
            <td>{{ item.ipsCardGroup.ipsCardGroupId }}</td>
            <td>{{ item.ipsCardGroup.ipsSymbol }}</td>
            <td>{{ item.rangeBegin }}</td>
            <td>{{ item.rangeEnd }}</td>
          </tr>
          <template v-if="isLoadingScroll">
            <tr>
              <td colspan="16">
                <div class="loading text-center">
                  <trn-loading></trn-loading>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>

  <bs-modal
    :modal-toggle-emit="showModal"
    :title="titleModal"
    @hideMainModal="showModal = false"
  >
    <template v-if="showFormGroup">
      <form
        class="needs-validation"
        @submit.prevent="submitChangeGroupNumber"
        novalidate
      >
        <!--  input-productId  -->
        <div class="row mb-3" v-if="selectedTableRow">
          <label for="input-productId" class="col-sm-6 col-form-label">
            {{ $t(`Products.table.productId`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              id="input-productId"
              v-model="selectedTableRow.productId"
              disabled
            />
          </div>
        </div>

        <!--  input-productName  -->
        <div class="row mb-3">
          <label for="input-productName" class="col-sm-6 col-form-label">
            {{ $t(`Products.table.productName`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              :class="addClassValidInput('productName')"
              id="input-productName"
              v-model="v$.formProductsGroup.productName.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formProductsGroup.productName.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formProductsGroup.productName.maxLength.$invalid">
                {{ $t(`Validations.maxLength`, { num: 30 }) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-ipsCardGroupId  -->
        <div class="row mb-3">
          <label for="input-ipsCardGroupId" class="col-sm-6 col-form-label">
            {{ $t(`Products.table.ipsCardGroupId`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              :class="addClassValidInput('ipsCardGroupId')"
              id="input-ipsCardGroupId"
              v-model="v$.formProductsGroup.ipsCardGroupId.$model"
            >
              <option
                :value="item.ipsCardGroupId"
                v-for="item in this.dataResp.allIpsCardGroups"
                :key="item.ipsCardGroupId"
              >
                {{ item.ipsName }}
              </option>
            </select>
            <div class="invalid-feedback">
              <div v-if="v$.formProductsGroup.ipsCardGroupId.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-rangeBegin  -->
        <div class="row mb-3">
          <label for="input-rangeBegin" class="col-sm-6 col-form-label">
            {{ $t(`Products.table.rangeBegin`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="number"
              class="form-control"
              :class="addClassValidInput('rangeBegin')"
              id="input-rangeBegin"
              v-model="v$.formProductsGroup.rangeBegin.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formProductsGroup.rangeBegin.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formProductsGroup.rangeBegin.maxLength.$invalid">
                {{ $t(`Validations.maxLength`, { num: 19 }) }}
              </div>
              <div v-if="v$.formProductsGroup.rangeBegin.minLength.$invalid">
                {{ $t(`Validations.minLength`, { num: 16 }) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-rangeEnd  -->
        <div class="row mb-3">
          <label for="input-rangeEnd" class="col-sm-6 col-form-label">
            {{ $t(`Products.table.rangeEnd`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="number"
              class="form-control"
              :class="addClassValidInput('rangeEnd')"
              id="input-rangeEnd"
              v-model="v$.formProductsGroup.rangeEnd.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formProductsGroup.rangeEnd.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formProductsGroup.rangeEnd.maxLength.$invalid">
                {{ $t(`Validations.maxLength`, { num: 19 }) }}
              </div>
              <div v-if="v$.formProductsGroup.rangeEnd.minLength.$invalid">
                {{ $t(`Validations.minLength`, { num: 16 }) }}
              </div>
            </div>
          </div>
        </div>

        <!--  Buttons form  -->
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-outline-secondary me-3"
            @click="showModal = false"
          >
            {{ $t(`uiElements.btn.close`) }}
          </button>
          <button
            type="submit"
            class="btn btn-outline-primary"
            :disabled="v$.$invalid && v$.$dirty ? true : false"
          >
            {{ $t(`uiElements.btn.save`) }}
          </button>
        </div>
      </form>
    </template>
  </bs-modal>
  <div class="toast-container position-absolute p-3 end-0" v-if="showToast">
    <bs-toast v-for="toast in toastPropsList" :key="toast" :="toast"></bs-toast>
  </div>
</template>

<script>
import TrnLoading from "@/components/Loading";
import BsModal from "@/components/UI-elements/BsModal";
import BsToast from "@/components/UI-elements/BsToast";
import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength } from "@vuelidate/validators";
import { mapState } from "vuex";
import { stringify } from "query-string";
import { actionTypes } from "@/store/modules/settings-store/products";
import productsApi from "@/api/settings-api/products";

export default {
  name: "TrnProducts",
  components: {
    TrnLoading,
    BsModal,
    BsToast,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      page: 0,
      size: 100,
      sort: {
        col: "productId",
        order: "asc",
      },
      showModal: false,
      titleModal: "",
      showFormGroup: false,
      formProductsGroup: {},
      formProductsGroupType: "",
      showToast: false,
      toastPropsList: [],
      selectedTableRow: null,
    };
  },
  validations() {
    return {
      formProductsGroup: {
        productName: {
          required,
          maxLength: maxLength(30),
        },
        ipsCardGroupId: {
          required,
        },
        rangeBegin: {
          required,
          minLength: minLength(16),
          maxLength: maxLength(19),
        },
        rangeEnd: {
          required,
          minLength: minLength(16),
          maxLength: maxLength(19),
        },
      },
    };
  },
  computed: {
    dataResp: {
      get() {
        return this.$store.state.products.dataResp;
      },
      set(newValue) {
        this.$store.state.products.dataResp = newValue;
      },
    },
    ...mapState({
      isLoading: (state) => state.products.isLoading,
      isLoadingScroll: (state) => state.products.isLoadingScroll,
      dataModalResp: (state) => state.products.dataModalResp,
      errorResp: (state) => state.products.errorResp,
    }),
  },
  methods: {
    fetchProductsGroups() {
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
      });
      this.$store.dispatch(actionTypes.getProducts, {
        apiParams: stringifiedParams,
      });
    },
    scrollTable(event) {
      const currentEl = event.target;
      let checkScrollEnd =
        currentEl.scrollTop + currentEl.clientHeight + 3 >= currentEl.scrollHeight;
      if (checkScrollEnd && this.isLoadingScroll === false) {
        const currentPage = this.page + 1;
        const totalPages = this.dataResp.totalPages;
        if (currentPage < totalPages) this.loadMoreData();
      }
    },
    loadMoreData() {
      this.page += 1;
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
      });
      this.$store.dispatch(actionTypes.getProductsScrollPush, {
        apiParams: stringifiedParams,
      });
    },
    reloadPage() {
      this.page = 0;
      this.fetchProductsGroups();
    },
    addClassValidInput(inputName) {
      return {
        "is-invalid":
          this.v$.formProductsGroup[inputName].$error === true &&
          this.v$.formProductsGroup[inputName].$dirty === true,
        "is-valid":
          this.v$.formProductsGroup[inputName].$error === false &&
          this.v$.formProductsGroup[inputName].$dirty === true,
      };
    },
    clickTableRow(rowItem) {
      this.selectedTableRow = rowItem;
    },
    clickChangeGroupObj(type) {
      this.formProductsGroupType = type;
      this.showModal = true;
      this.showFormGroup = true;
      if (type === "add") {
        this.selectedTableRow = null;
        this.formProductsGroup = {};
        this.titleModal = this.$t(`Products.modal.addNewProduct`);
      } else {
        this.titleModal = this.selectedTableRow.productId;
        this.formProductsGroup.productName = this.selectedTableRow.productName;
        this.formProductsGroup.ipsCardGroupId =
          this.selectedTableRow.ipsCardGroup.ipsCardGroupId;
        this.formProductsGroup.rangeBegin = this.selectedTableRow.rangeBegin;
        this.formProductsGroup.rangeEnd = this.selectedTableRow.rangeEnd;
      }
    },
    submitChangeGroupNumber() {
      this.v$.formProductsGroup.$touch();
      if (!this.v$.formProductsGroup.$error) {
        if (this.formProductsGroupType === "add") {
          productsApi
            .addProducts({ ...this.formProductsGroup })
            .then(function (result) {
              return result;
            })
            .catch(function (result) {
              return result;
            })
            .then((result) => {
              // console.log("result", result);
              if (result.status === 200) {
                this.showModal = false;
                this.selectedTableRow = null;
                this.formProductsGroup = {};

                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.successSave`)}`,
                  bgColor: "bg-success",
                  textColor: "text-white",
                });
                this.showToast = true;

                this.fetchProductsGroups();
              } else {
                console.log("err");
                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.errSave`)}`,
                  bgColor: "bg-danger",
                  textColor: "text-white",
                });
                this.showToast = true;
              }
            });
        } else {
          productsApi
            .updateProducts(this.selectedTableRow.productId, {
              ...this.formProductsGroup,
            })
            .then(function (result) {
              return result;
            })
            .catch(function (result) {
              return result;
            })
            .then((result) => {
              // console.log("result", result);
              if (result.status === 200) {
                this.showModal = false;

                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.successSave`)}`,
                  bgColor: "bg-success",
                  textColor: "text-white",
                });
                this.showToast = true;

                this.fetchProductsGroups();
              } else {
                console.log("err");
                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.errSave`)}`,
                  bgColor: "bg-danger",
                  textColor: "text-white",
                });
                this.showToast = true;
              }
            });
        }
      }
    },
  },
  mounted() {
    this.fetchProductsGroups();
  },
};
</script>

<style scoped>
.select-td > td {
  background-color: #0d6efd !important;
  color: white !important;
}
</style>
