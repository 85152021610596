<template>
  <form class="needs-validation" @submit.prevent="formRegMerchant" novalidate>
    <!--  input-userLogin  -->
    <div class="row mb-3">
      <label for="input-userLogin" class="col-sm-6 col-form-label">
        {{ $t(`MerchantRegistration.table.userLogin`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <input
          type="text"
          class="form-control"
          :class="addClassValidInput('userLogin')"
          id="input-userLogin"
          v-model="v$.form.userLogin.$model"
        />
        <div class="invalid-feedback">
          <div v-if="v$.form.userLogin.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
          <div v-if="v$.form.userLogin.regex.$invalid">
            {{ $t(`Validations.onlyLettersNumbers`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-merchantName  -->
    <div class="row mb-3">
      <label for="input-merchantName" class="col-sm-6 col-form-label">
        {{ $t(`MerchantRegistration.table.merchantName`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <input
          type="text"
          class="form-control"
          :class="addClassValidInput('merchantName')"
          id="input-merchantName"
          v-model="v$.form.merchantName.$model"
        />
        <div class="invalid-feedback">
          <div v-if="v$.form.merchantName.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
          <div v-if="v$.form.merchantName.maxLength.$invalid">
            {{ $t(`Validations.maxLength`, { num: 25 }) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-merchantNameEng  -->
    <div class="row mb-3">
      <label for="input-merchantNameEng" class="col-sm-6 col-form-label">
        {{ $t(`MerchantRegistration.table.merchantNameEng`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <input
          type="text"
          class="form-control"
          :class="addClassValidInput('merchantNameEng')"
          id="input-merchantNameEng"
          v-model="v$.form.merchantNameEng.$model"
        />
        <div class="invalid-feedback">
          <div v-if="v$.form.merchantNameEng.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
          <div v-if="v$.form.merchantNameEng.maxLength.$invalid">
            {{ $t(`Validations.maxLength`, { num: 25 }) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-merchantLocation  -->
    <div class="row mb-3">
      <label for="input-merchantLocation" class="col-sm-6 col-form-label">
        {{ $t(`MerchantRegistration.table.merchantLocation`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <input
          type="text"
          class="form-control"
          :class="addClassValidInput('merchantLocation')"
          id="input-merchantLocation"
          v-model="v$.form.merchantLocation.$model"
        />
        <div class="invalid-feedback">
          <div v-if="v$.form.merchantLocation.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
          <div v-if="v$.form.merchantLocation.maxLength.$invalid">
            {{ $t(`Validations.maxLength`, { num: 50 }) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-merchantLocationEng  -->
    <div class="row mb-3">
      <label for="input-merchantLocationEng" class="col-sm-6 col-form-label">
        {{ $t(`MerchantRegistration.table.merchantLocationEng`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <input
          type="text"
          class="form-control"
          :class="addClassValidInput('merchantLocationEng')"
          id="input-merchantLocationEng"
          v-model="v$.form.merchantLocationEng.$model"
        />
        <div class="invalid-feedback">
          <div v-if="v$.form.merchantLocationEng.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
          <div v-if="v$.form.merchantLocationEng.maxLength.$invalid">
            {{ $t(`Validations.maxLength`, { num: 50 }) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-merchantLegalName  -->
    <div class="row mb-3">
      <label for="input-merchantLegalName" class="col-sm-6 col-form-label">
        {{ $t(`MerchantRegistration.table.merchantLegalName`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <input
          type="text"
          class="form-control"
          :class="addClassValidInput('merchantLegalName')"
          id="input-merchantLegalName"
          v-model="v$.form.merchantLegalName.$model"
        />
        <div class="invalid-feedback">
          <div v-if="v$.form.merchantLegalName.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
          <div v-if="v$.form.merchantLegalName.maxLength.$invalid">
            {{ $t(`Validations.maxLength`, { num: 30 }) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-iban  -->
    <div class="row mb-3">
      <label for="input-iban" class="col-sm-6 col-form-label">
        {{ $t(`MerchantRegistration.table.iban`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <input
          type="text"
          class="form-control"
          :class="addClassValidInput('iban')"
          id="input-iban"
          v-model="v$.form.iban.$model"
        />
        <div class="invalid-feedback">
          <div v-if="v$.form.iban.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
          <div v-if="v$.form.iban.regex.$invalid">
            {{ $t(`MerchantRegistration.validationRegForm.ibanRegex`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-phoneNumber  -->
    <div class="row mb-3">
      <label for="input-phoneNumber" class="col-sm-6 col-form-label">
        {{ $t(`MerchantRegistration.table.phoneNumber`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <input
          type="text"
          class="form-control"
          :class="addClassValidInput('phoneNumber')"
          id="input-phoneNumber"
          v-model="v$.form.phoneNumber.$model"
        />
        <div class="invalid-feedback">
          <div v-if="v$.form.phoneNumber.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
          <div v-if="v$.form.phoneNumber.regex.$invalid">
            {{ $t(`MerchantRegistration.validationRegForm.phoneNumberRegex`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-email  -->
    <div class="row mb-3">
      <label for="input-email" class="col-sm-6 col-form-label">
        {{ $t(`MerchantRegistration.table.email`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <input
          type="text"
          class="form-control"
          :class="addClassValidInput('email')"
          id="input-email"
          v-model="v$.form.email.$model"
        />
        <div class="invalid-feedback">
          <div v-if="v$.form.email.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
          <div v-if="v$.form.email.email.$invalid">
            {{ $t(`MerchantRegistration.validationRegForm.email`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-userPassword  -->
    <div class="row mb-3">
      <label for="input-userPassword" class="col-sm-6 col-form-label">
        {{ $t(`Validations.userPassword`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <input
          type="password"
          class="form-control"
          :class="addClassValidInput('userPassword')"
          id="input-userPassword"
          v-model="v$.form.userPassword.$model"
        />
        <div class="invalid-feedback">
          <div v-if="v$.form.userPassword.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
          <div v-if="v$.form.userPassword.maxLength.$invalid">
            {{ $t(`Validations.maxLength`, { num: 6 }) }}
          </div>
          <div v-if="v$.form.userPassword.minLength.$invalid">
            {{ $t(`Validations.maxLength`, { num: 4 }) }}
          </div>
          <div v-if="v$.form.userPassword.numeric.$invalid">
            {{ $t(`Validations.onlyNumbers`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-userPasswordRepeatRepeat  -->
    <div class="row mb-3">
      <label for="input-userPasswordRepeat" class="col-sm-6 col-form-label">
        {{ $t(`Validations.passwordRepeat`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <input
          type="password"
          class="form-control"
          :class="addClassValidInput('userPasswordRepeat')"
          id="input-userPasswordRepeat"
          v-model="v$.form.userPasswordRepeat.$model"
        />
        <div class="invalid-feedback">
          <div v-if="v$.form.userPasswordRepeat.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
          <div v-if="v$.form.userPasswordRepeat.maxLength.$invalid">
            {{ $t(`Validations.maxLength`, { num: 6 }) }}
          </div>
          <div v-if="v$.form.userPasswordRepeat.sameAs.$invalid">
            {{ $t(`MerchantRegistration.validationRegForm.passwordRepeatErr`) }}
          </div>
          <div v-if="v$.form.userPasswordRepeat.minLength.$invalid">
            {{ $t(`Validations.maxLength`, { num: 4 }) }}
          </div>
          <div v-if="v$.form.userPasswordRepeat.numeric.$invalid">
            {{ $t(`Validations.onlyNumbers`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-merchantId  -->
    <div class="row mb-3">
      <label for="input-merchantId" class="col-sm-6 col-form-label">
        {{ $t(`MerchantRegistration.table.merchantId`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <input
          type="text"
          class="form-control"
          :class="addClassValidInput('merchantId')"
          id="input-merchantId"
          v-model="v$.form.merchantId.$model"
        />
        <div class="invalid-feedback">
          <div v-if="v$.form.merchantId.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
          <div v-if="v$.form.merchantId.regex.$invalid">
            {{ $t(`Validations.onlyLettersNumbers`) }}
          </div>
          <div v-if="v$.form.merchantId.maxLength.$invalid">
            {{ $t(`Validations.maxLength`, { num: 15 }) }}
          </div>
          <div v-if="v$.form.merchantId.minLength.$invalid">
            {{ $t(`Validations.minLength`, { num: 1 }) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-terminalId  -->
    <div class="row mb-3">
      <label for="input-terminalId" class="col-sm-6 col-form-label">
        {{ $t(`MerchantRegistration.table.terminalId`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <input
          type="text"
          class="form-control"
          :class="addClassValidInput('terminalId')"
          id="input-terminalId"
          v-model="v$.form.terminalId.$model"
        />
        <div class="invalid-feedback">
          <div v-if="v$.form.terminalId.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
          <div v-if="v$.form.terminalId.regex.$invalid">
            {{ $t(`Validations.onlyLettersNumbers`) }}
          </div>
          <div v-if="v$.form.terminalId.maxLength.$invalid">
            {{ $t(`Validations.maxLength`, { num: 8 }) }}
          </div>
          <div v-if="v$.form.terminalId.minLength.$invalid">
            {{ $t(`Validations.minLength`, { num: 8 }) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-taxId  -->
    <div class="row mb-3">
      <label for="input-taxId" class="col-sm-6 col-form-label">
        {{ $t(`MerchantRegistration.table.taxId`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <input
          type="text"
          v-maska
          data-maska="##########"
          class="form-control"
          :class="addClassValidInput('taxId')"
          id="input-taxId"
          v-model="v$.form.taxId.$model"
        />
        <div class="invalid-feedback">
          <div v-if="v$.form.taxId.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
          <div v-if="v$.form.taxId.maxLength.$invalid">
            {{ $t(`Validations.maxLength`, { num: 10 }) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-mcc  -->
    <div class="row mb-3">
      <label for="input-mcc" class="col-sm-6 col-form-label">
        {{ $t(`MerchantRegistration.table.mcc`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <input
          type="number"
          class="form-control"
          :class="addClassValidInput('mcc')"
          id="input-mcc"
          v-model="v$.form.mcc.$model"
        />
        <div class="invalid-feedback">
          <div v-if="v$.form.mcc.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
          <div v-if="v$.form.mcc.mccCheck.$invalid">
            {{ $t(`MerchantRegistration.validationRegForm.mccRegex`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-latitude  -->
    <div class="row mb-3">
      <label for="input-latitude" class="col-sm-6 col-form-label">
        {{ $t(`MerchantRegistration.table.latitude`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <input
          type="text"
          class="form-control"
          :class="addClassValidInput('latitude')"
          id="input-latitude"
          v-model="v$.form.latitude.$model"
        />
        <div class="invalid-feedback">
          <div v-if="v$.form.latitude.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
          <div v-if="v$.form.latitude.regex.$invalid">
            {{ $t(`MerchantRegistration.validationRegForm.titude`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-longitude  -->
    <div class="row mb-3">
      <label for="input-longitude" class="col-sm-6 col-form-label">
        {{ $t(`MerchantRegistration.table.longitude`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <input
          type="text"
          class="form-control"
          :class="addClassValidInput('longitude')"
          id="input-longitude"
          v-model="v$.form.longitude.$model"
        />
        <div class="invalid-feedback">
          <div v-if="v$.form.longitude.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
          <div v-if="v$.form.longitude.regex.$invalid">
            {{ $t(`MerchantRegistration.validationRegForm.titude`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-radius  -->
    <div class="row mb-3">
      <label for="input-radius" class="col-sm-6 col-form-label">
        {{ $t(`MerchantRegistration.table.radius`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <input
          type="text"
          class="form-control"
          :class="addClassValidInput('radius')"
          id="input-radius"
          v-model="v$.form.radius.$model"
        />
        <div class="invalid-feedback">
          <div v-if="v$.form.radius.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
          <div v-if="v$.form.radius.radiusCheck.$invalid">
            {{ $t(`MerchantRegistration.validationRegForm.radiusReg`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-groupNumber  -->
    <div class="row mb-3">
      <label for="input-groupNumber" class="col-sm-6 col-form-label">
        {{ $t(`MerchantRegistration.table.serviceGroupGroupNumber`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <select
          class="form-select"
          :class="addClassValidInput('groupNumber')"
          id="input-groupNumber"
          v-model="v$.form.groupNumber.$model"
        >
          <option
            v-for="item in allServiceGroups"
            :key="item"
            :value="item.groupNumber"
          >
            {{ item.groupName }}
          </option>
        </select>
        <div class="invalid-feedback">
          <div v-if="v$.form.groupNumber.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
          <div v-if="v$.form.groupNumber.minValue.$invalid">
            {{ $t(`Validations.minValue`, { num: 0 }) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-opCreateSubordinationTerminal  -->
    <div class="row mb-3">
      <label
        for="input-opCreateSubordinationTerminal"
        class="col-sm-6 col-form-label"
      >
        {{ $t(`TerminalGroups.table.opCreateSubordinationTerminal`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <select
          class="form-select"
          :class="addClassValidInput('opCreateSubordinationTerminal')"
          id="input-opCreateSubordinationTerminal"
          v-model="v$.form.opCreateSubordinationTerminal.$model"
        >
          <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
          <option value="N">{{ $t(`uiElements.text.no`) }}</option>
        </select>
        <div class="invalid-feedback">
          <div v-if="v$.form.opCreateSubordinationTerminal.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-numberOfSecondaryTerminals  -->
    <div
      class="row mb-3"
      v-if="v$.form.opCreateSubordinationTerminal.$model === 'Y'"
    >
      <label
        for="input-numberOfSecondaryTerminals"
        class="col-sm-6 col-form-label"
      >
        {{ $t(`TerminalGroups.table.numberOfSecondaryTerminals`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <input
          type="number"
          v-maska
          data-maska="#############"
          class="form-control"
          :class="addClassValidInput('numberOfSecondaryTerminals')"
          id="input-numberOfSecondaryTerminals"
          v-model="v$.form.numberOfSecondaryTerminals.$model"
        />
        <div class="invalid-feedback">
          <div v-if="v$.form.numberOfSecondaryTerminals.requiredIf.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <hr />
    <!--  Buttons form  -->
    <div class="d-flex justify-content-end">
      <button
        type="button"
        class="btn btn-outline-secondary me-3"
        @click="$emit('hideMainModal')"
      >
        {{ $t(`uiElements.btn.close`) }}
      </button>
      <button
        type="submit"
        class="btn btn-outline-primary"
        :disabled="v$.$invalid && v$.$dirty ? true : false"
      >
        {{ $t(`uiElements.btn.apply`) }}
      </button>
    </div>
  </form>

  <div class="toast-container position-fixed p-3 top-0 end-0" v-if="showToast">
    <bs-toast v-for="toast in toastPropsList" :key="toast" :="toast"></bs-toast>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {
  required,
  helpers,
  maxLength,
  sameAs,
  minLength,
  minValue,
  email,
  requiredIf,
  numeric,
} from "@vuelidate/validators";
import merchantRegistrationApi from "@/api/merchant-registration";
import BsToast from "@/components/UI-elements/BsToast";

export default {
  name: "TrnMerchantRegistrationForm",
  components: {
    BsToast,
  },
  emits: ["hideMainModal", "reloadPage"],
  setup() {
    return { v$: useVuelidate() };
  },
  props: {
    allServiceGroups: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        userLogin: "",
      },
      showToast: false,
      toastPropsList: [],
    };
  },
  validations() {
    return {
      form: {
        userLogin: {
          required,
          regex: helpers.regex(/^[a-zA-Z0-9]+$/),
        },
        merchantName: {
          required,
          maxLength: maxLength(25),
        },
        merchantNameEng: {
          required,
          maxLength: maxLength(25),
        },
        merchantLocation: {
          required,
          maxLength: maxLength(50),
        },
        merchantLocationEng: {
          required,
          maxLength: maxLength(50),
        },
        merchantLegalName: {
          required,
          maxLength: maxLength(30),
        },
        iban: {
          required,
          regex: helpers.regex(/^UA\d{8}[A-Z0-9]{19}$/), // IBAN тільки під Україну
        },
        phoneNumber: {
          required,
          regex: helpers.regex(/^(\+?\d{10,12})$/),
        },
        email: {
          required,
          email: email,
        },
        userPassword: {
          required,
          minLength: minLength(4),
          maxLength: maxLength(6),
          numeric,
        },
        userPasswordRepeat: {
          required,
          minLength: minLength(4),
          maxLength: maxLength(6),
          sameAs: sameAs(this.form.userPassword),
          numeric,
        },
        merchantId: {
          required,
          regex: helpers.regex(/^[a-zA-Z0-9]+$/),
          maxLength: maxLength(15),
          minLength: minLength(1),
        },
        terminalId: {
          required,
          regex: helpers.regex(/^[a-zA-Z0-9]+$/),
          maxLength: maxLength(8),
          minLength: minLength(8),
        },
        groupNumber: {
          required,
          minValue: minValue(0),
        },
        taxId: {
          required,
          maxLength: maxLength(10),
        },
        mcc: {
          required,
          mccCheck: function (val) {
            return val > 1000 && val < 10000;
          },
        },
        latitude: {
          required,
          regex: helpers.regex(/-?\d{2}(\.)\d+/),
        },
        longitude: {
          required,
          regex: helpers.regex(/-?\d{2}(\.)\d+/),
        },
        radius: {
          required,
          radiusCheck: function (val) {
            return val >= 0 && val <= 1000000;
          },
        },
        opCreateSubordinationTerminal: {
          required,
        },
        numberOfSecondaryTerminals: {
          requiredIf: requiredIf(
            this.form.opCreateSubordinationTerminal === "Y"
          ),
        },
      },
    };
  },
  computed: {},
  methods: {
    formRegMerchant() {
      this.v$.form.$touch();
      // const test = true;
      // !this.v$.form.$error
      if (!this.v$.form.$error) {
        merchantRegistrationApi
          .addNewMerchant({
            ...this.form,
          })
          .then(function (result) {
            return result;
          })
          .catch(function (result) {
            return result;
          })
          .then((result) => {
            console.log("result", result);
            if (result.status === 200) {
              this.showModal = false;

              this.toastPropsList.push({
                content: `${this.$t(`uiElements.toast.successSave`)}`,
                bgColor: "bg-success",
                textColor: "text-white",
              });
              this.showToast = true;

              this.$emit("hideMainModal");
              this.$emit("reloadPage");
            } else {
              console.log("err");
              this.toastPropsList.push({
                title: `${this.$t(`uiElements.toast.errSave`)}`,
                content: result.response.data.error.errorText,
                bgColor: "bg-danger",
                textColor: "text-white",
              });
              this.showToast = true;
            }
          });
      }
    },
    addClassValidInput(inputName) {
      return {
        "is-invalid":
          this.v$.form[inputName].$error === true &&
          this.v$.form[inputName].$dirty === true,
        "is-valid":
          this.v$.form[inputName].$error === false &&
          this.v$.form[inputName].$dirty === true,
      };
    },
  },
  watch: {
    "form.groupNumber"() {
      if (this.form.groupNumber > 0) {
        const selectedGroup = this.allServiceGroups.find(
          (group) => group.groupNumber === this.form.groupNumber
        );

        this.form.opCreateSubordinationTerminal =
          selectedGroup.opCreateSubordinationTerminal;
        this.form.numberOfSecondaryTerminals =
          selectedGroup.numberOfSecondaryTerminals;
      }
    },
    "form.opCreateSubordinationTerminal"() {
      if (this.form.opCreateSubordinationTerminal === "N") {
        this.form.numberOfSecondaryTerminals = 0;
      }
    },
  },
};
</script>

<style scoped></style>
