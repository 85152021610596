<template>
  <div class="my-2 h-100">
    <ul class="list-group list-group-flush">
      <li
        class="list-group-item d-flex justify-content-between align-items-center"
      >
        {{ $t(`Transaction.table.terminalId`)
        }}<span class="badge bg-primary">{{
          transaction?.terminal?.terminalId
        }}</span>
      </li>
      <li
        class="list-group-item d-flex justify-content-between align-items-center"
      >
        {{ $t(`Transaction.table.rrn`)
        }}<span class="badge bg-primary">{{ transaction.rrn }}</span>
      </li>
      <li
        class="list-group-item d-flex justify-content-between align-items-center"
      >
        {{ $t(`Transaction.table.amount`)
        }}<span class="badge bg-primary">{{
          $filters.formatAmount(transaction.amount) +
          " " +
          transaction.currency.letterCode
        }}</span>
      </li>
    </ul>

    <hr />

    <!--  Buttons form  -->
    <div class="d-flex justify-content-end">
      <button
        type="button"
        class="btn btn-outline-secondary me-3"
        @click="$emit('closeModal')"
      >
        {{ $t(`uiElements.btn.close`) }}
      </button>
      <button
        type="submit"
        class="btn btn-outline-danger"
        @click="reverseTransaction"
      >
        {{ $t(`Transaction.btn.reversal`) }}
      </button>
    </div>
  </div>
</template>

<script>
import transactionApi from "@/api/transaciton";
import $filters from "@/helpers/filters";

export default {
  name: "TrnTransactionModalReversal",
  computed: {
    $filters() {
      return $filters;
    },
  },
  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },
  emits: ["reverseTransaction", "closeModal"],
  methods: {
    reverseTransaction() {
      const requestObj = {
        rrn: this.transaction.rrn,
      };

      transactionApi
        .reversal(requestObj)
        .then((res) => {
          if (res.status === 200) {
            this.$emit("reverseTransaction", {
              response: res,
              status: "success",
            });
          } else {
            this.$emit("reverseTransaction", {
              response: res,
              status: "error",
            });
          }
        })
        .catch((error) => {
          this.$emit("reverseTransaction", {
            response: error,
            status: "error",
          });
        });
    },
  },
  mounted() {
    console.log(this.transaction);
  },
};
</script>

<style scoped></style>
