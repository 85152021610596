<template>
  <div class="title-table mb-2 ps-4 py-1 fw-bold">
    {{ $t(`settings-menu.userRoles`) }}
  </div>

  <!-- Фільтр -->
  <div class="toolbar">
    <div class="btn-group mb-2">
      <button
        class="btn btn-outline-primary btn-sm"
        type="button"
        @click="showAddRole()"
      >
        <i class="bi bi-file-earmark-plus"></i>
        <span class="ms-1">{{ $t(`Settings.UserRoles.btn.addRole`) }}</span>
      </button>
    </div>
  </div>

  <!-- Фільтр кінець -->

  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4">
        <div class="table-responsive table-block overflow-auto">
          <table class="table table-striped table-hover table-sm table-light">
            <thead class="">
              <tr>
                <th scope="col">{{ $t(`Settings.Banks.table.id`) }}</th>
                <th scope="col">
                  {{ $t(`Settings.UserRoles.table.roleTitle`) }}
                </th>
                <th scope="col">
                  {{ $t(`Settings.UserRoles.table.description`) }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="isLoading">
                <tr>
                  <td colspan="8">
                    <div class="loading text-center">
                      <trn-loading></trn-loading>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-if="dataResp">
                <tr
                  v-for="itemObj in dataResp.userRole"
                  :key="itemObj"
                  class="cursor-pointer"
                  :class="{
                    'table-primary': itemObj.roleCode === this.userRoleCode,
                  }"
                  @click="selectFormRole(itemObj)"
                >
                  <td>{{ itemObj.roleCode }}</td>
                  <td>{{ itemObj.roleTitle ? itemObj.roleTitle : "-" }}</td>
                  <td>{{ itemObj.description }}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>

      <div class="col-md-8" v-if="showSelectBlockRoleS">
        <div class="d-inline-flex w-100">
          <div class="flex-grow-1">
            <p>{{ $t(`Settings.UserRoles.permissionsAvailable`) }}</p>
            <select
              class="form-select"
              multiple
              size="20"
              aria-label="size 10 select multiple"
              v-model="selectRoleLeft"
            >
              <option
                :value="item"
                v-for="item in this.permissionsAvailable"
                :key="item"
              >
                {{ item }}
              </option>
            </select>
          </div>
          <div class="btn-action d-flex align-items-center">
            <div class="px-1">
              <p>
                <button
                  type="button"
                  class="btn btn-sm btn-success"
                  @click="moveItemRight"
                  :disabled="selectRoleLeft.length === 0"
                >
                  <i class="bi bi-arrow-right-square"></i>
                </button>
              </p>

              <p>
                <button
                  type="button"
                  class="btn btn-sm btn-success"
                  @click="moveItemLeft"
                  :disabled="selectRoleRight.length === 0"
                >
                  <i class="bi bi-arrow-left-square"></i>
                </button>
              </p>
            </div>
          </div>
          <div class="flex-grow-1">
            <p>{{ $t(`Settings.UserRoles.permissionsSelect`) }}</p>
            <select
              class="form-select"
              multiple
              size="20"
              aria-label="size 10 select multiple"
              v-model="selectRoleRight"
            >
              <option
                :value="item"
                v-for="item in this.permissionsSelected"
                :key="item"
              >
                {{ item }}
              </option>
            </select>
            <button
              type="button"
              class="btn btn-sm btn-success w-100 mt-3"
              @click="saveSelectItems"
            >
              {{ $t(`uiElements.btn.save`) }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <bs-modal
    :modal-toggle-emit="showModal"
    :title="titleModal"
    :modal-dialog-width="'modal-lg'"
    @hideMainModal="showModal = false"
  >
    <template v-if="formType === 'addFormUserRole'">
      <form
        class="needs-validation"
        @submit.prevent="submitFormUserRole"
        novalidate
      >
        <!--  input-roleCode  -->
        <div class="row mb-3">
          <label for="input-roleCode" class="col-sm-6 col-form-label">
            {{ $t(`Settings.UserRoles.table.roleCode`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              :class="addClassValidInput('roleCode')"
              id="input-roleCode"
              v-model="v$.formUserRole.roleCode.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formUserRole.roleCode.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formUserRole.roleCode.reTextAndNum.$invalid">
                {{ $t(`Validations.reTextAndNum`) }}
              </div>
              <div v-if="v$.formUserRole.roleCode.maxLength.$invalid">
                {{ $t(`Validations.maxLength`, { num: 255 }) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-roleTitle  -->
        <div class="row mb-3">
          <label for="input-roleTitle" class="col-sm-6 col-form-label">
            {{ $t(`Settings.UserRoles.table.roleTitle`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              :class="addClassValidInput('roleTitle')"
              id="input-roleTitle"
              v-model="v$.formUserRole.roleTitle.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formUserRole.roleTitle.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formUserRole.roleTitle.reTextAndNum.$invalid">
                {{ $t(`Validations.reTextAndNum`) }}
              </div>
              <div v-if="v$.formUserRole.roleTitle.maxLength.$invalid">
                {{ $t(`Validations.maxLength`, { num: 255 }) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-description  -->
        <div class="row mb-3">
          <label for="input-description" class="col-sm-6 col-form-label">
            {{ $t(`Settings.UserRoles.table.description`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              :class="addClassValidInput('description')"
              id="input-description"
              v-model="v$.formUserRole.description.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formUserRole.description.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div
                v-if="v$.formUserRole.description.reTextAndNumDot.$invalid"
              >
                {{ $t(`Validations.reTextAndNumDot`) }}
              </div>
              <div v-if="v$.formUserRole.description.maxLength.$invalid">
                {{ $t(`Validations.maxLength`, { num: 255 }) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-roleAuthorities  -->
        <div class="row mb-3">
          <label for="input-roleAuthorities" class="col-sm-6 col-form-label">
            {{ $t(`Settings.UserRoles.table.roleAuthorities`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-12">
            <div class="d-inline-flex w-100">
              <div class="flex-grow-1">
                <p>{{ $t(`Settings.UserRoles.permissionsAvailable`) }}</p>
                <select
                  class="form-select"
                  multiple
                  size="20"
                  aria-label="size 10 select multiple"
                  v-model="selectRoleLeft"
                >
                  <option
                    :value="item"
                    v-for="item in this.permissionsAvailable"
                    :key="item"
                  >
                    {{ item }}
                  </option>
                </select>
              </div>
              <div class="btn-action d-flex align-items-center">
                <div class="px-1">
                  <p>
                    <button
                      type="button"
                      class="btn btn-sm btn-success"
                      @click="moveItemRight"
                      :disabled="selectRoleLeft.length === 0"
                    >
                      <i class="bi bi-arrow-right-square"></i>
                    </button>
                  </p>

                  <p>
                    <button
                      type="button"
                      class="btn btn-sm btn-success"
                      @click="moveItemLeft"
                      :disabled="selectRoleRight.length === 0"
                    >
                      <i class="bi bi-arrow-left-square"></i>
                    </button>
                  </p>
                </div>
              </div>
              <div class="flex-grow-1">
                <p>{{ $t(`Settings.UserRoles.permissionsSelect`) }}</p>
                <select
                  class="form-select"
                  multiple
                  size="20"
                  aria-label="size 10 select multiple"
                  v-model="formUserRole.roleAuthorities"
                >
                  <option
                    :value="item"
                    v-for="item in this.permissionsSelected"
                    :key="item"
                  >
                    {{ item }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <!--  Buttons form  -->
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-outline-secondary me-3"
            @click="showModal = false"
          >
            {{ $t(`uiElements.btn.close`) }}
          </button>
          <button
            type="submit"
            class="btn btn-outline-primary"
            :disabled="v$.$invalid && v$.$dirty ? true : false"
          >
            {{ $t(`uiElements.btn.save`) }}
          </button>
        </div>
      </form>
    </template>
  </bs-modal>

  <div class="toast-container position-absolute p-3 end-0" v-if="showToast">
    <bs-toast v-for="toast in toastPropsList" :key="toast" :="toast"></bs-toast>
  </div>
</template>

<script>
import TrnLoading from "@/components/Loading";
import { actionTypes } from "@/store/modules/settings-store/user-roles";
import userRoleApi from "@/api/settings-api/user-roles";
import { mapState } from "vuex";
import BsToast from "@/components/UI-elements/BsToast";
import useVuelidate from "@vuelidate/core";
import BsModal from "@/components/UI-elements/BsModal";
import {helpers, maxLength, required} from "@vuelidate/validators";

export default {
  name: "TrnUserRoles",
  components: { BsToast, TrnLoading, BsModal },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      showModal: false,
      showToast: false,
      showSelectBlockRoleS: false,
      userRoleCode: "",
      userRoleDescription: "",
      permissionsAvailable: [],
      permissionsSelected: [],
      selectRoleLeft: [],
      selectRoleRight: [],
      toastPropsList: [],
      titleModal: "",
      formType: "",
      formUserRole: {},
    };
  },
  validations() {
    return {
      formUserRole: {
        roleCode: {
          required,
          reTextAndNum: helpers.regex(/^[a-zA-Zа-яА-ЯіїґєІЇҐЄёЁ0-9\s]+$/u),
          maxLength: maxLength(255),
        },
        roleTitle: {
          required,
          reTextAndNum: helpers.regex(/^[a-zA-Zа-яА-ЯіїґєІЇҐЄёЁ0-9\s]+$/u),
          maxLength: maxLength(255),
        },
        description: {
          required,
          reTextAndNumDot: helpers.regex(
              /^[a-zA-Zа-яА-ЯіїґєІЇҐЄёЁ0-9\s.\-,]+$/u
          ),
          maxLength: maxLength(255),
        },
      },
    };
  },
  methods: {
    fetchUserRole() {
      this.$store.dispatch(actionTypes.getSettingUserRole);
    },
    selectFormRole(items) {
      this.userRoleCode = items.roleCode;
      this.userRoleDescription = items.description;
      this.showSelectBlockRoleS = true;
      this.permissionsAvailable = [];
      this.permissionsSelected = [];
      for (let itemRole in this.dataResp.allAuthorities) {
        this.permissionsAvailable.push(
          this.dataResp.allAuthorities[itemRole].authority
        );
      }

      for (let itemRole in items.roleAuthorities) {
        this.permissionsSelected.push(items.roleAuthorities[itemRole]);
      }

      for (let i = 0; i < this.permissionsSelected.length; i++) {
        let indexFound = this.permissionsAvailable.findIndex(
          (val) => val === this.permissionsSelected[i]
        );
        this.permissionsAvailable.splice(indexFound, 1);
      }
    },
    moveItemRight() {
      if (this.selectRoleLeft.length === 0) {
        return;
      }

      for (let i = 0; i < this.selectRoleLeft.length; i++) {
        let indexFound = this.permissionsAvailable.findIndex((val) => {
          if (val === this.selectRoleLeft[i]) {
            this.permissionsSelected.push(val);
            return true;
          }
        });
        this.permissionsAvailable.splice(indexFound, 1);
      }
      this.selectRoleLeft = [];
    },
    moveItemLeft() {
      if (this.selectRoleRight.length === 0) {
        return;
      }

      for (let i = 0; i < this.selectRoleRight.length; i++) {
        let indexFound = this.permissionsSelected.findIndex((val) => {
          if (val === this.selectRoleRight[i]) {
            this.permissionsAvailable.push(val);
            return true;
          }
        });
        this.permissionsSelected.splice(indexFound, 1);
      }
      this.selectRoleRight = [];
    },
    saveSelectItems() {
      let bodyReq = {
        description: this.userRoleDescription,
        userAuthorityList: this.permissionsSelected,
      };
      userRoleApi
        .update_role_code(this.userRoleCode, bodyReq)
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          // console.log("result", result);
          this.showToast = true;
          if (result.status === 200) {
            this.toastPropsList.push({
              content: `Success`,
              bgColor: "bg-success",
              textColor: "text-white",
            });
            this.fetchUserRole();
          } else {
            this.toastPropsList.push({
              content: "Error",
              bgColor: "bg-danger",
              textColor: "text-white",
            });
          }
        });
    },
    addClassValidInput(inputName) {
      return {
        "is-invalid":
          this.v$.formUserRole[inputName].$error === true &&
          this.v$.formUserRole[inputName].$dirty === true,
        "is-valid":
          this.v$.formUserRole[inputName].$error === false &&
          this.v$.formUserRole[inputName].$dirty === true,
      };
    },
    showAddRole() {
      this.showModal = !this.showModal;
      this.showSelectBlockRoleS = false;
      this.formType = "addFormUserRole";
      this.titleModal = this.$t(`Settings.UserRoles.btn.addRole`);
      this.permissionsAvailable = [];
      this.permissionsSelected = [];
      this.formUserRole.roleAuthorities = [];
      for (let itemRole in this.dataResp.allAuthorities) {
        this.permissionsAvailable.push(
          this.dataResp.allAuthorities[itemRole].authority
        );
      }
    },
    submitFormUserRole() {
      this.v$.formUserRole.$touch();
      if (!this.v$.formUserRole.$error) {
        userRoleApi
          .add_role_code({
            ...this.formUserRole,
            roleAuthorities: [...this.permissionsSelected],
          })
          .then(function (result) {
            return result;
          })
          .catch(function (result) {
            return result;
          })
          .then((result) => {
            // console.log("result", result);
            if (result.status === 200) {
              this.showModal = false;

              this.toastPropsList.push({
                content: `${this.$t(`uiElements.toast.successSave`)}`,
                bgColor: "bg-success",
                textColor: "text-white",
              });
              this.showToast = true;

              this.formUserRole = {};

              this.fetchUserRole();
            } else {
              console.log("err");
              this.toastPropsList.push({
                content: `${this.$t(`uiElements.toast.errSave`)}`,
                bgColor: "bg-danger",
                textColor: "text-white",
              });
              this.showToast = true;
            }
          });
      }
    },
  },
  computed: {
    dataResp: {
      get() {
        return this.$store.state.settingUserRole.dataResp;
      },
      set(newValue) {
        this.$store.state.settingUserRole.dataResp = newValue;
      },
    },
    ...mapState({
      isLoading: (state) => state.settingUserRole.isLoading,
      // isLoadingScroll: (state) => state.transactions.isLoadingScroll,
      // dataResp: (state) => state.transactions.dataResp,
      dataModalResp: (state) => state.settingUserRole.dataModalResp,
      errorResp: (state) => state.settingUserRole.errorResp,
    }),
  },
  mounted() {
    this.fetchUserRole();
  },
};
</script>

<style scoped></style>
