import attestationHistoryApi from "@/api/attestation-history";

const state = {
  dataResp: null,
  dataModalResp: null,

  isLoading: false,
  isLoadingScroll: false,
  isLoadingModal: false,

  errorResp: null,
};

export const mutationTypes = {
  getAttestationHistoryStart:
    "[AttestationHistory] getAttestationHistory start",
  getAttestationHistorySuccess:
    "[AttestationHistory] getAttestationHistory success",
  getAttestationHistoryFailure:
    "[AttestationHistory] getAttestationHistory failure",

  getAttestationHistoryScrollPushStart:
    "[AttestationHistory] getAttestationHistoryScrollPush start",
  getAttestationHistoryScrollPushSuccess:
    "[AttestationHistory] getAttestationHistoryScrollPushSuccess success",
  getAttestationHistoryScrollPushFailure:
    "[AttestationHistory] getAttestationHistoryScrollPush failure",
};

export const actionTypes = {
  getAttestationHistory: "[AttestationHistory] getAttestationHistory",
  getAttestationHistoryScrollPush:
    "[AttestationHistory] getAttestationHistoryScrollPush",
};

const mutations = {
  [mutationTypes.getAttestationHistoryStart](state) {
    state.isLoading = true;
    state.data = null;
  },
  [mutationTypes.getAttestationHistorySuccess](state, payload) {
    state.isLoading = false;
    state.dataResp = payload;
  },
  [mutationTypes.getAttestationHistoryFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
  [mutationTypes.getAttestationHistoryScrollPushStart](state) {
    state.isLoadingScroll = true;
  },
  [mutationTypes.getAttestationHistoryScrollPushSuccess](state, payload) {
    for (let item in payload.content) {
      state.dataResp.content.push(payload.content[item]);
    }
    state.isLoadingScroll = false;
  },
  [mutationTypes.getAttestationHistoryScrollPushFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
};

const actions = {
  [actionTypes.getAttestationHistory](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getAttestationHistoryStart, apiParams);
      attestationHistoryApi
        .getAttestationHistory(apiParams)
        .then((response) => {
          context.commit(
            mutationTypes.getAttestationHistorySuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getAttestationHistoryFailure,
            result.response.data.error
          );
        });
    });
  },
  [actionTypes.getAttestationHistoryScrollPush](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(
        mutationTypes.getAttestationHistoryScrollPushStart,
        apiParams
      );
      attestationHistoryApi
        .getAttestationHistory(apiParams)
        .then((response) => {
          context.commit(
            mutationTypes.getAttestationHistoryScrollPushSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getAttestationHistoryScrollPushFailure,
            result.response.data
          );
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
