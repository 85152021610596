import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "@/assets/css/main.css";
import "bootstrap";
import i18n from "./i18n";
import filters from "@/helpers/filters";
import VueCookies from "vue-cookies";
import { vMaska } from "maska";

const app = createApp(App).use(i18n).use(store).use(router).use(VueCookies);

app.config.globalProperties.$filters = filters;
app.directive("maska", vMaska);

app.mount("#app");
