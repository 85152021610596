<template>
  <div
    class="modal fade"
    tabindex="-1"
    id="main-modal"
    ref="mainModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div
      class="modal-dialog modal-dialog-width modal-dialog-scrollable modal-dialog-centered"
      :class="modalDialogWidth"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button type="button" class="btn-close" @click="modalHide()"></button>
        </div>
        <div class="modal-body">
          <div class="content" v-html="content"></div>
          <slot></slot>
        </div>
        <template v-if="footerBtnClose">
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              {{ $t(`uiElements.btn.close`) }}
            </button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
export default {
  name: "BsModal",
  props: {
    modalToggleProp: {
      type: Boolean,
      default: false,
    },
    modalToggleEmit: {
      type: Boolean,
      default: false,
    },
    content: {
      required: false,
      default: "",
    },
    title: {
      default: "...",
    },
    footerBtnClose: {
      type: Boolean,
      default: false,
    },
    modalDialogWidth: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      modal: true,
      modalRef: null,
    };
  },
  watch: {
    modalToggleProp: function () {
      let mainModal = new Modal(document.getElementById("main-modal"));
      mainModal.toggle();
    },
    modalToggleEmit: function () {
      if (this.modalToggleEmit) {
        // console.log("show");
        this.modalRef.show();
      } else {
        // console.log("hide");
        this.modalRef.hide();
      }
    },
  },
  methods: {
    modalHide() {
      this.$emit("hideMainModal");
    },
  },
  mounted() {
    this.modalRef = new Modal(this.$refs.mainModal);
  },
};
</script>

<style scoped></style>
