import axios from "axios";
import { getItemLocalStorage } from "@/helpers/persistanceStorage";
import router from "@/router";

axios.defaults.baseURL = process.env.VUE_APP_API_HOST;

axios.interceptors.request.use((config) => {
  if (config.url !== "/auth/token") {
    const token = getItemLocalStorage("accessToken");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
  }
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.config.url === "/auth/token") {
      return error;
    }
    // const errorCode = error.response.data.error.errorCode;
    console.log("resp", error.response);
    const respCode = error.response.status;
    // if (errorCode === "3311" || errorCode === "3301") {
    //   router.push({ name: "login" });
    // }
    if (respCode === 401) {
      router.push({ name: "login" });
    }

    return error;
  }
);

export default axios;
