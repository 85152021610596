import axios from "@/api/axios";

const getSchedule = (apiParams) => {
  return axios.get("/background-jobs/with-dictionaries?" + apiParams);
};

const updateSchedule = (rcId, rcObj) => {
  return axios.put("/background-jobs/" + rcId, { ...rcObj });
};

const addSchedule = (rcObj) => {
  return axios.post("/background-jobs/", { ...rcObj });
};

const forcedStartJob = (id) => {
  return axios.post("background-jobs/schedule-immediate/" + id);
};

export default {
  getSchedule,
  updateSchedule,
  addSchedule,
  forcedStartJob,
};
