<template>
  <form
    class="needs-validation"
    @submit.prevent="submitChangeGroupNumber"
    novalidate
  >
    <!--  input-merchantId  -->
    <div class="row mb-3">
      <label for="input-merchantId" class="col-sm-6 col-form-label">
        {{ $t(`MerchantRegistration.table.merchantId`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <input
          type="text"
          class="form-control"
          :class="addClassValidInput('merchantId')"
          id="input-merchantId"
          v-model="v$.form.merchantId.$model"
          :disabled="true"
        />
        <div class="invalid-feedback">
          <div v-if="v$.form.merchantId.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
          <div v-if="v$.form.merchantId.reNumAndDot.$invalid">
            {{ $t(`Validations.reNumAndDot`) }}
          </div>
          <div v-if="v$.form.merchantId.maxLength.$invalid">
            {{ $t(`Validations.maxLength`, { num: 255 }) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-terminalId  -->
    <div class="row mb-3">
      <label for="input-terminalId" class="col-sm-6 col-form-label">
        {{ $t(`MerchantRegistration.table.terminalId`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <input
          type="text"
          class="form-control"
          :class="addClassValidInput('terminalId')"
          id="input-terminalId"
          v-model="v$.form.terminalId.$model"
          :disabled="true"
        />
        <div class="invalid-feedback">
          <div v-if="v$.form.terminalId.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
          <div v-if="v$.form.terminalId.regex.$invalid">
            {{ $t(`Validations.onlyLettersNumbers`) }}
          </div>
          <div v-if="v$.form.terminalId.maxLength.$invalid">
            {{ $t(`Validations.maxLength`, { num: 8 }) }}
          </div>
          <div v-if="v$.form.terminalId.minLength.$invalid">
            {{ $t(`Validations.minLength`, { num: 8 }) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-status  -->
    <div class="row mb-3">
      <label for="input-status" class="col-sm-6 col-form-label">
        {{ $t(`MerchantRegistration.table.status`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <select
          class="form-select"
          :class="addClassValidInput('status')"
          id="input-status"
          v-model="v$.form.status.$model"
        >
          <option value="ACTIVE">ACTIVE</option>
          <option value="DELETED">DELETED</option>
          <option value="BLOCKED">BLOCKED</option>
        </select>
        <div class="invalid-feedback">
          <div v-if="v$.form.status.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  input-addData  -->
    <div class="row mb-3">
      <label for="input-addData" class="col-sm-6 col-form-label">
        {{ $t(`MerchantRegistration.table.addData`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <textarea
          class="form-control"
          :class="addClassValidInput('addData')"
          id="input-addData"
          v-model="v$.form.addData.$model"
        />
        <div class="invalid-feedback">
          <div v-if="v$.form.addData.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  Buttons form  -->
    <div class="d-flex justify-content-end">
      <button
        type="button"
        class="btn btn-outline-secondary me-3"
        @click="$emit('hideMainModal')"
      >
        {{ $t(`uiElements.btn.close`) }}
      </button>
      <button
        type="submit"
        class="btn btn-outline-primary"
        :disabled="v$.$invalid && v$.$dirty ? true : false"
      >
        {{ $t(`uiElements.btn.change`) }}
      </button>
    </div>
  </form>
  <div
    class="toast-container position-fixed p-3 top-0 end-0 z-index-1500"
    v-if="showToast"
  >
    <bs-toast v-for="toast in toastPropsList" :key="toast" :="toast"></bs-toast>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import BsToast from "@/components/UI-elements/BsToast.vue";
import { helpers, maxLength, minLength, required } from "@vuelidate/validators";
import merchantApi from "@/api/merchant-registration";

export default {
  name: "TrnMerchantRegistrationEditStatus",
  components: {
    BsToast,
  },
  emits: ["hideMainModal", "showToastContent", "updatePage"],
  setup() {
    return { v$: useVuelidate() };
  },
  props: {
    merchantObj: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {},
      showToast: false,
      toastPropsList: [],
    };
  },
  validations() {
    return {
      form: {
        merchantId: {
          required,
          reNumAndDot: helpers.regex(/^[a-zA-Zа-яА-ЯіїґєІЇҐЄёЁ0-9\s]+$/u),
          maxLength: maxLength(255),
        },
        terminalId: {
          required,
          regex: helpers.regex(/^[a-zA-Z0-9]+$/),
          maxLength: maxLength(8),
          minLength: minLength(8),
        },
        status: {
          required,
        },
        addData: {
          required,
        },
      },
    };
  },
  methods: {
    addClassValidInput(inputName) {
      return {
        "is-invalid":
          this.v$.form[inputName].$error === true &&
          this.v$.form[inputName].$dirty === true,
        "is-valid":
          this.v$.form[inputName].$error === false &&
          this.v$.form[inputName].$dirty === true,
      };
    },
    submitChangeGroupNumber() {
      this.v$.form.$touch();
      if (!this.v$.form.$error) {
        merchantApi
          .updateMerchant({ ...this.form })
          .then(function (result) {
            return result;
          })
          .catch(function (result) {
            return result;
          })
          .then((result) => {
            // console.log("result", result);
            if (result.status === 200) {
              // this.toastPropsList.push({
              //   content: `${this.$t(`uiElements.toast.success`)}`,
              //   bgColor: "bg-success",
              //   textColor: "text-white",
              // });
              // this.showToast = true;
              this.$emit("showToastContent", {
                content: `${this.$t(`uiElements.toast.success`)}`,
                bgColor: "bg-success",
                textColor: "text-white",
              });
              this.$emit("hideMainModal");
              this.form.merchantId = "";
              this.form.terminalId = "";
              this.form.addData = "";
              this.$emit("hideMainModal");
              this.$emit("updatePage");
            } else {
              this.showModal = false;
              this.selectedTableRow = null;
              console.log("err");
              this.toastPropsList.push({
                content: `${this.$t(`uiElements.toast.err`)}`,
                bgColor: "bg-danger",
                textColor: "text-white",
              });
              this.showToast = true;
            }
          });
      }
    },
  },
  watch: {
    merchantObj() {
      this.form.terminalId = this.merchantObj.terminalId;
      this.form.merchantId = this.merchantObj.merchantId;
      // this.form.status = this.merchantObj.status;
      this.form.addData = this.merchantObj.addData;
    },
  },
  mounted() {
    this.form.terminalId = this.merchantObj.terminalId;
    this.form.merchantId = this.merchantObj.merchantId;
    // this.form.status = this.merchantObj.status;
    this.form.addData = this.merchantObj.addData;
  },
};
</script>

<style scoped></style>
