import axios from "@/api/axios";

const getIpsKey = (apiParams) => {
  return axios.get("/ips-keys?" + apiParams);
};

const updateIpsKey = (id, reqObj) => {
  return axios.put("/ips-keys/" + id, {
    ...reqObj,
  });
};

const addIpsKey = (reqObj) => {
  return axios.post("/ips-keys", {
    ...reqObj,
  });
};

export default {
  getIpsKey,
  updateIpsKey,
  addIpsKey,
};
