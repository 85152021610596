<template>
  <div class="title-table mb-2 ps-3 py-1 fw-bold">
    {{ $t(`left-menu.serviceGroup`) }}
  </div>

  <!-- ToolBar -->
  <div class="toolbar" :hidden="checkPermission('TERMINAL_GROUPS_UPDATE') === false">
    <div class="btn-group mb-2">
      <button
        class="btn btn-outline-primary btn-sm"
        type="button"
        @click="showAddGroup"
      >
        <i class="bi bi-file-earmark-plus"></i>
        <span class="ms-1">{{ $t(`uiElements.btn.add`) }}</span>
      </button>
      <button
        class="btn btn-outline-primary btn-sm"
        :class="{ disabled: !selectedTableRow?.groupNumber }"
        type="button"
        @click="showChangeGroupObj"
      >
        <i class="bi bi-pencil-square"></i>
        <span class="ms-1">{{ $t(`TerminalGroups.btn.changeGroup`) }}</span>
      </button>
      <button
        class="btn btn-outline-primary btn-sm"
        :class="{ disabled: !selectedTableRow?.groupNumber }"
        type="button"
        @click="showChangeGroupNumber"
      >
        <i class="bi bi-123"></i>
        <span class="ms-1">{{
          $t(`TerminalGroups.btn.changeNumberGroup`)
        }}</span>
      </button>
      <button
        class="btn btn-outline-primary btn-sm"
        :class="{ disabled: !selectedTableRow?.groupNumber }"
        type="button"
        @click="showDeleteGroupID"
      >
        <i class="bi bi-file-earmark-x"></i>
        <span class="ms-1">{{ $t(`uiElements.btn.delete`) }}</span>
      </button>
    </div>
  </div>

  <!-- ToolBar кінець -->

  <div
    class="table-responsive table-block overflow-auto"
    id="table-block"
    @scroll="scrollTable"
  >
    <table class="table table-sm table-light">
      <thead>
        <tr class="text-center">
          <th scope="col">
            {{ $t(`TerminalGroups.table.groupNumber`) }}
          </th>
          <th scope="col">
            {{ $t(`TerminalGroups.table.groupName`) }}
          </th>
          <th scope="col">
            {{ $t(`TerminalGroups.table.status`) }}
          </th>
          <th scope="col">
            {{ $t(`TerminalGroups.table.currencyCode`) }}
          </th>
          <th scope="col">
            {{ $t(`TerminalGroups.table.receiptTemplateId`) }}
          </th>
          <th scope="col">
            {{ $t(`TerminalGroups.table.opPurchase`) }}
          </th>
          <th scope="col">
            {{ $t(`TerminalGroups.table.opReversal`) }}
          </th>
          <th scope="col">
            {{ $t(`TerminalGroups.table.opRefund`) }}
          </th>
          <th scope="col">
            {{ $t(`TerminalGroups.table.opNfc`) }}
          </th>
          <th scope="col">
            {{ $t(`TerminalGroups.table.opManual`) }}
          </th>
          <th scope="col">
            {{ $t(`TerminalGroups.table.opCash`) }}
          </th>
          <th scope="col">
            {{ $t(`TerminalGroups.table.opPinMastercard`) }}
          </th>
          <th scope="col">
            {{ $t(`TerminalGroups.table.opPinVisa`) }}
          </th>
          <th scope="col">
            {{ $t(`TerminalGroups.table.opPinProstir`) }}
          </th>
          <th scope="col">
            {{ $t(`TerminalGroups.table.opPinAmex`) }}
          </th>
          <th scope="col">
            {{ $t(`TerminalGroups.table.opPinDiscover`) }}
          </th>
          <th scope="col">
            {{ $t(`TerminalGroups.table.opSingleTapAndPin`) }}
          </th>
          <th scope="col">
            {{ $t(`TerminalGroups.table.opCreateSubordinationTerminal`) }}
          </th>
          <th scope="col">
            {{ $t(`TerminalGroups.table.geoPosition`) }}
          </th>
          <th scope="col">
            {{ $t(`TerminalGroups.table.oneTransactionLimit`) }}
          </th>
          <th scope="col">
            {{ $t(`TerminalGroups.table.noPinLimit`) }}
          </th>
          <th scope="col">
            {{ $t(`TerminalGroups.table.productIds`) }}
          </th>
          <th scope="col">
            {{ $t(`TerminalGroups.table.groupFacilityId`) }}
          </th>
          <th scope="col">
            {{ $t(`TerminalGroups.table.transactionGeoposition`) }}
          </th>
          <th scope="col">
            {{ $t(`TerminalGroups.table.bankName`) }}
          </th>
        </tr>
      </thead>
      <tbody class="text-nowrap">
        <template v-if="isLoading">
          <tr>
            <td colspan="16">
              <div class="loading text-center">
                <trn-loading></trn-loading>
              </div>
            </td>
          </tr>
        </template>
        <template v-if="dataResp">
          <tr
            v-for="item in dataResp.page.content"
            :key="item"
            class="text-center cursor-pointer"
            :class="{
              'select-td': item.groupNumber === selectedTableRow?.groupNumber,
            }"
            @click="clickTableRow(item)"
          >
            <td>{{ item.groupNumber }}</td>
            <td>{{ item.groupName }}</td>
            <td>{{ item.status }}</td>
            <td>{{ item.currency.letterCode }}</td>
            <td>{{ item.receiptTemplate.id }}</td>
            <td>{{ item.opPurchase }}</td>
            <td>{{ item.opReversal }}</td>
            <td>{{ item.opRefund }}</td>
            <td>{{ item.opNfc }}</td>
            <td>{{ item.opManual }}</td>
            <td>{{ item.opCash }}</td>
            <td>{{ item.opPinMastercard }}</td>
            <td>{{ item.opPinVisa }}</td>
            <td>{{ item.opPinProstir }}</td>
            <td>{{ item.opPinAmex }}</td>
            <td>{{ item.opPinDiscover }}</td>
            <td>{{ item.opSingleTapAndPin }}</td>
            <td>{{ item.opCreateSubordinationTerminal }}</td>
            <td>{{ item.geoPosition }}</td>
            <td>{{ item.oneTransactionLimit }}</td>
            <td>{{ item.noPinLimit }}</td>
            <td>
              <template v-for="itemProduct in item.products" :key="itemProduct">
                {{ itemProduct.productId + "," }}
              </template>
            </td>
            <td>{{ item.groupFacilityId }}</td>
            <td>{{ item.transactionGeoposition }}</td>
            <td>{{ item.bank.name }}</td>
          </tr>
          <template v-if="isLoadingScroll">
            <tr>
              <td colspan="16">
                <div class="loading text-center">
                  <trn-loading></trn-loading>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>

  <bs-modal
    :modal-toggle-emit="showModal"
    :title="titleModal"
    @hideMainModal="showModal = false"
  >
    <template v-if="typeModalForm === 'add'">
      <trn-terminal-group-edit
        @hideMainModal="showModal = false"
        @reloadPageTerminalGroup="fetchTerminalGroups"
        :action-type="'Add'"
        :allReceiptTemplates="allReceiptTemplates"
        :allBanks="allBanks"
        :allIpsCardGroups="allIpsCardGroups"
        :allCurrencies="allCurrencies"
        :allMenuItems="allMenuItems"
        :allProducts="allProducts"
        :allReceiptSendChannels="allReceiptSendChannels"
        :key="'new' + Math.random()"
      ></trn-terminal-group-edit>
    </template>
    <template v-if="typeModalForm === 'changeGroupObj'">
      <trn-terminal-group-edit
        @hideMainModal="showModal = false"
        @reloadPageTerminalGroup="fetchTerminalGroups"
        :terminal-group-obj="selectedTableRow"
        :action-type="'Update'"
        :allReceiptTemplates="allReceiptTemplates"
        :allBanks="allBanks"
        :allIpsCardGroups="allIpsCardGroups"
        :allCurrencies="allCurrencies"
        :allMenuItems="allMenuItems"
        :allProducts="allProducts"
        :allReceiptSendChannels="allReceiptSendChannels"
        :key="selectedTableRow.groupNumber"
      ></trn-terminal-group-edit>
    </template>
    <template v-if="typeModalForm === 'changeGroupNumber'">
      <form
        class="needs-validation"
        @submit.prevent="submitChangeGroupNumber"
        novalidate
      >
        <!--  input-groupName  -->
        <div class="row mb-3">
          <label for="input-groupName" class="col-sm-6 col-form-label">
            {{ $t(`TerminalGroups.table.groupName`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              id="input-groupName"
              v-model="formChangeGroupNumber.groupName"
              disabled
            />
          </div>
        </div>

        <!--  input-groupNumber  -->
        <div class="row mb-3">
          <label for="input-groupNumber" class="col-sm-6 col-form-label">
            {{ $t(`TerminalGroups.table.toGroupNumber`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="number"
              min="0"
              class="form-control"
              :class="addClassValidInput('toGroupNumberToChangeTerminals')"
              id="input-groupNumber"
              v-model="
                v$.formChangeGroupNumber.toGroupNumberToChangeTerminals.$model
              "
            />
            <div class="invalid-feedback">
              <div
                v-if="
                  v$.formChangeGroupNumber.toGroupNumberToChangeTerminals
                    .required.$invalid
                "
              >
                {{ $t(`Validations.required`) }}
              </div>
              <div
                v-if="
                  v$.formChangeGroupNumber.toGroupNumberToChangeTerminals
                    .minValue.$invalid
                "
              >
                {{ $t(`Validations.minValue`, { num: 0 }) }}
              </div>
            </div>
          </div>
        </div>

        <!--  Buttons form  -->
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-outline-secondary me-3"
            @click="showModal = false"
          >
            {{ $t(`uiElements.btn.close`) }}
          </button>
          <button
            type="submit"
            class="btn btn-outline-primary"
            :disabled="v$.$invalid && v$.$dirty ? true : false"
          >
            {{ $t(`uiElements.btn.save`) }}
          </button>
        </div>
      </form>
    </template>
    <template v-if="typeModalForm === 'deleteGroupID'">
      <form
        class="needs-validation"
        @submit.prevent="submitDeleteGroupNumber"
        novalidate
      >
        <!--  input-groupName  -->
        <div class="row mb-3">
          <label for="input-groupName" class="col-sm-6 col-form-label">
            {{ $t(`TerminalGroups.table.groupName`) }}
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              id="input-groupName"
              v-model="selectedTableRow.groupName"
              disabled
            />
          </div>
        </div>

        <!--  input-groupNumber  -->
        <div class="row mb-3">
          <label for="input-groupNumber" class="col-sm-6 col-form-label">
            {{ $t(`TerminalGroups.table.groupNumber`) }}
          </label>
          <div class="col-sm-6">
            <input
              type="number"
              class="form-control"
              id="input-groupNumber"
              v-model="selectedTableRow.groupNumber"
              disabled
            />
          </div>
        </div>

        <!--  Buttons form  -->
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-outline-secondary me-3"
            @click="showModal = false"
          >
            {{ $t(`uiElements.btn.close`) }}
          </button>
          <button type="submit" class="btn btn-outline-danger">
            {{ $t(`uiElements.btn.delete`) }}
          </button>
        </div>
      </form>
    </template>
  </bs-modal>
  <div
    class="toast-container position-absolute z-index-1500 p-3 end-0"
    v-if="showToast"
  >
    <bs-toast v-for="toast in toastPropsList" :key="toast" :="toast"></bs-toast>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { stringify } from "query-string";
import { actionTypes } from "@/store/modules/terminal-groups";
import terminalGroupsApi from "@/api/terminal-groups";
import TrnLoading from "@/components/Loading";
import BsModal from "@/components/UI-elements/BsModal";
import BsToast from "@/components/UI-elements/BsToast";
import TrnTerminalGroupEdit from "@/components/block-pages/TerminalGroupEdit";
import useVuelidate from "@vuelidate/core";
import { minValue, required } from "@vuelidate/validators";
import {checkPermission} from "@/helpers/checkPermission";

export default {
  name: "TrnTerminalGroups",
  components: {
    TrnTerminalGroupEdit,
    TrnLoading,
    BsModal,
    BsToast,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      page: 0,
      size: 100,
      sort: {
        col: "groupNumber",
        order: "asc",
      },
      showModal: false,
      titleModal: "",
      typeModalForm: "",
      formChangeGroupNumber: {},
      showToast: false,
      toastPropsList: [],
      selectedTableRow: null,
    };
  },
  validations() {
    return {
      formChangeGroupNumber: {
        toGroupNumberToChangeTerminals: {
          required,
          minValue: minValue(0),
        },
      },
    };
  },
  computed: {
    dataResp: {
      get() {
        return this.$store.state.terminalGroups.dataResp;
      },
      set(newValue) {
        this.$store.state.terminalGroups.dataResp = newValue;
      },
    },
    allReceiptTemplates() {
      return this.dataResp.allReceiptTemplates;
    },
    allBanks() {
      return this.dataResp.allBanks;
    },
    allIpsCardGroups() {
      return this.dataResp.allIpsCardGroups;
    },
    allCurrencies() {
      return this.dataResp.allCurrencies;
    },
    allMenuItems() {
      return this.dataResp.allMenuItems;
    },
    allProducts() {
      return this.dataResp.allProducts;
    },
    allReceiptSendChannels() {
      return this.dataResp.allReceiptSendChannels;
    },
    ...mapState({
      isLoading: (state) => state.terminalGroups.isLoading,
      isLoadingScroll: (state) => state.terminalGroups.isLoadingScroll,
      dataModalResp: (state) => state.terminalGroups.dataModalResp,
      errorResp: (state) => state.terminalGroups.errorResp,
    }),
  },
  methods: {
    checkPermission,
    fetchTerminalGroups() {
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
      });
      this.$store.dispatch(actionTypes.getTerminalGroups, {
        apiParams: stringifiedParams,
      });
    },
    scrollTable(event) {
      const currentEl = event.target;
      let checkScrollEnd =
        currentEl.scrollTop + currentEl.clientHeight + 3 >=
        currentEl.scrollHeight;
      if (checkScrollEnd && this.isLoadingScroll === false) {
        const currentPage = this.page + 1;
        const totalPages = this.dataResp.totalPages;
        if (currentPage < totalPages) this.loadMoreData();
      }
    },
    loadMoreData() {
      this.page += 1;
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
      });
      this.$store.dispatch(actionTypes.getTerminalGroupsScrollPush, {
        apiParams: stringifiedParams,
      });
    },
    reloadPage() {
      this.page = 0;
      this.fetchTerminalGroups();
    },
    addClassValidInput(inputName) {
      return {
        "is-invalid":
          this.v$.formChangeGroupNumber[inputName].$error === true &&
          this.v$.formChangeGroupNumber[inputName].$dirty === true,
        "is-valid":
          this.v$.formChangeGroupNumber[inputName].$error === false &&
          this.v$.formChangeGroupNumber[inputName].$dirty === true,
      };
    },
    submitChangeGroupNumber() {
      this.v$.formChangeGroupNumber.$touch();
      if (!this.v$.formChangeGroupNumber.$error) {
        terminalGroupsApi
          .transferTerminalsToNewServiceGroup(
            this.formChangeGroupNumber.fromGroupNumberToChangeTerminals,
            this.formChangeGroupNumber.toGroupNumberToChangeTerminals
          )
          .then(function (result) {
            return result;
          })
          .catch(function (result) {
            return result;
          })
          .then((result) => {
            // console.log("result", result);
            if (result.status === 200) {
              this.showModal = false;

              this.toastPropsList.push({
                content: `${this.$t(`uiElements.toast.successSave`)}`,
                bgColor: "bg-success",
                textColor: "text-white",
              });
              this.showToast = true;

              this.fetchTerminalGroups();
            } else {
              console.log("err");
              this.toastPropsList.push({
                content: `${this.$t(`uiElements.toast.errSave`)}`,
                bgColor: "bg-danger",
                textColor: "text-white",
              });
              this.showToast = true;
            }
          });
      }
    },
    clickTableRow(rowItem) {
      this.selectedTableRow = rowItem;
    },
    showAddGroup() {
      this.typeModalForm = "add";
      this.showModal = true;
      this.titleModal = this.$t(`TerminalGroups.modal.addNewGroup`);
    },
    showChangeGroupObj() {
      this.typeModalForm = "changeGroupObj";
      this.showModal = true;
      this.titleModal =
        this.$t(`TerminalGroups.modal.editGroup`) +
        this.selectedTableRow.groupNumber;
    },
    showChangeGroupNumber() {
      this.typeModalForm = "changeGroupNumber";
      this.showModal = true;
      this.titleModal = this.$t(`TerminalGroups.btn.changeNumberGroup`);
      this.formChangeGroupNumber.fromGroupNumberToChangeTerminals =
        this.selectedTableRow.groupNumber;
      this.formChangeGroupNumber.groupName = this.selectedTableRow.groupName;
    },
    showDeleteGroupID() {
      this.typeModalForm = "deleteGroupID";
      this.showModal = true;
      this.titleModal = this.$t(`TerminalGroups.modal.deleteNumberGroup`);
    },
    submitDeleteGroupNumber() {
      terminalGroupsApi
        .deleteTerminalGroup(this.selectedTableRow.groupNumber)
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          // console.log("result", result);
          if (result.status === 200) {
            this.showModal = false;

            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.successSave`)}`,
              bgColor: "bg-success",
              textColor: "text-white",
            });
            this.showToast = true;

            this.fetchTerminalGroups();
          } else {
            console.log("err", result);
            this.toastPropsList.push({
              title: `${this.$t(`uiElements.toast.err`)}`,
              content: result.response.data.message,
              bgColor: "bg-danger",
              textColor: "text-white",
            });
            this.showToast = true;
          }
        });
    },
  },
  mounted() {
    this.fetchTerminalGroups();
  },
};
</script>

<style scoped>
.select-td > td {
  background-color: #0d6efd !important;
  color: white !important;
}
</style>
