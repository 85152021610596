<template>
  <div class="row filter mb-2 g-0">
    <div class="col-md-5">
      <div class="row">
        <div class="col-md-4">{{ $t("Datepicker.textChoiceRangeDate") }}</div>
        <div class="col-md-8">
          <Datepicker
            v-model="dateRange"
            v-if="dateRange"
            range
            autoApply
            :clearable="false"
            locale="ru"
            :format="dateFormat"
            :previewFormat="dateFormat"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="loading text-center col-5" v-show="isLoading">
    <trn-loading></trn-loading>
  </div>
  <template v-if="dataResp">
    <div class="row g-0">
      <div class="col-md-5">
        <ul class="list-group list-group-horizontal mb-3">
          <li class="list-group-item">
            {{ $t(`TrnReportsSettlement.id`) }}
            <span class="badge bg-info text-dark">{{ dataResp.id }}</span>
          </li>
          <li class="list-group-item">
            {{ $t(`TrnReportsSettlement.initDate`) }}
            <span class="badge bg-info text-dark">{{ dataResp.initDate }}</span>
          </li>
        </ul>
        <ul class="list-group">
          <li
            class="list-group-item d-flex justify-content-between align-items-center"
            v-for="(val, key) in dataResp.content"
            :key="key"
          >
            {{ $t(`TrnReportsSettlement.${key}`) }}
            <span class="badge bg-primary">{{ val }}</span>
          </li>
        </ul>
      </div>
    </div>
  </template>
</template>

<script>
import TrnLoading from "@/components/Loading";
import Datepicker from "vue3-date-time-picker";
import { mapState } from "vuex";
import { stringify } from "query-string";
import { actionTypes } from "@/store/modules/settlement-report";
import "vue3-date-time-picker/dist/main.css";

export default {
  name: "TrnReportsSettlementTabs",
  props: {
    pathName: {
      type: String,
      required: true,
    },
  },
  components: { TrnLoading, Datepicker },
  data() {
    return {
      dateRangeD: null,
      dateFormat: "dd/MM/yyyy",
      startDateD: null,
      endDateD: null,
    };
  },
  computed: {
    dateRange: {
      get() {
        return this.dateRangeD;
      },
      set(newValue) {
        this.dateRangeD = newValue;
      },
    },
    startDate: {
      get() {
        return new Date(this.dateRange[0]).toISOString().split("T")[0];
      },
      set(newValue) {
        this.dateRange[0] = newValue;
      },
    },
    endDate: {
      get() {
        return new Date(this.dateRange[1]).toISOString().split("T")[0];
      },
      set(newValue) {
        this.dateRange[1] = newValue;
      },
    },
    ...mapState({
      isLoading: (state) => state.settlementReport.isLoading,
      dataResp: (state) => state.settlementReport.dataResp,
      errorResp: (state) => state.settlementReport.errorResp,
    }),
  },
  watch: {
    dateRange() {
      const nowDate = new Date();
      if (this.dateRange[0] > nowDate || this.dateRange[1] > nowDate) {
        this.setDefaultDate();
      }
      this.fetchSettlementReportTerminal();
    },
  },
  mounted() {
    this.setDefaultDate();
  },
  methods: {
    setDefaultDate() {
      const endDate = new Date();
      const startDate = new Date(new Date().setDate(endDate.getDate() - 30));

      this.dateRange = [startDate, endDate];
    },
    fetchSettlementReportTerminal() {
      const path = this.$router.resolve({ name: this.pathName }).path;

      this.$router.replace({
        name: this.pathName,
        query: {
          start: this.startDate,
          end: this.endDate,
        },
      });
      const stringifiedParams = stringify({
        start: this.startDate,
        end: this.endDate,
      });
      const apiUrlWithParams = `${path}?${stringifiedParams}`;
      this.$store.dispatch(actionTypes.getSettlementReport, {
        apiUrl: apiUrlWithParams,
      });
    },
  },
};
</script>

<style scoped></style>
