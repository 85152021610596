<template>
  <div class="h3 text-center">{{ $t(`SetupConfig.title`) }}</div>
  <div class="container">
    <form @submit.prevent="submitForm" novalidate>
      <!--    receipt_send_channel    -->
      <div class="border p-2 mb-2">
        <div class="fw-bold">
          {{ $t(`TerminalGroups.table.receiptSendChannelIdList`) }}
        </div>
        <div>
          <div
            v-for="(value, key) in setupConfigForm.receipt_send_channel"
            :key="key"
            class="mb-3 form-check-inline"
          >
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                :id="key"
                :value="value"
                v-model="setupConfigForm.receipt_send_channel[key]"
              />
              <label class="form-check-label" :for="key">{{ key }}</label>
            </div>
          </div>
        </div>
      </div>
      <!--    currencies block    -->
      <div class="border p-2 mb-2">
        <div class="fw-bold">{{ $t(`SetupConfig.currencies`) }}</div>
        <trn-setup-config-currency
          v-for="(currency, index) of setupConfigForm.currencies"
          :currency="currency"
          :indexF="index"
          :key="index"
          @updateCurrency="(n) => Object.assign(currency, n)"
          @deleteCurrency="removeCurrency"
        ></trn-setup-config-currency>
        <button
          type="button"
          class="btn btn-secondary mb-3"
          @click="addCurrency"
        >
          Додати нову валюту
        </button>
      </div>

      <!--    general-configuration block    -->
      <div class="border p-2 mb-2">
        <div class="fw-bold">{{ $t(`SetupConfig.generalConfiguration`) }}</div>
        <trn-setup-config-general-configuration
          :general_config_obj="setupConfigForm.general_configuration"
        ></trn-setup-config-general-configuration>
      </div>

      <!--    ips_card_group block    -->
      <div class="border p-2 mb-2">
        <div class="fw-bold">{{ $t(`SetupConfig.IpsCardGroup`) }}</div>
        <trn-setup-config-ips-card-group
          v-for="(
            ips_card_group_obj, index
          ) of setupConfigForm.ips_card_group_list"
          :ips_card_group_obj="ips_card_group_obj"
          :indexF="index"
          :existing-i-p-s-groups="setupConfigForm.ips_card_group_list"
          :editable-form="index < 2"
          :key="index"
          @deleteIpsCardGroup="removeIpsCardGroup"
        ></trn-setup-config-ips-card-group>
        <button
          type="button"
          class="btn btn-secondary mb-3"
          @click="addIpsCardGroup"
        >
          Додати нову групу
        </button>
      </div>

      <!--    service-group block    -->
      <div class="border p-2 mb-2">
        <div class="fw-bold">{{ $t(`SetupConfig.serviceGroup`) }}</div>
        <trn-setup-config-service-group
          :service_group="setupConfigForm.service_group"
          :currencies="setupConfigForm.currencies"
          :receipt-send-channels="receipt_send_channel_list"
          :ips-card-groups="setupConfigForm.ips_card_group_list"
        ></trn-setup-config-service-group>
      </div>

      <!--    bank block    -->
      <div class="border p-2 mb-2">
        <div class="fw-bold">{{ $t(`SetupConfig.bank`) }}</div>
        <trn-setup-config-bank
          :bank_obj="setupConfigForm.bank"
        ></trn-setup-config-bank>
      </div>

      <!--    UserRole block    -->
      <div class="border p-2 mb-2">
        <div class="fw-bold">{{ $t(`SetupConfig.UserRole`) }}</div>
        <trn-setup-config-user-role
          v-for="(user_role_obj, index) of setupConfigForm.user_roles"
          :user_role_obj="user_role_obj"
          :indexF="index"
          :key="index"
          @deleteUserRole="removeUserRole"
        ></trn-setup-config-user-role>
        <button
          type="button"
          class="btn btn-secondary mb-3"
          @click="addUserRole"
        >
          Додати нову роль
        </button>
      </div>

      <!--    User block    -->
      <div class="border p-2 mb-2">
        <div class="fw-bold">{{ $t(`SetupConfig.User`) }}</div>
        <trn-setup-config-user
          v-for="(user_obj, index) of setupConfigForm.users"
          :user_obj="user_obj"
          :indexF="index"
          :user-roles="setupConfigForm.user_roles"
          :key="index"
          @deleteUser="removeUser"
        ></trn-setup-config-user>
        <button type="button" class="btn btn-secondary mb-3" @click="addUser">
          Додати нового користувача
        </button>
      </div>

      <!--    Products block    -->
      <div class="border p-2 mb-2">
        <div class="fw-bold">{{ $t(`SetupConfig.Products`) }}</div>
        <trn-setup-config-product
          v-for="(product, index) of setupConfigForm.products"
          :product_obj="product"
          :indexF="index"
          :ips-card-groups="setupConfigForm.ips_card_group_list"
          :key="index"
          @deleteProduct="removeProduct"
        ></trn-setup-config-product>
        <button
          type="button"
          class="btn btn-secondary mb-3"
          @click="addProduct"
        >
          Додати новий продукт
        </button>
      </div>

      <button type="submit" class="btn btn-primary">Застосувати конфіг</button>
    </form>
  </div>

  <div class="toast-container position-absolute p-3 end-0" v-if="showToast">
    <bs-toast v-for="toast in toastPropsList" :key="toast" :="toast"></bs-toast>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
// import Multiselect from "@vueform/multiselect/src/Multiselect.vue";
import TrnSetupConfigCurrency from "@/components/setup-config/Currency.vue";
import TrnSetupConfigGeneralConfiguration from "@/components/setup-config/GeneralConfiguration.vue";
import TrnSetupConfigIpsCardGroup from "@/components/setup-config/IpsCardGroup.vue";
import TrnSetupConfigServiceGroup from "@/components/setup-config/ServiceGroup.vue";
import TrnSetupConfigBank from "@/components/setup-config/Bank.vue";
import TrnSetupConfigUserRole from "@/components/setup-config/UserRole.vue";
import TrnSetupConfigUser from "@/components/setup-config/User.vue";
import TrnSetupConfigProduct from "@/components/setup-config/Product.vue";
import gsApi from "@/api/settings-api/installations";
import BsToast from "@/components/UI-elements/BsToast.vue";

export default {
  name: "TrnSetupConfig",
  components: {
    BsToast,
    TrnSetupConfigProduct,
    TrnSetupConfigUser,
    TrnSetupConfigUserRole,
    TrnSetupConfigBank,
    TrnSetupConfigServiceGroup,
    TrnSetupConfigIpsCardGroup,
    TrnSetupConfigGeneralConfiguration,
    TrnSetupConfigCurrency,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      setupConfigForm: {
        receipt_send_channel: {
          Email: false,
          QR: false,
          Viber: false,
          Whatsapp: false,
          Telegram: false,
          SMS: false,
        },
        currencies: [
          {
            code: "0980",
            letter_code: "UAH",
            symbol: "₴",
          },
          {
            code: "0840",
            letter_code: "USD",
            symbol: "$",
          },
        ],
        general_configuration: {
          amount_timeout: "30", //srconds
          app_active_time: "4", //minutes
          attestation_time_max: "00:36:00", //maximum period in minutes for random attestation
          attestation_time_min: "00:24:00", //minimum period in minutes for random attestation
          begin_card_mask: "6", //how many first digits of card could be shown
          card_mask_symbol: "******", //card digits placeholder
          end_card_mask: "4", //how many last digits of card number can be showen
          manual_timeout: "60", //seconds for manual enter pan. Applicaable if op_manual == Y
          max_receipt_number: "999999", //maximum possible receipt number
          min_receipt_number: "100000", //minimum possible receipt number
          nfc_timeout: "15", //seconds, amount of time of for nfc transaction. Applicable if op_nfc == Y
          phone_mask: "+38 (0**)-***-**-**", //phone mask pattern for matching
          proposal_email: "info@transaplat.com.ua", //email for proposals, should be taken from bank object
          question_email: "info@transaplat.com.ua", // email for questions, should be taken from bank object
          receipt_host: "https://e-receipt.transplat.com.ua", // host for e-receipt server. Applicable only if e-receipt is delivered
          time_z_report: "20:00:00", //time of a day when z-report should be sent.
          refund_limit_days: "30", //days during which period refund for particular transaction could be made
          num_refund_attempts_for_transaction: "3", //how many time merchant can make refund for one transaction
          technical_merchant_id: "techmerch01", //can be empty, depends on authorization host, like UPC
          technical_terminal_id: "techterm01", //can be empty, depends on authorization host, like UPC
          supervisor_card_number: "111111111111111", //can be empty, depends on authorization host, like UPC
        },
        ips_card_group_list: [
          //visa and mastercard added by default
          //default IPSes, always must be
          {
            ips_card_group: "1",
            first_card_number: "5",
            ips_name: "Mastercard",
            ips_symbol: "M",
            no_pin_limit: "50000", //no default value, check if not empty or null
            op_manual: "N", // N default, overrides service_group value
            op_nfc: "Y", // Y default, overrides service_group value
            op_pin: "Y", //Y default, overrides service_group value
            op_qr: "N", // N default, overrides service_group value
          },
          {
            ips_card_group: "2",
            first_card_number: "3",
            ips_name: "Visa",
            ips_symbol: "V",
            no_pin_limit: "150000", //no default value, check if not empty or null
            op_manual: "N", // N default, overrides service_group value
            op_nfc: "Y", // Y default, overrides service_group value
            op_pin: "Y", //Y default, overrides service_group value
            op_qr: "N", // N default, overrides service_group value
          },
          {
            ips_card_group: "",
            first_card_number: "",
            ips_name: "",
            ips_symbol: "",
            no_pin_limit: "150000", //no default value, check if not empty or null
            op_manual: "N",
            op_nfc: "N",
            op_pin: "N",
            op_qr: "N",
          },
        ],
        service_group: {
          geo_position: "N", // N default
          group_name: "some_group", //non null, not empty
          no_pin_limit: "50000", //should be same as minimum value from ips_card_group
          one_transaction_limit: "150000", //max value for one transaction, can't be empty
          op_manual: "N", //N default
          op_nfc: "N", //N default
          op_purchase: "Y", // Y default
          op_qr: "N", //N default
          op_refund: "Y", // Y default
          op_reversal: "Y", //Y default
          op_tips: "N", //  N default, always N if tips not delivered
          total_amount_limit: "15000000", // total limit of transaction amount per period
          total_count_limit: "1000", // total limit of transactions per period
          total_limit_period: "2", //period limit in days
          velocity_count: "2", //number of transaction per period
          velocity_period: "2", //velocity time unit count
          velocity_time_unit: "SECONDS", //velocity time unit type
          currency_code: "0948", //should be one of received in currencies array, can't be empty
          receipt_template_id: "1", // can't be null or empty
          status: "ACTIVE", //new service group should be ACTIVE
          op_create_subordinate_terminal: "N", //N default, N always if function not delivered
          num_sec_term: "5", //number of subordinate terminals allowed to create, applicable only of op_create_subordinate_terminal == Y
          service_group_has_receipt_send_channel: [], // one channel should be selected
          service_group_has_ips_card_group: [], // one should be selected
        },
        bank: {
          address: "Kyiv", //mandatory field
          email: "info@transplat.com.ua", //mandatory field, used in general_configuration
          mfo: "123456", //mandatory field
          bank_name: "Some bank name", //mandatory
          phone: "+380961234567", // mandatory
          tax_id: "123456789", //mandatory
        },
        user_roles: [
          {
            role_code: "SUPER_ADMIN",
            role_title: "Super admin",
            description: "Super admin user",
          },
        ],
        users: [
          {
            username: "super-admin@transplat.com.ua",
            email: "info@transplat.com.ua",
            first_name: "Super",
            last_name: "admin Transplat",
            password: "admin",
            language: "ENG",
            user_role: "SUPER_ADMIN",
          },
        ],
        products: [
          {
            //mandatory record
            product_name: "M-TIP product",
            range_begin: "200000000000",
            range_end: "2999999999999999999",
            ips_card_group_id: "1",
          },
        ],
      },
      receipt_send_channel_list: [],
      showToast: false,
      toastPropsList: [],
    };
  },
  validations() {
    return {
      setupConfigForm: {},
    };
  },
  methods: {
    submitForm() {
      this.v$.$touch();
      this.v$.$validate();
      // set all fields to touched
      // this.v$.$touch();
      console.log("subbmit form");
      if (!this.v$.$error) {
        console.log("no error");
        gsApi
          .setupConfig({ ...this.setupConfigForm })
          .then(function (result) {
            return result;
          })
          .catch(function (result) {
            return result;
          })
          .then((result) => {
            // console.log("result", result);
            if (result.status === 200) {
              this.showModal = false;

              this.toastPropsList.push({
                content: `${this.$t(`uiElements.toast.successSave`)}`,
                bgColor: "bg-success",
                textColor: "text-white",
              });
              this.showToast = true;

              this.fetchSettings();
            } else {
              console.log("err");
              this.toastPropsList.push({
                content: `${this.$t(`uiElements.toast.errSave`)}`,
                bgColor: "bg-danger",
                textColor: "text-white",
              });
              this.showToast = true;
            }
          });
      }
      // тут можна відправити дані на сервер
    },
    addCurrency() {
      this.setupConfigForm.currencies.push({
        code: "",
        letter_code: "",
        symbol: "",
      });
    },
    addIpsCardGroup() {
      this.setupConfigForm.ips_card_group_list.push({
        ips_card_group: "",
        first_card_number: "",
        ips_name: "",
        ips_symbol: "",
        no_pin_limit: "50000",
        op_manual: "Y",
        op_nfc: "Y",
        op_pin: "Y",
        op_qr: "Y",
      });
    },
    addUserRole() {
      this.setupConfigForm.user_roles.push({
        role_code: "",
        role_title: "",
        description: "",
      });
    },
    addUser() {
      this.setupConfigForm.users.push({
        username: "",
        email: "",
        first_name: "",
        last_name: "",
        password: "",
        language: "",
        user_role: "",
      });
    },
    addProduct() {
      this.setupConfigForm.products.push({
        product_name: "",
        range_begin: "",
        range_end: "",
        ips_card_group_id: "",
      });
    },
    removeCurrency(index) {
      if (this.setupConfigForm.currencies.length <= 1) {
        return;
      }
      this.setupConfigForm.currencies.splice(index, 1);
    },
    removeIpsCardGroup(index) {
      if (this.setupConfigForm.ips_card_group_list.length <= 1) {
        return;
      }
      this.setupConfigForm.ips_card_group_list.splice(index, 1);
    },
    removeUserRole(index) {
      if (this.setupConfigForm.user_roles.length <= 1) {
        return;
      }
      this.setupConfigForm.user_roles.splice(index, 1);
    },
    removeUser(index) {
      if (this.setupConfigForm.users.length <= 1) {
        return;
      }
      this.setupConfigForm.users.splice(index, 1);
    },
    removeProduct(index) {
      if (this.setupConfigForm.products.length <= 1) {
        return;
      }
      this.setupConfigForm.products.splice(index, 1);
    },
  },
  // watch: {
  //   "setupConfigForm.receipt_send_channel"() {
  //     let keysWithTrueValues = Object.entries(
  //       this.setupConfigForm.receipt_send_channel
  //     )
  //       .filter(([value]) => value === true)
  //       .map(([key]) => key);
  //     console.log(keysWithTrueValues);
  //   },
  // },
  mounted() {
    this.$watch(
      () => this.setupConfigForm.receipt_send_channel,
      () => {
        const trueKeys = Object.keys(
          this.setupConfigForm.receipt_send_channel
        ).filter(
          (key) => this.setupConfigForm.receipt_send_channel[key] === true
        );

        this.receipt_send_channel_list = trueKeys;
      },
      { deep: true }
    );
  },
};
</script>

<style scoped></style>
