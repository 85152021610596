<template>
  <div class="mb-3">
    <div class="row">
      <div class="col">
        <label for="code">Код</label>
        <input
          type="text"
          class="form-control"
          :id="`code-${indexF}`"
          :value="currency.code"
          v-maska
          data-maska="####"
          @input="updateValueForm($event, 'code')"
        />
        <div v-if="v$.currency.code.required.$invalid" class="text-danger">
          Введіть код валюти
        </div>
        <div v-if="v$.currency.code.minLength.$invalid" class="text-danger">
          Код валюти повинен містити 4 символи
        </div>
      </div>
      <div class="col">
        <label for="letter_code">Буквений код</label>
        <input
          type="text"
          class="form-control"
          :id="`letter_code-${indexF}`"
          :value="currency.letter_code"
          v-maska
          data-maska="@@@"
          @input="updateValueForm($event, 'letter_code')"
        />
        <div
          v-if="v$.currency.letter_code.required.$invalid"
          class="text-danger"
        >
          Введіть буквений код валюти
        </div>
      </div>
      <div class="col">
        <label for="symbol">Символ</label>
        <input
          type="text"
          class="form-control"
          :id="`symbol-${indexF}`"
          :value="currency.symbol"
          maxlength="1"
          @input="updateValueForm($event, 'symbol')"
        />
        <div v-if="v$.currency.symbol.required.$invalid" class="text-danger">
          Введіть символ валюти
        </div>
      </div>
      <div class="col d-flex align-items-end">
        <button
          type="button"
          class="btn btn-danger"
          @click="removeCurrency(indexF)"
        >
          Видалити
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { maxLength, minLength, required } from "@vuelidate/validators";

export default {
  name: "TrnSetupConfigCurrency",
  setup() {
    return { v$: useVuelidate() };
  },
  props: {
    currency: { type: Object, required: true },
    indexF: { type: Number, required: true },
  },
  validations() {
    return {
      currency: {
        code: {
          required,
          minLength: minLength(4),
          maxLength: maxLength(4),
        },
        letter_code: { required },
        symbol: { required },
      },
    };
  },
  methods: {
    removeCurrency(index) {
      // this.currencies.splice(index, 1);
      this.$emit("deleteCurrency", index);
    },
    updateValueForm(event, inputName) {
      // Відправляємо подію з оновленим значенням
      let tempObj = {
        ...this.currency,
      };
      tempObj[inputName] = event.target.value;
      this.$emit("updateCurrency", tempObj);
    },
  },
};
</script>

<style scoped></style>
