const filters = {
  formatDateTime(fullDate, format = "full") {
    if (isNaN(fullDate)) {
      return "-";
    }
    if (fullDate === null) {
      return "-";
    }
    const d = new Date(fullDate);
    const day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
    const month =
      d.getMonth() < 9 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
    const year = d.getFullYear();
    const hours = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
    const minutes = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
    const seconds = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();

    switch (format) {
      case "full":
        return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
      case "date":
        return `${day}.${month}.${year}`;
      case "date-obj":
        return d;
      case "yyyy-MM-dd HH:mm:ss":
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      case "yyyy-MM-dd HH:mm":
        return `${year}-${month}-${day} ${hours}:${minutes}`;
      case "time":
        return `${hours}:${minutes}`;
      case "dateShort":
        return `${day}.${month}`;
      default:
        return "-";
    }
  },
  formatDateTimeUTC(fullDate, format = "full") {
    if (isNaN(fullDate)) {
      return "-";
    }
    if (fullDate === null) {
      return "-";
    }
    const d = new Date(fullDate);
    const day = d.getUTCDate() < 10 ? "0" + d.getUTCDate() : d.getUTCDate();
    const month =
      d.getUTCMonth() < 9 ? "0" + (d.getUTCMonth() + 1) : d.getUTCMonth() + 1;
    const year = d.getUTCFullYear();
    const hours =
      d.getUTCHours() < 10 ? "0" + d.getUTCHours() : d.getUTCHours();
    const minutes =
      d.getUTCMinutes() < 10 ? "0" + d.getUTCMinutes() : d.getUTCMinutes();
    const seconds =
      d.getUTCSeconds() < 10 ? "0" + d.getUTCSeconds() : d.getUTCSeconds();

    switch (format) {
      case "full":
        return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
      case "date":
        return `${day}.${month}.${year}`;
      case "date-obj":
        return d;
      case "yyyy-MM-dd HH:mm:ss":
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      case "yyyy-MM-dd HH:mm":
        return `${year}-${month}-${day} ${hours}:${minutes}`;
      case "time":
        return `${hours}:${minutes}`;
      case "dateShort":
        return `${day}.${month}`;
      default:
        return "-";
    }
  },
  formatAmount(amount) {
    if (isNaN(amount)) {
      return "-";
    }
    if (amount === null) {
      return "-";
    }

    let amountVal = parseInt(amount);
    return parseFloat(amountVal / 100).toFixed(2);
  },
};
export default filters;
