import businessDayCalculationApi from "@/api/business-day-calculation";

const state = {
  dataResp: null,
  dataModalResp: null,

  isLoading: false,
  isLoadingScroll: false,
  isLoadingModal: false,

  errorResp: null,
  errorRespArchive: null,

  dataRespArchive: null,
  dataModalRespArchive: null,

  isLoadingArchive: false,
  isLoadingScrollArchive: false,
};

export const mutationTypes = {
  getBusinessDayCalculationStart:
    "[BusinessDayCalculation] getBusinessDayCalculation start",
  getBusinessDayCalculationSuccess:
    "[BusinessDayCalculation] getBusinessDayCalculation success",
  getBusinessDayCalculationFailure:
    "[BusinessDayCalculation] getBusinessDayCalculation failure",

  getBusinessDayCalculationScrollPushStart:
    "[BusinessDayCalculation] getBusinessDayCalculationScrollPush start",
  getBusinessDayCalculationScrollPushSuccess:
    "[BusinessDayCalculation] getBusinessDayCalculationScrollPushSuccess success",
  getBusinessDayCalculationScrollPushFailure:
    "[BusinessDayCalculation] getBusinessDayCalculationScrollPush failure",

  getBusinessDayCalculationArchiveStart:
    "[BusinessDayCalculationArchive] getBusinessDayCalculationArchive start",
  getBusinessDayCalculationArchiveSuccess:
    "[BusinessDayCalculationArchive] getBusinessDayCalculationArchive success",
  getBusinessDayCalculationArchiveFailure:
    "[BusinessDayCalculationArchive] getBusinessDayCalculationArchive failure",

  getBusinessDayCalculationArchiveScrollPushStart:
    "[BusinessDayCalculationArchive] getBusinessDayCalculationArchiveScrollPush start",
  getBusinessDayCalculationArchiveScrollPushSuccess:
    "[BusinessDayCalculationArchive] getBusinessDayCalculationArchiveScrollPushSuccess success",
  getBusinessDayCalculationArchiveScrollPushFailure:
    "[BusinessDayCalculationArchive] getBusinessDayCalculationArchiveScrollPush failure",
};

export const actionTypes = {
  getBusinessDayCalculation:
    "[BusinessDayCalculation] getBusinessDayCalculation",
  getBusinessDayCalculationScrollPush:
    "[BusinessDayCalculation] getBusinessDayCalculationScrollPush",

  getBusinessDayCalculationArchive:
    "[BusinessDayCalculationArchive] getBusinessDayCalculationArchive",
  getBusinessDayCalculationArchiveScrollPush:
    "[BusinessDayCalculationArchive] getBusinessDayCalculationArchiveScrollPush",
};

const mutations = {
  [mutationTypes.getBusinessDayCalculationStart](state) {
    state.isLoading = true;
    state.data = null;
  },
  [mutationTypes.getBusinessDayCalculationSuccess](state, payload) {
    state.isLoading = false;
    state.dataResp = payload;
  },
  [mutationTypes.getBusinessDayCalculationFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
  [mutationTypes.getBusinessDayCalculationScrollPushStart](state) {
    state.isLoadingScroll = true;
  },
  [mutationTypes.getBusinessDayCalculationScrollPushSuccess](state, payload) {
    for (let item in payload.content) {
      state.dataResp.content.push(payload.content[item]);
    }
    state.isLoadingScroll = false;
  },
  [mutationTypes.getBusinessDayCalculationScrollPushFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },

  [mutationTypes.getBusinessDayCalculationArchiveStart](state) {
    state.isLoadingArchive = true;
    state.data = null;
  },
  [mutationTypes.getBusinessDayCalculationArchiveSuccess](state, payload) {
    state.isLoadingArchive = false;
    state.dataRespArchive = payload;
  },
  [mutationTypes.getBusinessDayCalculationArchiveFailure](state, payload) {
    state.isLoadingArchive = false;
    state.errorRespArchive = payload;
  },
  [mutationTypes.getBusinessDayCalculationArchiveScrollPushStart](state) {
    state.isLoadingScrollArchive = true;
  },
  [mutationTypes.getBusinessDayCalculationArchiveScrollPushSuccess](
    state,
    payload
  ) {
    for (let item in payload.content) {
      state.dataResp.content.push(payload.content[item]);
    }
    state.isLoadingScrollArchive = false;
  },
  [mutationTypes.getBusinessDayCalculationArchiveScrollPushFailure](
    state,
    payload
  ) {
    state.isLoadingArchive = false;
    state.errorRespArchive = payload;
  },
};

const actions = {
  [actionTypes.getBusinessDayCalculation](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getBusinessDayCalculationStart, apiParams);
      businessDayCalculationApi
        .getBusinessDayCalculation(apiParams)
        .then((response) => {
          context.commit(
            mutationTypes.getBusinessDayCalculationSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getBusinessDayCalculationFailure,
            result.response.data.error
          );
        });
    });
  },
  [actionTypes.getBusinessDayCalculationScrollPush](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(
        mutationTypes.getBusinessDayCalculationScrollPushStart,
        apiParams
      );
      businessDayCalculationApi
        .getBusinessDayCalculation(apiParams)
        .then((response) => {
          context.commit(
            mutationTypes.getBusinessDayCalculationScrollPushSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getBusinessDayCalculationScrollPushFailure,
            result.response.data
          );
        });
    });
  },

  [actionTypes.getBusinessDayCalculationArchive](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(
        mutationTypes.getBusinessDayCalculationArchiveStart,
        apiParams
      );
      businessDayCalculationApi
        .getBusinessDayCalculationArchive(apiParams)
        .then((response) => {
          context.commit(
            mutationTypes.getBusinessDayCalculationArchiveSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getBusinessDayCalculationArchiveFailure,
            result.response.data.error
          );
        });
    });
  },
  [actionTypes.getBusinessDayCalculationArchiveScrollPush](
    context,
    { apiParams }
  ) {
    return new Promise((resolve) => {
      context.commit(
        mutationTypes.getBusinessDayCalculationArchiveScrollPushStart,
        apiParams
      );
      businessDayCalculationApi
        .getBusinessDayCalculationArchive(apiParams)
        .then((response) => {
          context.commit(
            mutationTypes.getBusinessDayCalculationArchiveScrollPushSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getBusinessDayCalculationArchiveScrollPushFailure,
            result.response.data
          );
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
