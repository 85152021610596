export default {
  "Analytics": {
    "charts": {
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Count"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "refusal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refusal"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
      "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
      "title": {
        "declinedAnalytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cause of failures"])},
        "formFactorAnalytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment device"])},
        "paymentSystemAnalytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment systems"])},
        "rroAnalytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software cash desk"])},
        "statusAnalytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions"])},
        "transactionActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Activity"])}
      }
    },
    "rro": {
      "table": {
        "appName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "numberOfTransactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of transactions"])},
        "ratio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%"])},
        "totalTransactionsAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount of transactions"])}
      }
    },
    "select": {
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom dates"])},
      "customDateRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dates:"])},
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for the current day"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For 30 days"])},
      "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])}
    }
  },
  "Attestation": {
    "action": {
      "select": {
        "option": {
          "BLOCK_DEVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lock per device"])},
          "BLOCK_MANUAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual block"])},
          "BLOCK_NFC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFC lock"])},
          "BLOCK_PIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIN lock"])},
          "BLOCK_QR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR blocking"])},
          "BLOCK_TRANSACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction lock"])},
          "NO_BLOCK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No blocking"])}
        }
      },
      "table": {
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
        "actionWeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])},
        "shortName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbreviation"])}
      }
    },
    "sequenceThreats": {
      "table": {
        "attestationActions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])}
      }
    },
    "tab": {
      "actionsThreats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions / Threats"])},
      "sequenceThreats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sequence of threats"])}
    },
    "threat": {
      "table": {
        "CHANNEL_INTEGRITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel Integrity"])},
        "DEBUG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application testing"])},
        "EMULATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application emulation"])},
        "GEO_POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geoposition"])},
        "INSTALL_SOURCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install source"])},
        "INTEGRITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrity"])},
        "KEY_ATTESTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key attestation"])},
        "ROOT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application rights"])},
        "VELOCITY_CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction frequency"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
        "threat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Threat"])}
      }
    }
  },
  "AttestationHistory": {
    "table": {
      "attestationPhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phase"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certification result"])},
      "deviceSn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device ID"])},
      "pinChannelIntegrity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin Channel Integrity"])},
      "terminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal ID"])}
    }
  },
  "BusinessDayCalculation": {
    "btn": {
      "generateCalculation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate calculation"])}
    },
    "form": {
      "billingCalculationNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report No."])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business day billing"])}
    },
    "tab": {
      "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive"])},
      "calculation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculation"])}
    },
    "table": {
      "allAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
      "allCalculatedFacilityValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
      "allPercentageFacility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Percentage Facility"])},
      "allStaticFacility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission value"])},
      "amountMinusFacility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit amount"])},
      "calculatedValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The amount of commission"])},
      "facilityId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission ID"])},
      "facilityName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the commission"])},
      "groupFacilityId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission group number"])},
      "groupTerminalNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group terminal number"])},
      "numberOfAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total transactions"])},
      "numberOfPurchases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number Of Purchases"])},
      "numberOfRefund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund"])},
      "numberOfReversal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reversal"])},
      "terminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal ID"])}
    },
    "text": {
      "billingCalculationNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculation number: "])},
      "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
      "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created date:: "])},
      "toBeEnrolled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To be enrolled"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
    }
  },
  "ChatHistory": {
    "table": {
      "answerIdList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response IDs"])},
      "answerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description of the problem"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "sessionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session ID"])},
      "terminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal ID"])}
    }
  },
  "Datepicker": {
    "textChoiceRangeDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the start and end dates of the report"])}
  },
  "Devices": {
    "deviceStatus": {
      "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
      "Blocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocked"])},
      "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
      "Key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key"])},
      "Loan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loan"])},
      "Unblocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unblocked"])}
    },
    "table": {
      "appStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Status"])},
      "deviceFingerprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device fingerprint"])},
      "deviceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device name"])},
      "deviceSn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device ID"])},
      "deviceStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device Status"])},
      "initDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of initiation"])},
      "osVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Version"])},
      "terminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal ID"])}
    }
  },
  "EntityAudit": {
    "table": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date and time"])},
      "entityId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log ID"])},
      "entityName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log name"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User name"])}
    }
  },
  "Footer": {
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© Copyright Transplat 2024."])},
    "versionStr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["version"])}
  },
  "Investigation": {
    "table": {
      "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
      "date_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date:"])},
      "date_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date:"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["description"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "requestId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request ID"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
      "terminal_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal ID"])}
    }
  },
  "IpsCardGroups": {
    "modal": {
      "addNewGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
      "deleteGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete a payment system group:"])}
    },
    "table": {
      "firstCardNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First digits"])},
      "ipsCardGroupId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifier"])},
      "ipsName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IPS Name"])},
      "ipsSymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment system symbol"])},
      "noPinLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit without PIN"])},
      "opManual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual marker"])},
      "opNfc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marker without Nfc"])},
      "opPin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marker without PIN"])},
      "opQr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marker without Qr"])}
    }
  },
  "IpsKey": {
    "table": {
      "caHashAlgInd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сa Hash AlgInd"])},
      "caPkAlgInd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сa Pk AlgInd"])},
      "exponentLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exponent Length"])},
      "exponentValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exponent value"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key entry ID"])},
      "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Index"])},
      "keyExpDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key exp date"])},
      "modulus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modulus"])},
      "modulusLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modulus length"])},
      "rid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rid"])},
      "scheme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheme"])},
      "secureHashAlg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secure HashAlg"])}
    }
  },
  "Merchant": {
    "modal": {
      "deleteMerchant": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Merchant ID"])}
      }
    },
    "table": {
      "bankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank"])},
      "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "mcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MCC"])},
      "merchantId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant ID"])},
      "merchantLegalName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant legal name"])},
      "merchantLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant location"])},
      "merchantName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant name"])},
      "taxId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax ID"])},
      "terminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal ID"])}
    }
  },
  "MerchantRegistration": {
    "btn": {
      "addFata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migration to FATA server"])},
      "addNewMerchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new merchant"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
      "reloadPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload page"])},
      "sendSmsPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send SMS with new password"])},
      "submitMigrateMerchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migrate Merchant"])},
      "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload file registration"])}
    },
    "fileForDownloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files for download"])},
    "fileForDownloadsType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The file extension should be: *.trn"])},
    "regLastDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration for the last days"])},
    "table": {
      "ReActivationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-activation code"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
      "addData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The reason for the change of status"])},
      "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation date"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "latitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latitude"])},
      "longitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longitude"])},
      "mcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MCC"])},
      "merchantId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant ID"])},
      "merchantLegalName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the organization"])},
      "merchantLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The actual address of the merchant"])},
      "merchantLocationEng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The actual address of the merchant (eng)"])},
      "merchantName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant name"])},
      "merchantNameEng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant name (English)"])},
      "merchantRegId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account ID"])},
      "numSecTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qty. subordinate terminals"])},
      "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin. phone number"])},
      "radius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radius"])},
      "registrationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of registration"])},
      "serviceGroupGroupNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal group number"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "taxId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TAXID"])},
      "termPriority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Terminal"])},
      "terminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal ID"])},
      "terminalLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal address"])},
      "terminalLocationEng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal address in English"])},
      "userLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant login"])},
      "zreportTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto time sending a z-report"])}
    },
    "titleModalMerchantStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change of merchant status"])},
    "titleModalMigrateToFata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forced migration of the merchant from the Transenix server to the FATA server."])},
    "titleModalRegMerchantFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant registration via file"])},
    "toast": {
      "sendSms": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sent to numbers:<br> ", _interpolate(_named("numbers"))])}
    },
    "validationRegForm": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect email address entered"])},
      "ibanRegex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN entered in wrong format"])},
      "mccRegex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid values are greater than 1000 and less than 10,000"])},
      "merchantLocationRegEx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid characters entered"])},
      "passwordRepeatErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The re-entered password does not match"])},
      "phoneNumberRegex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the phone number in the format: +380991234567"])},
      "radiusReg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid values are greater than 0 and less than 1,000,000"])},
      "titude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid data entered (format 99.0000)"])}
    }
  },
  "Message": {
    "blockTemplate": {
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])}
    },
    "blockTitle": {
      "org": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notice to Organizations"])},
      "searchMerchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant search"])},
      "searchTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal search"])},
      "selectedMerchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of selected merchants"])},
      "selectedTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of selected terminals"])},
      "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification Template"])},
      "terminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notice to Terminals"])}
    },
    "btn": {
      "clearText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
      "createMess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create message"])},
      "saveText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as template"])},
      "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all found"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send message"])}
    },
    "form": {
      "countText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["number of characters used: ", _interpolate(_named("num"))])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])}
    },
    "toast": {
      "itemUnique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The element has already been added to the list"])}
    }
  },
  "Monitoring": {
    "charts": {
      "refusal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refusal"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
      "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
      "title": {
        "transactionActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Activity"])}
      }
    },
    "health": {
      "dbConnectionHealth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DB Connection Health"])},
      "hsmConnectionHealth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HSM Connection Health"])},
      "isoAuthConnectionHealth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISO Connection Health"])},
      "isoTessConnectionHealth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISO Tess Connection Health"])},
      "serverHealth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server Health"])},
      "smppConnectionHealth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMPP Connection Health"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services Status"])}
    }
  },
  "Products": {
    "modal": {
      "addNewProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new product"])}
    },
    "table": {
      "ipsCardGroupId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment system identifier"])},
      "ipsSymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment system symbol"])},
      "productId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product ID"])},
      "productName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The product's name"])},
      "rangeBegin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start range"])},
      "rangeEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Range"])}
    }
  },
  "ReceiptSendAudits": {
    "table": {
      "receiptNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check number"])},
      "receiptSendChannel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send channel"])},
      "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient"])},
      "sendDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "successful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "transactionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction number"])}
    }
  },
  "RegularReports": {
    "activeTerminals": {
      "table": {
        "dateTimeInit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date time init"])},
        "lastTransactionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last transaction date"])},
        "lastUpdateDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update date"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "terminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal ID"])}
      }
    },
    "form": {
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report export"])},
      "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period: "])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select report type:"])},
      "type2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type:"])}
    },
    "lifecycle": {
      "table": {
        "fieldName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field name"])},
        "newValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New value"])},
        "oldValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old value"])}
      }
    },
    "select": {
      "export": {
        "CSV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV"])},
        "PDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF"])},
        "XLS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XLS"])}
      },
      "formPeriod": {
        "ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALL"])},
        "DAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
        "MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
        "WEEK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week"])}
      },
      "type": {
        "PURCHASE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PURCHASE"])},
        "REFUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REFUND"])},
        "REVERSAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REVERSAL"])},
        "ZREPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZREPORT"])}
      }
    },
    "terminalsByGroups": {
      "table": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount of terminals"])},
        "numberOfSecondaryTerminals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numberOfSecondaryTerminals"])}
      }
    },
    "text": {
      "toStartGenReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To start generating a report, enter: "])}
    },
    "transactionsByMerchant": {
      "table": {
        "refundAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["refundAmount"])}
      }
    },
    "typeReportList": {
      "activeTerminals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active terminals"])},
      "merchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of terminals in the enterprise"])},
      "terminalLifecycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal life cycle change report "])},
      "terminalRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal registration"])},
      "terminalsByGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of terminals by groups"])},
      "transactionsByBlame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction report by transaction type in the enterprise"])},
      "transactionsByIps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction report by payment systems"])},
      "transactionsByMerchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction report by enterprise"])},
      "transactionsByTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction report by terminal"])},
      "transactionsByType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction report by transaction type"])}
    }
  },
  "RegulatoryCommissions": {
    "btn": {
      "makeWiring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload report"])}
    },
    "form": {
      "messageEng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message text in \"English\""])},
      "messageRu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message text in \"Russian\""])},
      "messageUkr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message text in \"Ukrainian\""])}
    },
    "tab": {
      "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outgoing Transaction"])},
      "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissions"])},
      "enrollments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Income"])},
      "generalFinBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Financial Book"])},
      "merchants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant & Balance"])},
      "proceduralNotices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification Settings"])},
      "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction upload report"])}
    },
    "table": {
      "accrual": {
        "merchantDebtAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To be enrolled"])},
        "shipmentReportId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report ID"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conducted"])}
      },
      "balance": {
        "ibanPayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN payer"])},
        "ibanReceiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN Receiver"])},
        "operationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation type"])},
        "paymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment details"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])}
      },
      "commission": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
        "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])}
      },
      "generalFinBook": {
        "dateCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date created"])},
        "incomingTransactionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoming<br> transaction Id"])}
      },
      "merchant": {
        "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
        "creditPeriodValueWithType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit period"])},
        "fcmMessageTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FCM message types"])},
        "merchantId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant ID"])},
        "overdraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdraft"])}
      },
      "transaction": {
        "bankFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank fee"])},
        "dateTransferred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date transferred"])},
        "fataDebt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FATA amount"])},
        "fataFeeRegulatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FATA<br> Regulatory <br> Commission"])},
        "fataFeeTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FATA<br> transaction<br> fee"])},
        "isMergeWithBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "isTransferred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conducted"])},
        "merchantDebt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant amount"])},
        "referencedId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report ID"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
        "totalMinusBankFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FATA <br> bank amount"])}
      }
    },
    "text": {
      "searchMerchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a seller by:"])},
      "typeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start typing..."])}
    }
  },
  "Schedule": {
    "btn": {
      "changeStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change status"])},
      "forcedStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forced start"])}
    },
    "table": {
      "cron": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cron"])},
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "lastSuccessRunTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last run"])},
      "nextScheduledDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next start time"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])}
    }
  },
  "Settings": {
    "Banks": {
      "table": {
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
        "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank website"])},
        "mfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MFO"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
        "taxId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TaxID"])}
      }
    },
    "Installations": {
      "form": {
        "amountTimeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time to enter amount (seconds)"])},
        "appActiveTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application activity time (minutes)"])},
        "appActiveTimeInMinutesMs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile application session time (min)"])},
        "attestationTimeMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. time for random validation (hours/minutes)"])},
        "attestationTimeMin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min. time for random validation (hours/minutes)"])},
        "beginCardMask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of card digits at the beginning"])},
        "cardMaskSymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-maskable map text"])},
        "endCardMask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of card digits at the end"])},
        "isoClientCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client certificate"])},
        "isoIntermediateCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intermediate certificate"])},
        "isoNetworkClientPk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private key"])},
        "isoRootCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root certificate"])},
        "manualTimeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual-pay timeout (seconds)"])},
        "manualTimeoutMs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual-pay waiting time (milliseconds)"])},
        "maxReceiptNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum check number"])},
        "minReceiptNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum check number"])},
        "nfcTimeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFC pay timeout (seconds)"])},
        "nfcTimeoutInMsMs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFC card application time"])},
        "numRefundAttemptsForTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of refund attempts"])},
        "pendingNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of request attempts (pieces)"])},
        "pendingTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request time interval (seconds)"])},
        "phoneMask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone display template"])},
        "proposalEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email for offers"])},
        "questionEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email for questions"])},
        "receiptHost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-check link display template"])},
        "refundLimitDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of days for a refund"])},
        "supervisorCardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System card"])},
        "technicalMerchantId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System merchant-ID"])},
        "technicalTerminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System terminal-ID"])},
        "timeZReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z-report time"])}
      },
      "text": {
        "networkCertificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network certificates"])}
      }
    },
    "ReceiptTemplate": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editor"])},
      "table": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
        "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Style"])},
        "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template"])}
      },
      "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])}
    },
    "TerminalFacility": {
      "btn": {
        "addFullTariff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add full tariff"])},
        "addLoyaltyTariff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a loyalty tariff"])},
        "addProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a profile"])},
        "deleteProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete profile"])},
        "deleteTariffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Tariffs"])},
        "updateProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change profile: "])}
      },
      "modal": {
        "addFullTariff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation of a full tariff"])},
        "addLoyaltyTariff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating a loyalty rate"])},
        "changeTariff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tariff change:"])},
        "choiceProfileTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a profile to go to"])},
        "choiceTariffByType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a rate for the type of transaction"])},
        "deleteId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile ID to delete"])}
      },
      "tab": {
        "tariffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tariffs"])},
        "tariffsProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tariff profiles"])}
      },
      "table": {
        "beginDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begin Date"])},
        "choiceTariffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#"])},
        "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created date"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "expiredDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration date"])},
        "fixedFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed fee"])},
        "groupFacility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
        "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Tariff"])},
        "limitToApplyPercentageFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum commission per %"])},
        "loyaltyPeriodType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loyalty period"])},
        "loyaltyPeriodValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loyalty period value"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "nextFacilityId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer tariff"])},
        "noTariffProfileData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For this profile, no rates have been selected"])},
        "originType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tariff Type"])},
        "percentageFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage fee"])},
        "relatedLoyaltyFacilityId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attached loyalty tariff"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of operation"])}
      }
    },
    "UserRoles": {
      "btn": {
        "addRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new role"])}
      },
      "permissionsAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions available"])},
      "permissionsSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User permissions selected"])},
      "table": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "roleAuthorities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choice of permissions"])},
        "roleCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role code"])},
        "roleTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role name"])}
      }
    }
  },
  "SetupConfig": {
    "IpsCardGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Група карток IPS"])},
    "Products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продукти"])},
    "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Користувачі"])},
    "UserRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ролі користувача"])},
    "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банк"])},
    "currencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта"])},
    "generalConfiguration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загальна конфігурація"])},
    "serviceGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервіс група"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup Config"])}
  },
  "Terminal": {
    "btn": {
      "addSubTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add subordinate terminal"])}
    },
    "formSubTerminal": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation code"])}
    },
    "formUpdateTID": {
      "addData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional information"])},
      "repeatRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat registration"])},
      "zreportEnabledAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z-report enabled"])}
    },
    "selectStatus": {
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
      "BLOCK_ATTESTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocked by attestation"])},
      "BLOCK_BANK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank blocked"])},
      "BLOCK_CLIENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocked by client"])},
      "BLOCK_MANUAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocked by manager"])},
      "BLOCK_SYSTEM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocked by the system"])},
      "DELETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted"])},
      "NOT_READY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not ready"])}
    },
    "table": {
      "bankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank"])},
      "currencyCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
      "dateTimeInit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of registration"])},
      "deviceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device"])},
      "groupNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group number"])},
      "lastTransactionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of operation"])},
      "lastUpdateDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update date"])},
      "masterTerminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Terminal ID"])},
      "mcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MCC"])},
      "merchantId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant ID"])},
      "merchantLegalName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the organization"])},
      "merchantName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant name"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "terminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal ID"])},
      "terminalLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal installation address"])},
      "terminalLocationEng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal installation address (eng.)"])},
      "zReportAuto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto Z-report"])}
    }
  },
  "TerminalGroups": {
    "btn": {
      "changeGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change group"])},
      "changeNumberGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change group number"])}
    },
    "modal": {
      "addNewGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new group"])},
      "deleteNumberGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting a group"])},
      "editGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change group: "])}
    },
    "table": {
      "bankId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank ID"])},
      "bankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank"])},
      "currencyCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
      "geoPosition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Determination of geolocation"])},
      "groupFacilityId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Facility ID"])},
      "groupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group name"])},
      "groupNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group number"])},
      "ipsCardGroupIdList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card acceptance"])},
      "menuItemList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application menu options"])},
      "noPinLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit without PIN"])},
      "numberOfSecondaryTerminals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number of subordinate terminals"])},
      "oneTransactionLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit per transaction"])},
      "opCash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation Cash"])},
      "opCreateSubordinationTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Possibility of creation of subordinate terminals"])},
      "opManual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation Entry-Mode"])},
      "opNfc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay-NFC operation"])},
      "opPinAmex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entering a pin code for Amex"])},
      "opPinDiscover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entering a pin code for Discover"])},
      "opPinMastercard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entering a pin code for Mastercard"])},
      "opPinProstir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entering a pin code for Простір"])},
      "opPinVisa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entering a pin code for Visa"])},
      "opPurchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment operations"])},
      "opQr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay-QR operation"])},
      "opRefund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund operation"])},
      "opReversal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reversal operation"])},
      "opSingleTapAndPin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support of Single Tap and PIN"])},
      "opTips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tips"])},
      "productIdList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product ID"])},
      "productIds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product ID"])},
      "productName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product name"])},
      "receiptSendChannelIdList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check sending channel"])},
      "receiptTemplateId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt Template"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "toGroupNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move number to group"])},
      "totalAmountLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total amount limit day"])},
      "totalCountLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A limit on the total number of transactions per day"])},
      "totalLimitPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total limit period"])},
      "transactionGeoposition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction geoposition"])},
      "velocityCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocity count"])},
      "velocityPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocity period"])},
      "velocityTimeUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocity time unit"])}
    }
  },
  "TerminalKeys": {
    "btn": {
      "keyManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forced management keys"])},
      "keyManagerAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Run for all keys"])}
    },
    "tab": {
      "allPosKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All POS keys"])},
      "termKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal keys"])}
    },
    "table": {
      "checkValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control value"])},
      "effDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key activation date"])},
      "expDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key end date"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key entry ID"])},
      "keyType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key type"])},
      "keyValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key value under LMK"])},
      "refCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal ID"])},
      "statCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
      "terminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal ID"])}
    }
  },
  "TmsKey": {
    "table": {
      "checkValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check value"])},
      "effDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key activation date"])},
      "expDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key end date"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key entry ID"])},
      "keyType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key type"])},
      "keyValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key value under LMK"])},
      "refCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference value"])},
      "statCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
    }
  },
  "Transaction": {
    "btn": {
      "reversal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reversal"])}
    },
    "table": {
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
      "actionShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
      "amountOther": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional sum"])},
      "approvalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorization code"])},
      "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
      "deviceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device"])},
      "formFactor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form-Factor"])},
      "fraudMonitoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["F"])},
      "fraudMonitoringFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraud status"])},
      "geoposition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geolocation"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
      "localTransactionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local transaction date"])},
      "merchantId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant ID"])},
      "operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of transaction"])},
      "panMasked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAN mask"])},
      "receiptNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check number"])},
      "responseCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response code"])},
      "responseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["response Date"])},
      "reversal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reversal"])},
      "rrn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RRN"])},
      "statusCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status Code"])},
      "terminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal ID"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
      "transactionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction date"])},
      "transactionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction number"])}
    },
    "toast": {
      "reversalSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reversal success"])}
    }
  },
  "TrnReportsSettlement": {
    "TerminalTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal report"])},
    "TransactionTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction report"])},
    "amountAllTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["amountAllTransaction"])},
    "amountBankCommission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount bank commission"])},
    "amountPinTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["amount Pin Transaction"])},
    "amountPurchaseTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["amount Purchase Transaction"])},
    "amountRefundTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["amount Refund Transaction"])},
    "amountReversalTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["amount Reversal Transaction"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "initDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Init Date"])},
    "quantityActiveTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity Active Terminal"])},
    "quantityAllTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity All Terminal"])},
    "quantityAllTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quantity All Transaction"])},
    "quantityDeclinedTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quantity Declined Transaction"])},
    "quantityInactiveTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity Inactive Terminal"])},
    "quantityLoginTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quantity Login Terminal"])},
    "quantityPinTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quantity Pin Transaction"])},
    "quantityReactivateTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity Reactivate Terminal"])},
    "quantityRefundTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quantity Refund Transaction"])},
    "quantityRegisterFullTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quantity Register Full Terminal"])},
    "quantityRegisterWaitTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quantity Register Wait Terminal"])},
    "quantityReversalTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quantity Reversal Transaction"])},
    "quantitySuccessTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quantity Success Transaction"])},
    "quantityTurnActiveTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity Turn Active Terminal"])},
    "quantityTurnInactiveTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity Turn Inactive Terminal"])}
  },
  "Users": {
    "addUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add user"])},
    "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
    "table": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "userRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])}
    }
  },
  "Validations": {
    "checkSendChannelRequiredList": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Choose at least one mandatory channel for sending checks: ", _interpolate(_named("chList"))])},
    "isForbidToChangePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password has expired, please contact the administrator for a change."])},
    "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The maximum length allowed characters is: ", _interpolate(_named("num"))])},
    "maxValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maximum allowed value: ", _interpolate(_named("num"))])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Минимально допустимая длина символов: ", _interpolate(_named("num"))])},
    "minValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Minimum allowed value: ", _interpolate(_named("num"))])},
    "notChosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not chosen"])},
    "onlyLettersNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only letters and numbers of the Latin alphabet are allowed"])},
    "onlyNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only digits are allowed for input."])},
    "passRule1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Password requirements:<br>  At least one digit.<br> At least one uppercase letter.<br> At least one special character (", _interpolate(_named("specChar")), ").<br> No spaces or whitespace characters.<br"])},
    "passRule2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum of 3 consecutive repeated characters."])},
    "passwordExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password has expired, please enter a new one."])},
    "passwordExpiresSoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password expiration date is approaching, please change your password."])},
    "passwordRepeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-enter the password"])},
    "reEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введен некорректный электронный адрес"])},
    "reNumAndDot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only numbers and dots are allowed"])},
    "reTextAndNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid characters entered. Only letters and numbers are available for input."])},
    "reTextAndNumDot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid characters entered. Only letters and numbers and (\",\" \".\" \"-\") are available for input."])},
    "reTextLatin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only Latin characters are allowed"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required"])},
    "userPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the password"])}
  },
  "VendorRSO": {
    "table": {
      "addVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adding a new one"])},
      "aidlLibVersionCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AILD version"])},
      "appName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application name"])},
      "createDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created date"])},
      "expiredDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mounth"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "packageName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package name"])},
      "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
      "shortName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short name"])},
      "vendorCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor code"])},
      "vendorId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
      "versionCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version code"])},
      "versionName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version name"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])}
    }
  },
  "Version": {
    "table": {
      "expDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "updateDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of registration"])},
      "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])}
    }
  },
  "ZReportTransactions": {
    "table": {
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
      "approvalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorization code"])},
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Count"])},
      "localTransactionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local Date/Time"])},
      "merchantName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant ID"])},
      "operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation"])},
      "responseCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response code"])},
      "responseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response time"])},
      "responseMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response text"])},
      "rrn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RRN"])},
      "statusCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "terminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal ID"])},
      "transactionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date/Time"])}
    }
  },
  "lang": {
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Русский"])},
    "uk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Українська"])}
  },
  "left-menu": {
    "analytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analytics"])},
    "attestationHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History of attestations"])},
    "businessDayCalculation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business day calculation"])},
    "chatHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat history"])},
    "devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devices"])},
    "investigation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investigation"])},
    "merchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant"])},
    "merchantRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant Registration"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "monitoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitoring"])},
    "receiptSendAudit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the sent receipt"])},
    "regularReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regulatory reports"])},
    "regulatoryCommissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial company"])},
    "reportsSettlement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settlements with the bank"])},
    "serviceGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal groups"])},
    "terminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminals"])},
    "terminalKeys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal keys"])},
    "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "zReportTransactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z-Reports"])}
  },
  "settings-menu": {
    "VendorRSO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RSO Vendor"])},
    "attestation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attestation parameters"])},
    "banks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banks"])},
    "changeAudit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Audit"])},
    "entityAudit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System changes"])},
    "installations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installation"])},
    "ipsCardGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment systems"])},
    "ipsKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment system keys"])},
    "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
    "receiptTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt Template"])},
    "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedule"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "terminalFacility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tariffs"])},
    "tmsKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Keys"])},
    "userRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
    "vendorRSO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RSO Vendor"])},
    "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])}
  },
  "test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["test"])},
  "uiElements": {
    "btn": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
      "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
      "deleteAllFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete all files"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
      "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
      "resetFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset filters"])},
      "run": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forced run"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
      "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])}
    },
    "tableUpload": {
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
      "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File name"])},
      "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
    },
    "text": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
      "columnName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column name"])},
      "countItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of records"])},
      "currencyCode": {
        "0980": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UAH"])}
      },
      "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datail"])},
      "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable"])},
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled"])},
      "listEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The list is empty"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
      "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data"])},
      "noDataSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no results for this search"])},
      "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
      "signInPanel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrance to the office"])},
      "tableColumnSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting the table view"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])}
    },
    "toast": {
      "err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
      "errSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save error"])},
      "sendSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["successfully sent"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
      "successDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully removed"])},
      "successSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully saved"])}
    }
  }
}