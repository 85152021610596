import terminalFacilityApi from "@/api/settings-api/terminal-facility";

const state = {
  dataResp: null,
  dataModalResp: null,

  isLoading: false,
  isLoadingScroll: false,
  isLoadingModal: false,

  errorResp: null,
};

export const mutationTypes = {
  getTerminalFacilityStart: "[terminalFacility] getTerminalFacility start",
  getTerminalFacilitySuccess: "[terminalFacility] getTerminalFacility success",
  getTerminalFacilityFailure: "[terminalFacility] getTerminalFacility failure",
};

export const actionTypes = {
  getTerminalFacility: "[terminalFacility] getTerminalFacility",
};

const mutations = {
  [mutationTypes.getTerminalFacilityStart](state) {
    state.isLoading = true;
    state.data = null;
  },
  [mutationTypes.getTerminalFacilitySuccess](state, payload) {
    state.isLoading = false;
    state.dataResp = payload;
  },
  [mutationTypes.getTerminalFacilityFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
};

const actions = {
  [actionTypes.getTerminalFacility](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getTerminalFacilityStart);
      terminalFacilityApi
        .getTerminalFacility()
        .then((response) => {
          context.commit(
            mutationTypes.getTerminalFacilitySuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getTerminalFacilityFailure,
            result.response.data.error
          );
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
