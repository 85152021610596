import axios from "@/api/axios";

const getVersion = (apiParams) => {
  return axios.get("/version?" + apiParams);
};

const updateVersion = (id, reqObj) => {
  return axios.put("/version/" + id, {
    ...reqObj,
  });
};

const addVersion = (reqObj) => {
  return axios.post("/version", {
    ...reqObj,
  });
};

export default {
  getVersion,
  updateVersion,
  addVersion,
};
