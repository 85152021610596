<template>
  <div class="row m-3 p-3 border border-1">
    <div class="col-md-6">
      <div class="form-group">
        <label for="ips_card_group">Номер групи карток IPS:</label>
        <input
          type="text"
          class="form-control"
          :id="`ips_card_group-${indexF}`"
          v-maska
          data-maska="####"
          v-model.trim="v$.ips_card_group_obj.ips_card_group.$model"
          :disabled="editableForm"
        />

        <div
          v-if="v$.ips_card_group_obj.ips_card_group.required.$invalid"
          class="text-danger"
        >
          Поле обов'язкове для заповнення
        </div>
        <div
          v-if="v$.ips_card_group_obj.ips_card_group.uniqueValue.$invalid"
          class="text-danger"
        >
          Вкажіть унікальний номер групи карток IPS (Даний уже присутній в
          попередніх полях)
        </div>
      </div>
      <div class="form-group">
        <label for="first_card_number">Перші цифри картки:</label>
        <input
          type="text"
          class="form-control"
          :id="`first_card_number-${indexF}`"
          v-maska
          data-maska="#"
          v-model.trim="v$.ips_card_group_obj.first_card_number.$model"
          :disabled="editableForm"
        />
        <div
          v-if="v$.ips_card_group_obj.first_card_number.required.$invalid"
          class="text-danger"
        >
          Поле обов'язкове для заповнення
        </div>
      </div>
      <div class="form-group">
        <label for="ips_name">Назва IPS:</label>
        <input
          type="text"
          class="form-control"
          :id="`ips_name-${indexF}`"
          v-model.trim="v$.ips_card_group_obj.ips_name.$model"
          :disabled="editableForm"
        />
        <div
          v-if="v$.ips_card_group_obj.ips_name.required.$invalid"
          class="text-danger"
        >
          Поле обов'язкове для заповнення
        </div>
      </div>
      <div class="form-group">
        <label for="ips_symbol">Символ IPS:</label>
        <input
          type="text"
          class="form-control"
          :id="`ips_symbol-${indexF}`"
          v-model.trim="v$.ips_card_group_obj.ips_symbol.$model"
          :disabled="editableForm"
        />
        <div
          v-if="v$.ips_card_group_obj.ips_symbol.required.$invalid"
          class="text-danger"
        >
          Поле обов'язкове для заповнення
        </div>
      </div>
      <div class="form-group">
        <label for="no_pin_limit">Ліміт без ПІН:</label>
        <input
          type="text"
          class="form-control"
          :id="`no_pin_limit-${indexF}`"
          v-model.trim="v$.ips_card_group_obj.no_pin_limit.$model"
          :disabled="editableForm"
        />
        <div
          v-if="v$.ips_card_group_obj.no_pin_limit.required.$invalid"
          class="text-danger"
        >
          Поле обов'язкове для заповнення
        </div>
        <div
          v-if="v$.ips_card_group_obj.no_pin_limit.numeric.$invalid"
          class="text-danger"
        >
          Значення повинно бути числом
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="op_manual">Операції без ПІН:</label>
        <select
          class="form-control"
          :id="`op_manual-${indexF}`"
          v-model.trim="v$.ips_card_group_obj.op_manual.$model"
          :disabled="editableForm"
        >
          <option value="Y">Так</option>
          <option value="N">Ні</option>
        </select>
      </div>
      <div class="form-group">
        <label for="op_nfc">Операції через NFC:</label>
        <select
          class="form-control"
          :id="`op_nfc-${indexF}`"
          v-model.trim="v$.ips_card_group_obj.op_nfc.$model"
          :disabled="editableForm"
        >
          <option value="Y">Так</option>
          <option value="N">Ні</option>
        </select>
      </div>
      <div class="form-group">
        <label for="op_pin">Операції з ПІН:</label>
        <select
          class="form-control"
          :id="`op_pin-${indexF}`"
          v-model.trim="v$.ips_card_group_obj.op_pin.$model"
          :disabled="editableForm"
        >
          <option value="Y">Так</option>
          <option value="N">Ні</option>
        </select>
      </div>
      <div class="form-group">
        <label for="op_qr">Операції через QR:</label>
        <select
          class="form-control"
          :id="`op_qr-${indexF}`"
          v-model.trim="v$.ips_card_group_obj.op_qr.$model"
          :disabled="editableForm"
        >
          <option value="Y">Так</option>
          <option value="N">Ні</option>
        </select>
      </div>
      <div class="d-flex justify-content-center py-3">
        <button
          type="button"
          class="btn btn-danger"
          @click="remove(indexF)"
          :disabled="editableForm"
        >
          Видалити
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { numeric, required } from "@vuelidate/validators";

export default {
  name: "TrnSetupConfigIpsCardGroup",
  setup() {
    return { v$: useVuelidate() };
  },
  props: {
    ips_card_group_obj: { type: Object, required: true },
    indexF: { type: Number, required: true },
    editableForm: { type: Boolean, required: true },
    existingIPSGroups: { type: Array, required: true },
  },
  emits: ["deleteIpsCardGroup", "updateIpsCardGroup"],
  validations() {
    return {
      ips_card_group_obj: {
        ips_card_group: {
          required,
          uniqueValue: this.checkUniqueIPSGroup,
        },
        first_card_number: { required },
        ips_name: { required },
        ips_symbol: { required },
        no_pin_limit: { required, numeric },
        op_manual: { required },
        op_nfc: { required },
        op_pin: { required },
        op_qr: { required },
      },
    };
  },
  methods: {
    remove(index) {
      // this.currencies.splice(index, 1);
      this.$emit("deleteIpsCardGroup", index);
    },
    updateValueForm(event, inputName) {
      // Відправляємо подію з оновленим значенням
      let tempObj = {
        ...this.currency,
      };
      tempObj[inputName] = event.target.value;
      this.$emit("updateIpsCardGroup", tempObj);
    },
    checkUniqueIPSGroup(value) {
      let checkNum = true;
      for (const key in this.existingIPSGroups) {
        if (Number(this.indexF) !== Number(key)) {
          if (this.existingIPSGroups[key].ips_card_group === value) {
            checkNum = false;
          }
        }
      }
      return checkNum;
    },
  },
};
</script>

<style scoped></style>
