<template>
  <div class="title-table mb-2 ps-3 py-1 fw-bold">
    {{ $t(`left-menu.zReportTransactions`) }}
  </div>
  <!-- Фільтр -->
  <div class="toolbar">
    <div class="btn-group mb-2">
      <button
        class="btn btn-outline-primary btn-sm dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        data-bs-auto-close="outside"
        aria-expanded="false"
        ref="dropdownBtnFilter"
      >
        <i class="bi bi-filter-square me-2"></i>
        <span>{{ $t(`uiElements.btn.filters`) }}</span>
      </button>
      <button
        class="btn btn-outline-primary btn-sm"
        type="button"
        @click="resetFilterForm"
      >
        <i class="bi bi-x-square"></i>
      </button>
      <form
        class="dropdown-menu p-4"
        style="width: 500px"
        @submit.prevent="formFilterRm"
        ref="dropdownFormFilter"
      >
        <div class="row mb-3">
          <label
            for="terminalId-ff"
            class="col-sm-4 col-form-label col-form-label-sm"
            >{{ $t(`ZReportTransactions.table.terminalId`) }}</label
          >
          <div class="col-sm-8">
            <input
              type="text"
              class="form-control form-control-sm"
              id="terminalId-ff"
              v-model.trim="filters['terminalId']"
              :placeholder="$t(`Validations.notChosen`)"
            />
          </div>
        </div>

        <div class="row mb-3">
          <label
            for="merchantName-ff"
            class="col-sm-4 col-form-label col-form-label-sm"
            >{{ $t(`ZReportTransactions.table.merchantName`) }}</label
          >
          <div class="col-sm-8">
            <input
              v-model.trim="filters['merchantName']"
              type="text"
              class="form-control form-control-sm"
              id="merchantName-ff"
              :placeholder="$t(`Validations.notChosen`)"
            />
          </div>
        </div>

        <div class="justify-content-end d-flex">
          <button
            class="btn btn-outline-primary btn-sm me-3"
            type="button"
            @click="resetFilterForm"
          >
            <i class="bi bi-x-square me-1"></i>
            <span>{{ $t(`uiElements.btn.resetFilters`) }}</span>
          </button>
          <button type="submit" class="btn btn-primary btn-sm">
            <i class="bi bi-clipboard-check me-1"></i>
            <span>{{ $t(`uiElements.btn.apply`) }}</span>
          </button>
        </div>
      </form>
    </div>
  </div>

  <!-- Фільтр кінець -->

  <div
    class="table-responsive table-block overflow-auto"
    id="table-block"
    @scroll="scrollTable"
  >
    <table class="table table-striped table-hover table-sm table-light">
      <thead>
        <tr class="text-center">
          <th
            scope="col"
            data-col-sort="terminalId"
            @click="clickSortCol($event, 'terminalId')"
          >
            {{ $t(`ZReportTransactions.table.terminalId`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="merchantName"
            @click="clickSortCol($event, 'merchantName')"
          >
            {{ $t(`ZReportTransactions.table.merchantName`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="operation"
            @click="clickSortCol($event, 'operation')"
          >
            {{ $t(`ZReportTransactions.table.operation`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="amount"
            @click="clickSortCol($event, 'amount')"
          >
            {{ $t(`ZReportTransactions.table.amount`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="count"
            @click="clickSortCol($event, 'count')"
          >
            {{ $t(`ZReportTransactions.table.count`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="transactionDate"
            @click="clickSortCol($event, 'transactionDate')"
          >
            {{ $t(`ZReportTransactions.table.transactionDate`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="localTransactionDate"
            @click="clickSortCol($event, 'localTransactionDate')"
          >
            {{ $t(`ZReportTransactions.table.localTransactionDate`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="responseDate"
            @click="clickSortCol($event, 'responseDate')"
          >
            {{ $t(`ZReportTransactions.table.responseDate`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="approvalCode"
            @click="clickSortCol($event, 'approvalCode')"
          >
            {{ $t(`ZReportTransactions.table.approvalCode`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="responseCode"
            @click="clickSortCol($event, 'responseCode')"
          >
            {{ $t(`ZReportTransactions.table.responseCode`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="responseMessage"
            @click="clickSortCol($event, 'responseMessage')"
          >
            {{ $t(`ZReportTransactions.table.responseMessage`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="statusCode"
            @click="clickSortCol($event, 'statusCode')"
          >
            {{ $t(`ZReportTransactions.table.statusCode`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="rrn"
            @click="clickSortCol($event, 'rrn')"
          >
            {{ $t(`ZReportTransactions.table.rrn`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>
        </tr>
      </thead>
      <tbody class="text-nowrap">
        <template v-if="isLoading">
          <tr>
            <td colspan="16">
              <div class="loading text-center">
                <trn-loading></trn-loading>
              </div>
            </td>
          </tr>
        </template>
        <template v-if="dataResp">
          <tr v-for="item in dataResp.content" :key="item" class="text-center">
            <td>{{ item.terminalId }}</td>
            <td>{{ item.merchantName }}</td>
            <td>{{ item.operation }}</td>
            <td>{{ $filters.formatAmount(item.amount) }}</td>
            <td>{{ item.count }}</td>
            <td>{{ $filters.formatDateTime(item.transactionDate) }}</td>
            <td>{{ $filters.formatDateTimeUTC(item.localTransactionDate) }}</td>
            <td>{{ $filters.formatDateTime(item.responseDate) }}</td>
            <td>{{ item.approvalCode }}</td>
            <td>{{ item.responseCode }}</td>
            <td>{{ item.responseMessage }}</td>
            <td>{{ item.statusCode }}</td>
            <td>{{ item.rrn }}</td>
          </tr>
          <template v-if="isLoadingScroll">
            <tr>
              <td colspan="16">
                <div class="loading text-center">
                  <trn-loading></trn-loading>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import TrnLoading from "@/components/Loading";
import { mapState } from "vuex";
import { stringify } from "query-string";
import { actionTypes } from "@/store/modules/z-report-transactions";
import useVuelidate from "@vuelidate/core";

export default {
  name: "TrnZReportTransactions",
  components: {
    TrnLoading,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      page: 0,
      size: 50,
      sort: {
        col: "transactionDate",
        order: "desc",
      },
      filters: {
        terminalId: "",
        merchantName: "",
      },
    };
  },
  computed: {
    dataResp: {
      get() {
        return this.$store.state.zReportTransactions.dataResp;
      },
      set(newValue) {
        this.$store.state.zReportTransactions.dataResp = newValue;
      },
    },
    ...mapState({
      isLoading: (state) => state.zReportTransactions.isLoading,
      isLoadingScroll: (state) => state.zReportTransactions.isLoadingScroll,
      dataModalResp: (state) => state.zReportTransactions.dataModalResp,
      errorResp: (state) => state.zReportTransactions.errorResp,
    }),
  },
  methods: {
    fetchZReportTransactions() {
      this.changeArrowIcon();
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
        ...this.filters,
      });
      this.$store.dispatch(actionTypes.getZReportTransactions, {
        apiParams: stringifiedParams,
      });
    },
    scrollTable(event) {
      const currentEl = event.target;
      let checkScrollEnd =
        currentEl.scrollTop + currentEl.clientHeight + 3 >=
        currentEl.scrollHeight;
      if (checkScrollEnd && this.isLoadingScroll === false) {
        const currentPage = this.page + 1;
        const totalPages = this.dataResp.totalPages;
        if (currentPage < totalPages) this.loadMoreData();
      }
    },
    loadMoreData() {
      this.page += 1;
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
      });
      this.$store.dispatch(actionTypes.getZReportTransactionsScrollPush, {
        apiParams: stringifiedParams,
      });
    },
    reloadPage() {
      this.page = 0;
      this.fetchZReportTransactions();
    },
    clickSortCol(event, newSortItem) {
      const newSortItemData = newSortItem;
      newSortItem = newSortItem.replaceAll(".", "-");
      const scrollTableEl = document.getElementById("table-block");
      const beforeSortKey = this.sort.col.replaceAll(".", "-");
      const beforeSortDirection = this.sort.order;

      const beforeEl = document.querySelectorAll(
        `[data-col-sort~=${beforeSortKey}]`
      );
      beforeEl[0].children[0].innerHTML = '<i class="bi bi-arrow-down-up"></i>';

      // let newSortVal = [newSortItem, ""];

      if (beforeSortDirection === "desc" && beforeSortKey === newSortItem) {
        this.sort.order = "asc";
      } else {
        this.sort.order = "desc";
      }
      this.page = 0;
      this.sort.col = newSortItemData;

      scrollTableEl.scrollTo(0, 0);
      this.fetchZReportTransactions();
    },
    changeArrowIcon(sortKey = this.sort.col, sortDirection = this.sort.order) {
      sortKey = sortKey.replaceAll(".", "-");
      const targetEl = document.querySelectorAll(`[data-col-sort~=${sortKey}]`);
      if (sortDirection === "asc") {
        targetEl[0].children[0].innerHTML =
          '<i class="bi bi-arrow-up-square"></i>';
      } else {
        targetEl[0].children[0].innerHTML =
          '<i class="bi bi-arrow-down-square"></i>';
      }
    },
    formFilterRm() {
      this.$refs.dropdownBtnFilter.click();
      this.fetchZReportTransactions();
    },
    resetFilterForm() {
      for (let item in this.filters) {
        this.filters[item] = "";
      }
      this.$refs.dropdownBtnFilter.click();
      this.fetchZReportTransactions();
    },
  },
  mounted() {
    this.fetchZReportTransactions();
  },
};
</script>

<style scoped></style>
