import axios from "@/api/axios";

const getDevices = (apiParams) => {
  return axios.get("/devices?" + apiParams);
};

const updateDevice = (deviceObj) => {
  return axios.post("/devices/change-device-status", {
    ...deviceObj,
  });
};

export default {
  getDevices,
  updateDevice,
};
