<template>
  <ul class="nav nav-tabs mb-3" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <button
        class="nav-link"
        :class="{ active: isTerminalTab }"
        id="terminal-tab"
        data-bs-toggle="tab"
        data-bs-target="#terminal"
        type="button"
        role="tab"
        aria-controls="home"
        aria-selected="true"
        @click="clickTab"
      >
        <i class="bi bi-phone-landscape"></i>
        {{ $t("TrnReportsSettlement.TerminalTab") }}
      </button>
    </li>
    <li class="nav-item" role="presentation" @click="clickTab">
      <button
        class="nav-link"
        :class="{ active: isTransactionTab }"
        id="transaction-tab"
        data-bs-toggle="tab"
        data-bs-target="#transaction"
        type="button"
        role="tab"
        aria-controls="profile"
        aria-selected="false"
        @click="clickTab"
      >
        <i class="bi bi-cash-coin"></i>
        {{ $t("TrnReportsSettlement.TransactionTab") }}
      </button>
    </li>
  </ul>
  <div class="tab-content" id="myTabContent">
    <div
      class="tab-pane fade"
      :class="{ show: isTerminalTab, active: isTerminalTab }"
      id="terminal"
      role="tabpanel"
      aria-labelledby="terminal-tab"
    >
      <trn-reports-settlement-tabs
        :path-name="pathName"
        v-if="isTerminalTab"
      ></trn-reports-settlement-tabs>
    </div>
    <div
      class="tab-pane fade"
      :class="{ show: isTransactionTab, active: isTransactionTab }"
      id="transaction"
      role="tabpanel"
      aria-labelledby="transaction-tab"
    >
      <trn-reports-settlement-tabs
        :path-name="pathName"
        v-if="isTransactionTab"
      ></trn-reports-settlement-tabs>
    </div>
  </div>
</template>

<script>
import TrnReportsSettlementTabs from "@/components/ReportsSettlementTabs";

export default {
  name: "TrnReportsSettlement",
  components: { TrnReportsSettlementTabs },
  data() {
    return {
      pathName: null,
      isTerminalTab: null,
      isTransactionTab: null,
    };
  },
  mounted() {
    this.pathName = this.$router.currentRoute.value.name;

    if (this.pathName === "reportsSettlementTransaction") {
      this.isTransactionTab = true;
    } else {
      this.isTerminalTab = true;
    }
  },
  methods: {
    clickTab(event) {
      if (event.target.id === "terminal-tab") {
        this.pathName = "reportsSettlementTerminal";
        this.isTransactionTab = false;
        this.isTerminalTab = true;
      } else {
        this.pathName = "reportsSettlementTransaction";
        this.isTerminalTab = false;
        this.isTransactionTab = true;
      }
    },
  },
};
</script>

<style scoped>
.icon-tab {
  display: inline;
}
</style>
