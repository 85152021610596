import axios from "@/api/axios";

const getGeneralConfiguration = () => {
  return axios.get("/general-configuration");
};

const updateGeneralConfiguration = (reqObj) => {
  return axios.put("/general-configuration", {
    ...reqObj,
  });
};

const updateUserLanguage = (langCode) => {
  return axios.put("/language-items/set-to-user/" + langCode);
};

const setupConfig = (reqObj) => {
  return axios.post("/config/generate", {
    ...reqObj,
  });
};

export default {
  getGeneralConfiguration,
  updateGeneralConfiguration,
  updateUserLanguage,
  setupConfig,
};
