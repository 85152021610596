import axios from "@/api/axios";

const get_banks = () => {
  return axios.get("/banks");
};

const update_bank = (id, formObj) => {
  return axios.put("/banks/" + id, {
    ...formObj,
  });
};

export default {
  get_banks,
  update_bank,
};
