<template>
  <div class="title-table mb-2 ps-3 py-1 fw-bold">
    {{ $t(`left-menu.analytics`) }}
  </div>
  <div class="container-fluid h-100">
    <!--  row select range date  -->
    <div class="row">
      <div class="col-lg-4">
        <div class="row mb-3">
          <label for="selectRange" class="col-lg-3 col-form-label">{{
            $t(`Analytics.select.period`)
          }}</label>
          <div class="col-lg-9">
            <select
              class="form-select"
              id="selectRange"
              v-model="selectRange"
              @change="changeSelectRange"
            >
              <option value="daily">
                {{ $t(`Analytics.select.daily`) }}
              </option>
              <option value="monthly">
                {{ $t(`Analytics.select.monthly`) }}
              </option>
              <option value="custom">
                {{ $t(`Analytics.select.custom`) }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-lg-5" v-if="showCustomDateRange">
        <div class="row mb-3">
          <label for="selectRange" class="col-lg-2 col-sm-1 col-form-label">{{
            $t(`Analytics.select.customDateRange`)
          }}</label>
          <div class="col-lg-10 col-sm-11">
            <div class="form-check">
              <VueDatePicker
                class=""
                v-model="dateRange"
                v-if="dateRange"
                range
                autoApply
                :clearable="false"
                locale="uk"
                :format="dateFormat"
                :previewFormat="dateFormat"
                max-range="45"
                :max-date="maxDate"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  Транзакции & Активность транзакции  -->
    <div class="row row-tr border rounded">
      <div class="col-md-5">
        <vue-apex-charts
          type="bar"
          height="100%"
          v-if="respDataAnalytics"
          :options="chartStatusAnalytics.chartOptions"
          :series="chartStatusAnalytics.series"
        ></vue-apex-charts>
      </div>
      <div class="col-md-7">
        <template v-if="respDataAnalytics && selectRange === 'daily'">
          <vue-apex-charts
            width="100%"
            height="100%"
            type="line"
            :options="chartTransactionActivityDaily.chartOptions"
            :series="chartTransactionActivityDaily.series"
          ></vue-apex-charts>
        </template>
        <template v-else-if="respDataAnalytics && selectRange === 'monthly'">
          <vue-apex-charts
            width="100%"
            height="100%"
            type="line"
            :options="chartTransactionActivityMonthly.chartOptions"
            :series="chartTransactionActivityMonthly.series"
          ></vue-apex-charts>
        </template>
        <template v-else-if="respDataAnalytics && selectRange === 'custom'">
          <vue-apex-charts
            width="100%"
            height="100%"
            type="line"
            :options="chartTransactionActivityMonthly.chartOptions"
            :series="chartTransactionActivityMonthly.series"
          ></vue-apex-charts>
        </template>
        <template v-else>
          <div class="position-absolute text-center">
            <trn-loading></trn-loading>
          </div>
        </template>
      </div>
    </div>

    <!--  Платежные системы  -->
    <div class="row row-ps border rounded">
      <div class="col">
        <vue-apex-charts
          type="bar"
          height="100%"
          v-if="respDataAnalytics"
          :options="chartPaymentSystemAnalytics.chartOptions"
          :series="chartPaymentSystemAnalytics.series"
        ></vue-apex-charts>
      </div>
    </div>

    <!--  row Причина отказов & Способ оплаты & Устройство оплаты  -->
    <div class="row row-op border rounded">
      <div class="col">
        <vue-apex-charts
          v-if="respDataAnalytics"
          type="pie"
          height="200"
          :options="chartDeclinedAnalytics.chartOptions"
          :series="chartDeclinedAnalytics.series"
        ></vue-apex-charts>
      </div>
      <div class="col">
        <vue-apex-charts
          v-if="respDataAnalytics"
          type="pie"
          height="200"
          :options="chartFormFactorAnalytics.chartOptions"
          :series="chartFormFactorAnalytics.series"
        ></vue-apex-charts>
      </div>
    </div>

    <!--  row rroAnalytics  -->
    <div
      v-if="respDataAnalytics?.rroAnalytics.length > 0"
      class="row row-op border rounded"
    >
      <div class="col">
        <vue-apex-charts
          v-if="respDataAnalytics"
          type="pie"
          height="200"
          :options="chartRroAnalyticsAnalytics.chartOptions"
          :series="chartRroAnalyticsAnalytics.series"
        ></vue-apex-charts>
      </div>
      <div v-if="respDataAnalytics?.rroAnalytics" class="col">
        <!--    table    -->
        <div class="table-responsive">
          <table class="table table-striped table-hover">
            <thead>
              <tr class="text-center">
                <th>{{ $t(`Analytics.rro.table.appName`) }}</th>
                <th>{{ $t(`Analytics.rro.table.ratio`) }}</th>
                <th>{{ $t(`Analytics.rro.table.numberOfTransactions`) }}</th>
                <th>{{ $t(`Analytics.rro.table.totalTransactionsAmount`) }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in respDataAnalytics.rroAnalytics"
                :key="index"
              >
                <td>{{ item.appName }}</td>
                <td class="text-center">{{ item.ratio }}</td>
                <td class="text-center">{{ item.numberOfTransactions }}</td>
                <td class="text-center">
                  {{ $filters.formatAmount(item.totalTransactionsAmount) }}
                </td>
              </tr>
              <tr v-if="respDataAnalytics?.rroAnalytics.length === 0">
                <td colspan="4">
                  <div class="text-center fw-bold bg-info text-black">
                    {{ $t(`uiElements.text.noData`) }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="toast-container position-absolute p-3 end-0" v-if="showToast">
    <bs-toast v-for="toast in toastPropsList" :key="toast" :="toast"></bs-toast>
  </div>
</template>

<script>
import TrnLoading from "@/components/Loading";
import VueApexCharts from "vue3-apexcharts";
import monitoringApi from "@/api/analytics";
import useVuelidate from "@vuelidate/core";
import { stringify } from "query-string";
import BsToast from "@/components/UI-elements/BsToast";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import $filters from "@/helpers/filters";

export default {
  name: "TrnAnalytics",
  components: {
    VueDatePicker,
    VueApexCharts,
    TrnLoading,
    BsToast,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      showToast: false,
      toastPropsList: [],
      chartTransactionActivityDaily: {
        series: [
          {
            name: this.$t("Analytics.charts.success"),
            data: [],
          },
          {
            name: this.$t("Analytics.charts.refusal"),
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            height: 250,
            type: "line",
            dropShadow: {
              enabled: true,
              color: "#000",
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2,
            },
            toolbar: {
              show: false,
            },
          },
          colors: ["#02b638", "#da1b1b"],
          dataLabels: {
            enabled: true,
          },
          stroke: {
            curve: "smooth",
          },
          title: {
            text: this.$t("Analytics.charts.title.transactionActivity"),
            align: "left",
          },
          grid: {
            borderColor: "#e7e7e7",
            row: {
              colors: ["#f3f3f3", "transparent"],
              opacity: 0.5,
            },
          },
          markers: {
            size: 1,
          },
          xaxis: {
            categories: [],
            title: {
              text: this.$t("Analytics.charts.time"),
            },
          },
          legend: {
            position: "top",
            horizontalAlign: "right",
            floating: true,
            offsetY: -25,
            offsetX: -5,
          },
        },
      },
      chartTransactionActivityMonthly: {
        startDate: "",
        endDate: "",
        series: [
          {
            name: this.$t("Analytics.charts.success"),
            data: [],
          },
          {
            name: this.$t("Analytics.charts.refusal"),
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            height: 250,
            type: "line",
            dropShadow: {
              enabled: true,
              color: "#000",
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2,
            },
            toolbar: {
              show: false,
            },
          },
          colors: ["#02b638", "#da1b1b"],
          dataLabels: {
            enabled: true,
          },
          stroke: {
            curve: "smooth",
          },
          title: {
            text: this.$t("Analytics.charts.title.transactionActivity"),
            align: "left",
          },
          grid: {
            borderColor: "#e7e7e7",
            row: {
              colors: ["#f3f3f3", "transparent"],
              opacity: 0.5,
            },
          },
          markers: {
            size: 1,
          },
          xaxis: {
            categories: [],
            title: {
              text: this.$t("Analytics.charts.date"),
            },
          },
          legend: {
            position: "top",
            horizontalAlign: "right",
            floating: true,
            offsetY: -25,
            offsetX: -5,
          },
        },
      },
      chartDeclinedAnalytics: {
        series: [],
        chartOptions: {
          chart: {
            width: 380,
            type: "pie",
          },
          labels: [],
          title: {
            text: this.$t("Analytics.charts.title.declinedAnalytics"),
            align: "left",
          },
        },
      },
      chartFormFactorAnalytics: {
        series: [],
        chartOptions: {
          chart: {
            width: 380,
            type: "pie",
          },
          labels: [],
          title: {
            text: this.$t("Analytics.charts.title.formFactorAnalytics"),
            align: "left",
          },
        },
      },
      chartPaymentSystemAnalytics: {
        series: [{ name: "", data: [] }],
        chartOptions: {
          chart: {
            type: "bar",
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: [],
          },
          fill: {
            colors: ["#1A73E8", "#005777"],
          },
          title: {
            text: this.$t("Analytics.charts.title.paymentSystemAnalytics"),
            align: "left",
          },
        },
      },
      chartStatusAnalytics: {
        series: [
          { name: "Count", data: [] },
          { name: "Amount", data: [] },
        ],
        chartOptions: {
          chart: {
            type: "bar",
          },
          plotOptions: {
            bar: {
              horizontal: true,
              borderRadius: 4,
              dataLabels: {
                position: "top",
              },
            },
          },
          dataLabels: {
            enabled: true,
            offsetX: -6,
            style: {
              fontSize: "12px",
              colors: ["#fff"],
            },
          },
          tooltip: {
            shared: true,
            intersect: false,
          },
          xaxis: {
            categories: [],
            floating: true,
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            labels: {
              show: false,
            },
          },
          yaxis: {},
          fill: {
            colors: ["#1A73E8", "#005777"],
          },
          title: {
            text: this.$t("Analytics.charts.title.statusAnalytics"),
            align: "left",
          },
        },
      },
      chartRroAnalyticsAnalytics: {
        series: [],
        chartOptions: {
          chart: {
            width: 380,
            type: "pie",
          },
          labels: [],
          title: {
            text: this.$t("Analytics.charts.title.rroAnalytics"),
            align: "left",
          },
        },
      },
      selectRange: "daily",
      respDataAnalytics: null,
      dateFormat: "dd/MM/yyyy",
      dateRange: [],
      showCustomDateRange: false,
    };
  },
  computed: {
    $filters() {
      return $filters;
    },
    minDate() {
      return new Date(new Date().setDate(new Date().getDate() - 45));
    },
    maxDate() {
      return new Date();
    },
  },
  methods: {
    fetchAnalyticsMonthly() {
      const stringifiedParams = stringify({
        startDate: this.chartTransactionActivityMonthly.startDate,
        endDate: this.chartTransactionActivityMonthly.endDate,
      });
      monitoringApi
        .getAnalyticsMonthly(stringifiedParams)
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          // console.log("result", result);
          if (result.status === 200) {
            this.respDataAnalytics = result.data;
            this.fillChartData();
          } else {
            console.log("err");
          }
        });
    },
    fetchAnalyticsDaily() {
      let currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const stringifiedParams = stringify({
        startDate: currentDate.toISOString(),
        endDate: new Date().toISOString(),
      });
      monitoringApi
        .getAnalyticsDaily(stringifiedParams)
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          // console.log("result", result);
          if (result.status === 200) {
            this.respDataAnalytics = result.data;
            this.fillChartData();
          } else {
            console.log("err");
          }
        });
    },
    fillChartData() {
      // fill TransactionActivity
      if (this.selectRange === "daily") {
        this.chartTransactionActivityDaily.chartOptions.xaxis.categories = [];
        this.chartTransactionActivityDaily.series[0].data = [];
        this.chartTransactionActivityDaily.series[1].data = [];
        for (let key in this.respDataAnalytics.successfulHourlyAnalytics) {
          this.chartTransactionActivityDaily.chartOptions.xaxis.categories.push(
            this.$filters.formatDateTime(
              this.respDataAnalytics.successfulHourlyAnalytics[key].period,
              "time"
            )
          );

          this.chartTransactionActivityDaily.series[0].data.push(
            this.respDataAnalytics.successfulHourlyAnalytics[key].count
          );

          this.chartTransactionActivityDaily.series[1].data.push(
            this.respDataAnalytics.declinedHourlyAnalytics[key].count
          );
        }
      } else {
        this.chartTransactionActivityMonthly.chartOptions.xaxis.categories = [];
        this.chartTransactionActivityMonthly.series[0].data = [];
        this.chartTransactionActivityMonthly.series[1].data = [];
        for (let key in this.respDataAnalytics.successfulDailyAnalytics) {
          this.chartTransactionActivityMonthly.chartOptions.xaxis.categories.push(
            this.$filters.formatDateTime(
              this.respDataAnalytics.successfulDailyAnalytics[key].period,
              "dateShort"
            )
          );

          this.chartTransactionActivityMonthly.series[0].data.push(
            this.respDataAnalytics.successfulDailyAnalytics[key].count
          );

          this.chartTransactionActivityMonthly.series[1].data.push(
            this.respDataAnalytics.declinedDailyAnalytics[key].count
          );
        }
      }

      // fill declinedAnalytics
      this.chartDeclinedAnalytics.chartOptions.labels = [];
      this.chartDeclinedAnalytics.series = [];

      this.chartDeclinedAnalytics.series.push(
        this.respDataAnalytics.declinedAnalytics.attestationDeclinedCount,
        this.respDataAnalytics.declinedAnalytics.authDeclinedCount,
        this.respDataAnalytics.declinedAnalytics.technicalDeclinedCount
      );

      this.chartDeclinedAnalytics.chartOptions.labels.push(
        "Attestation",
        "Auth",
        "Technical"
      );

      // fill formFactorAnalytics
      this.chartFormFactorAnalytics.chartOptions.labels = [];
      this.chartFormFactorAnalytics.series = [];

      this.chartFormFactorAnalytics.series.push(
        this.respDataAnalytics.formFactorAnalytics.cardCount,
        this.respDataAnalytics.formFactorAnalytics.phoneCount,
        this.respDataAnalytics.formFactorAnalytics.wearableCount,
        this.respDataAnalytics.formFactorAnalytics.watchCount,
        this.respDataAnalytics.formFactorAnalytics.tabletCount,
        this.respDataAnalytics.formFactorAnalytics.otherCount
      );

      this.chartFormFactorAnalytics.chartOptions.labels.push(
        "Card",
        "Phone",
        "Wearable",
        "Watch",
        "Tablet",
        "Other"
      );

      // fill paymentSystemAnalytics
      this.chartPaymentSystemAnalytics.series[0].data = [];
      this.chartPaymentSystemAnalytics.chartOptions.xaxis.categories = [];

      for (let key in this.respDataAnalytics.paymentSystemAnalytics) {
        this.chartPaymentSystemAnalytics.chartOptions.xaxis.categories.push(
          this.respDataAnalytics.paymentSystemAnalytics[key].paymentSystemName
        );

        this.chartPaymentSystemAnalytics.series[0].data.push(
          this.respDataAnalytics.paymentSystemAnalytics[key].count
        );
      }

      // fill paymentSystemAnalytics
      this.chartStatusAnalytics.chartOptions.xaxis.categories = [];
      this.chartStatusAnalytics.series[0].data = [];
      this.chartStatusAnalytics.series[1].data = [];

      this.chartStatusAnalytics.chartOptions.xaxis.categories.push(
        this.$t("Analytics.charts.success"),
        this.$t("Analytics.charts.refusal")
      );
      this.chartStatusAnalytics.series[0].data.push(
        this.respDataAnalytics.statusAnalytics.successfulCount,
        this.respDataAnalytics.statusAnalytics.declinedCount
      );
      this.chartStatusAnalytics.series[1].data.push(
        this.respDataAnalytics.statusAnalytics.successfulAmount,
        this.respDataAnalytics.statusAnalytics.declinedAmount
      );

      // fill rroAnalytics
      this.chartRroAnalyticsAnalytics.chartOptions.labels = [];
      this.chartRroAnalyticsAnalytics.series = [];

      for (let key in this.respDataAnalytics.rroAnalytics) {
        this.chartRroAnalyticsAnalytics.chartOptions.labels.push(
          this.respDataAnalytics.rroAnalytics[key].appName
        );

        this.chartRroAnalyticsAnalytics.series.push(
          this.respDataAnalytics.rroAnalytics[key].ratio
        );
      }
    },
    setMonthlyDate() {
      const nowDate = new Date();
      const startDate = new Date(new Date().setDate(nowDate.getDate() - 30));

      this.chartTransactionActivityMonthly.startDate = new Date(
        startDate
      ).toISOString();
      this.chartTransactionActivityMonthly.endDate = new Date(
        nowDate
      ).toISOString();
    },
    changeSelectRange() {
      this.showCustomDateRange = false;
      switch (this.selectRange) {
        case "monthly":
          this.respDataAnalytics = null;
          this.setMonthlyDate();
          this.fetchAnalyticsMonthly();
          break;
        case "daily":
          this.respDataAnalytics = null;
          this.fetchAnalyticsDaily();
          break;
        case "custom":
          this.respDataAnalytics = null;
          this.showCustomDateRange = true;
          this.dateRange = [
            this.chartTransactionActivityMonthly.startDate,
            this.chartTransactionActivityMonthly.endDate,
          ];
          // this.fetchAnalyticsMonthly();
          break;
      }
    },
  },
  watch: {
    dateRange: {
      handler: function (val) {
        if (val && val[0]) {
          this.chartTransactionActivityMonthly.startDate = new Date(
            val[0]
          ).toISOString();
          this.chartTransactionActivityMonthly.endDate = new Date(
            val[1]
          ).toISOString();
        } else {
          this.setMonthlyDate();
        }
        this.respDataAnalytics = null;
        this.fetchAnalyticsMonthly();
      },
      deep: true,
    },
    // this.$i18n.locale
    "$i18n.locale": {
      handler: function () {
        window.location.reload();
      },
      deep: true,
    },
  },
  mounted() {
    this.changeSelectRange();
  },
};
</script>

<style scoped>
.row-op,
.row-ps,
.row-tr {
  margin-top: 15px;
  margin-bottom: 15px;
}

.row-tr {
  height: 250px;
}

.row-ps {
  height: 200px;
}

.row-op {
  height: 250px;
}
</style>
