<template>
  <div class="row m-3 p-3 border border-1">
    <div class="col-md-6">
      <div class="mb-3">
        <label for="product_name" class="form-label">Назва продукту*</label>
        <input
          type="text"
          class="form-control"
          id="product_name"
          v-model.trim="v$.product_obj.product_name.$model"
        />
        <div
          v-if="v$.product_obj.product_name.required.$invalid"
          class="text-danger"
        >
          Введіть назву продукту
        </div>
      </div>
      <div class="mb-3">
        <label for="range_begin" class="form-label">Початок діапазону*</label>
        <input
          type="text"
          class="form-control"
          id="range_begin"
          v-model.trim="v$.product_obj.range_begin.$model"
        />
        <div
          v-if="v$.product_obj.range_begin.required.$invalid"
          class="text-danger"
        >
          Введіть початок діапазону
        </div>
        <div v-if="v$.product_obj.range_begin.regex">
          Початок діапазону повинен бути 12-значним числом
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="mb-3">
        <label for="range_end" class="form-label">Кінець діапазону*</label>
        <input
          type="text"
          class="form-control"
          id="range_end"
          v-model.trim="v$.product_obj.range_end.$model"
        />
        <div
          v-if="v$.product_obj.range_end.required.$invalid"
          class="text-danger"
        >
          Введіть кінець діапазону
        </div>
      </div>

      <div class="mb-3">
        <label for="ips_card_group_id" class="form-label"
          >Група карток IPS</label
        >
        <select
          class="form-select"
          id="ips_card_group_id"
          v-model.trim="v$.product_obj.ips_card_group_id.$model"
        >
          <option value="">-- Оберіть групу карток IPS --</option>
          <option
            v-for="group in ipsCardGroups"
            :key="group"
            :value="group.ips_card_group"
            :class="{ invisible: group.ips_card_group === '' }"
          >
            {{ group.ips_card_group }} : {{ group.ips_name }}
          </option>
        </select>
        <div v-if="v$.product_obj.ips_card_group_id.$error" class="text-danger">
          Група карток IPS повинна бути обрана.
        </div>
      </div>
      <div class="d-flex justify-content-end py-3">
        <button type="button" class="btn btn-danger" @click="remove(indexF)">
          Видалити
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "TrnSetupConfigProduct",
  setup() {
    return { v$: useVuelidate() };
  },
  props: {
    product_obj: { type: Object, required: true },
    indexF: { type: Number, required: true },
    ipsCardGroups: {
      type: Object,
      required: true,
    },
  },
  emits: ["deleteProduct", "updateProduct"],
  validations() {
    return {
      product_obj: {
        product_name: { required },
        range_begin: { required },
        range_end: { required },
        ips_card_group_id: { required },
      },
    };
  },
  methods: {
    remove(index) {
      // this.currencies.splice(index, 1);
      this.$emit("deleteProduct", index);
    },
    updateValueForm(event, inputName) {
      // Відправляємо подію з оновленим значенням
      let tempObj = {
        ...this.currency,
      };
      tempObj[inputName] = event.target.value;
      this.$emit("updateProduct", tempObj);
    },
  },
};
</script>

<style scoped></style>
