import banksApi from "@/api/settings-api/settings-banks";

const state = {
  dataResp: null,
  dataModalResp: null,

  isLoading: false,
  isLoadingScroll: false,
  isLoadingModal: false,

  errorResp: null,
};

export const mutationTypes = {
  getSettingsBanksStart: "[settingBanks] getSettingsBanks start",
  getSettingsBanksSuccess: "[settingBanks] getSettingsBanks success",
  getSettingsBanksFailure: "[settingBanks] getSettingsBanks failure",
};

export const actionTypes = {
  getSettingsBanks: "[settingBanks] getSettingsBanks",
};

const mutations = {
  [mutationTypes.getSettingsBanksStart](state) {
    state.isLoading = true;
    state.data = null;
  },
  [mutationTypes.getSettingsBanksSuccess](state, payload) {
    state.isLoading = false;
    state.dataResp = payload;
  },
  [mutationTypes.getSettingsBanksFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
};

const actions = {
  [actionTypes.getSettingsBanks](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getSettingsBanksStart);
      banksApi
        .get_banks()
        .then((response) => {
          context.commit(mutationTypes.getSettingsBanksSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getSettingsBanksFailure,
            result.response.data.error
          );
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
