import axios from "@/api/axios";

const getChatHistory = (apiParams) => {
  return axios.get("/chat/history?" + apiParams);
};

const getChatHistoryID = (chatId) => {
  return axios.get("/chat/history/" + chatId);
};

export default {
  getChatHistory,
  getChatHistoryID,
};
