<template>
  <div
    class="table-responsive table-block overflow-auto"
    id="table-block"
    @scroll="scrollTable"
  >
    <table
      class="table table-striped table-hover table-sm table-light table-ll"
    >
      <thead>
        <tr class="text-center">
          <th
            scope="col"
            data-col-sort="merchantId"
            @click="clickSortCol($event, 'merchantId')"
          >
            {{ $t(`RegulatoryCommissions.table.merchant.merchantId`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="balance"
            @click="clickSortCol($event, 'balance')"
          >
            {{ $t(`RegulatoryCommissions.table.merchant.balance`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="merchantName"
            @click="clickSortCol($event, 'merchantName')"
          >
            {{ $t(`MerchantRegistration.table.merchantName`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="merchantLegalName"
            @click="clickSortCol($event, 'merchantLegalName')"
          >
            {{ $t(`Merchant.table.merchantLegalName`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="merchantLocation"
            @click="clickSortCol($event, 'merchantLocation')"
          >
            {{ $t(`MerchantRegistration.table.merchantLocation`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="taxId"
            @click="clickSortCol($event, 'taxId')"
          >
            {{ $t(`Merchant.table.taxId`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="phone"
            @click="clickSortCol($event, 'phone')"
          >
            {{ $t(`MerchantRegistration.table.phoneNumber`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="overdraft"
            @click="clickSortCol($event, 'overdraft')"
          >
            {{ $t(`RegulatoryCommissions.table.merchant.overdraft`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="creditPeriodValueWithType"
            @click="clickSortCol($event, 'creditPeriodValueWithType')"
          >
            {{
              $t(
                `RegulatoryCommissions.table.merchant.creditPeriodValueWithType`
              )
            }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="IBAN"
            @click="clickSortCol($event, 'IBAN')"
          >
            {{ $t(`Merchant.table.iban`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="fcmMessageTypes"
            @click="clickSortCol($event, 'fcmMessageTypes')"
          >
            {{ $t(`RegulatoryCommissions.table.merchant.fcmMessageTypes`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>
        </tr>
      </thead>
      <tbody class="text-nowrap">
        <template v-if="isLoading">
          <tr>
            <td colspan="16">
              <div class="loading text-center">
                <trn-loading></trn-loading>
              </div>
            </td>
          </tr>
        </template>
        <template v-if="dataResp?.data.content.length === 0">
          <tr>
            <td colspan="16">
              <div class="text-center fw-bold bg-info text-black">
                {{ $t(`uiElements.text.noData`) }}
              </div>
            </td>
          </tr>
        </template>
        <template v-if="dataResp">
          <tr
            v-for="item in dataResp.data.content"
            :key="item"
            class="text-center"
          >
            <td>{{ item.merchantId }}</td>
            <td class="text-end">
              {{ item.balance }}
              {{ $t(`uiElements.text.currencyCode.${item.currencyCode}`) }}
            </td>
            <td>{{ item.merchantName }}</td>
            <td>{{ item.merchantLegalName }}</td>
            <td>{{ item.merchantLocation }}</td>
            <td>{{ item.taxId }}</td>
            <td>{{ item.phone }}</td>
            <td class="text-end">
              {{ item.overdraft }}
              {{ $t(`uiElements.text.currencyCode.${item.currencyCode}`) }}
            </td>
            <td>{{ item.creditPeriodValueWithType }}</td>
            <td>{{ item.iban }}</td>
            <td>{{ item.fcmMessageTypes }}</td>
          </tr>
          <template v-if="isLoadingScroll">
            <tr>
              <td colspan="16">
                <div class="loading text-center">
                  <trn-loading></trn-loading>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>

  <div class="toast-container position-fixed p-3 top-0 end-0" v-if="showToast">
    <bs-toast v-for="toast in toastPropsList" :key="toast" :="toast"></bs-toast>
  </div>
</template>

<script>
import TrnLoading from "@/components/Loading";
import BsToast from "@/components/UI-elements/BsToast";
import { stringify } from "query-string";
import regulatoryCommissionsApi from "@/api/regulatory-commissions";

export default {
  name: "TrnRegulatoryCommissionsMerchant",
  components: {
    TrnLoading,
    BsToast,
  },
  data() {
    return {
      page: 0,
      size: 50,
      sort: {
        col: "merchantId",
        order: "desc",
      },
      dataResp: null,
      isLoading: false,
      isLoadingScroll: false,
      filters: {
        dateTransferred: "",
      },
      showToast: false,
      toastPropsList: [],
    };
  },
  methods: {
    fetchDataReport() {
      this.isLoading = true;
      this.changeArrowIcon();
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
        ...this.filters,
      });
      regulatoryCommissionsApi
        .getMerchant(stringifiedParams)
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          // console.log("result", result);
          if (result.status === 200) {
            this.dataResp = result.data;
            this.isLoading = false;
          } else {
            console.log("err");
            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.err`)}`,
              bgColor: "bg-danger",
              textColor: "text-white",
            });
            this.showToast = true;
            this.isLoading = false;
          }
        });
    },
    scrollTable(event) {
      const currentEl = event.target;
      let checkScrollEnd =
        currentEl.scrollTop + currentEl.clientHeight + 3 >= currentEl.scrollHeight;
      if (checkScrollEnd && this.isLoadingScroll === false) {
        const currentPage = this.page + 1;
        const totalPages = this.dataResp.totalPages;
        if (currentPage < totalPages) this.loadMoreData();
      }
    },
    loadMoreData() {
      this.isLoadingScroll = true;
      this.page += 1;
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
        ...this.filters,
      });
      regulatoryCommissionsApi
        .getMerchant(stringifiedParams)
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          if (result.status === 200) {
            for (let item in result.data.data.content) {
              this.dataResp.content.push(result.data.data.content[item]);
            }
            this.isLoadingScroll = false;
          } else {
            console.log("err");
            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.err`)}`,
              bgColor: "bg-danger",
              textColor: "text-white",
            });
            this.showToast = true;
            this.isLoadingScroll = false;
          }
        });
    },
    clickSortCol(event, newSortItem) {
      const newSortItemData = newSortItem;
      newSortItem = newSortItem.replaceAll(".", "-");
      const scrollTableEl = document.getElementById("table-block");
      const beforeSortKey = this.sort.col.replaceAll(".", "-");
      const beforeSortDirection = this.sort.order;

      const beforeEl = document.querySelectorAll(
        `[data-col-sort~=${beforeSortKey}]`
      );

      let child_index = 0;
      if (beforeEl[0].children.length > 1) {
        child_index = 1;
      }
      beforeEl[0].children[child_index].innerHTML =
        '<i class="bi bi-arrow-down-up"></i>';

      // let newSortVal = [newSortItem, ""];

      if (beforeSortDirection === "desc" && beforeSortKey === newSortItem) {
        this.sort.order = "asc";
      } else {
        this.sort.order = "desc";
      }
      this.page = 0;
      this.sort.col = newSortItemData;

      scrollTableEl.scrollTo(0, 0);
      this.fetchDataReport();
    },
    changeArrowIcon(sortKey = this.sort.col, sortDirection = this.sort.order) {
      sortKey = sortKey.replaceAll(".", "-");
      const targetEl = document.querySelectorAll(`[data-col-sort~=${sortKey}]`);
      let child_index = 0;
      if (targetEl[0].children.length > 1) {
        child_index = 1;
      }
      if (sortDirection === "asc") {
        targetEl[0].children[child_index].innerHTML =
          '<i class="bi bi-arrow-up-square"></i>';
      } else {
        targetEl[0].children[child_index].innerHTML =
          '<i class="bi bi-arrow-down-square"></i>';
      }
    },
    isTransferredIcon(val) {
      if (val) {
        return '<i class="bi bi-check fs-3 text-success"></i>';
      } else {
        return "is waiting";
      }
    },
  },
  mounted() {
    this.fetchDataReport();
  },
};
</script>

<style scoped></style>
