export const getItemLocalStorage = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (e) {
    console.error("Error in getting data from localStorage", e);
  }
};

export const setItemLocalStorage = (key, data) => {
  try {
    localStorage.setItem(key, JSON.stringify(data));
  } catch (e) {
    console.error("Error in setting data to localStorage", e);
  }
};
