<template>
  <div class="title-table mb-2 ps-4 py-1 fw-bold">
    {{ $t(`settings-menu.receiptTemplate`) }}
  </div>
  <!-- ToolBar -->
  <div class="toolbar">
    <div class="btn-group mb-2">
      <button
        class="btn btn-outline-primary btn-sm"
        type="button"
        @click="clickAddRT()"
      >
        <i class="bi bi-file-earmark-plus"></i>
        <span class="ms-1">{{ $t(`uiElements.btn.add`) }}</span>
      </button>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4">
        <div class="table-responsive table-block overflow-auto">
          <table class="table table-striped table-hover table-sm table-light">
            <thead class="">
              <tr>
                <th scope="col">
                  {{ $t(`Settings.ReceiptTemplate.table.number`) }}
                </th>
                <th scope="col">
                  {{ $t(`Settings.ReceiptTemplate.table.name`) }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="isLoading">
                <tr>
                  <td colspan="8">
                    <div class="loading text-center">
                      <trn-loading></trn-loading>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-if="dataResp">
                <tr
                  v-for="itemObj in dataResp.content"
                  :key="itemObj"
                  class="cursor-pointer"
                  :class="{
                    'table-primary': itemObj.id === this.id,
                  }"
                  @click="clickItemTemplate(itemObj)"
                >
                  <td>{{ itemObj.id }}</td>
                  <td>{{ itemObj.templateName }}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>

      <div class="col-md-8" v-if="showSelectBlockTemplateS">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home"
              type="button"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              {{ $t(`Settings.ReceiptTemplate.edit`) }}
            </button>
          </li>
          <li class="nav-item" role="presentation" v-show="!addForm">
            <button
              class="nav-link"
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
              @click="clickReceiptTemplatePreview"
            >
              {{ $t(`Settings.ReceiptTemplate.view`) }}
            </button>
          </li>
          <li class="nav-item position-absolute me-3 end-0" role="presentation">
            <button class="nav-link" id="contact-tab" @click="closeTabs">
              X
            </button>
          </li>
        </ul>
        <div class="tab-content mt-3" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div class="w-100">
              <form class="" @submit.prevent="submitFormUpdate">
                <div class="row">
                  <div class="col">
                    <div class="row mb-3">
                      <label
                        for="ff-templateName"
                        class="col-sm-2 col-form-label"
                        >{{ $t(`Settings.ReceiptTemplate.table.name`) }}</label
                      >
                      <div class="col-sm-10">
                        <input
                          type="text"
                          class="form-control"
                          :class="addClassValidInput('templateName')"
                          id="ff-templateName"
                          v-model.trim="formTemplate.templateName"
                          :placeholder="$t(`Validations.notChosen`)"
                        />
                        <div class="invalid-feedback">
                          <div
                            v-if="
                              v$.formTemplate.templateName.required.$invalid
                            "
                          >
                            {{ $t(`Validations.required`) }}
                          </div>
                          <div v-if="v$.formTemplate.templateName.reTextAndNum.$invalid">
                            {{ $t(`Validations.reTextAndNum`) }}
                          </div>
                          <div v-if="v$.formTemplate.templateName.maxLength.$invalid">
                            {{ $t(`Validations.maxLength`, { num: 255 }) }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <label for="ff-template" class="form-label">{{
                        $t(`Settings.ReceiptTemplate.table.template`)
                      }}</label>
                      <textarea
                        class="form-control"
                        :class="addClassValidInput('templateBody')"
                        id="ff-template"
                        rows="10"
                        v-model.trim="formTemplate.templateBody"
                        :placeholder="$t(`Validations.notChosen`)"
                      ></textarea>
                      <div class="invalid-feedback">
                        <div
                          v-if="v$.formTemplate.templateBody.required.$invalid"
                        >
                          {{ $t(`Validations.required`) }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="row mb-3">
                      <label for="ff-id" class="col-sm-2 col-form-label">{{
                        $t(`Settings.ReceiptTemplate.table.number`)
                      }}</label>
                      <div class="col-sm-10">
                        <input
                          type="text"
                          class="form-control"
                          id="ff-id"
                          v-model.trim="formTemplate.id"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="mb-3">
                      <label for="ff-style" class="form-label">{{
                        $t(`Settings.ReceiptTemplate.table.style`)
                      }}</label>
                      <textarea
                        class="form-control"
                        id="ff-style"
                        rows="10"
                        v-model.trim="formTemplate.templateStyle"
                        :placeholder="$t(`Validations.notChosen`)"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <button type="submit" class="btn btn-sm btn-success w-100 mt-3">
                  {{ $t(`uiElements.btn.save`) }}
                </button>
              </form>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <iframe
              frameborder="0"
              ref="preview_iframe"
              width="100%"
              height="500px"
              :srcdoc="receiptTemplatePreviewHtml"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="toast-container position-absolute p-3 end-0" v-if="showToast">
    <bs-toast v-for="toast in toastPropsList" :key="toast" :="toast"></bs-toast>
  </div>
</template>

<script>
import TrnLoading from "@/components/Loading";
import { actionTypes } from "@/store/modules/settings-store/receipt-remplate";
import receiptTemplateApi from "@/api/settings-api/receipt-remplate";
import { mapState } from "vuex";
import BsToast from "@/components/UI-elements/BsToast";
import { stringify } from "query-string";
import useVuelidate from "@vuelidate/core";
import {helpers, maxLength, required} from "@vuelidate/validators";

export default {
  name: "TrnReceiptTemplate",
  components: { BsToast, TrnLoading },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      page: 0,
      size: 100,
      sort: {
        col: "id",
        order: "asc",
      },
      id: "",
      showModal: false,
      showToast: false,
      showSelectBlockTemplateS: false,
      toastPropsList: [],
      formTemplate: {},
      receiptTemplatePreviewHtml: null,
      addForm: false,
    };
  },
  validations() {
    return {
      formTemplate: {
        id: {},
        templateName: {
          required,
          reTextAndNum: helpers.regex(/^[a-zA-Zа-яА-ЯіїґєІЇҐЄёЁ0-9\s]+$/u),
          maxLength: maxLength(255),
        },
        templateBody: {
          required,
        },
        templateStyle: {},
        receiptTemplatePreviewId: {},
      },
    };
  },
  methods: {
    fetchTemplate() {
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
        ...this.filters,
      });
      this.$store.dispatch(actionTypes.getReceiptTemplate, {
        apiParams: stringifiedParams,
      });
    },
    clickItemTemplate(items) {
      this.id = items.id;
      this.addForm = false;
      this.showSelectBlockTemplateS = true;

      this.formTemplate.id = items.id;
      this.formTemplate.templateName = items.templateName;
      this.formTemplate.templateBody = items.templateBody;
      this.formTemplate.templateStyle = items.templateStyle;
      this.formTemplate.receiptTemplatePreviewId =
        items.receiptTemplatePreviewId;
    },
    clickAddRT() {
      this.showSelectBlockTemplateS = true;
      this.addForm = true;
      this.formTemplate.id = "";
      this.formTemplate.templateBody = "";
      this.formTemplate.templateName = "";
      this.formTemplate.templateStyle = "";
    },
    closeTabs() {
      this.showSelectBlockTemplateS = false;
    },
    submitFormUpdate() {
      this.v$.formTemplate.$touch();
      if (!this.v$.formTemplate.$error) {
        const bodyReq = {
          templateBody: this.formTemplate.templateBody,
          templateName: this.formTemplate.templateName,
          templateStyle: this.formTemplate.templateStyle,
        };

        if (this.addForm) {
          receiptTemplateApi
            .addReceiptTemplate(bodyReq)
            .then(function (result) {
              return result;
            })
            .catch(function (result) {
              return result;
            })
            .then((result) => {
              // console.log("result", result);
              this.showToast = true;
              if (result.status === 200) {
                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.successSave`)}`,
                  bgColor: "bg-success",
                  textColor: "text-white",
                });
                this.fetchTemplate();
              } else {
                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.errSave`)}`,
                  bgColor: "bg-danger",
                  textColor: "text-white",
                });
              }
            });
        } else {
          receiptTemplateApi
            .updateReceiptTemplate(this.formTemplate.id, bodyReq)
            .then(function (result) {
              return result;
            })
            .catch(function (result) {
              return result;
            })
            .then((result) => {
              // console.log("result", result);
              this.showToast = true;
              if (result.status === 200) {
                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.successSave`)}`,
                  bgColor: "bg-success",
                  textColor: "text-white",
                });
                this.fetchTemplate();
              } else {
                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.errSave`)}`,
                  bgColor: "bg-danger",
                  textColor: "text-white",
                });
              }
            });
        }
      }
    },
    clickReceiptTemplatePreview() {
      this.receiptTemplatePreviewHtml = "...";
      if (this.formTemplate.id !== "") {
        receiptTemplateApi
          .getReceiptTemplatePreview(this.formTemplate.receiptTemplatePreviewId)
          .then(function (result) {
            return result;
          })
          .catch(function (result) {
            return result;
          })
          .then((result) => {
            // console.log("result", result);
            this.showToast = true;
            if (result.status === 200) {
              this.receiptTemplatePreviewHtml = result.data;
            } else {
              this.toastPropsList.push({
                content: `${this.$t(`uiElements.toast.err`)}`,
                bgColor: "bg-danger",
                textColor: "text-white",
              });
            }
          });
      } else {
        this.receiptTemplatePreviewHtml = "...";
      }
    },
    addClassValidInput(inputName) {
      return {
        "is-invalid":
          this.v$.formTemplate[inputName].$error === true &&
          this.v$.formTemplate[inputName].$dirty === true,
        "is-valid":
          this.v$.formTemplate[inputName].$error === false &&
          this.v$.formTemplate[inputName].$dirty === true,
      };
    },
  },
  computed: {
    dataResp: {
      get() {
        return this.$store.state.receiptTemplate.dataResp;
      },
      set(newValue) {
        this.$store.state.receiptTemplate.dataResp = newValue;
      },
    },
    ...mapState({
      isLoading: (state) => state.receiptTemplate.isLoading,
      // isLoadingScroll: (state) => state.transactions.isLoadingScroll,
      // dataResp: (state) => state.transactions.dataResp,
      dataModalResp: (state) => state.receiptTemplate.dataModalResp,
      errorResp: (state) => state.receiptTemplate.errorResp,
    }),
  },
  mounted() {
    this.fetchTemplate();
  },
};
</script>

<style scoped></style>
