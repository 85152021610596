import ipsKeyApi from "@/api/settings-api/ips-key";

const state = {
  dataResp: null,
  dataModalResp: null,

  isLoading: false,
  isLoadingScroll: false,
  isLoadingModal: false,

  errorResp: null,
};

export const mutationTypes = {
  getIpsKeyStart: "[IpsKey] getIpsKey start",
  getIpsKeySuccess: "[IpsKey] getIpsKey success",
  getIpsKeyFailure: "[IpsKey] getIpsKey failure",

  getIpsKeyScrollPushStart: "[IpsKey] getIpsKeyScrollPush start",
  getIpsKeyScrollPushSuccess: "[IpsKey] getIpsKeyScrollPushSuccess success",
  getIpsKeyScrollPushFailure: "[IpsKey] getIpsKeyScrollPush failure",
};

export const actionTypes = {
  getIpsKey: "[IpsKey] getIpsKey",
  getIpsKeyScrollPush: "[IpsKey] getIpsKeyScrollPush",
};

const mutations = {
  [mutationTypes.getIpsKeyStart](state) {
    state.isLoading = true;
    state.data = null;
  },
  [mutationTypes.getIpsKeySuccess](state, payload) {
    state.isLoading = false;
    state.dataResp = payload;
  },
  [mutationTypes.getIpsKeyFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
  [mutationTypes.getIpsKeyScrollPushStart](state) {
    state.isLoadingScroll = true;
  },
  [mutationTypes.getIpsKeyScrollPushSuccess](state, payload) {
    for (let item in payload.content) {
      state.dataResp.content.push(payload.content[item]);
    }
    state.isLoadingScroll = false;
  },
  [mutationTypes.getIpsKeyScrollPushFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
};

const actions = {
  [actionTypes.getIpsKey](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getIpsKeyStart, apiParams);
      ipsKeyApi
        .getIpsKey(apiParams)
        .then((response) => {
          context.commit(mutationTypes.getIpsKeySuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getIpsKeyFailure,
            result.response.data.error
          );
        });
    });
  },
  [actionTypes.getIpsKeyScrollPush](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getIpsKeyScrollPushStart, apiParams);
      ipsKeyApi
        .getIpsKey(apiParams)
        .then((response) => {
          context.commit(
            mutationTypes.getIpsKeyScrollPushSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getIpsKeyScrollPushFailure,
            result.response.data
          );
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
