<template>
  <div class="title-table mb-2 ps-3 py-1 fw-bold">
    {{ $t(`left-menu.merchant`) }}
  </div>
  <!-- Фільтр -->
  <div class="toolbar">
    <div class="btn-group mb-2">
      <button
        class="btn btn-outline-primary btn-sm dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        data-bs-auto-close="outside"
        aria-expanded="false"
        ref="dropdownBtnFilter"
      >
        <i class="bi bi-filter-square me-2"></i>
        <span>{{ $t(`uiElements.btn.filters`) }}</span>
      </button>

      <form
        class="dropdown-menu p-4"
        style="width: 500px"
        @submit.prevent="formFilterRm"
        ref="dropdownFormFilter"
      >
        <div class="row mb-3">
          <label
            for="merchantId-ff"
            class="col-sm-4 col-form-label col-form-label-sm"
            >{{ $t(`Merchant.table.merchantId`) }}</label
          >
          <div class="col-sm-8">
            <input
              type="text"
              class="form-control form-control-sm"
              id="merchantId-ff"
              v-model.trim="filters.merchantId"
              :placeholder="$t(`Validations.notChosen`)"
            />
          </div>
        </div>

        <div class="row mb-3">
          <label
            for="merchantName-ff"
            class="col-sm-4 col-form-label col-form-label-sm"
            >{{ $t(`Merchant.table.merchantName`) }}</label
          >
          <div class="col-sm-8">
            <input
              v-model.trim="filters.merchantName"
              type="text"
              class="form-control form-control-sm"
              id="merchantName-ff"
              :placeholder="$t(`Validations.notChosen`)"
            />
          </div>
        </div>

        <div class="row mb-3">
          <label
            for="merchantLocation-ff"
            class="col-sm-4 col-form-label col-form-label-sm"
            >{{ $t(`Merchant.table.merchantLocation`) }}</label
          >
          <div class="col-sm-8">
            <input
              v-model.trim="filters.merchantLocation"
              type="text"
              class="form-control form-control-sm"
              id="merchantLocation-ff"
              :placeholder="$t(`Validations.notChosen`)"
            />
          </div>
        </div>

        <div class="row mb-3">
          <label
            for="taxId-ff"
            class="col-sm-4 col-form-label col-form-label-sm"
            >{{ $t(`Merchant.table.taxId`) }}</label
          >
          <div class="col-sm-8">
            <input
              v-model.trim="filters.taxId"
              type="text"
              class="form-control form-control-sm"
              id="taxId-ff"
              :placeholder="$t(`Validations.notChosen`)"
            />
          </div>
        </div>

        <div class="row mb-3">
          <label
            for="mcc-ff"
            class="col-sm-4 col-form-label col-form-label-sm"
            >{{ $t(`Merchant.table.mcc`) }}</label
          >
          <div class="col-sm-8">
            <input
              v-model.trim="filters.mcc"
              type="text"
              class="form-control form-control-sm"
              id="mcc-ff"
              :placeholder="$t(`Validations.notChosen`)"
            />
          </div>
        </div>

        <div class="row mb-3">
          <label
            for="merchantLegalName-ff"
            class="col-sm-4 col-form-label col-form-label-sm"
            >{{ $t(`Merchant.table.merchantLegalName`) }}</label
          >
          <div class="col-sm-8">
            <input
              v-model.trim="filters.merchantLegalName"
              type="text"
              class="form-control form-control-sm"
              id="merchantLegalName-ff"
              :placeholder="$t(`Validations.notChosen`)"
            />
          </div>
        </div>

        <div class="row mb-3" v-if="dataRespBanks">
          <label
            for="bankName-ff"
            class="col-sm-4 col-form-label col-form-label-sm"
            >{{ $t(`Merchant.table.bankName`) }}</label
          >
          <div class="col-sm-8">
            <select
              class="form-select"
              id="bankName-ff-s"
              v-model="filters['bankId']"
            >
              <option value="">{{ $t(`Validations.notChosen`) }}</option>
              <option
                v-for="item in dataRespBanks.content"
                :key="item"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="row mb-3">
          <label
            for="terminalId-ff"
            class="col-sm-4 col-form-label col-form-label-sm"
            >{{ $t(`Merchant.table.terminalId`) }}</label
          >
          <div class="col-sm-8">
            <input
              type="text"
              class="form-control form-control-sm"
              id="terminalId-ff"
              v-model.trim="filters.terminalId"
              :placeholder="$t(`Validations.notChosen`)"
            />
          </div>
        </div>

        <div class="justify-content-end d-flex">
          <button
            class="btn btn-outline-primary btn-sm me-3"
            type="button"
            @click="resetFilterForm"
          >
            <i class="bi bi-x-square me-1"></i>
            <span>{{ $t(`uiElements.btn.resetFilters`) }}</span>
          </button>
          <button type="submit" class="btn btn-primary btn-sm">
            <i class="bi bi-clipboard-check me-1"></i>
            <span>{{ $t(`uiElements.btn.apply`) }}</span>
          </button>
        </div>
      </form>
    </div>
  </div>

  <!-- Фільтр кінець -->

  <div
    class="table-responsive table-block overflow-auto"
    id="table-block"
    @scroll="scrollTable"
  >
    <table class="table table-striped table-hover table-sm table-light">
      <thead>
        <tr class="text-center">
          <th
            scope="col"
            data-col-sort="merchantId"
            @click="clickSortCol($event, 'merchantId')"
          >
            {{ $t(`Merchant.table.merchantId`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="merchantName"
            @click="clickSortCol($event, 'merchantName')"
          >
            {{ $t(`Merchant.table.merchantName`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="merchantLocation"
            @click="clickSortCol($event, 'merchantLocation')"
          >
            {{ $t(`Merchant.table.merchantLocation`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="taxId"
            @click="clickSortCol($event, 'taxId')"
          >
            {{ $t(`Merchant.table.taxId`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="mcc"
            @click="clickSortCol($event, 'mcc')"
          >
            {{ $t(`Merchant.table.mcc`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="merchantLegalName"
            @click="clickSortCol($event, 'merchantLegalName')"
          >
            {{ $t(`Merchant.table.merchantLegalName`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th scope="col" data-col-sort="bankName">
            {{ $t(`Merchant.table.bankName`) }}
            <div class="icon-sort"><i class="bi bi-app"></i></div>
          </th>
          <th scope="col" data-col-sort="iban" v-if="includeServiceOfFata">
            {{ $t(`Merchant.table.iban`) }}
            <div class="icon-sort"><i class="bi bi-app"></i></div>
          </th>
        </tr>
      </thead>
      <tbody class="text-nowrap">
        <template v-if="isLoading">
          <tr>
            <td colspan="16">
              <div class="loading text-center">
                <trn-loading></trn-loading>
              </div>
            </td>
          </tr>
        </template>
        <template v-if="dataResp">
          <tr
            v-for="item in dataResp.content"
            :key="item"
            class="text-center cursor-pointer"
            @click="showUpdateMIDForm(item)"
          >
            <td>{{ item.merchantId }}</td>
            <td>{{ item.merchantName }}</td>
            <td>{{ item.merchantLocation }}</td>
            <td>{{ item.taxId }}</td>
            <td>{{ item.mcc }}</td>
            <td>{{ item.merchantLegalName }}</td>
            <td>{{ item.bank.name }}</td>
            <td v-if="includeServiceOfFata">
              {{ item.iban ? item.iban : "-" }}
            </td>
          </tr>
          <template v-if="isLoadingScroll">
            <tr>
              <td colspan="16">
                <div class="loading text-center">
                  <trn-loading></trn-loading>
                </div>
              </td>
            </tr>
          </template>
        </template>
        <template v-if="dataResp?.content.length === 0">
          <tr>
            <td colspan="16">
              <div class="text-center fw-bold bg-info text-black">
                {{ $t(`uiElements.text.noData`) }}
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>

  <bs-modal
    :modal-toggle-emit="showModal"
    :title="titleModal"
    @hideMainModal="showModal = false"
  >
    <template v-if="typeModal === 'update'">
      <form class="needs-validation" @submit.prevent="formUpdateMID" novalidate>
        <!--  input-merchantName  -->
        <div class="row mb-3">
          <label for="input-merchantName" class="col-sm-6 col-form-label">
            {{ $t(`MerchantRegistration.table.merchantName`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              :class="addClassValidInput('merchantName')"
              id="input-merchantName"
              v-model="v$.formMerchantUpdate.merchantName.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formMerchantUpdate.merchantName.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formMerchantUpdate.merchantName.maxLength.$invalid">
                {{ $t(`Validations.maxLength`, { num: 25 }) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-merchantLocation  -->
        <div class="row mb-3">
          <label for="input-merchantLocation" class="col-sm-6 col-form-label">
            {{ $t(`MerchantRegistration.table.merchantLocation`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              :class="addClassValidInput('merchantLocation')"
              id="input-merchantLocation"
              v-model="v$.formMerchantUpdate.merchantLocation.$model"
            />
            <div class="invalid-feedback">
              <div
                v-if="v$.formMerchantUpdate.merchantLocation.required.$invalid"
              >
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formMerchantUpdate.merchantLocation.regex.$invalid">
                {{
                  $t(
                    `MerchantRegistration.validationRegForm.merchantLocationRegEx`
                  )
                }}
              </div>
              <div
                v-if="v$.formMerchantUpdate.merchantLocation.maxLength.$invalid"
              >
                {{ $t(`Validations.maxLength`, { num: 50 }) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-taxId  -->
        <div class="row mb-3">
          <label for="input-taxId" class="col-sm-6 col-form-label">
            {{ $t(`MerchantRegistration.table.taxId`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="number"
              class="form-control"
              :class="addClassValidInput('taxId')"
              id="input-taxId"
              v-model="v$.formMerchantUpdate.taxId.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formMerchantUpdate.taxId.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formMerchantUpdate.taxId.maxLength.$invalid">
                {{ $t(`Validations.maxLength`, { num: 10 }) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-mcc  -->
        <div class="row mb-3">
          <label for="input-mcc" class="col-sm-6 col-form-label">
            {{ $t(`MerchantRegistration.table.mcc`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="number"
              class="form-control"
              :class="addClassValidInput('mcc')"
              id="input-mcc"
              v-model="v$.formMerchantUpdate.mcc.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formMerchantUpdate.mcc.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formMerchantUpdate.mcc.mccCheck.$invalid">
                {{ $t(`MerchantRegistration.validationRegForm.mccRegex`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-merchantLegalName  -->
        <div class="row mb-3">
          <label for="input-merchantLegalName" class="col-sm-6 col-form-label">
            {{ $t(`MerchantRegistration.table.merchantLegalName`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              :class="addClassValidInput('merchantLegalName')"
              id="input-merchantLegalName"
              v-model="v$.formMerchantUpdate.merchantLegalName.$model"
            />
            <div class="invalid-feedback">
              <div
                v-if="v$.formMerchantUpdate.merchantLegalName.required.$invalid"
              >
                {{ $t(`Validations.required`) }}
              </div>
              <div
                v-if="
                  v$.formMerchantUpdate.merchantLegalName.maxLength.$invalid
                "
              >
                {{ $t(`Validations.maxLength`, { num: 30 }) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-iban  -->
        <div class="row mb-3" v-show="includeServiceOfFata">
          <label for="input-iban" class="col-sm-6 col-form-label">
            {{ $t(`MerchantRegistration.table.iban`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              :class="addClassValidInput('iban')"
              id="input-iban"
              v-model="v$.formMerchantUpdate.iban.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formMerchantUpdate.iban.requiredIf.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formMerchantUpdate.iban.regex.$invalid">
                {{ $t(`MerchantRegistration.validationRegForm.ibanRegex`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  Buttons form  -->
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-outline-secondary me-3"
            @click="showModal = false"
          >
            {{ $t(`uiElements.btn.close`) }}
          </button>
          <button
            type="submit"
            class="btn btn-outline-primary"
            :disabled="v$.$invalid && v$.$dirty ? true : false"
          >
            {{ $t(`uiElements.btn.save`) }}
          </button>
        </div>
      </form>
    </template>
  </bs-modal>
  <div
    class="toast-container position-absolute p-3 end-0 z-index-1500"
    v-if="showToast"
  >
    <bs-toast v-for="toast in toastPropsList" :key="toast" :="toast"></bs-toast>
  </div>
</template>

<script>
import TrnLoading from "@/components/Loading";
import BsModal from "@/components/UI-elements/BsModal";
import { mapState } from "vuex";
import { stringify } from "query-string";
import { actionTypes } from "@/store/modules/merchant";
import {
  helpers,
  maxLength,
  required,
  requiredIf,
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import merchantApi from "@/api/merchant";
import BsToast from "@/components/UI-elements/BsToast";
import { getItemLocalStorage } from "@/helpers/persistanceStorage";

export default {
  name: "TrnMerchant",
  components: {
    TrnLoading,
    BsModal,
    BsToast,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      page: 0,
      size: 100,
      sort: {
        col: "merchantId",
        order: "desc",
      },
      filters: {
        merchantId: "",
        merchantName: "",
        merchantLocation: "",
        taxId: "",
        mcc: "",
        merchantLegalName: "",
        bankId: "",
      },
      bank: {
        name: "",
      },
      showModal: false,
      typeModal: "",
      titleModal: "",
      formMerchantUpdate: {},
      showToast: false,
      toastPropsList: [],
      includeServiceOfFata: null,
      dataRespBanks: null,
    };
  },
  validations() {
    return {
      formMerchantUpdate: {
        merchantName: {
          required,
          maxLength: maxLength(25),
        },
        merchantLocation: {
          required,
          regex: helpers.regex(/^[\\s,./a-zA-Zа-яёА-ЯЁїЇєЄіІҐґ0-9 -]+$/),
          maxLength: maxLength(50),
        },
        merchantLegalName: {
          required,
          maxLength: maxLength(30),
        },
        taxId: {
          required,
          maxLength: maxLength(20),
        },
        mcc: {
          required,
          mccCheck: function (val) {
            return val > 1000 && val < 10000;
          },
        },
        iban: {
          regex: helpers.regex(/^UA\d{8}[A-Z0-9]{19}$/), // IBAN тільки під Україну
          requiredIf: requiredIf(this.includeServiceOfFata),
        },
      },
    };
  },
  computed: {
    dataResp: {
      get() {
        return this.$store.state.merchant.dataResp;
      },
      set(newValue) {
        this.$store.state.merchant.dataResp = newValue;
      },
    },
    ...mapState({
      isLoading: (state) => state.merchant.isLoading,
      isLoadingScroll: (state) => state.merchant.isLoadingScroll,
      dataModalResp: (state) => state.merchant.dataModalResp,
      errorResp: (state) => state.merchant.errorResp,
    }),
  },
  methods: {
    fetchMerchants() {
      this.changeArrowIcon();
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
        ...this.filters,
      });
      this.$store.dispatch(actionTypes.getMerchant, {
        apiParams: stringifiedParams,
      });
    },
    scrollTable(event) {
      const currentEl = event.target;
      let checkScrollEnd =
        currentEl.scrollTop + currentEl.clientHeight + 3 >=
        currentEl.scrollHeight;
      if (checkScrollEnd && this.isLoadingScroll === false) {
        const currentPage = this.page + 1;
        const totalPages = this.dataResp.totalPages;
        if (currentPage < totalPages) this.loadMoreData();
      }
    },
    loadMoreData() {
      this.page += 1;
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
      });
      this.$store.dispatch(actionTypes.getMerchantScrollPush, {
        apiParams: stringifiedParams,
      });
    },
    reloadPage() {
      this.page = 0;
      this.fetchMerchants();
    },
    clickSortCol(event, newSortItem) {
      const scrollTableEl = document.getElementById("table-block");
      const beforeSortKey = this.sort.col;
      const beforeSortDirection = this.sort.order;

      const beforeEl = document.querySelectorAll(
        `[data-col-sort~=${beforeSortKey}]`
      );
      beforeEl[0].children[0].innerHTML = '<i class="bi bi-arrow-down-up"></i>';

      // let newSortVal = [newSortItem, ""];

      if (beforeSortDirection === "desc" && beforeSortKey === newSortItem) {
        this.sort.order = "asc";
      } else {
        this.sort.order = "desc";
      }
      this.page = 0;
      this.sort.col = newSortItem;
      scrollTableEl.scrollTo(0, 0);
      this.fetchMerchants();
    },
    changeArrowIcon(sortKey = this.sort.col, sortDirection = this.sort.order) {
      const targetEl = document.querySelectorAll(`[data-col-sort~=${sortKey}]`);
      if (sortDirection === "asc") {
        targetEl[0].children[0].innerHTML =
          '<i class="bi bi-arrow-up-square"></i>';
      } else {
        targetEl[0].children[0].innerHTML =
          '<i class="bi bi-arrow-down-square"></i>';
      }
    },
    formFilterRm() {
      this.$refs.dropdownBtnFilter.click();
      this.fetchMerchants();
    },
    resetFilterForm() {
      for (let item in this.filters) {
        this.filters[item] = "";
      }
      this.$refs.dropdownBtnFilter.click();
      this.fetchMerchants();
    },
    showUpdateMIDForm(rowItem) {
      this.showModal = !this.showModal;
      this.typeModal = "update";
      this.titleModal = rowItem.merchantId;
      this.formMerchantUpdate.merchantName = rowItem.merchantName;
      this.formMerchantUpdate.merchantLocation = rowItem.merchantLocation;
      this.formMerchantUpdate.merchantLegalName = rowItem.merchantLegalName;
      this.formMerchantUpdate.taxId = rowItem.taxId;
      this.formMerchantUpdate.mcc = rowItem.mcc;
      this.formMerchantUpdate.iban = rowItem.iban;
    },
    addClassValidInput(inputName) {
      return {
        "is-invalid":
          this.v$.formMerchantUpdate[inputName].$error === true &&
          this.v$.formMerchantUpdate[inputName].$dirty === true,
        "is-valid":
          this.v$.formMerchantUpdate[inputName].$error === false &&
          this.v$.formMerchantUpdate[inputName].$dirty === true,
      };
    },
    formUpdateMID() {
      this.v$.formMerchantUpdate.$touch();
      if (!this.v$.formMerchantUpdate.$error) {
        merchantApi
          .updateMerchant(this.titleModal, {
            merchantName: this.formMerchantUpdate.merchantName,
            merchantLocation: this.formMerchantUpdate.merchantLocation,
            merchantLegalName: this.formMerchantUpdate.merchantLegalName,
            taxId: this.formMerchantUpdate.taxId,
            mcc: this.formMerchantUpdate.mcc,
            iban: this.formMerchantUpdate.iban,
          })
          .then(function (result) {
            return result;
          })
          .catch(function (result) {
            return result;
          })
          .then((result) => {
            // console.log("result", result);
            if (result.status === 200) {
              this.showModal = false;

              this.toastPropsList.push({
                content: `${this.$t(`uiElements.toast.successSave`)}`,
                bgColor: "bg-success",
                textColor: "text-white",
              });
              this.showToast = true;

              this.fetchMerchants();
            } else {
              console.log("err");
              this.toastPropsList.push({
                content: `${this.$t(`uiElements.toast.errSave`)}`,
                bgColor: "bg-danger",
                textColor: "text-white",
              });
              this.showToast = true;
            }
          });
      }
    },
    fetchBanks() {
      const stringifiedParams = stringify({
        size: 100,
      });
      merchantApi
        .getBanks(stringifiedParams)
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          // console.log("result", result);
          if (result.status === 200) {
            this.dataRespBanks = result.data;
            // console.log(this.dataRespBanks);
          } else {
            console.log("err");
            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.err`)}`,
              bgColor: "bg-danger",
              textColor: "text-white",
            });
            this.showToast = true;
          }
        });
    },
  },
  mounted() {
    this.fetchBanks();
    this.includeServiceOfFata = getItemLocalStorage("isIncludeServiceOfFata");
    this.fetchMerchants();
  },
};
</script>

<style scoped></style>
