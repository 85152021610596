<template>
  <!-- ToolBar -->
  <div class="toolbar">
    <div class="btn-group my-2">
      <button
        class="btn btn-outline-primary btn-sm"
        type="button"
        @click="clickShowProfileForm('add', '')"
      >
        <i class="bi bi-file-earmark-plus"></i>
        <span class="ms-1">{{
          $t(`Settings.TerminalFacility.btn.addProfile`)
        }}</span>
      </button>
    </div>
  </div>
  <!-- ToolBar кінець -->

  <div class="">
    <div class="row g-0 me-1" v-if="dataResp">
      <div class="col">
        <div class="container-data" v-for="item in dataResp.data" :key="item">
          <div
            class="merchant-row d-flex justify-content-between my-3 bg-gray text-dark ps-3 rounded-2 fw-bold"
          >
            <div class="left-block">
              <span>ID: {{ item.id }} | </span>
              <span>{{ item.name }}</span>
            </div>
            <div class="right-block">
              <div class="btn-group">
                <button
                  class="btn btn-outline-primary btn-sm"
                  type="button"
                  @click="clickShowProfileForm('update', item)"
                >
                  <i class="bi bi-pencil-square"></i>
                  <span class="ms-1">{{ $t(`uiElements.btn.change`) }}</span>
                </button>
                <button
                  class="btn btn-outline-danger btn-sm"
                  type="button"
                  @click="clickShowDeleteProfileForm('delete', item)"
                >
                  <i class="bi bi-x-square"></i>
                  <span class="ms-1">{{ $t(`uiElements.btn.delete`) }}</span>
                </button>
              </div>
            </div>
          </div>
          <div
            class="table-responsive table-block overflow-auto border border-2 border-secondary p-1 rounded-2 mx-2"
            id="table-block"
            v-if="item.facilities"
          >
            <table
              class="table table-striped table-hover table-sm table-light fs-6"
            >
              <thead>
                <tr class="">
                  <th scope="col">
                    {{ $t(`Settings.TerminalFacility.table.id`) }}
                  </th>
                  <th scope="col">
                    {{ $t(`Settings.TerminalFacility.table.name`) }}
                  </th>
                  <th scope="col">
                    {{ $t(`Settings.TerminalFacility.table.description`) }}
                  </th>
                  <th scope="col">
                    {{ $t(`Settings.TerminalFacility.table.type`) }}
                  </th>
                </tr>
              </thead>
              <tbody class="text-nowrap">
                <tr
                  v-for="itemTable in item.facilities"
                  :key="itemTable"
                  class=""
                >
                  <td>{{ itemTable.id }}</td>
                  <td>{{ itemTable.name }}</td>
                  <td>{{ itemTable.description }}</td>
                  <td>{{ itemTable.type }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="w-100 text-center my-3 border border-2 border-secondary p-1 rounded-2"
            v-else
          >
            {{ $t(`Settings.TerminalFacility.table.noTariffProfileData`) }}
          </div>
        </div>
      </div>
    </div>
    <div class="w-100 text-center my-3" v-else>
      <trn-loading></trn-loading>
    </div>
  </div>

  <bs-modal
    :modal-toggle-emit="showModal"
    :title="titleModal"
    @hideMainModal="showModal = false"
  >
    <!--  template change or add Profile  -->
    <template v-if="showFormGroup === 'changeOrAdd' && dataFacilityObj">
      <form
        class="needs-validation"
        @submit.prevent="submitProfileForm"
        novalidate
      >
        <!--  input-name  -->
        <div class="row mb-3">
          <label for="input-name" class="col-sm-6 col-form-label">
            {{ $t(`Settings.TerminalFacility.table.name`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              :class="addClassValidInput('name')"
              id="input-name"
              v-model="v$.formFacilityGroup.name.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formFacilityGroup.name.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formFacilityGroup.name.reTextAndNum.$invalid">
                {{ $t(`Validations.reTextAndNum`) }}
              </div>
              <div v-if="v$.formFacilityGroup.name.maxLength.$invalid">
                {{ $t(`Validations.maxLength`, { num: 255 }) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-description  -->
        <div class="row mb-3">
          <label for="input-description" class="col-sm-6 col-form-label">
            {{ $t(`Settings.TerminalFacility.table.description`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <textarea
              rows="3"
              class="form-control"
              :class="addClassValidInput('description')"
              id="input-description"
              v-model="v$.formFacilityGroup.description.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formFacilityGroup.description.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div
                v-if="v$.formFacilityGroup.description.reTextAndNumDot.$invalid"
              >
                {{ $t(`Validations.reTextAndNumDot`) }}
              </div>
              <div v-if="v$.formFacilityGroup.description.maxLength.$invalid">
                {{ $t(`Validations.maxLength`, { num: 255 }) }}
              </div>
            </div>
          </div>
        </div>

        <!-- Rows Select Tariffs  -->
        <div class="w-100">
          {{ $t(`Settings.TerminalFacility.modal.choiceTariffByType`) }}:
        </div>
        <template v-if="formFacilityGroup.operationType">
          <div
            class="row mb-3"
            v-for="(item, index) in this.dataFacilityByOpType"
            :key="item"
          >
            <label
              for="input-loyaltyPeriodType"
              class="col-sm-6 col-form-label"
            >
              <em
                ><u>{{ index }}</u></em
              >
            </label>
            <div class="col-sm-6">
              <select
                class="form-select"
                id="input-opManual"
                v-model="formFacilityGroup.operationType[index]"
              >
                <option
                  :value="itemTariff.id"
                  v-for="itemTariff in this.dataFacilityByOpType[index]"
                  :key="itemTariff"
                >
                  {{ itemTariff.id }} | {{ itemTariff.name }} |
                  {{ itemTariff.description }}
                </option>
              </select>
            </div>
          </div>
        </template>

        <!--  Buttons form  -->
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-outline-secondary me-3"
            @click="showModal = false"
          >
            {{ $t(`uiElements.btn.close`) }}
          </button>
          <button
            type="submit"
            class="btn btn-outline-primary"
            v-if="!btnLoadingSave"
            :disabled="
              v$.formFacilityGroup.$invalid && v$.formFacilityGroup.$dirty
                ? true
                : false
            "
          >
            {{ $t(`uiElements.btn.save`) }}
          </button>
          <button type="submit" class="btn btn-outline-primary" v-else disabled>
            <span class="spinner-border spinner-border-sm"></span>
            {{ $t(`uiElements.btn.save`) }}
          </button>
        </div>
      </form>
    </template>

    <!--  template delete Profile  -->
    <template v-else-if="showFormGroup === 'delete' && dataFacilityObj">
      <form
        class="needs-validation"
        @submit.prevent="submitDeleteProfileForm"
        novalidate
      >
        <!--  input-name  -->
        <div class="row mb-3">
          <label for="input-name" class="col-sm-6 col-form-label">
            {{ $t(`Settings.TerminalFacility.modal.deleteId`) }}
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              id="input-name"
              v-model="v$.formDeleteFacilityGroup.deleteId.$model"
              disabled
            />
            <div class="invalid-feedback">
              <div v-if="v$.formDeleteFacilityGroup.deleteId.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!-- Rows Select profile  -->
        <template v-if="dataTransferFacilityObj">
          <div class="row mb-3">
            <label for="input-trProfile" class="col-sm-6 col-form-label">
              {{ $t(`Settings.TerminalFacility.modal.choiceProfileTransfer`) }}
            </label>
            <div class="col-sm-6">
              <select
                class="form-select"
                id="input-opManual"
                v-model="formDeleteFacilityGroup.transferId"
              >
                <option
                  :value="itemTariff.id"
                  v-for="itemTariff in this.dataTransferFacilityObj"
                  :key="itemTariff"
                >
                  {{ itemTariff.id }} | {{ itemTariff.name }}
                </option>
              </select>
            </div>
          </div>
        </template>

        <!--  Buttons form  -->
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-outline-secondary me-3"
            @click="showModal = false"
          >
            {{ $t(`uiElements.btn.close`) }}
          </button>
          <button
            type="submit"
            class="btn btn-outline-primary"
            v-if="!btnLoadingSave"
            :disabled="
              v$.formDeleteFacilityGroup.$invalid &&
              v$.formDeleteFacilityGroup.$dirty
                ? true
                : false
            "
          >
            {{ $t(`uiElements.btn.save`) }}
          </button>
          <button type="submit" class="btn btn-outline-primary" v-else disabled>
            <span class="spinner-border spinner-border-sm"></span>
            {{ $t(`uiElements.btn.save`) }}
          </button>
        </div>
      </form>
    </template>
    <template v-else>
      <div class="w-100 text-center my-3">
        <trn-loading></trn-loading>
      </div>
    </template>
  </bs-modal>

  <div
    class="toast-container position-absolute p-3 top-0 end-0 z-index-1500"
    v-if="showToast"
  >
    <bs-toast v-for="toast in toastPropsList" :key="toast" :="toast"></bs-toast>
  </div>
</template>

<script>
import settingTerminalFacilityApi from "@/api/settings-api/terminal-facility";
import TrnLoading from "@/components/Loading";
import BsModal from "@/components/UI-elements/BsModal";
import BsToast from "@/components/UI-elements/BsToast";
import { maska } from "maska";
import useVuelidate from "@vuelidate/core";
import { helpers, maxLength, required } from "@vuelidate/validators";
import { stringify } from "query-string";

export default {
  name: "TrnTerminalFacilityTabProfiles",
  components: {
    TrnLoading,
    BsModal,
    BsToast,
  },
  directives: { maska },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      originType: "FULL_FACILITY",
      dataResp: null,
      isLoading: false,
      dateFormat: "dd/MM/yyyy",
      showToast: false,
      toastPropsList: [],
      showModal: false,
      titleModal: "",
      showFormGroup: "",
      formFacilityGroup: {},
      dataFacilityObj: null,
      dataFacilityByOpType: {},
      changeFacilityGroupObj: {},
      typeFormFacilityGroup: "",
      formDeleteFacilityGroup: {},
      dataTransferFacilityObj: null,
      btnLoadingSave: false,
    };
  },
  validations() {
    return {
      formFacilityGroup: {
        name: {
          required,
          reTextAndNum: helpers.regex(/^[a-zA-Zа-яА-ЯіїґєІЇҐЄёЁ0-9\s]+$/u), // input expression is only Latin, Cyrillic and numbers.
          maxLength: maxLength(255),
        },
        description: {
          required,
          reTextAndNumDot: helpers.regex(
            /^[a-zA-Zа-яА-ЯіїґєІЇҐЄёЁ0-9\s.\-,]+$/u
          ),
          maxLength: maxLength(255),
        },
      },
      formDeleteFacilityGroup: {
        deleteId: {
          required,
        },
        transferId: {
          required,
        },
      },
    };
  },
  methods: {
    fetchTerminalGroup() {
      this.isLoading = true;

      settingTerminalFacilityApi
        .getTerminalGroupFacility()
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          // console.log("result", result);
          if (result.status === 200) {
            this.dataResp = result.data;
            this.isLoading = false;
          } else {
            console.log("err");
            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.errSave`)}`,
              bgColor: "bg-danger",
              textColor: "text-white",
            });
            this.showToast = true;
            this.isLoading = false;
          }
        });
    },
    fetchFacility() {
      const stringifiedParams = stringify({
        originType: this.originType,
      });
      settingTerminalFacilityApi
        .getTerminalFacilityParams(stringifiedParams)
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          // console.log("result", result);
          if (result.status === 200) {
            this.dataFacilityObj = result.data;
            if (!this.formFacilityGroup.operationType) {
              this.formFacilityGroup.operationType = {};
            }
            for (let operationType in result.data.allOperationTypes) {
              const opType = result.data.allOperationTypes[operationType];
              this.dataFacilityByOpType[opType] = [];
              if (!this.formFacilityGroup.operationType[opType]) {
                this.formFacilityGroup.operationType[opType] = "";
              }
              for (let item in result.data.data) {
                if (result.data.data[item].type === opType) {
                  this.dataFacilityByOpType[opType].push(
                    result.data.data[item]
                  );
                }
              }
            }
          } else {
            console.log("err");
            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.err`)}`,
              bgColor: "bg-danger",
              textColor: "text-white",
            });
            this.showToast = true;
          }
        });
    },
    clickShowProfileForm(type, profileTarget) {
      this.formFacilityGroup = {};
      this.typeFormFacilityGroup = type;
      this.fetchFacility();
      this.titleModal = this.$t(`Settings.TerminalFacility.btn.addProfile`);
      this.showModal = true;
      this.showFormGroup = "changeOrAdd";
      if (type === "update") {
        this.changeFacilityGroupObj = profileTarget;
        this.titleModal =
          this.$t(`Settings.TerminalFacility.btn.updateProfile`) +
          profileTarget.id;
        this.formFacilityGroup.name = profileTarget.name;

        this.formFacilityGroup.description = profileTarget.description;
        if (profileTarget.facilities) {
          if (!this.formFacilityGroup.operationType) {
            this.formFacilityGroup.operationType = {};
          }
          for (let index in profileTarget.facilities) {
            const item = profileTarget.facilities[index];
            this.formFacilityGroup.operationType[item.type] = item.id;
          }
        }
      }
    },
    submitProfileForm() {
      this.v$.formFacilityGroup.$touch();
      if (!this.v$.formFacilityGroup.$error) {
        this.btnLoadingSave = true;
        let formFacilityGroupBody = {};
        formFacilityGroupBody.name = this.formFacilityGroup.name;
        formFacilityGroupBody.description = this.formFacilityGroup.description;

        let facilitiesIds = [];
        for (let item in this.formFacilityGroup.operationType) {
          if (this.formFacilityGroup.operationType[item] !== "") {
            facilitiesIds.push(this.formFacilityGroup.operationType[item]);
          }
        }
        formFacilityGroupBody.facilitiesIds = facilitiesIds;

        if (this.typeFormFacilityGroup === "update") {
          settingTerminalFacilityApi
            .updateTerminalGroupFacility(this.changeFacilityGroupObj.id, {
              ...formFacilityGroupBody,
            })
            .then(function (result) {
              return result;
            })
            .catch(function (result) {
              return result;
            })
            .then((result) => {
              // console.log("result", result);
              if (result.status === 200) {
                this.btnLoadingSave = false;
                this.showModal = false;
                this.changeFacilityGroupObj = null;
                this.formFacilityGroup = {};

                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.successSave`)}`,
                  bgColor: "bg-success",
                  textColor: "text-white",
                });
                this.showToast = true;

                this.fetchTerminalGroup();
              } else {
                console.log("err");
                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.errSave`)}`,
                  bgColor: "bg-danger",
                  textColor: "text-white",
                });
                this.showToast = true;
                this.btnLoadingSave = false;
              }
            });
        } else {
          settingTerminalFacilityApi
            .addTerminalGroupFacility({
              ...formFacilityGroupBody,
            })
            .then(function (result) {
              return result;
            })
            .catch(function (result) {
              return result;
            })
            .then((result) => {
              // console.log("result", result);
              if (result.status === 200) {
                this.btnLoadingSave = false;
                this.showModal = false;
                this.changeFacilityGroupObj = null;
                this.formFacilityGroup = {};

                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.successSave`)}`,
                  bgColor: "bg-success",
                  textColor: "text-white",
                });
                this.showToast = true;

                this.fetchTerminalGroup();
              } else {
                console.log("err");
                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.errSave`)}`,
                  bgColor: "bg-danger",
                  textColor: "text-white",
                });
                this.showToast = true;
                this.btnLoadingSave = false;
              }
            });
        }
      }
    },
    clickShowDeleteProfileForm(type, profileTarget) {
      this.formFacilityGroup = {};
      this.typeFormFacilityGroup = type;
      this.fetchFacility();
      this.titleModal = this.$t(`Settings.TerminalFacility.btn.deleteProfile`);
      this.showModal = true;
      this.showFormGroup = "delete";
      const profileId = profileTarget.id;
      this.formDeleteFacilityGroup.deleteId = profileId;

      settingTerminalFacilityApi
        .getTransferTerminalGroupFacility(profileId)
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          // console.log("result", result);
          if (result.status === 200) {
            this.dataTransferFacilityObj = result.data;
            console.log(result.data);
          } else {
            console.log("err");
            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.err`)}`,
              bgColor: "bg-danger",
              textColor: "text-white",
            });
            this.showToast = true;
          }
        });
    },
    submitDeleteProfileForm() {
      this.v$.formDeleteFacilityGroup.$touch();
      if (!this.v$.formDeleteFacilityGroup.$error) {
        this.btnLoadingSave = true;

        settingTerminalFacilityApi
          .deleteTerminalGroupFacility(
            this.formDeleteFacilityGroup.deleteId,
            this.formDeleteFacilityGroup.transferId
          )
          .then(function (result) {
            return result;
          })
          .catch(function (result) {
            return result;
          })
          .then((result) => {
            // console.log("result", result);
            if (result.status === 200) {
              this.btnLoadingSave = false;
              this.showModal = false;
              this.formDeleteFacilityGroup = {};

              this.toastPropsList.push({
                content: `${this.$t(`uiElements.toast.successDelete`)}`,
                bgColor: "bg-success",
                textColor: "text-white",
              });
              this.showToast = true;

              this.fetchTerminalGroup();
            } else {
              console.log("err");
              this.toastPropsList.push({
                content: `${this.$t(`uiElements.toast.err`)}`,
                bgColor: "bg-danger",
                textColor: "text-white",
              });
              this.showToast = true;
              this.btnLoadingSave = false;
            }
          });
      }
    },
    addClassValidInput(inputName) {
      return {
        "is-invalid":
          this.v$.formFacilityGroup[inputName].$error === true &&
          this.v$.formFacilityGroup[inputName].$dirty === true,
        "is-valid":
          this.v$.formFacilityGroup[inputName].$error === false &&
          this.v$.formFacilityGroup[inputName].$dirty === true,
      };
    },
  },
  mounted() {
    this.fetchTerminalGroup();
  },
};
</script>

<style scoped>
.bg-gray {
  background-color: #e1e1e1;
}
</style>
