import axios from "@/api/axios";

const getMerchant = (apiParams) => {
  return axios.get("/merchants?" + apiParams);
};

const getBanks = (apiParams) => {
  return axios.get("/banks?" + apiParams);
};

const updateMerchant = (mid, merchantObj) => {
  return axios.put("/merchants/" + mid, { ...merchantObj });
};

const deleteMerchant = (mid) => {
  return axios.post("/registration/delete_account/" + mid);
};

export default {
  getMerchant,
  updateMerchant,
  getBanks,
  deleteMerchant
};
