<template>
  <template v-if="dataResp">
    <div class="d-flex align-items-start my-3">
      <div
        class="nav flex-column nav-pills me-3"
        id="v-pills-tab"
        role="tablist"
        aria-orientation="vertical"
      >
        <button
          v-for="item in dataResp"
          :key="item"
          class="nav-link"
          :class="{
            active: tabTitleActive === item.fataNotificationMessageType,
          }"
          :id="'v-pills-' + item.fataNotificationMessageType + '-tab'"
          data-bs-toggle="pill"
          :data-bs-target="'#v-pills-' + item.fataNotificationMessageType"
          type="button"
          role="tab"
          :aria-controls="'v-pills-' + item.fataNotificationMessageType"
          @click="clickTab(item)"
        >
          {{ item.fataNotificationMessageType }}
        </button>
      </div>
      <div class="tab-content" id="v-pills-tabContent">
        <div
          v-for="item in dataResp"
          :key="item"
          class="tab-pane fade"
          :class="{
            show: tabBodyActive === item.fataNotificationMessageType,
            active: tabBodyActive === item.fataNotificationMessageType,
          }"
          :id="'#v-pills-' + item.fataNotificationMessageType"
          role="tabpanel"
          :aria-labelledby="
            'v-pills-' + item.fataNotificationMessageType + '-tab'
          "
        >
          <form
            @submit.prevent="onSubmit"
            v-if="tabBodyActive === item.fataNotificationMessageType"
          >
            <div class="mb-3">
              <label :for="'input-messageUkr-' + item.id" class="form-label">{{
                $t(`RegulatoryCommissions.form.messageUkr`)
              }}</label>
              <input
                type="text"
                v-maska
                data-maska="W"
                data-maska-tokens="{ 'W': { 'pattern': '[a-zA-Zа-яА-ЯіїґєІЇҐЄёЁ0-9\\s.,_]', 'repeated' : true }}"
                maxlength="99"
                :id="'input-messageUkr-' + item.id"
                class="form-control"
                v-model="formUpdate.messageUkr"
              />
            </div>
            <div class="mb-3">
              <label :for="'input--messageRu-' + item.id" class="form-label">{{
                $t(`RegulatoryCommissions.form.messageRu`)
              }}</label>
              <input
                type="text"
                v-maska
                data-maska="W"
                data-maska-tokens="{ 'W': { 'pattern': '[a-zA-Zа-яА-ЯіїґєІЇҐЄёЁ0-9\\s.,_]', 'repeated' : true }}"
                maxlength="99"
                :id="'input-messageRu-' + item.id"
                class="form-control"
                v-model="formUpdate.messageRu"
              />
            </div>
            <div class="mb-3">
              <label :for="'input-messageEng-' + item.id" class="form-label">{{
                $t(`RegulatoryCommissions.form.messageEng`)
              }}</label>
              <input
                type="text"
                v-maska
                data-maska="W"
                data-maska-tokens="{ 'W': { 'pattern': '[a-zA-Zа-яА-ЯіїґєІЇҐЄёЁ0-9\\s.,_]', 'repeated' : true }}"
                maxlength="99"
                :id="'input-messageEng-' + item.id"
                class="form-control"
                v-model="formUpdate.messageEng"
              />
            </div>
            <div class="d-flex justify-content-end">
              <button
                type="submit"
                class="btn btn-success"
                :disabled="updateMessDisableBtn"
              >
                {{ $t(`uiElements.btn.save`) }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="loading text-center w-100 my-3">
      <trn-loading></trn-loading>
    </div>
  </template>

  <div class="toast-container position-fixed p-3 top-0 end-0" v-if="showToast">
    <bs-toast v-for="toast in toastPropsList" :key="toast" :="toast"></bs-toast>
  </div>
</template>

<script>
import TrnLoading from "@/components/Loading";
import BsToast from "@/components/UI-elements/BsToast";
import regulatoryCommissionsApi from "@/api/regulatory-commissions";

export default {
  name: "TrnRegulatoryCommissionsProceduralNotices",
  components: {
    TrnLoading,
    BsToast,
  },
  data() {
    return {
      tabTitleActive: "BALANCE",
      tabBodyActive: "BALANCE",
      dataResp: null,
      isLoading: false,
      showToast: false,
      toastPropsList: [],
      updateMessDisableBtn: false,
      formUpdate: {
        fataNotificationMessageType: "",
        id: "",
        messageEng: "",
        messageRu: "",
        messageUkr: "",
      },
    };
  },
  methods: {
    fetchDataReport() {
      this.isLoading = true;
      regulatoryCommissionsApi
        .getFataMessage()
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          // console.log("result", result);
          if (result.status === 200) {
            this.dataResp = result.data;
            this.isLoading = false;
            this.fillForm();
          } else {
            console.log("err");
            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.err`)}`,
              bgColor: "bg-danger",
              textColor: "text-white",
            });
            this.showToast = true;
            this.isLoading = false;
          }
        });
    },
    onSubmit() {
      this.updateMessDisableBtn = true;
      regulatoryCommissionsApi
        .updateFataMessage({ ...this.formUpdate })
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          // console.log("result", result);
          if (result.status === 200) {
            this.showToast = true;
            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.success`)}`,
              bgColor: "bg-success",
              textColor: "text-white",
            });
            this.fetchDataReport();
          } else {
            console.log("err");
            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.err`)}`,
              bgColor: "bg-danger",
              textColor: "text-white",
            });
            this.showToast = true;
          }
          this.updateMessDisableBtn = false;
        });
    },
    clickTab(item) {
      this.tabBodyActive = item.fataNotificationMessageType;
      this.tabTitleActive = item.fataNotificationMessageType;

      this.formUpdate.fataNotificationMessageType =
        item.fataNotificationMessageType;
      this.formUpdate.id = item.id;
      this.formUpdate.messageEng = item.messageEng;
      this.formUpdate.messageRu = item.messageRu;
      this.formUpdate.messageUkr = item.messageUkr;
    },
    fillForm() {
      for (let index in this.dataResp) {
        if (
          this.tabTitleActive ===
          this.dataResp[index].fataNotificationMessageType
        ) {
          const item = this.dataResp[index];
          this.formUpdate.fataNotificationMessageType =
            item.fataNotificationMessageType;
          this.formUpdate.id = item.id;
          this.formUpdate.messageEng = item.messageEng;
          this.formUpdate.messageRu = item.messageRu;
          this.formUpdate.messageUkr = item.messageUkr;
        }
      }
    },
  },
  mounted() {
    this.fetchDataReport();
  },
};
</script>

<style scoped></style>
