import terminalGroupsApi from "@/api/terminal-groups";

const state = {
  dataResp: null,
  dataModalResp: null,

  isLoading: false,
  isLoadingScroll: false,
  isLoadingModal: false,

  errorResp: null,
};

export const mutationTypes = {
  getTerminalGroupsStart: "[TerminalGroups] getTerminalGroups start",
  getTerminalGroupsSuccess: "[TerminalGroups] getTerminalGroups success",
  getTerminalGroupsFailure: "[TerminalGroups] getTerminalGroups failure",

  getTerminalGroupsScrollPushStart:
    "[TerminalGroups] getTerminalGroupsScrollPush start",
  getTerminalGroupsScrollPushSuccess:
    "[TerminalGroups] getTerminalGroupsScrollPushSuccess success",
  getTerminalGroupsScrollPushFailure:
    "[TerminalGroups] getTerminalGroupsScrollPush failure",
};

export const actionTypes = {
  getTerminalGroups: "[TerminalGroups] getTerminalGroups",
  getTerminalGroupsScrollPush: "[TerminalGroups] getTerminalGroupsScrollPush",
};

const mutations = {
  [mutationTypes.getTerminalGroupsStart](state) {
    state.isLoading = true;
    state.data = null;
  },
  [mutationTypes.getTerminalGroupsSuccess](state, payload) {
    state.isLoading = false;
    state.dataResp = payload;
  },
  [mutationTypes.getTerminalGroupsFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
  [mutationTypes.getTerminalGroupsScrollPushStart](state) {
    state.isLoadingScroll = true;
  },
  [mutationTypes.getTerminalGroupsScrollPushSuccess](state, payload) {
    for (let item in payload.content) {
      state.dataResp.content.push(payload.content[item]);
    }
    state.isLoadingScroll = false;
  },
  [mutationTypes.getTerminalGroupsScrollPushFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
};

const actions = {
  [actionTypes.getTerminalGroups](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getTerminalGroupsStart, apiParams);
      terminalGroupsApi
        .getTerminalGroups(apiParams)
        .then((response) => {
          context.commit(mutationTypes.getTerminalGroupsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getTerminalGroupsFailure,
            result.response.data.error
          );
        });
    });
  },
  [actionTypes.getTerminalGroupsScrollPush](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getTerminalGroupsScrollPushStart, apiParams);
      terminalGroupsApi
        .getTerminalGroups(apiParams)
        .then((response) => {
          context.commit(
            mutationTypes.getTerminalGroupsScrollPushSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getTerminalGroupsScrollPushFailure,
            result.response.data
          );
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
