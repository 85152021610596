import vendorRSOApi from "@/api/settings-api/vendor-rso";

const state = {
  dataResp: null,
  dataModalResp: null,

  isLoading: false,
  isLoadingScroll: false,
  isLoadingModal: false,

  errorResp: null,
};

export const mutationTypes = {
  getVendorRSOStart: "[VendorRSO] getVendorRSO start",
  getVendorRSOSuccess: "[VendorRSO] getVendorRSO success",
  getVendorRSOFailure: "[VendorRSO] getVendorRSO failure",

  getVendorRSOScrollPushStart: "[VendorRSO] getVendorRSOScrollPush start",
  getVendorRSOScrollPushSuccess:
    "[VendorRSO] getVendorRSOScrollPushSuccess success",
  getVendorRSOScrollPushFailure:
    "[VendorRSO] getVendorRSOScrollPush failure",
};

export const actionTypes = {
  getVendorRSO: "[VendorRSO] getVendorRSO",
  getVendorRSOScrollPush: "[VendorRSO] getVendorRSOScrollPush",
};

const mutations = {
  [mutationTypes.getVendorRSOStart](state) {
    state.isLoading = true;
    state.data = null;
  },
  [mutationTypes.getVendorRSOSuccess](state, payload) {
    state.isLoading = false;
    state.dataResp = payload;
  },
  [mutationTypes.getVendorRSOFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
  [mutationTypes.getVendorRSOScrollPushStart](state) {
    state.isLoadingScroll = true;
  },
  [mutationTypes.getVendorRSOScrollPushSuccess](state, payload) {
    for (let item in payload.content) {
      state.dataResp.content.push(payload.content[item]);
    }
    state.isLoadingScroll = false;
  },
  [mutationTypes.getVendorRSOScrollPushFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
};

const actions = {
  [actionTypes.getVendorRSO](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getVendorRSOStart, apiParams);
      vendorRSOApi
        .getVendorRSO(apiParams)
        .then((response) => {
          context.commit(mutationTypes.getVendorRSOSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getVendorRSOFailure,
            result.response.data.error
          );
        });
    });
  },
  [actionTypes.getVendorRSOScrollPush](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getVendorRSOScrollPushStart, apiParams);
      vendorRSOApi
        .getVendorRSO(apiParams)
        .then((response) => {
          context.commit(
            mutationTypes.getVendorRSOScrollPushSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getVendorRSOScrollPushFailure,
            result.response.data
          );
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
