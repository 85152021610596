import axios from "@/api/axios";

const getTransactionAnalytics = (apiParams) => {
  return axios.get("/transactions/analytics/admin/daily?" + apiParams );
};

const getHealth = () => {
  return axios.get("/health");
};

export default {
  getTransactionAnalytics,
  getHealth,
};
