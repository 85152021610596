import chatHistoryApi from "@/api/chat-history";

const state = {
  dataResp: null,
  dataModalResp: null,

  isLoading: false,
  isLoadingScroll: false,
  isLoadingModal: false,

  errorResp: null,
};

export const mutationTypes = {
  getChatHistoryStart: "[ChatHistory] getChatHistory start",
  getChatHistorySuccess: "[ChatHistory] getChatHistory success",
  getChatHistoryFailure: "[ChatHistory] getChatHistory failure",

  getChatHistoryScrollPushStart: "[ChatHistory] getChatHistoryScrollPush start",
  getChatHistoryScrollPushSuccess:
    "[ChatHistory] getChatHistoryScrollPushSuccess success",
  getChatHistoryScrollPushFailure:
    "[ChatHistory] getChatHistoryScrollPush failure",
};

export const actionTypes = {
  getChatHistory: "[ChatHistory] getChatHistory",
  getChatHistoryScrollPush: "[ChatHistory] getChatHistoryScrollPush",
};

const mutations = {
  [mutationTypes.getChatHistoryStart](state) {
    state.isLoading = true;
    state.data = null;
  },
  [mutationTypes.getChatHistorySuccess](state, payload) {
    state.isLoading = false;
    state.dataResp = payload;
  },
  [mutationTypes.getChatHistoryFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
  [mutationTypes.getChatHistoryScrollPushStart](state) {
    state.isLoadingScroll = true;
  },
  [mutationTypes.getChatHistoryScrollPushSuccess](state, payload) {
    for (let item in payload.content) {
      state.dataResp.content.push(payload.content[item]);
    }
    state.isLoadingScroll = false;
  },
  [mutationTypes.getChatHistoryScrollPushFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
};

const actions = {
  [actionTypes.getChatHistory](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getChatHistoryStart, apiParams);
      chatHistoryApi
        .getChatHistory(apiParams)
        .then((response) => {
          context.commit(mutationTypes.getChatHistorySuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getChatHistoryFailure,
            result.response.data.error
          );
        });
    });
  },
  [actionTypes.getChatHistoryScrollPush](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getChatHistoryScrollPushStart, apiParams);
      chatHistoryApi
        .getChatHistory(apiParams)
        .then((response) => {
          context.commit(
            mutationTypes.getChatHistoryScrollPushSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getChatHistoryScrollPushFailure,
            result.response.data
          );
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
