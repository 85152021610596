import axios from "@/api/axios";

const getTmsKey = (apiParams) => {
  return axios.get("/tms-keys?" + apiParams);
};

const updateTmsKey = (id, reqObj) => {
  return axios.put("/tms-keys/" + id, {
    ...reqObj,
  });
};

const addTmsKey = (reqObj) => {
  return axios.post("/tms-keys", {
    ...reqObj,
  });
};

export default {
  getTmsKey,
  updateTmsKey,
  addTmsKey,
};
