import devicesApi from "@/api/devices";

const state = {
  dataResp: null,
  dataModalResp: null,

  isLoading: false,
  isLoadingScroll: false,
  isLoadingModal: false,

  errorResp: null,
};

export const mutationTypes = {
  getDevicesStart: "[Devices] getDevices start",
  getDevicesSuccess: "[Devices] getDevices success",
  getDevicesFailure: "[Devices] getDevices failure",

  getDevicesScrollPushStart: "[Devices] getDevicesScrollPush start",
  getDevicesScrollPushSuccess: "[Devices] getDevicesScrollPushSuccess success",
  getDevicesScrollPushFailure: "[Devices] getDevicesScrollPush failure",
};

export const actionTypes = {
  getDevices: "[Devices] getDevices",
  getDevicesScrollPush: "[Devices] getDevicesScrollPush",
};

const mutations = {
  [mutationTypes.getDevicesStart](state) {
    state.isLoading = true;
    state.data = null;
  },
  [mutationTypes.getDevicesSuccess](state, payload) {
    state.isLoading = false;
    state.dataResp = payload;
  },
  [mutationTypes.getDevicesFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
  [mutationTypes.getDevicesScrollPushStart](state) {
    state.isLoadingScroll = true;
  },
  [mutationTypes.getDevicesScrollPushSuccess](state, payload) {
    for (let item in payload.content) {
      state.dataResp.content.push(payload.content[item]);
    }
    state.isLoadingScroll = false;
  },
  [mutationTypes.getDevicesScrollPushFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
};

const actions = {
  [actionTypes.getDevices](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getDevicesStart, apiParams);
      devicesApi
        .getDevices(apiParams)
        .then((response) => {
          context.commit(mutationTypes.getDevicesSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getDevicesFailure,
            result.response.data.error
          );
        });
    });
  },
  [actionTypes.getDevicesScrollPush](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getDevicesScrollPushStart, apiParams);
      devicesApi
        .getDevices(apiParams)
        .then((response) => {
          context.commit(
            mutationTypes.getDevicesScrollPushSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getDevicesScrollPushFailure,
            result.response.data
          );
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
