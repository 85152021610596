<template>
  <div class="title-table mb-2 ps-3 py-1 fw-bold">
    {{ $t(`left-menu.regulatoryCommissions`) }}
  </div>

  <div class="fluid-container">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="transaction-tab"
          data-bs-toggle="tab"
          data-bs-target="#transaction"
          type="button"
          role="tab"
          aria-controls="transaction"
          aria-selected="true"
          @click="componentName = 'transaction'"
        >
          <i class="bi bi-calculator"></i>
          {{ $t(`RegulatoryCommissions.tab.transaction`) }}
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="merchants-tab"
          data-bs-toggle="tab"
          data-bs-target="#bdc-merchants"
          type="button"
          role="tab"
          aria-controls="bdc-merchants"
          aria-selected="false"
          @click="componentName = 'merchant'"
        >
          <i class="bi bi-card-list"></i>
          {{ $t(`RegulatoryCommissions.tab.merchants`) }}
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="enrollments-tab"
          data-bs-toggle="tab"
          data-bs-target="#bdc-enrollments"
          type="button"
          role="tab"
          aria-controls="bdc-enrollments"
          aria-selected="false"
          @click="componentName = 'enrollment'"
        >
          <i class="bi bi-card-list"></i>
          {{ $t(`RegulatoryCommissions.tab.enrollments`) }}
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="balance-tab"
          data-bs-toggle="tab"
          data-bs-target="#bdc-balance"
          type="button"
          role="tab"
          aria-controls="bdc-balance"
          aria-selected="false"
          @click="componentName = 'balance'"
        >
          <i class="bi bi-card-list"></i>
          {{ $t(`RegulatoryCommissions.tab.balance`) }}
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="generalFinBook-tab"
          data-bs-toggle="tab"
          data-bs-target="#bdc-generalFinBook"
          type="button"
          role="tab"
          aria-controls="bdc-generalFinBook"
          aria-selected="false"
          @click="componentName = 'generalFinBook'"
        >
          <i class="bi bi-card-list"></i>
          {{ $t(`RegulatoryCommissions.tab.generalFinBook`) }}
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="commission-tab"
          data-bs-toggle="tab"
          data-bs-target="#bdc-commission"
          type="button"
          role="tab"
          aria-controls="bdc-commission"
          aria-selected="false"
          @click="componentName = 'commission'"
        >
          <i class="bi bi-card-list"></i>
          {{ $t(`RegulatoryCommissions.tab.commission`) }}
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="proceduralNotices-tab"
          data-bs-toggle="tab"
          data-bs-target="#bdc-proceduralNotices"
          type="button"
          role="tab"
          aria-controls="bdc-proceduralNotices"
          aria-selected="false"
          @click="componentName = 'proceduralNotices'"
        >
          <i class="bi bi-card-list"></i>
          {{ $t(`RegulatoryCommissions.tab.proceduralNotices`) }}
        </button>
      </li>
    </ul>
    <div class="tab-content" id="tabContent">
      <!--   tab transaction   -->
      <div
        class="tab-pane fade show active"
        id="transaction"
        role="tabpanel"
        aria-labelledby="transaction-tab"
      >
        <trn-regulatory-commissions-transaction
          v-if="componentName === 'transaction'"
        ></trn-regulatory-commissions-transaction>
      </div>
      <!--   tab merchants   -->
      <div
        class="tab-pane fade"
        id="bdc-merchants"
        role="tabpanel"
        aria-labelledby="merchants-tab"
      >
        <trn-regulatory-commissions-merchant
          v-if="componentName === 'merchant'"
        ></trn-regulatory-commissions-merchant>
      </div>
      <!--   tab enrollments   -->
      <div
        class="tab-pane fade"
        id="bdc-enrollments"
        role="tabpanel"
        aria-labelledby="enrollments-tab"
      >
        <trn-regulatory-commissions-accrual
          v-if="componentName === 'enrollment'"
        ></trn-regulatory-commissions-accrual>
      </div>
      <!--   tab balance   -->
      <div
        class="tab-pane fade"
        id="bdc-balance"
        role="tabpanel"
        aria-labelledby="balance-tab"
      >
        <trn-regulatory-commissions-balance
          v-if="componentName === 'balance'"
        ></trn-regulatory-commissions-balance>
      </div>
      <!--   tab generalFinBook   -->
      <div
        class="tab-pane fade"
        id="bdc-generalFinBook"
        role="tabpanel"
        aria-labelledby="generalFinBook-tab"
      >
        <trn-regulatory-commissions-general-fin-book
          v-if="componentName === 'generalFinBook'"
        ></trn-regulatory-commissions-general-fin-book>
      </div>
      <!--   tab commission   -->
      <div
        class="tab-pane fade"
        id="bdc-commission"
        role="tabpanel"
        aria-labelledby="commission-tab"
      >
        <trn-regulatory-commissions-commission
          v-if="componentName === 'commission'"
        ></trn-regulatory-commissions-commission>
      </div>
      <!--   tab proceduralNotices   -->
      <div
        class="tab-pane fade"
        id="bdc-proceduralNotices"
        role="tabpanel"
        aria-labelledby="proceduralNotices-tab"
      >
        <trn-regulatory-commissions-procedural-notices
          v-if="componentName === 'proceduralNotices'"
        ></trn-regulatory-commissions-procedural-notices>
      </div>
    </div>
  </div>
</template>

<script>
import TrnRegulatoryCommissionsTransaction from "@/components/block-pages/regulatory-commissions/RegulatoryCommissionsTransaction";
import TrnRegulatoryCommissionsMerchant from "@/components/block-pages/regulatory-commissions/RegulatoryCommissionsMerchant";
import TrnRegulatoryCommissionsAccrual from "@/components/block-pages/regulatory-commissions/RegulatoryCommissionsAccrual";
import TrnRegulatoryCommissionsBalance from "@/components/block-pages/regulatory-commissions/RegulatoryCommissionsBalance";
import TrnRegulatoryCommissionsProceduralNotices from "@/components/block-pages/regulatory-commissions/RegulatoryCommissionsProceduralNotices";
import TrnRegulatoryCommissionsCommission from "@/components/block-pages/regulatory-commissions/RegulatoryCommissionsCommission";
import TrnRegulatoryCommissionsGeneralFinBook from "@/components/block-pages/regulatory-commissions/RegulatoryCommissionsGeneralFinBook";

export default {
  name: "TrnRegulatoryCommissions",
  components: {
    TrnRegulatoryCommissionsGeneralFinBook,
    TrnRegulatoryCommissionsCommission,
    TrnRegulatoryCommissionsProceduralNotices,
    TrnRegulatoryCommissionsBalance,
    TrnRegulatoryCommissionsAccrual,
    TrnRegulatoryCommissionsMerchant,
    TrnRegulatoryCommissionsTransaction,
  },
  data() {
    return {
      componentName: "transaction",
    };
  },
};
</script>

<style scoped></style>
