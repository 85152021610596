import axios from "@/api/axios";

const getReceiptTemplate = (apiParams) => {
  return axios.get("/receipt-templates?" + apiParams);
};

const getReceiptTemplatePreview = (id) => {
  return axios.get("receipt-templates/preview/" + id);
};

const updateReceiptTemplate = (rcId, rcObj) => {
  return axios.put("/receipt-templates/" + rcId, { ...rcObj });
};

const addReceiptTemplate = (rcObj) => {
  return axios.post("/receipt-templates", { ...rcObj });
};

export default {
  getReceiptTemplate,
  updateReceiptTemplate,
  addReceiptTemplate,
  getReceiptTemplatePreview,
};
