import axios from "@/api/axios";

const getTerminalByStatus = (apiParams) => {
  return axios.get("/reports/terminal-by-status?" + apiParams);
};
const getTerminalByStatusAsFile = (apiParams) => {
  return axios.get("/reports/terminal-by-status-as-file?" + apiParams, {
    responseType: "blob",
  });
};

const getRegistration = (apiParams) => {
  return axios.get("/reports/registration?" + apiParams);
};
const getRegistrationAsFile = (apiParams) => {
  return axios.get("/reports/registration-as-file?" + apiParams, {
    responseType: "blob",
  });
};

const getMerchant = (apiParams) => {
  return axios.get("/reports/report-by-merchant?" + apiParams);
};
const getMerchantAsFile = (apiParams) => {
  return axios.get("/reports/report-by-merchant-as-file?" + apiParams, {
    responseType: "blob",
  });
};

const getTransactionsByMerchant = (apiParams) => {
  return axios.get("/reports/transactions-by-merchant?" + apiParams);
};
const getTransactionsByMerchantAsFile = (apiParams) => {
  return axios.get("/reports/transactions-by-merchant-as-file?" + apiParams, {
    responseType: "blob",
  });
};

const getTransactionsByTerminal = (apiParams) => {
  return axios.get("/reports/transactions-by-terminal?" + apiParams);
};
const getTransactionsByTerminalAsFile = (apiParams) => {
  return axios.get("/reports/transactions-by-terminal-as-file?" + apiParams, {
    responseType: "blob",
  });
};

const getTransactionsByType = (apiParams) => {
  return axios.get("/reports/transactions-by-type?" + apiParams);
};
const getTransactionsByTypeAsFile = (apiParams) => {
  return axios.get("/reports/transactions-by-type-as-file?" + apiParams, {
    responseType: "blob",
  });
};

const getTransactionsByIps = (apiParams) => {
  return axios.get("/reports/transactions-by-ips?" + apiParams);
};
const getTransactionsByIpsAsFile = (apiParams) => {
  return axios.get("/reports/transactions-by-ips-as-file?" + apiParams, {
    responseType: "blob",
  });
};

const getTransactionsByBlame = (apiParams) => {
  return axios.get("/reports/transactions-by-blame?" + apiParams);
};
const getTransactionsByBlameAsFile = (apiParams) => {
  return axios.get("/reports/transactions-by-blame-as-file?" + apiParams, {
    responseType: "blob",
  });
};

const getTerminalLifecycle = (apiParams) => {
  return axios.get("/reports/terminal-lifecycle?" + apiParams);
};
const getTerminalLifecycleAsFile = (apiParams) => {
  return axios.get("/reports/terminal-lifecycle-as-file?" + apiParams, {
    responseType: "blob",
  });
};

const getTerminalsByGroups = (apiParams) => {
  return axios.get("/reports/terminals-by-groups?" + apiParams);
};
const getTerminalsByGroupsAsFile = (apiParams) => {
  return axios.get("/reports/terminals-by-groups-as-file?" + apiParams, {
    responseType: "blob",
  });
};

export default {
  getTerminalByStatus,
  getTerminalByStatusAsFile,
  getRegistration,
  getRegistrationAsFile,
  getMerchant,
  getMerchantAsFile,
  getTransactionsByMerchant,
  getTransactionsByMerchantAsFile,
  getTransactionsByTerminal,
  getTransactionsByTerminalAsFile,
  getTransactionsByType,
  getTransactionsByTypeAsFile,
  getTransactionsByIps,
  getTransactionsByIpsAsFile,
  getTransactionsByBlame,
  getTransactionsByBlameAsFile,
  getTerminalLifecycle,
  getTerminalLifecycleAsFile,
  getTerminalsByGroups,
  getTerminalsByGroupsAsFile,
};
