<template>
  <nav class="navbar navbar-expand-lg py-1">
    <div class="container-fluid">
      <router-link :to="{ name: 'transactions' }" class="navbar-brand">
        <img
          src="@/assets/img/logo_transenix.png"
          alt="Transenix"
          height="36"
          class="d-inline-block align-text-top"
        />
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"><i class="bi bi-list"></i></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <div class="d-flex justify-content-end flex-grow-1">
          <trn-locale-switcher></trn-locale-switcher>
          <ul class="navbar-nav">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="bi bi-gear pe-1"></i>
                <span>{{ $t(`settings-menu.settings`) }}</span>
              </a>
              <ul
                class="dropdown-menu dropdown-menu-end menu-setting"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <div class="row">
                  <div class="col-md-4">
                    <router-link
                      :to="{ name: 'settingsInstallations' }"
                      class="dropdown-item text-wrap"
                      active-class="active"
                      v-if="checkMenuAccess('GENERAL_SETTINGS_VIEW')"
                    >
                      <i class="bi bi-bricks me-3"></i>
                      <span>{{ $t("settings-menu.installations") }}</span>
                    </router-link>
                    <router-link
                      :to="{ name: 'attestation' }"
                      class="dropdown-item text-wrap"
                      active-class="active"
                      v-if="checkMenuAccess('ATTESTATION_PARAMETERS_VIEW')"
                    >
                      <i class="bi bi-gear-wide-connected me-3"></i>
                      <span>{{ $t("settings-menu.attestation") }}</span>
                    </router-link>
                    <router-link
                      :to="{ name: 'settingsBanks' }"
                      class="dropdown-item"
                      active-class="active"
                      v-if="checkMenuAccess('BANK_VIEW')"
                    >
                      <i class="bi bi-bank me-3"></i>
                      <span>{{ $t("settings-menu.banks") }}</span>
                    </router-link>
                    <router-link
                      :to="{ name: 'terminalFacility' }"
                      class="dropdown-item visually-hidden"
                      active-class="active"
                      v-if="checkMenuAccess('ROLE_VIEW')"
                    >
                      <i class="bi bi-card-list me-3"></i>
                      <span>{{ $t("settings-menu.terminalFacility") }}</span>
                    </router-link>
                    <router-link
                      :to="{ name: 'version' }"
                      class="dropdown-item"
                      active-class="active"
                      v-if="checkMenuAccess('VERSION_VIEW')"
                    >
                      <i class="bi bi-git me-3"></i>
                      <span>{{ $t("settings-menu.version") }}</span>
                    </router-link>
                    <router-link
                      :to="{ name: 'vendorRSO' }"
                      class="dropdown-item"
                      active-class="active"
                      v-if="checkMenuAccess('VERSION_VIEW')"
                    >
                      <i class="bi bi-menu-button me-3"></i>
                      <span>{{ $t("settings-menu.vendorRSO") }}</span>
                    </router-link>
                  </div>
                  <div class="col-md-4">
                    <router-link
                      :to="{ name: 'ipsCardGroup' }"
                      class="dropdown-item text-wrap"
                      active-class="active"
                      v-if="checkMenuAccess('PAYMENT_SYSTEMS_VIEW')"
                    >
                      <i class="bi bi-credit-card-2-front me-3"></i>
                      <span>{{ $t("settings-menu.ipsCardGroup") }}</span>
                    </router-link>
                    <router-link
                      :to="{ name: 'products' }"
                      class="dropdown-item text-wrap"
                      active-class="active"
                      v-if="checkMenuAccess('PRODUCTS_VIEW')"
                    >
                      <i class="bi bi-list-task me-3"></i>
                      <span>{{ $t("settings-menu.products") }}</span>
                    </router-link>
                    <router-link
                      :to="{ name: 'receiptTemplate' }"
                      class="dropdown-item text-wrap"
                      active-class="active"
                      v-if="checkMenuAccess('RECEIPT_TEMPLATE_VIEW')"
                    >
                      <i class="bi bi-receipt me-3"></i>
                      <span>{{ $t("settings-menu.receiptTemplate") }}</span>
                    </router-link>
                    <router-link
                      :to="{ name: 'schedule' }"
                      class="dropdown-item text-wrap"
                      active-class="active"
                      v-if="checkMenuAccess('SCHEDULE_VIEW')"
                    >
                      <i class="bi bi-clock-history me-3"></i>
                      <span>{{ $t("settings-menu.schedule") }}</span>
                    </router-link>
                  </div>
                  <div class="col-md-4">
                    <router-link
                      :to="{ name: 'user' }"
                      class="dropdown-item"
                      active-class="active"
                      v-if="checkMenuAccess('USER_VIEW')"
                    >
                      <i class="bi bi-people-fill me-3"></i>
                      <span>{{ $t("left-menu.user") }}</span>
                    </router-link>
                    <router-link
                      :to="{ name: 'settingsUserRoles' }"
                      class="dropdown-item"
                      active-class="active"
                      v-if="checkMenuAccess('ROLE_VIEW')"
                    >
                      <i class="bi bi-person-video2 me-3"></i>
                      <span>{{ $t("settings-menu.userRoles") }}</span>
                    </router-link>
                    <router-link
                      :to="{ name: 'settingsEntityAudit' }"
                      class="dropdown-item text-wrap"
                      active-class="active"
                      v-if="checkMenuAccess('ENTITY_AUDIT_VIEW')"
                    >
                      <i class="bi bi-pencil-square me-3"></i>
                      <span>{{ $t("settings-menu.entityAudit") }}</span>
                    </router-link>
                  </div>
                </div>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLinkProfile"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="bi bi-person pe-1"></i>
                <span>{{ userName }}</span>
              </a>
              <ul
                class="dropdown-menu dropdown-menu-end"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <li>
                  <a class="dropdown-item" href="#" @click="exitApp">Exit</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import TrnLocaleSwitcher from "@/components/LocaleSwitcher";
import { getItemLocalStorage } from "@/helpers/persistanceStorage";

export default {
  name: "TrnTopbar",
  components: {
    TrnLocaleSwitcher,
  },
  data() {
    return {
      userAuthorities: [],
    };
  },
  computed: {
    userName: {
      get() {
        return getItemLocalStorage("userName");
      },
    },
  },
  methods: {
    exitApp() {
      // setItemLocalStorage("accessToken", "");
      // setItemLocalStorage("userName", "");
      // setItemLocalStorage("authorities", "");
      localStorage.clear();
      this.$router.push({ name: "login" });
    },
    checkMenuAccess(itemMenu) {
      return this.userAuthorities.indexOf(itemMenu) !== -1;
    },
  },
  mounted() {
    this.userAuthorities = getItemLocalStorage("authorities");
  },
};
</script>

<style scoped>
.navbar {
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.05);
  padding-top: 0.5625rem;
}

.menu-setting {
  width: 700px;
  max-width: 700px;
  min-width: 300px;
}
</style>
