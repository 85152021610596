// функція для перевірки прав доступу користувача
// якщо користувач має права доступу, то функція повертає true
// якщо користувач не має прав доступу, то функція повертає false
import { getItemLocalStorage } from "@/helpers/persistanceStorage";

export const checkPermission = (key) => {
  try {
    const getPermissionList = getItemLocalStorage("authorities");
    return !!getPermissionList.includes(key);
  } catch (e) {
    return false;
  }
};
