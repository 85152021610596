<template>
  <!-- ToolBar -->
  <div class="toolbar">
    <div class="btn-group my-2">
      <button
        class="btn btn-outline-primary btn-sm"
        type="button"
        @click="clickFormTariffObj('addFullTariff')"
      >
        <i class="bi bi-file-earmark-plus"></i>
        <span class="ms-1">{{
          $t(`Settings.TerminalFacility.btn.addFullTariff`)
        }}</span>
      </button>
      <button
        class="btn btn-outline-primary btn-sm"
        type="button"
        @click="clickFormTariffObj('addLoyaltyTariff')"
      >
        <i class="bi bi-file-earmark-plus"></i>
        <span class="ms-1">{{
          $t(`Settings.TerminalFacility.btn.addLoyaltyTariff`)
        }}</span>
      </button>
      <button
        class="btn btn-outline-primary btn-sm"
        :class="{ disabled: !selectedTableRow?.id }"
        type="button"
        @click="clickFormTariffObj('update')"
      >
        <i class="bi bi-pencil-square"></i>
        <span class="ms-1">{{ $t(`uiElements.btn.change`) }}</span>
      </button>
    </div>
  </div>

  <!-- ToolBar кінець -->

  <div class="table-responsive table-block overflow-auto" id="table-block">
    <table class="table table-sm table-light">
      <thead>
        <tr class="text-center">
          <th scope="col">
            {{ $t(`Settings.TerminalFacility.table.id`) }}
          </th>

          <th scope="col">{{ $t(`Settings.TerminalFacility.table.name`) }}</th>
          <th scope="col">
            {{ $t(`Settings.TerminalFacility.table.description`) }}
          </th>
          <th scope="col">
            {{ $t(`Settings.TerminalFacility.table.beginDate`) }}
          </th>
          <th scope="col">
            {{ $t(`Settings.TerminalFacility.table.expiredDate`) }}
          </th>
          <th scope="col">
            {{ $t(`Settings.TerminalFacility.table.groupFacility`) }}
          </th>
          <th scope="col">
            {{ $t(`Settings.TerminalFacility.table.nextFacilityId`) }}
          </th>
          <th scope="col">
            {{
              $t(`Settings.TerminalFacility.table.limitToApplyPercentageFee`)
            }}
          </th>
          <th scope="col">
            {{ $t(`Settings.TerminalFacility.table.percentageFee`) }}
          </th>
          <th scope="col">
            {{ $t(`Settings.TerminalFacility.table.fixedFee`) }}
          </th>
          <th scope="col">{{ $t(`Settings.TerminalFacility.table.type`) }}</th>
          <th scope="col">
            {{ $t(`Settings.TerminalFacility.table.originType`) }}
          </th>
          <th scope="col">
            {{ $t(`Settings.TerminalFacility.table.createdDate`) }}
          </th>

          <th scope="col">
            {{ $t(`Settings.TerminalFacility.table.relatedLoyaltyFacilityId`) }}
          </th>
        </tr>
      </thead>
      <tbody class="">
        <template v-if="isLoading">
          <tr>
            <td colspan="16">
              <div class="loading text-center">
                <trn-loading></trn-loading>
              </div>
            </td>
          </tr>
        </template>
        <template v-if="dataResp">
          <tr
            v-for="item in dataResp.data"
            :key="item"
            class="cursor-pointer"
            :class="{
              'select-td': item.id === selectedTableRow?.id,
            }"
            @click="clickTableRow(item)"
          >
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.description }}</td>
            <td>{{ $filters.formatDateTime(item.beginDate, "date") }}</td>
            <td>{{ $filters.formatDateTime(item.expiredDate, "date") }}</td>
            <td>{{ formatStrColGroupFacility(item.groupFacilityIds) }}</td>
            <td>{{ item.nextFacilityId }}</td>
            <td>{{ $filters.formatAmount(item.limitToApplyPercentageFee) }}</td>
            <td>{{ item.percentageFee }}</td>
            <td>{{ $filters.formatAmount(item.fixedFee) }}</td>
            <td>{{ item.type }}</td>
            <td>{{ item.originType }}</td>
            <td>{{ $filters.formatDateTime(item.createdDate, "date") }}</td>
            <td>{{ item.relatedLoyaltyFacilityId }}</td>
          </tr>
          <template v-if="isLoadingScroll">
            <tr>
              <td colspan="16">
                <div class="loading text-center">
                  <trn-loading></trn-loading>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>

  <bs-modal
    :modal-toggle-emit="showModal"
    :title="titleModal"
    @hideMainModal="showModal = false"
  >
    <template v-if="showFormGroup">
      <form
        class="needs-validation"
        @submit.prevent="submitChangeGroupNumber"
        novalidate
      >
        <!--  input-name  -->
        <div class="row mb-3">
          <label for="input-name" class="col-sm-6 col-form-label">
            {{ $t(`Settings.TerminalFacility.table.name`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              :class="addClassValidInput('name')"
              id="input-name"
              v-model="v$.formFacility.name.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formFacility.name.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formFacility.name.reTextAndNum.$invalid">
                {{ $t(`Validations.reTextAndNum`) }}
              </div>
              <div v-if="v$.formFacility.name.maxLength.$invalid">
                {{ $t(`Validations.maxLength`, { num: 255 }) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-description  -->
        <div class="row mb-3">
          <label for="input-description" class="col-sm-6 col-form-label">
            {{ $t(`Settings.TerminalFacility.table.description`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <textarea
              rows="3"
              class="form-control"
              :class="addClassValidInput('description')"
              id="input-description"
              v-model="v$.formFacility.description.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formFacility.description.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div
                  v-if="v$.formFacility.description.reTextAndNumDot.$invalid"
              >
                {{ $t(`Validations.reTextAndNumDot`) }}
              </div>
              <div v-if="v$.formFacility.description.maxLength.$invalid">
                {{ $t(`Validations.maxLength`, { num: 255 }) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-type  -->
        <div class="row mb-3">
          <label for="input-type" class="col-sm-6 col-form-label">
            {{ $t(`Settings.TerminalFacility.table.type`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              :class="addClassValidInput('type')"
              id="input-opManual"
              v-model="v$.formFacility.type.$model"
            >
              <option
                :value="item"
                v-for="item in this.dataResp.allOperationTypes"
                :key="item"
              >
                {{ item }}
              </option>
            </select>
            <div class="invalid-feedback">
              <div v-if="v$.formFacility.type.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-beginDate  -->
        <div class="row mb-3" v-if="formFacilityRequired.beginDate">
          <label for="input-beginDate" class="col-sm-6 col-form-label">
            {{ $t(`Settings.TerminalFacility.table.beginDate`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <Datepicker
              id="beginDate-ff"
              :class="addClassValidInput('beginDate')"
              v-model="v$.formFacility.beginDate.$model"
              autoApply
              locale="en"
              :format="dateFormat"
              required
            />
            <div class="invalid-feedback">
              <div v-if="v$.formFacility.beginDate.requiredIf.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-expiredDate  -->
        <div class="row mb-3" v-if="formFacilityRequired.expiredDate">
          <label for="input-expiredDate" class="col-sm-6 col-form-label">
            {{ $t(`Settings.TerminalFacility.table.expiredDate`) }}
          </label>
          <div class="col-sm-6">
            <Datepicker
              id="beginDate-ff"
              :class="addClassValidInput('expiredDate')"
              v-model="v$.formFacility.expiredDate.$model"
              autoApply
              locale="en"
              :format="dateFormat"
              required
            />
            <div class="invalid-feedback">
              <div v-if="v$.formFacility.expiredDate.requiredIf.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-percentageFee  -->
        <div class="row mb-3">
          <label for="input-percentageFee" class="col-sm-6 col-form-label">
            {{ $t(`Settings.TerminalFacility.table.percentageFee`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              v-maska
              data-maska="#*.##"
              class="form-control"
              :class="addClassValidInput('percentageFee')"
              id="input-percentageFee"
              v-model="v$.formFacility.percentageFee.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formFacility.percentageFee.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formFacility.percentageFee.minValue.$invalid">
                {{ $t(`Validations.minValue`, { num: 0 }) }}
              </div>
              <div v-if="v$.formFacility.percentageFee.maxValue.$invalid">
                {{ $t(`Validations.maxValue`, { num: 100 }) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-limitToApplyPercentageFee  -->
        <div class="row mb-3">
          <label
            for="input-limitToApplyPercentageFee"
            class="col-sm-6 col-form-label"
          >
            {{
              $t(`Settings.TerminalFacility.table.limitToApplyPercentageFee`)
            }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              v-maska
              data-maska="#*.##"
              :class="addClassValidInput('limitToApplyPercentageFee')"
              id="input-limitToApplyPercentageFee"
              v-model="v$.formFacility.limitToApplyPercentageFee.$model"
            />
            <div class="invalid-feedback">
              <div
                v-if="
                  v$.formFacility.limitToApplyPercentageFee.required.$invalid
                "
              >
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-fixedFee  -->
        <div class="row mb-3">
          <label for="input-fixedFee" class="col-sm-6 col-form-label">
            {{ $t(`Settings.TerminalFacility.table.fixedFee`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              v-maska
              data-maska="#*.##"
              :class="addClassValidInput('fixedFee')"
              id="input-fixedFee"
              v-model="v$.formFacility.fixedFee.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formFacility.fixedFee.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-originType  -->
        <div class="row mb-3">
          <label for="input-originType" class="col-sm-6 col-form-label">
            {{ $t(`Settings.TerminalFacility.table.originType`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              :class="addClassValidInput('originType')"
              id="input-opManual"
              v-model="v$.formFacility.originType.$model"
              disabled
            >
              <option
                :value="item"
                v-for="item in this.dataResp.allOriginTypes"
                :key="item"
              >
                {{ item }}
              </option>
            </select>
            <div class="invalid-feedback">
              <div v-if="v$.formFacility.originType.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-loyaltyPeriodType  -->
        <div class="row mb-3" v-if="formFacilityRequired.loyaltyPeriodType">
          <label for="input-loyaltyPeriodType" class="col-sm-6 col-form-label">
            {{ $t(`Settings.TerminalFacility.table.loyaltyPeriodType`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              :class="addClassValidInput('loyaltyPeriodType')"
              id="input-opManual"
              v-model="v$.formFacility.loyaltyPeriodType.$model"
            >
              <option
                :value="item"
                v-for="item in this.dataResp.allPeriodicTypes"
                :key="item"
              >
                {{ item }}
              </option>
            </select>
            <div class="invalid-feedback">
              <div v-if="v$.formFacility.loyaltyPeriodType.requiredIf.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-loyaltyPeriodValue  -->
        <div class="row mb-3" v-if="formFacilityRequired.loyaltyPeriodValue">
          <label for="input-loyaltyPeriodValue" class="col-sm-6 col-form-label">
            {{ $t(`Settings.TerminalFacility.table.loyaltyPeriodValue`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="number"
              class="form-control"
              :class="addClassValidInput('loyaltyPeriodValue')"
              id="input-loyaltyPeriodValue"
              v-model="v$.formFacility.loyaltyPeriodValue.$model"
            />
            <div class="invalid-feedback">
              <div
                v-if="v$.formFacility.loyaltyPeriodValue.requiredIf.$invalid"
              >
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-nextFacilityId  -->
        <div class="row mb-3" v-if="formFacilityRequired.nextFacilityId">
          <label for="input-nextFacilityId" class="col-sm-6 col-form-label">
            {{ $t(`Settings.TerminalFacility.table.nextFacilityId`) }}
          </label>
          <div class="col-sm-6">
            <input
              type="number"
              min="1"
              class="form-control"
              :class="addClassValidInput('nextFacilityId')"
              id="input-nextFacilityId"
              v-model="v$.formFacility.nextFacilityId.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formFacility.nextFacilityId.minValue.$invalid">
                {{ $t(`Validations.minValue`, { num: 1 }) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-relatedLoyaltyFacilityId  -->
        <div
          class="row mb-3"
          v-if="formFacilityRequired.relatedLoyaltyFacilityId"
        >
          <label for="input-loyaltyPeriodType" class="col-sm-6 col-form-label">
            {{ $t(`Settings.TerminalFacility.table.relatedLoyaltyFacilityId`) }}
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              id="input-opManual"
              v-model="v$.formFacility.relatedLoyaltyFacilityId.$model"
            >
              <option value="">{{ $t(`Validations.notChosen`) }}</option>
              <option :value="item.id" v-for="item in this.listRLF" :key="item">
                {{ item.id }} | {{ item.name }}
              </option>
            </select>
          </div>
        </div>

        <!--  Buttons form  -->
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-outline-secondary me-3"
            @click="showModal = false"
          >
            {{ $t(`uiElements.btn.close`) }}
          </button>
          <button
            type="submit"
            class="btn btn-outline-primary"
            v-if="!btnLoadingSave"
            :disabled="v$.$invalid && v$.$dirty ? true : false"
          >
            {{ $t(`uiElements.btn.save`) }}
          </button>
          <button type="submit" class="btn btn-outline-primary" v-else disabled>
            <span class="spinner-border spinner-border-sm"></span>
            {{ $t(`uiElements.btn.save`) }}
          </button>
        </div>
      </form>
    </template>
  </bs-modal>
  <div
    class="toast-container position-absolute p-3 top-0 end-0 z-index-1500"
    v-if="showToast"
  >
    <bs-toast v-for="toast in toastPropsList" :key="toast" :="toast"></bs-toast>
  </div>
</template>

<script>
import TrnLoading from "@/components/Loading";
import BsModal from "@/components/UI-elements/BsModal";
import BsToast from "@/components/UI-elements/BsToast";
import useVuelidate from "@vuelidate/core";
import {
  helpers,
  maxLength,
  maxValue,
  minValue,
  required,
  requiredIf,
} from "@vuelidate/validators";
import { mapState } from "vuex";
import { actionTypes } from "@/store/modules/settings-store/terminal-facility";
import settingTerminalFacilityApi from "@/api/settings-api/terminal-facility";
import Datepicker from "vue3-date-time-picker";

export default {
  name: "TrnTerminalFacilityTabTariffs",
  components: {
    TrnLoading,
    BsModal,
    BsToast,
    Datepicker,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      page: 0,
      size: 100,
      sort: {
        col: "ipsCardGroupId",
        order: "asc",
      },
      showModal: false,
      titleModal: "",
      showFormGroup: false,
      formFacility: {},
      formFacilityRequired: {
        nextFacilityId: false,
        beginDate: false,
        expiredDate: false,
        loyaltyPeriodType: false,
        loyaltyPeriodValue: false,
      },
      formFacilityType: "",
      showToast: false,
      toastPropsList: [],
      selectedTableRow: null,
      dateFormat: "dd.MM.yyyy",
      btnLoadingSave: false,
      listRLF: [],
    };
  },
  validations() {
    return {
      formFacility: {
        name: {
          required,
          reTextAndNum: helpers.regex(/^[a-zA-Zа-яА-ЯіїґєІЇҐЄёЁ0-9\s]+$/u), // input expression is only Latin, Cyrillic and numbers.
          maxLength: maxLength(255),
        },
        description: {
          required,
          reTextAndNumDot: helpers.regex(
            /^[a-zA-Zа-яА-ЯіїґєІЇҐЄёЁ0-9\s.\-,]+$/u
          ),
          maxLength: maxLength(255),
        },
        type: {
          required,
        },
        beginDate: {
          requiredIf: requiredIf(this.formFacilityRequired.beginDate),
        },
        expiredDate: {
          requiredIf: requiredIf(false),
        },
        percentageFee: {
          required,
          minValue: minValue(0),
          maxValue: maxValue(100),
        },
        limitToApplyPercentageFee: {
          required,
        },
        fixedFee: {
          required,
        },
        originType: {
          required,
        },
        loyaltyPeriodType: {
          requiredIf: requiredIf(this.formFacilityRequired.loyaltyPeriodType),
        },
        loyaltyPeriodValue: {
          requiredIf: requiredIf(this.formFacilityRequired.loyaltyPeriodValue),
        },
        nextFacilityId: {
          minValue: minValue(1),
        },
        relatedLoyaltyFacilityId: {},
      },
    };
  },
  computed: {
    dataResp: {
      get() {
        return this.$store.state.settingTerminalFacility.dataResp;
      },
      set(newValue) {
        this.$store.state.settingTerminalFacility.dataResp = newValue;
      },
    },
    ...mapState({
      isLoading: (state) => state.settingTerminalFacility.isLoading,
      isLoadingScroll: (state) => state.settingTerminalFacility.isLoadingScroll,
      dataModalResp: (state) => state.settingTerminalFacility.dataModalResp,
      errorResp: (state) => state.settingTerminalFacility.errorResp,
    }),
  },
  methods: {
    fetchFacility() {
      // const stringifiedParams = stringify({
      //   page: this.page,
      //   size: this.size,
      //   sort: `${this.sort.col},${this.sort.order}`,
      // });
      // this.$store.dispatch(actionTypes.getIpsCardGroups, {
      //   apiParams: stringifiedParams,
      // });
      this.$store.dispatch(actionTypes.getTerminalFacility, {});
    },
    addClassValidInput(inputName) {
      return {
        "is-invalid":
          this.v$.formFacility[inputName].$error === true &&
          this.v$.formFacility[inputName].$dirty === true,
        "is-valid":
          this.v$.formFacility[inputName].$error === false &&
          this.v$.formFacility[inputName].$dirty === true,
      };
    },
    clickTableRow(rowItem) {
      this.selectedTableRow = rowItem;
    },
    clickFormTariffObj(type) {
      for (let item in this.formFacilityRequired) {
        this.formFacilityRequired[item] = false;
      }
      this.getRelatedLoyaltyFacilityIds();

      this.formFacilityType = type;
      this.showModal = true;

      this.showFormGroup = true;
      if (type === "addFullTariff") {
        this.selectedTableRow = null;
        this.formFacility = {};
        this.titleModal = this.$t(
          `Settings.TerminalFacility.modal.addFullTariff`
        );
        this.formFacilityRequired.beginDate = true;
        this.formFacilityRequired.expiredDate = true;

        this.formFacilityRequired.nextFacilityId = true;
        this.formFacilityRequired.relatedLoyaltyFacilityId = true;

        this.formFacility.originType = "FULL_FACILITY";
      } else if (type === "addLoyaltyTariff") {
        this.selectedTableRow = null;
        this.formFacility = {};
        this.titleModal = this.$t(
          `Settings.TerminalFacility.modal.addLoyaltyTariff`
        );
        this.formFacilityRequired.loyaltyPeriodType = true;
        this.formFacilityRequired.loyaltyPeriodValue = true;
        this.formFacility.originType = "LOYALTY_FACILITY";
      } else {
        if (this.selectedTableRow.originType === "FULL_FACILITY") {
          this.formFacilityRequired.beginDate = true;
          this.formFacilityRequired.expiredDate = true;
          this.formFacilityRequired.nextFacilityId = true;
          this.formFacilityRequired.relatedLoyaltyFacilityId = true;
          this.formFacility.nextFacilityId =
            this.selectedTableRow.nextFacilityId;
          this.formFacility.relatedLoyaltyFacilityId =
            this.selectedTableRow.relatedLoyaltyFacilityId;

          if (this.selectedTableRow.beginDate) {
            this.formFacility.beginDate = this.$filters.formatDateTime(
              this.selectedTableRow.beginDate,
              "date-obj"
            );
          } else {
            this.formFacility.beginDate = null;
          }

          if (this.selectedTableRow.expiredDate) {
            this.formFacility.expiredDate = this.$filters.formatDateTime(
              this.selectedTableRow.expiredDate,
              "date-obj"
            );
          } else {
            this.formFacility.expiredDate = null;
          }
        } else if (this.selectedTableRow.originType === "LOYALTY_FACILITY") {
          this.formFacilityRequired.loyaltyPeriodType = true;
          this.formFacilityRequired.loyaltyPeriodValue = true;
          this.formFacility.loyaltyPeriodType =
            this.selectedTableRow.loyaltyPeriodType;
          this.formFacility.loyaltyPeriodValue =
            this.selectedTableRow.loyaltyPeriodValue;
        }

        this.titleModal =
          this.$t(`Settings.TerminalFacility.modal.changeTariff`) +
          this.selectedTableRow.id;
        this.formFacility.name = this.selectedTableRow.name;
        this.formFacility.description = this.selectedTableRow.description;
        this.formFacility.type = this.selectedTableRow.type;

        this.formFacility.percentageFee = this.selectedTableRow.percentageFee;
        this.formFacility.limitToApplyPercentageFee =
          this.$filters.formatAmount(
            this.selectedTableRow.limitToApplyPercentageFee
          );
        this.formFacility.fixedFee = this.$filters.formatAmount(
          this.selectedTableRow.fixedFee
        );
        this.formFacility.originType = this.selectedTableRow.originType;
      }
    },
    submitChangeGroupNumber() {
      this.v$.formFacility.$touch();
      if (!this.v$.formFacility.$error) {
        this.btnLoadingSave = true;
        let formFacilityBody = {};
        formFacilityBody.name = this.formFacility.name;
        formFacilityBody.description = this.formFacility.description;
        formFacilityBody.type = this.formFacility.type;

        formFacilityBody.percentageFee = this.formFacility.percentageFee * 100;
        formFacilityBody.limitToApplyPercentageFee =
          this.formFacility.limitToApplyPercentageFee * 100;
        formFacilityBody.fixedFee = this.formFacility.fixedFee * 100;

        formFacilityBody.originType = this.formFacility.originType;

        if (this.formFacility.originType === "FULL_FACILITY") {
          formFacilityBody.nextFacilityId = this.formFacility.nextFacilityId;
          formFacilityBody.relatedLoyaltyFacilityId =
            this.formFacility.relatedLoyaltyFacilityId;
          formFacilityBody.beginDate = new Date(this.formFacility.beginDate)
            .toISOString()
            .split("T")[0];
          if (formFacilityBody.expiredDate) {
            formFacilityBody.expiredDate = new Date(
              this.formFacility.expiredDate
            )
              .toISOString()
              .split("T")[0];
          } else {
            formFacilityBody.expiredDate = "";
          }
        } else if (this.formFacility.originType === "LOYALTY_FACILITY") {
          formFacilityBody.loyaltyPeriodType =
            this.formFacility.loyaltyPeriodType;
          formFacilityBody.loyaltyPeriodValue =
            this.formFacility.loyaltyPeriodValue;
        }

        if (this.formFacilityType === "update") {
          settingTerminalFacilityApi
            .updateTerminalFacility(this.selectedTableRow.id, {
              ...formFacilityBody,
            })
            .then(function (result) {
              return result;
            })
            .catch(function (result) {
              return result;
            })
            .then((result) => {
              // console.log("result", result);
              if (result.status === 200) {
                this.btnLoadingSave = false;
                this.showModal = false;
                this.selectedTableRow = null;
                this.formFacility = {};

                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.successSave`)}`,
                  bgColor: "bg-success",
                  textColor: "text-white",
                });
                this.showToast = true;

                this.fetchFacility();
              } else {
                console.log("err");
                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.errSave`)}`,
                  bgColor: "bg-danger",
                  textColor: "text-white",
                });
                this.showToast = true;
                this.btnLoadingSave = false;
              }
            });
        } else {
          settingTerminalFacilityApi
            .addTerminalFacility({
              ...formFacilityBody,
            })
            .then(function (result) {
              return result;
            })
            .catch(function (result) {
              return result;
            })
            .then((result) => {
              // console.log("result", result);
              if (result.status === 200) {
                this.btnLoadingSave = false;
                this.showModal = false;
                this.selectedTableRow = null;
                this.formFacility = {};

                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.successSave`)}`,
                  bgColor: "bg-success",
                  textColor: "text-white",
                });
                this.showToast = true;

                this.fetchFacility();
              } else {
                console.log("err");
                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.errSave`)}`,
                  bgColor: "bg-danger",
                  textColor: "text-white",
                });
                this.showToast = true;
                this.btnLoadingSave = false;
              }
            });
        }
      }
    },
    formatStrColGroupFacility(objFG) {
      if (objFG) {
        let fgString = "";
        const lenObj = objFG.length;
        for (let i = 0; i < lenObj; i += 1) {
          fgString = fgString + objFG[i] + (i < lenObj - 1 ? ", " : "");
        }
        return fgString;
      } else {
        return "";
      }
    },
    getRelatedLoyaltyFacilityIds() {
      this.listRLF = [];
      for (let index in this.dataResp.data) {
        if (this.dataResp.data[index].originType === "LOYALTY_FACILITY") {
          this.listRLF.push(this.dataResp.data[index]);
        }
      }
    },
  },
  watch: {
    "formFacility.beginDate"() {
      if (this.formFacility.expiredDate) {
        let startDate = new Date(this.formFacility.beginDate);
        let endDate = new Date(this.formFacility.expiredDate);
        // console.log("formTmsKey.effDate", startDate > endDate)
        if (startDate > endDate) {
          this.formFacility.expiredDate = startDate;
        }
      }
    },
    "formFacility.expiredDate"() {
      if (this.formFacility.expiredDate) {
        let startDate = new Date(this.formFacility.beginDate);
        let endDate = new Date(this.formFacility.expiredDate);
        if (startDate > endDate) {
          this.formFacility.expiredDate = startDate;
        }
      }
    },
  },
  mounted() {
    this.fetchFacility();
  },
};
</script>

<style scoped>
.select-td > td {
  background-color: #0d6efd !important;
  color: white !important;
}
</style>
