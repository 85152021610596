<template>
  <div class="row m-3 p-3 border border-1">
    <div class="col-md-6">
      <div class="mb-3">
        <label for="username" class="form-label">Ім'я користувача*</label>
        <input
          type="text"
          class="form-control"
          id="username"
          v-model.trim="v$.user_obj.username.$model"
        />
        <div v-if="v$.user_obj.username.required.$invalid" class="text-danger">
          Ім'я користувача є обов'язковим
        </div>
      </div>
      <div class="mb-3">
        <label for="email" class="form-label">Електронна пошта*</label>
        <input
          type="email"
          class="form-control"
          id="email"
          v-model.trim="v$.user_obj.email.$model"
        />
        <div v-if="v$.user_obj.email.required.$invalid" class="text-danger">
          Електронна пошта є обов'язковою
        </div>
        <div v-else-if="v$.user_obj.email.email.$invalid" class="text-danger">
          Введіть дійсну електронну адресу
        </div>
      </div>
      <div class="mb-3">
        <label for="first_name" class="form-label">Ім'я*</label>
        <input
          type="text"
          class="form-control"
          id="first_name"
          v-model.trim="v$.user_obj.first_name.$model"
        />
        <div
          v-if="v$.user_obj.first_name.required.$invalid"
          class="text-danger"
        >
          Ім'я є обов'язковим
        </div>
      </div>
      <div class="mb-3">
        <label for="last_name" class="form-label">Прізвище*</label>
        <input
          type="text"
          class="form-control"
          id="last_name"
          v-model.trim="v$.user_obj.last_name.$model"
        />
        <div v-if="v$.user_obj.last_name.required.$invalid" class="text-danger">
          Прізвище є обов'язковим
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="mb-3">
        <label for="password" class="form-label">Пароль*</label>
        <input
          type="text"
          class="form-control"
          id="password"
          v-model.trim="v$.user_obj.password.$model"
        />
        <div v-if="v$.user_obj.password.required.$invalid" class="text-danger">
          Пароль є обов'язковим
        </div>
      </div>
      <div class="mb-3">
        <label for="language" class="form-label">Мова*</label>
        <select
          class="form-select"
          id="language"
          v-model="v$.user_obj.language.$model"
        >
          <option value="" disabled>Виберіть мову</option>
          <option value="ENG">Англійська</option>
          <option value="UKR">Українська</option>
        </select>
        <div v-if="v$.user_obj.language.required.$invalid" class="text-danger">
          Мова є обов'язковою
        </div>
      </div>
      <div class="mb-3">
        <label for="user_role" class="form-label">Роль користувача*</label>
        <select
          class="form-select"
          id="user_role"
          v-model="v$.user_obj.user_role.$model"
        >
          <option value="" disabled>Виберіть роль користувача</option>
          <option
            v-for="role in userRoles"
            :key="role"
            :value="role.role_code"
            :class="{ invisible: role.role_code === '' }"
          >
            {{ role.role_title }}
          </option>
        </select>
        <div v-if="v$.user_obj.user_role.required.$invalid" class="text-danger">
          Роль користувача є обов'язковою
        </div>
      </div>
      <div class="d-flex justify-content-center py-3">
        <button type="button" class="btn btn-danger" @click="remove(indexF)">
          Видалити
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";

export default {
  name: "TrnSetupConfigUser",
  setup() {
    return { v$: useVuelidate() };
  },
  props: {
    user_obj: { type: Object, required: true },
    indexF: { type: Number, required: true },
    userRoles: { type: Object, required: true },
  },
  emits: ["deleteUser", "updateUser"],
  validations() {
    return {
      user_obj: {
        username: { required },
        email: { required, email },
        first_name: { required },
        last_name: { required },
        password: { required },
        language: { required },
        user_role: { required },
      },
    };
  },
  methods: {
    remove(index) {
      // this.currencies.splice(index, 1);
      this.$emit("deleteUser", index);
    },
    updateValueForm(event, inputName) {
      // Відправляємо подію з оновленим значенням
      let tempObj = {
        ...this.currency,
      };
      tempObj[inputName] = event.target.value;
      this.$emit("updateUser", tempObj);
    },
  },
};
</script>

<style scoped></style>
