<template>
  <div class="title-table mb-2 ps-3 py-1 fw-bold">
    {{ $t(`settings-menu.installations`) }}
  </div>
  <div class="container">
    <!--  Form start  -->
    <form class="needs-validation" @submit.prevent="formSetting" novalidate>
      <div class="row">
        <div class="col-md-6">
          <!--  input-appActiveTime  -->
          <div class="row mb-3">
            <label for="input-appActiveTime" class="col-sm-4 col-form-label">
              {{ $t(`Settings.Installations.form.appActiveTime`) }}
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-8">
              <input
                type="number"
                class="form-control"
                :class="addClassValidInput('appActiveTime')"
                id="input-appActiveTime"
                v-model="v$.form.appActiveTime.$model"
              />
              <div class="invalid-feedback">
                <div v-if="v$.form.appActiveTime.required.$invalid">
                  {{ $t(`Validations.required`) }}
                </div>
                <div v-if="v$.form.appActiveTime.minValue.$invalid">
                  {{ $t(`Validations.minValue`, { num: 1 }) }}
                </div>
                <div v-if="v$.form.appActiveTime.maxValue.$invalid">
                  {{ $t(`Validations.maxValue`, { num: 59 }) }}
                </div>
              </div>
            </div>
          </div>

          <!--  input-attestationTimeMin  -->
          <div class="row mb-3">
            <label
              for="input-attestationTimeMin"
              class="col-sm-4 col-form-label"
            >
              {{ $t(`Settings.Installations.form.attestationTimeMin`) }}
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-8">
              <input
                type="time"
                step="2"
                class="form-control"
                :class="addClassValidInput('attestationTimeMin')"
                id="input-attestationTimeMin"
                v-model="v$.form.attestationTimeMin.$model"
              />
              <div class="invalid-feedback">
                <div v-if="v$.form.attestationTimeMin.required.$invalid">
                  {{ $t(`Validations.required`) }}
                </div>
              </div>
            </div>
          </div>

          <!--  input-attestationTimeMax  -->
          <div class="row mb-3">
            <label
              for="input-attestationTimeMax"
              class="col-sm-4 col-form-label"
            >
              {{ $t(`Settings.Installations.form.attestationTimeMax`) }}
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-8">
              <input
                type="time"
                step="2"
                class="form-control"
                :class="addClassValidInput('attestationTimeMax')"
                id="input-attestationTimeMax"
                v-model="v$.form.attestationTimeMax.$model"
              />
              <div class="invalid-feedback">
                <div v-if="v$.form.attestationTimeMax.required.$invalid">
                  {{ $t(`Validations.required`) }}
                </div>
              </div>
            </div>
          </div>

          <!--  input-minReceiptNumber  -->
          <div class="row mb-3">
            <label for="input-minReceiptNumber" class="col-sm-4 col-form-label">
              {{ $t(`Settings.Installations.form.minReceiptNumber`) }}
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-8">
              <input
                type="number"
                class="form-control"
                :class="addClassValidInput('minReceiptNumber')"
                id="input-minReceiptNumber"
                v-model="v$.form.minReceiptNumber.$model"
              />
              <div class="invalid-feedback">
                <div v-if="v$.form.minReceiptNumber.required.$invalid">
                  {{ $t(`Validations.required`) }}
                </div>
                <div v-if="v$.form.minReceiptNumber.minValue.$invalid">
                  {{ $t(`Validations.minValue`, { num: 0 }) }}
                </div>
                <div v-if="v$.form.minReceiptNumber.maxValue.$invalid">
                  {{ $t(`Validations.maxValue`, { num: 999999 }) }}
                </div>
              </div>
            </div>
          </div>

          <!--  input-maxReceiptNumber  -->
          <div class="row mb-3">
            <label for="input-maxReceiptNumber" class="col-sm-4 col-form-label">
              {{ $t(`Settings.Installations.form.maxReceiptNumber`) }}
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-8">
              <input
                type="number"
                class="form-control"
                :class="addClassValidInput('maxReceiptNumber')"
                id="input-maxReceiptNumber"
                v-model="v$.form.maxReceiptNumber.$model"
              />
              <div class="invalid-feedback">
                <div v-if="v$.form.maxReceiptNumber.required.$invalid">
                  {{ $t(`Validations.required`) }}
                </div>
              </div>
            </div>
          </div>

          <!--  input-pendingNumber  -->
          <div class="row mb-3">
            <label for="input-pendingNumber" class="col-sm-4 col-form-label">
              {{ $t(`Settings.Installations.form.pendingNumber`) }}
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-8">
              <input
                type="number"
                class="form-control"
                :class="addClassValidInput('pendingNumber')"
                id="input-pendingNumber"
                v-model="v$.form.pendingNumber.$model"
              />
              <div class="invalid-feedback">
                <div v-if="v$.form.pendingNumber.required.$invalid">
                  {{ $t(`Validations.required`) }}
                </div>
              </div>
            </div>
          </div>

          <!--  input-pendingTime  -->
          <div class="row mb-3">
            <label for="input-pendingTime" class="col-sm-4 col-form-label">
              {{ $t(`Settings.Installations.form.pendingTime`) }}
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-8">
              <input
                type="number"
                class="form-control"
                :class="addClassValidInput('pendingTime')"
                id="input-pendingTime"
                v-model="v$.form.pendingTime.$model"
              />
              <div class="invalid-feedback">
                <div v-if="v$.form.pendingTime.required.$invalid">
                  {{ $t(`Validations.required`) }}
                </div>
                <div v-if="v$.form.pendingTime.minValue.$invalid">
                  {{ $t(`Validations.minValue`, { num: 0 }) }}
                </div>
              </div>
            </div>
          </div>

          <!--  input-timeZReport  -->
          <div class="row mb-3">
            <label for="input-timeZReport" class="col-sm-4 col-form-label">
              {{ $t(`Settings.Installations.form.timeZReport`) }}
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-8">
              <input
                type="time"
                step="2"
                class="form-control"
                :class="addClassValidInput('timeZReport')"
                id="input-timeZReport"
                v-model="v$.form.timeZReport.$model"
              />
              <div class="invalid-feedback">
                <div v-if="v$.form.timeZReport.required.$invalid">
                  {{ $t(`Validations.required`) }}
                </div>
              </div>
            </div>
          </div>

          <!--  input-phoneMask  -->
          <div class="row mb-3">
            <label for="input-phoneMask" class="col-sm-4 col-form-label">
              {{ $t(`Settings.Installations.form.phoneMask`) }}
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control"
                :class="addClassValidInput('phoneMask')"
                id="input-phoneMask"
                v-model="v$.form.phoneMask.$model"
              />
              <div class="invalid-feedback">
                <div v-if="v$.form.phoneMask.required.$invalid">
                  {{ $t(`Validations.required`) }}
                </div>
              </div>
            </div>
          </div>

          <!--  input-receiptHost  -->
          <div class="row mb-3">
            <label for="input-receiptHost" class="col-sm-4 col-form-label">
              {{ $t(`Settings.Installations.form.receiptHost`) }}
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control"
                :class="addClassValidInput('receiptHost')"
                id="input-receiptHost"
                v-model="v$.form.receiptHost.$model"
              />
              <div class="invalid-feedback">
                <div v-if="v$.form.receiptHost.required.$invalid">
                  {{ $t(`Validations.required`) }}
                </div>
                <div v-if="v$.form.receiptHost.maxLength.$invalid">
                  {{ $t(`Validations.maxLength`, { num: 32 }) }}
                </div>
                <div v-if="v$.form.receiptHost.minLength.$invalid">
                  {{ $t(`Validations.minLength`, { num: 1 }) }}
                </div>
              </div>
            </div>
          </div>

          <!--  input-cardMaskSymbol  -->
          <div class="row mb-3">
            <label for="input-cardMaskSymbol" class="col-sm-4 col-form-label">
              {{ $t(`Settings.Installations.form.cardMaskSymbol`) }}
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control"
                :class="addClassValidInput('cardMaskSymbol')"
                id="input-cardMaskSymbol"
                v-model="v$.form.cardMaskSymbol.$model"
              />
              <div class="invalid-feedback">
                <div v-if="v$.form.cardMaskSymbol.required.$invalid">
                  {{ $t(`Validations.required`) }}
                </div>
              </div>
            </div>
          </div>

          <!--  input-amountTimeout  -->
          <div class="row mb-3">
            <label for="input-amountTimeout" class="col-sm-4 col-form-label">
              {{ $t(`Settings.Installations.form.amountTimeout`) }}
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-8">
              <input
                type="number"
                class="form-control"
                :class="addClassValidInput('amountTimeout')"
                id="input-amountTimeout"
                v-model="v$.form.amountTimeout.$model"
              />
              <div class="invalid-feedback">
                <div v-if="v$.form.amountTimeout.required.$invalid">
                  {{ $t(`Validations.required`) }}
                </div>
                <div v-if="v$.form.amountTimeout.minValue.$invalid">
                  {{ $t(`Validations.minValue`, { num: 15 }) }}
                </div>
              </div>
            </div>
          </div>

          <!--  input-nfcTimeout  -->
          <div class="row mb-3">
            <label for="input-nfcTimeout" class="col-sm-4 col-form-label">
              {{ $t(`Settings.Installations.form.nfcTimeout`) }}
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-8">
              <input
                type="number"
                class="form-control"
                :class="addClassValidInput('nfcTimeout')"
                id="input-nfcTimeout"
                v-model="v$.form.nfcTimeout.$model"
              />
              <div class="invalid-feedback">
                <div v-if="v$.form.nfcTimeout.required.$invalid">
                  {{ $t(`Validations.required`) }}
                </div>
                <div v-if="v$.form.nfcTimeout.minValue.$invalid">
                  {{ $t(`Validations.minValue`, { num: 180 }) }}
                </div>
              </div>
            </div>
          </div>

          <!--  input-manualTimeout  -->
          <div class="row mb-3">
            <label for="input-manualTimeout" class="col-sm-4 col-form-label">
              {{ $t(`Settings.Installations.form.manualTimeoutMs`) }}
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-8">
              <input
                type="number"
                min="0"
                class="form-control"
                :class="addClassValidInput('manualTimeout')"
                id="input-manualTimeout"
                v-model="v$.form.manualTimeout.$model"
              />
              <div class="invalid-feedback">
                <div v-if="v$.form.manualTimeout.required.$invalid">
                  {{ $t(`Validations.required`) }}
                </div>
                <div v-if="v$.form.manualTimeout.minValue.$invalid">
                  {{ $t(`Validations.minValue`, { num: 180 }) }}
                </div>
              </div>
            </div>
          </div>

          <!--  input-proposalEmail  -->
          <div class="row mb-3">
            <label for="input-proposalEmail" class="col-sm-4 col-form-label">
              {{ $t(`Settings.Installations.form.proposalEmail`) }}
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-8">
              <input
                type="email"
                class="form-control"
                :class="addClassValidInput('proposalEmail')"
                id="input-proposalEmail"
                v-model="v$.form.proposalEmail.$model"
              />
              <div class="invalid-feedback">
                <div v-if="v$.form.proposalEmail.required.$invalid">
                  {{ $t(`Validations.required`) }}
                </div>
              </div>
            </div>
          </div>

          <!--  input-questionEmail  -->
          <div class="row mb-3">
            <label for="input-questionEmail" class="col-sm-4 col-form-label">
              {{ $t(`Settings.Installations.form.questionEmail`) }}
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-8">
              <input
                type="email"
                class="form-control"
                :class="addClassValidInput('questionEmail')"
                id="input-questionEmail"
                v-model="v$.form.questionEmail.$model"
              />
              <div class="invalid-feedback">
                <div v-if="v$.form.questionEmail.required.$invalid">
                  {{ $t(`Validations.required`) }}
                </div>
              </div>
            </div>
          </div>

          <!--  input-refundLimitDays  -->
          <div class="row mb-3">
            <label for="input-refundLimitDays" class="col-sm-4 col-form-label">
              {{ $t(`Settings.Installations.form.refundLimitDays`) }}
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-8">
              <input
                type="number"
                class="form-control"
                :class="addClassValidInput('refundLimitDays')"
                id="input-refundLimitDays"
                v-model="v$.form.refundLimitDays.$model"
              />
              <div class="invalid-feedback">
                <div v-if="v$.form.refundLimitDays.required.$invalid">
                  {{ $t(`Validations.required`) }}
                </div>
              </div>
            </div>
          </div>

          <!--  input-numRefundAttemptsForTransaction  -->
          <div class="row mb-3">
            <label
              for="input-numRefundAttemptsForTransaction"
              class="col-sm-4 col-form-label"
            >
              {{
                $t(
                  `Settings.Installations.form.numRefundAttemptsForTransaction`
                )
              }}
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-8">
              <input
                type="number"
                class="form-control"
                :class="addClassValidInput('numRefundAttemptsForTransaction')"
                id="input-numRefundAttemptsForTransaction"
                v-model="v$.form.numRefundAttemptsForTransaction.$model"
              />
              <div class="invalid-feedback">
                <div
                  v-if="
                    v$.form.numRefundAttemptsForTransaction.required.$invalid
                  "
                >
                  {{ $t(`Validations.required`) }}
                </div>
                <div
                  v-if="
                    v$.form.numRefundAttemptsForTransaction.minValue.$invalid
                  "
                >
                  {{ $t(`Validations.minValue`, { num: 1 }) }}
                </div>
              </div>
            </div>
          </div>

          <!--  input-technicalMerchantId  -->
          <div class="row mb-3">
            <label
              for="input-technicalMerchantId"
              class="col-sm-4 col-form-label"
            >
              {{ $t(`Settings.Installations.form.technicalMerchantId`) }}
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control"
                :class="addClassValidInput('technicalMerchantId')"
                id="input-technicalMerchantId"
                v-model="v$.form.technicalMerchantId.$model"
              />
              <div class="invalid-feedback">
                <div v-if="v$.form.technicalMerchantId.required.$invalid">
                  {{ $t(`Validations.required`) }}
                </div>
              </div>
            </div>
          </div>

          <!--  input-technicalTerminalId  -->
          <div class="row mb-3">
            <label
              for="input-technicalTerminalId"
              class="col-sm-4 col-form-label"
            >
              {{ $t(`Settings.Installations.form.technicalTerminalId`) }}
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control"
                :class="addClassValidInput('technicalTerminalId')"
                id="input-technicalTerminalId"
                v-model="v$.form.technicalTerminalId.$model"
              />
              <div class="invalid-feedback">
                <div v-if="v$.form.technicalTerminalId.required.$invalid">
                  {{ $t(`Validations.required`) }}
                </div>
              </div>
            </div>
          </div>

          <!--  input-supervisorCardNumber  -->
          <div class="row mb-3">
            <label
              for="input-supervisorCardNumber"
              class="col-sm-4 col-form-label"
            >
              {{ $t(`Settings.Installations.form.supervisorCardNumber`) }}
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control"
                :class="addClassValidInput('supervisorCardNumber')"
                id="input-supervisorCardNumber"
                v-model="v$.form.supervisorCardNumber.$model"
              />
              <div class="invalid-feedback">
                <div v-if="v$.form.supervisorCardNumber.required.$invalid">
                  {{ $t(`Validations.required`) }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card border-info mb-3">
            <div class="card-header">
              {{ $t(`Settings.Installations.text.networkCertificates`) }}
            </div>
            <div class="card-body">
              <!--  input-isoClientCertificate  -->
              <div class="row mb-3">
                <label
                  for="input-isoClientCertificate"
                  class="col-sm-4 col-form-label"
                >
                  {{ $t(`Settings.Installations.form.isoClientCertificate`) }}
                </label>
                <div class="col-sm-8">
                  <textarea
                    class="form-control"
                    :class="addClassValidInput('isoClientCertificate')"
                    id="input-isoClientCertificate"
                    v-model="v$.form.isoClientCertificate.$model"
                  />
                  <div class="invalid-feedback">
                  </div>
                </div>
              </div>

              <!--  input-isoIntermediateCertificate  -->
              <div class="row mb-3">
                <label
                  for="input-isoIntermediateCertificate"
                  class="col-sm-4 col-form-label"
                >
                  {{
                    $t(`Settings.Installations.form.isoIntermediateCertificate`)
                  }}
                </label>
                <div class="col-sm-8">
                  <textarea
                    class="form-control"
                    :class="addClassValidInput('isoIntermediateCertificate')"
                    id="input-isoIntermediateCertificate"
                    v-model="v$.form.isoIntermediateCertificate.$model"
                  />
                  <div class="invalid-feedback">
                  </div>
                </div>
              </div>

              <!--  input-isoRootCertificate  -->
              <div class="row mb-3">
                <label
                  for="input-isoRootCertificate"
                  class="col-sm-4 col-form-label"
                >
                  {{ $t(`Settings.Installations.form.isoRootCertificate`) }}
                </label>
                <div class="col-sm-8">
                  <textarea
                    class="form-control"
                    :class="addClassValidInput('isoRootCertificate')"
                    id="input-isoRootCertificate"
                    v-model="v$.form.isoRootCertificate.$model"
                  />
                  <div class="invalid-feedback">
                  </div>
                </div>
              </div>

              <!--  input-isoNetworkClientPk  -->
              <div class="row mb-3">
                <label
                  for="input-isoNetworkClientPk"
                  class="col-sm-4 col-form-label"
                >
                  {{ $t(`Settings.Installations.form.isoNetworkClientPk`) }}
                </label>
                <div class="col-sm-8">
                  <textarea
                    class="form-control"
                    :class="addClassValidInput('isoNetworkClientPk')"
                    id="input-isoNetworkClientPk"
                    v-model="v$.form.isoNetworkClientPk.$model"
                  />
                  <div class="invalid-feedback">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--  Buttons form  -->
      <div class="d-flex justify-content-center mb-3">
        <button
          type="submit"
          class="btn btn-outline-primary"
          :disabled="v$.$invalid && v$.$dirty ? true : false"
          ref="btnSubmit"
        >
          {{ $t(`uiElements.btn.save`) }}
        </button>
      </div>
    </form>
    <!--  Form end  -->
  </div>

  <div class="toast-container position-absolute p-3 end-0" v-if="showToast">
    <bs-toast v-for="toast in toastPropsList" :key="toast" :="toast"></bs-toast>
  </div>
</template>

<script>
import {
  maxLength,
  maxValue,
  minLength,
  minValue,
  required,
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import gsApi from "@/api/settings-api/installations";
import BsToast from "@/components/UI-elements/BsToast";

export default {
  name: "TrnInstallations",
  components: {
    BsToast,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      form: {},
      respData: {},
      showToast: false,
      toastPropsList: [],
    };
  },
  validations() {
    return {
      form: {
        appActiveTime: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(59),
        },
        attestationTimeMin: {
          required,
        },
        attestationTimeMax: {
          required,
        },
        minReceiptNumber: {
          required,
          minValue: minValue(0),
          maxValue: maxValue(999999),
        },
        maxReceiptNumber: {
          required,
        },
        pendingNumber: {
          required,
        },
        pendingTime: {
          required,
          minValue: minValue(0),
        },
        timeZReport: {
          required,
        },
        phoneMask: {
          required,
        },
        receiptHost: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(32),
        },
        cardMaskSymbol: {
          required,
        },
        amountTimeout: {
          required,
          minValue: minValue(15),
        },
        nfcTimeout: {
          required,
          minValue: minValue(180),
        },
        proposalEmail: {
          required,
        },
        questionEmail: {
          required,
        },
        refundLimitDays: {
          required,
        },
        numRefundAttemptsForTransaction: {
          required,
          minValue: minValue(1),
        },
        technicalMerchantId: {
          required,
        },
        technicalTerminalId: {
          required,
        },
        supervisorCardNumber: {
          required,
        },
        manualTimeout: {
          required,
          minValue: minValue(180),
        },
        isoClientCertificate: {},
        isoIntermediateCertificate: {},
        isoRootCertificate: {},
        isoNetworkClientPk: {},
      },
    };
  },
  methods: {
    addClassValidInput(inputName) {
      return {
        "is-invalid":
          this.v$.form[inputName].$error === true &&
          this.v$.form[inputName].$dirty === true,
        "is-valid":
          this.v$.form[inputName].$error === false &&
          this.v$.form[inputName].$dirty === true,
      };
    },
    formSetting() {
      this.v$.form.$touch();
      if (!this.v$.form.$error) {
        this.updateSettings();
      }
    },
    fetchSettings() {
      gsApi
        .getGeneralConfiguration()
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          // console.log("result", result);
          if (result.status === 200) {
            // console.log("result", result);
            this.respData = { ...result.data };
            this.form = { ...this.respData };
          } else {
            console.log("err");
            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.errSave`)}`,
              bgColor: "bg-danger",
              textColor: "text-white",
            });
            this.showToast = true;
          }
        });
    },
    updateSettings() {
      this.$refs.btnSubmit.disabled = true;
      gsApi
        .updateGeneralConfiguration({ ...this.form })
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          // console.log("result", result);
          if (result.status === 200) {
            this.showModal = false;

            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.successSave`)}`,
              bgColor: "bg-success",
              textColor: "text-white",
            });
            this.showToast = true;
            this.$refs.btnSubmit.disabled = false;

            // this.fetchSettings();
          } else {
            console.log("err");
            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.errSave`)}`,
              bgColor: "bg-danger",
              textColor: "text-white",
            });
            this.showToast = true;
            this.$refs.btnSubmit.disabled = false;
          }
        });
    },
  },
  mounted() {
    this.fetchSettings();
  },
};
</script>

<style scoped></style>
