<template>
  <div class="title-table mb-2 ps-3 py-1 fw-bold">
    {{ $t(`settings-menu.entityAudit`) }}
  </div>
  <div class="toolbar mb-2">
    <div class="btn-group btn-group-sm ps-3" role="group">
      <button
        type="button"
        class="btn btn-outline-primary"
        title="Reload page"
        @click="reloadPage"
      >
        <i class="bi bi-arrow-clockwise"></i>
      </button>
      <!--Фільтри-->
      <button
        type="button"
        class="btn btn-outline-primary"
        data-bs-toggle="dropdown"
        data-bs-auto-close="outside"
        aria-expanded="false"
        ref="dropdownBtnFilter"
      >
        <i class="bi bi-funnel-fill"></i>
      </button>
      <form
        class="dropdown-menu p-4"
        style="width: 500px"
        @submit.prevent="formFilterRm"
        ref="dropdownFormFilter"
      >
        <div class="row mb-3">
          <label
            for="registrationDateByDays-ff"
            class="col-sm-4 col-form-label col-form-label-sm"
            >{{ $t(`EntityAudit.table.date`) }}</label
          >
          <div class="col-sm-8">
            <input
              v-model.trim="filters.date"
              type="date"
              class="form-control form-control-sm"
              id="registrationDateByDays-ff"
              placeholder="Не выбрано"
            />
          </div>
        </div>
        <div class="row mb-3">
          <label
            for="merchantName-ff"
            class="col-sm-4 col-form-label col-form-label-sm"
            >{{ $t(`EntityAudit.table.entityId`) }}</label
          >
          <div class="col-sm-8">
            <input
              v-model.trim="filters.entityId"
              type="text"
              class="form-control form-control-sm"
              id="merchantName-ff"
              placeholder="Не выбрано"
              v-maska
              data-maska="##################"
            />
          </div>
        </div>
        <div class="row mb-3">
          <label
            for="entityName-ff"
            class="col-sm-4 col-form-label col-form-label-sm"
            >{{ $t(`EntityAudit.table.entityName`) }}</label
          >
          <div class="col-sm-8">
            <input
              v-model.trim="filters.entityName"
              type="text"
              class="form-control form-control-sm"
              id="entityName-ff"
              placeholder="Не выбрано"
              v-maska
              data-maska="@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@"
            />
          </div>
        </div>
        <div class="row mb-3">
          <label
            for="merchantId-ff"
            class="col-sm-4 col-form-label col-form-label-sm"
            >{{ $t(`EntityAudit.table.type`) }}</label
          >
          <div class="col-sm-8">
            <input
              v-model.trim="filters.type"
              type="text"
              class="form-control form-control-sm"
              id="merchantId-ff"
              placeholder="Не выбрано"
              v-maska
              data-maska="@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@"
            />
          </div>
        </div>

        <div class="row mb-3">
          <label
            for="username-ff"
            class="col-sm-4 col-form-label col-form-label-sm"
            >{{ $t(`EntityAudit.table.username`) }}</label
          >
          <div class="col-sm-8">
            <input
              v-model.trim="filters.username"
              type="text"
              class="form-control form-control-sm"
              id="username-ff"
              placeholder="Не выбрано"
              maxlength="255"
            />
          </div>
        </div>

        <div class="justify-content-end d-flex">
          <button
            class="btn btn-outline-primary btn-sm me-3"
            type="button"
            @click="resetFilterForm"
          >
            <i class="bi bi-x-square me-1"></i>
            <span>{{ $t(`uiElements.btn.resetFilters`) }}</span>
          </button>
          <button type="submit" class="btn btn-primary btn-sm">
            <i class="bi bi-clipboard-check me-1"></i>
            <span>{{ $t(`uiElements.btn.apply`) }}</span>
          </button>
        </div>
      </form>
      <!--Кінець фільтрів-->
    </div>
  </div>
  <div
    class="table-responsive table-block overflow-auto w-auto"
    id="table-block"
    @scroll="scrollTable"
  >
    <table
      class="table table-striped table-hover table-sm table-light text-center"
    >
      <thead>
        <tr>
          <th
            scope="col"
            data-col-sort="date"
            @click="clickSortCol($event, 'date')"
          >
            {{ $t(`EntityAudit.table.date`) }}
            <div class="icon-sort">
              <i class="bi bi-arrow-down-up"></i>
            </div>
          </th>
          <th
            scope="col"
            data-col-sort="entityId"
            @click="clickSortCol($event, 'entityId')"
          >
            {{ $t(`EntityAudit.table.entityId`) }}
            <div class="icon-sort">
              <i class="bi bi-arrow-down-up"></i>
            </div>
          </th>
          <th
            scope="col"
            data-col-sort="entityName"
            @click="clickSortCol($event, 'entityName')"
          >
            {{ $t(`EntityAudit.table.entityName`) }}
            <div class="icon-sort">
              <i class="bi bi-arrow-down-up"></i>
            </div>
          </th>
          <th
            scope="col"
            data-col-sort="type"
            @click="clickSortCol($event, 'type')"
          >
            {{ $t(`EntityAudit.table.type`) }}
            <div class="icon-sort">
              <i class="bi bi-arrow-down-up"></i>
            </div>
          </th>
          <th
            scope="col"
            data-col-sort="username"
            @click="clickSortCol($event, 'username')"
          >
            {{ $t(`EntityAudit.table.username`) }}
            <div class="icon-sort">
              <i class="bi bi-arrow-down-up"></i>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-if="isLoading">
          <tr>
            <td colspan="4">
              <div class="loading text-center">
                <trn-loading></trn-loading>
              </div>
            </td>
          </tr>
        </template>
        <template v-if="dataResp">
          <tr v-for="item in dataResp.content" :key="item">
            <td class="">
              {{ $filters.formatDateTime(item.date) }}
            </td>
            <td class="text-nowrap">{{ item.entityId }}</td>
            <td class="text-nowrap">{{ item.entityName }}</td>
            <td class="text-nowrap">{{ item.type }}</td>
            <td class="text-nowrap">{{ item.username }}</td>
          </tr>
          <template v-if="isLoadingScroll">
            <tr>
              <td colspan="22">
                <div class="loading text-center">
                  <trn-loading></trn-loading>
                </div>
              </td>
            </tr>
          </template>
          <template v-if="dataResp?.content.length === 0">
            <tr>
              <td colspan="16">
                <div class="text-center fw-bold bg-info text-black">
                  {{ $t(`uiElements.text.noData`) }}
                </div>
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import TrnLoading from "@/components/Loading";
import { mapState } from "vuex";
import { stringify } from "query-string";
import { actionTypes } from "@/store/modules/settings-store/entity-audit";

export default {
  name: "TrnEntityAudit",
  components: { TrnLoading },
  data() {
    return {
      page: 0,
      size: 50,
      sort: {
        col: "date",
        order: "desc",
      },
      filters: {
        date: "",
        entityId: "",
        entityName: "",
        type: "",
        username: "",
      },
      showToast: false,
      showModal: false,
      showFormReg: false,
      showFileReg: false,
      toastPropsList: [],
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.settingEntityAudit.isLoading,
      isLoadingScroll: (state) => state.settingEntityAudit.isLoadingScroll,
      dataResp: (state) => state.settingEntityAudit.dataResp,
      errorResp: (state) => state.settingEntityAudit.errorResp,
    }),
  },
  methods: {
    fetchAllAuditEntity() {
      this.changeArrowIcon();
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
        ...this.filters,
      });
      this.$store.dispatch(actionTypes.getEntityAudit, {
        apiParams: stringifiedParams,
      });
    },
    scrollTable(event) {
      const currentEl = event.target;
      let checkScrollEnd =
        currentEl.scrollTop + currentEl.clientHeight + 3 >= currentEl.scrollHeight;
      if (checkScrollEnd && this.isLoadingScroll === false) {
        const currentPage = this.page + 1;
        const totalPages = this.dataResp.totalPages;
        if (currentPage < totalPages) this.loadMoreData();
      }
    },
    loadMoreData() {
      this.page += 1;
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
        ...this.filters,
      });
      this.$store.dispatch(actionTypes.getEntityAuditScrollPush, {
        apiParams: stringifiedParams,
      });
    },
    reloadPage() {
      this.page = 0;
      this.fetchAllAuditEntity();
    },
    clickSortCol(event, newSortItem) {
      const scrollTableEl = document.getElementById("table-block");
      const beforeSortKey = this.sort.col;
      const beforeSortDirection = this.sort.order;

      const beforeEl = document.querySelectorAll(
        `[data-col-sort~=${beforeSortKey}]`
      );
      beforeEl[0].children[0].innerHTML = '<i class="bi bi-arrow-down-up"></i>';

      // let newSortVal = [newSortItem, ""];

      if (beforeSortDirection === "desc" && beforeSortKey === newSortItem) {
        this.sort.order = "asc";
      } else {
        this.sort.order = "desc";
      }
      this.page = 0;
      this.sort.col = newSortItem;
      scrollTableEl.scrollTo(0, 0);
      this.fetchAllAuditEntity();
    },
    changeArrowIcon(sortKey = this.sort.col, sortDirection = this.sort.order) {
      const targetEl = document.querySelectorAll(`[data-col-sort~=${sortKey}]`);
      if (sortDirection === "asc") {
        targetEl[0].children[0].innerHTML =
          '<i class="bi bi-arrow-up-square"></i>';
      } else {
        targetEl[0].children[0].innerHTML =
          '<i class="bi bi-arrow-down-square"></i>';
      }
    },
    formFilterRm() {
      this.$refs.dropdownBtnFilter.click();
      this.fetchAllAuditEntity();
    },
    resetFilterForm() {
      for (let item in this.filters) {
        this.filters[item] = "";
      }
      this.registrationDateRange = ["", ""];
      this.$refs.dropdownBtnFilter.click();
      this.fetchAllAuditEntity();
    },
  },
  mounted() {
    this.fetchAllAuditEntity();
  },
};
</script>

<style scoped></style>
