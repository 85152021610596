<template>
  <div class="list-group list-group-flush mt-3">
    <router-link
      :to="{ name: 'merchants' }"
      class="list-group-item list-group-item-action"
      active-class="active"
      v-if="checkMenuAccess('MERCHANT_VIEW')"
    >
      <i class="bi bi-people-fill me-3"></i>
      <span>{{ $t("left-menu.merchant") }}</span>
    </router-link>

    <router-link
      :to="{ name: 'terminal' }"
      class="list-group-item list-group-item-action"
      active-class="active"
      v-if="checkMenuAccess('TERMINAL_VIEW')"
    >
      <i class="bi bi-phone me-3"></i>
      <span>{{ $t("left-menu.terminal") }}</span>
    </router-link>

    <router-link
      :to="{ name: 'terminalGroups' }"
      class="list-group-item list-group-item-action"
      active-class="active"
      v-if="checkMenuAccess('TERMINAL_GROUPS_VIEW')"
    >
      <i class="bi bi-collection me-3"></i>
      <span>{{ $t("left-menu.serviceGroup") }}</span>
    </router-link>

    <router-link
      :to="{ name: 'merchantRegistration' }"
      class="list-group-item list-group-item-action"
      active-class="active"
      v-if="checkMenuAccess('MERCHANT_VIEW')"
    >
      <i class="bi bi-person-rolodex me-3"></i>
      <span>{{ $t("left-menu.merchantRegistration") }}</span>
    </router-link>

    <router-link
      :to="{ name: 'transactions' }"
      class="list-group-item list-group-item-action"
      active-class="active"
      v-if="checkMenuAccess('TRANSACTIONS_VIEW')"
    >
      <i class="bi bi-arrow-left-right me-3"></i>
      <span>{{ $t("left-menu.transaction") }}</span>
    </router-link>

    <router-link
      :to="{ name: 'attestationHistory' }"
      class="list-group-item list-group-item-action"
      active-class="active"
      v-if="checkMenuAccess('ATTESTATION_HISTORY_VIEW')"
    >
      <i class="bi bi-calendar3-range me-3"></i>
      <span>{{ $t("left-menu.attestationHistory") }}</span>
    </router-link>

    <router-link
      :to="{ name: 'zReportTransactions' }"
      class="list-group-item list-group-item-action"
      active-class="active"
      v-if="checkMenuAccess('TERMINAL_VIEW')"
    >
      <i class="bi bi-clipboard-check me-3"></i>
      <span>{{ $t("left-menu.zReportTransactions") }}</span>
    </router-link>

    <router-link
      :to="{ name: 'analytics' }"
      class="list-group-item list-group-item-action"
      active-class="active"
      v-if="checkMenuAccess('ANALYTICS_VIEW')"
    >
      <i class="bi bi-graph-up me-3"></i>
      <span>{{ $t("left-menu.analytics") }}</span>
    </router-link>

    <router-link
      :to="{ name: 'monitoring' }"
      class="list-group-item list-group-item-action"
      active-class="active"
      v-if="checkMenuAccess('MONITORING_VIEW')"
    >
      <i class="bi bi-tv me-3"></i>
      <span>{{ $t("left-menu.monitoring") }}</span>
    </router-link>

    <router-link
      :to="{ name: 'message' }"
      class="list-group-item list-group-item-action"
      active-class="active"
      v-if="checkMenuAccess('MESSAGE_VIEW')"
    >
      <i class="bi bi-envelope-check me-3"></i>
      <span>{{ $t("left-menu.message") }}</span>
    </router-link>

    <router-link
      :to="{ name: 'devices' }"
      class="list-group-item list-group-item-action"
      active-class="active"
      v-if="checkMenuAccess('TERMINAL_VIEW')"
    >
      <i class="bi bi-arrow-left-right me-3"></i>
      <span>{{ $t("left-menu.devices") }}</span>
    </router-link>

    <router-link
      :to="{ name: 'regularReports' }"
      class="list-group-item list-group-item-action"
      active-class="active"
      v-if="checkMenuAccess('TERMINAL_VIEW')"
    >
      <i class="bi bi-file-ruled me-3"></i>
      <span>{{ $t("left-menu.regularReports") }}</span>
    </router-link>

    <router-link
      :to="{ name: 'businessDayCalculation' }"
      class="list-group-item list-group-item-action visually-hidden"
      active-class="active"
      v-if="checkMenuAccess('TERMINAL_VIEW')"
    >
      <i class="bi bi-calendar2-event me-3"></i>
      <span>{{ $t("left-menu.businessDayCalculation") }}</span>
    </router-link>

    <router-link
      :to="{ name: 'reportsSettlement' }"
      class="list-group-item list-group-item-action"
      active-class="active"
      v-if="checkMenuAccess('SETTLEMENT_TERMINAL_VIEW')"
    >
      <i class="bi bi-calendar2-week me-3"></i>
      <span>{{ $t("left-menu.reportsSettlement") }}</span>
    </router-link>

    <router-link
      :to="{ name: 'regulatoryCommissions' }"
      class="list-group-item list-group-item-action"
      active-class="active"
      v-if="
        checkMenuAccess('FATA_SHIPMENT_REPORT_VIEW') && isIncludeServiceOfFata
      "
    >
      <i class="bi bi-calendar3 me-3"></i>
      <span>{{ $t("left-menu.regulatoryCommissions") }}</span>
    </router-link>
  </div>
</template>

<script>
import { getItemLocalStorage } from "@/helpers/persistanceStorage";

export default {
  name: "TrnLeftbar",
  data() {
    return {
      userAuthorities: [],
      isIncludeServiceOfFata: null,
    };
  },
  methods: {
    checkMenuAccess(itemMenu) {
      return this.userAuthorities.indexOf(itemMenu) !== -1;
    },
  },
  mounted() {
    if (getItemLocalStorage("authorities") === undefined) {
      this.userAuthorities = [];
    } else {
      this.userAuthorities = getItemLocalStorage("authorities");
    }
    if (getItemLocalStorage("isIncludeServiceOfFata") === undefined) {
      this.isIncludeServiceOfFata = false;
    } else {
      this.isIncludeServiceOfFata = JSON.parse(
        getItemLocalStorage("isIncludeServiceOfFata")
      );
    }
  },
};
</script>

<style scoped>
.left-block .active {
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
</style>
