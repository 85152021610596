<script>
import { required } from "@vuelidate/validators";
import merchantApi from "@/api/merchant";
import useVuelidate from "@vuelidate/core";

export default {
  name: "TrnMerchantRegistrationDeleteForm",
  setup() {
    return { v$: useVuelidate() };
  },
  emits: ["hideMainModal", "showToastContent", "updatePage"],
  data() {
    return {
      formMerchantDelete: {},
    };
  },
  validations() {
    return {
      formMerchantDelete: {
        merchantRegId: {
          required: required,
        },
      },
    };
  },
  methods: {
    addClassValidInputDelete(inputName) {
      return {
        "is-invalid":
          this.v$.formMerchantDelete[inputName].$error === true &&
          this.v$.formMerchantDelete[inputName].$dirty === true,
        "is-valid":
          this.v$.formMerchantDelete[inputName].$error === false &&
          this.v$.formMerchantDelete[inputName].$dirty === true,
      };
    },
    formDeleteMID() {
      this.v$.formMerchantDelete.$touch();
      if (!this.v$.formMerchantDelete.$error) {
        merchantApi
          .deleteMerchant(this.formMerchantDelete.merchantRegId)
          .then(function (result) {
            return result;
          })
          .catch(function (result) {
            return result;
          })
          .then((result) => {
            // console.log("result", result);
            if (result.status === 200) {
              this.formMerchantDelete.merchantRegId = "";
              this.$emit("showToastContent", {
                content: `${this.$t(`uiElements.toast.success`)}`,
                bgColor: "bg-success",
                textColor: "text-white",
              });
              this.$emit("hideMainModal");
              this.$emit("updatePage");
            } else {
              console.log("err");
              this.toastPropsList.push({
                content: `${this.$t(`uiElements.toast.err`)}`,
                bgColor: "bg-danger",
                textColor: "text-white",
              });
              this.showToast = true;
            }
          });
      }
    },
  },
};
</script>

<template>
  <form class="needs-validation" @submit.prevent="formDeleteMID" novalidate>
    <!--  input-merchantName  -->
    <div class="row mb-3">
      <label for="input-merchantName" class="col-sm-6 col-form-label">
        {{ $t(`MerchantRegistration.table.merchantRegId`) }}
        <span class="text-danger">*</span>
      </label>
      <div class="col-sm-6">
        <input
          type="number"
          class="form-control"
          :class="addClassValidInputDelete('merchantRegId')"
          id="input-merchantRegId"
          v-model="v$.formMerchantDelete.merchantRegId.$model"
        />
        <div class="invalid-feedback">
          <div v-if="v$.formMerchantDelete.merchantRegId.required.$invalid">
            {{ $t(`Validations.required`) }}
          </div>
        </div>
      </div>
    </div>

    <!--  Buttons form  -->
    <div class="d-flex justify-content-end">
      <button
        type="button"
        class="btn btn-outline-secondary me-3"
        @click="$emit('hideMainModal')"
      >
        {{ $t(`uiElements.btn.close`) }}
      </button>
      <button
        type="submit"
        class="btn btn-danger"
        :disabled="v$.$invalid && v$.$dirty ? true : false"
      >
        {{ $t(`uiElements.btn.delete`) }}
      </button>
    </div>
  </form>
</template>

<style scoped></style>
