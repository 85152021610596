<template>
  <div class="title-table mb-2 ps-3 py-1 fw-bold">
    {{ $t(`left-menu.businessDayCalculation`) }}
  </div>

  <div class="fluid-container">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="home-tab"
          data-bs-toggle="tab"
          data-bs-target="#home"
          type="button"
          role="tab"
          aria-controls="home"
          aria-selected="true"
        >
          <i class="bi bi-calculator"></i>
          {{ $t(`BusinessDayCalculation.tab.calculation`) }}
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="profile-tab"
          data-bs-toggle="tab"
          data-bs-target="#bdc-archive"
          type="button"
          role="tab"
          aria-controls="bdc-archive"
          aria-selected="false"
        >
          <i class="bi bi-card-list"></i>
          {{ $t(`BusinessDayCalculation.tab.archive`) }}
        </button>
      </li>
    </ul>
    <div class="tab-content" id="tabContent">
      <!--   tab HOME   -->
      <div
        class="tab-pane fade show active"
        id="home"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <div class="">
          <div
            class="row g-0 border-bottom border-2 border-secondary"
            v-if="dataRespHome"
          >
            <div class="col-md-4">
              <div
                class="my-3 row-container text-end bg-info text-dark px-3 rounded-2 fw-bold"
              >
                <span
                  >{{ $t(`BusinessDayCalculation.text.total`) }}
                  {{ $filters.formatAmount(dataRespHome.allAmount) }}
                  {{ dataRespHome.currency?.letterCode }} |
                </span>
                <span
                  >{{ $t(`BusinessDayCalculation.text.commission`) }}
                  {{ $filters.formatAmount(dataRespHome.allFacility) }}
                  {{ dataRespHome.currency?.letterCode }} |
                </span>
                <span
                  >{{ $t(`BusinessDayCalculation.text.toBeEnrolled`) }}
                  {{
                    $filters.formatAmount(dataRespHome.allAmountMinusFacility)
                  }}
                  {{ dataRespHome.currency?.letterCode }}
                </span>
              </div>
            </div>
            <div class="col-md-8">
              <div class="d-flex justify-content-end my-2 me-3">
                <button
                  class="btn btn-success fw-bolder"
                  @click="clickGenerateCalculation"
                  :disabled="dataRespHome.content.length === 0"
                >
                  {{ $t(`BusinessDayCalculation.btn.generateCalculation`) }}
                  <i class="bi my-3 bi-play"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="row g-0" v-if="dataRespHome">
            <div class="col">
              <div
                class="container-data"
                v-for="item in dataRespHome.content"
                :key="item"
              >
                <div
                  class="merchant-row d-flex justify-content-between my-3 bg-info text-dark px-3 rounded-2 fw-bold"
                >
                  <div class="left-block">
                    <span>{{ item.merchantId }} | </span>
                    <span>{{ item.merchantLocation }}</span>
                  </div>
                  <div class="right-block">
                    <span
                      >{{ $t(`BusinessDayCalculation.text.total`) }}
                      {{ $filters.formatAmount(item.allAmount) }}
                      {{ item.currency.letterCode }} |
                    </span>
                    <span
                      >{{ $t(`BusinessDayCalculation.text.commission`) }}
                      {{ $filters.formatAmount(item.allFacility) }}
                      {{ item.currency.letterCode }} |
                    </span>
                    <span
                      >{{ $t(`BusinessDayCalculation.text.toBeEnrolled`) }}
                      {{ $filters.formatAmount(item.allAmountMinusFacility) }}
                      {{ item.currency.letterCode }}
                    </span>
                  </div>
                </div>
                <div
                  class="table-responsive table-block overflow-auto border border-2 border-primary p-1 rounded-2 mx-2"
                  id="table-block"
                >
                  <table
                    class="table table-striped table-hover table-sm table-light fs-6"
                  >
                    <thead>
                      <tr class="">
                        <th scope="col">
                          {{ $t(`BusinessDayCalculation.table.terminalId`) }}
                        </th>
                        <th scope="col">
                          {{
                            $t(`BusinessDayCalculation.table.groupFacilityId`)
                          }}
                        </th>
                        <th scope="col">
                          {{
                            $t(
                              `BusinessDayCalculation.table.groupTerminalNumber`
                            )
                          }}
                        </th>
                        <th scope="col">
                          {{
                            $t(`BusinessDayCalculation.table.allStaticFacility`)
                          }}
                        </th>
                        <th scope="col">
                          {{
                            $t(
                              `BusinessDayCalculation.table.allCalculatedFacilityValue`
                            )
                          }}
                        </th>
                        <th scope="col">
                          {{ $t(`BusinessDayCalculation.table.allAmount`) }}
                        </th>
                        <th scope="col">
                          {{
                            $t(
                              `BusinessDayCalculation.table.amountMinusFacility`
                            )
                          }}
                        </th>
                        <th scope="col">
                          {{
                            $t(`BusinessDayCalculation.table.numberOfPurchases`)
                          }}
                        </th>
                        <th scope="col">
                          {{
                            $t(`BusinessDayCalculation.table.numberOfReversal`)
                          }}
                        </th>
                        <th scope="col">
                          {{
                            $t(`BusinessDayCalculation.table.numberOfRefund`)
                          }}
                        </th>
                        <th scope="col">
                          {{ $t(`BusinessDayCalculation.table.numberOfAll`) }}
                        </th>
                      </tr>
                    </thead>
                    <tbody class="text-nowrap">
                      <tr
                        v-for="itemTable in item.usedFacilities"
                        :key="itemTable"
                        class=""
                      >
                        <td>{{ itemTable.terminalId }}</td>
                        <td>{{ itemTable.groupFacilityId }}</td>
                        <td>{{ itemTable.groupTerminalNumber }}</td>
                        <td>
                          {{ itemTable.allStaticFacility }}
                          {{ itemTable.currency.letterCode }} +
                          {{ itemTable.allPercentageFacility }}
                        </td>
                        <td>
                          {{
                            $filters.formatAmount(
                              itemTable.allCalculatedFacilityValue
                            )
                          }}
                          {{ itemTable.currency.letterCode }}
                        </td>
                        <td>
                          {{ $filters.formatAmount(itemTable.allAmount) }}
                          {{ itemTable.currency.letterCode }}
                        </td>
                        <td>
                          {{
                            $filters.formatAmount(itemTable.amountMinusFacility)
                          }}
                          {{ itemTable.currency.letterCode }}
                        </td>
                        <td>{{ itemTable.numberOfPurchases }}</td>
                        <td>{{ itemTable.numberOfReversal }}</td>
                        <td>{{ itemTable.numberOfRefund }}</td>
                        <td>{{ itemTable.numberOfAll }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="w-100 text-center my-3" v-else>
            <trn-loading></trn-loading>
          </div>
        </div>
      </div>
      <!--   tab ARCHIVE   -->
      <div
        class="tab-pane fade"
        id="bdc-archive"
        role="tabpanel"
        aria-labelledby="profile-tab"
      >
        <div class="tool-bar my-3 col-md-4">
          <div class="input-group">
            <span class="input-group-text fw-bold">{{
              $t(`BusinessDayCalculation.form.date`)
            }}</span>
            <Datepicker
              id="ff-date"
              v-model="formArchive.date"
              autoApply
              locale="en"
              :format="dateFormat"
            />
            <span class="input-group-text fw-bold">{{
              $t(`BusinessDayCalculation.form.billingCalculationNumber`)
            }}</span>
            <input
              type="number"
              min="0"
              v-maska="'#*'"
              data-maska="#*"
              class="form-control"
              id="input-billingCalculationNumber"
              v-model="formArchive.billingCalculationNumber"
              @input="businessDayCalculationArchive"
            />
          </div>
        </div>
        <div class="col-md-12">
          <div class="row g-0" v-if="dataRespArchive">
            <div class="col">
              <div
                class="container-data"
                v-for="item in dataRespArchive.content"
                :key="item"
              >
                <div
                  class="merchant-row d-flex justify-content-between my-3 bg-info text-dark px-3 rounded-2 fw-bold"
                >
                  <div class="left-block">
                    <span>{{ item.terminalId }} | </span>
                    <span
                      >{{
                        $t(
                          `BusinessDayCalculation.text.billingCalculationNumber`
                        )
                      }}{{ item.billingCalculationNumber }}</span
                    >
                  </div>
                  <div class="right-block">
                    <span
                      >{{ $t(`BusinessDayCalculation.text.total`) }}
                      {{ $filters.formatAmount(item.allFacilityAmount) }}
                      {{ item.currency.letterCode }} |
                    </span>
                    <span
                      >{{ $t(`BusinessDayCalculation.text.createdDate`) }}
                      {{ $filters.formatDateTime(item.createdDate) }}
                    </span>
                  </div>
                </div>
                <div
                  class="table-responsive table-block overflow-auto border border-2 border-primary p-1 rounded-2 mx-2"
                  id="table-block"
                >
                  <table
                    class="table table-striped table-hover table-sm table-light fs-6"
                  >
                    <thead>
                      <tr class="">
                        <th scope="col">
                          {{ $t(`BusinessDayCalculation.table.facilityId`) }}
                        </th>
                        <th scope="col">
                          {{ $t(`BusinessDayCalculation.table.facilityName`) }}
                        </th>
                        <th scope="col">
                          {{
                            $t(`BusinessDayCalculation.table.calculatedValue`)
                          }}
                        </th>
                      </tr>
                    </thead>
                    <tbody class="text-nowrap">
                      <tr
                        v-for="itemTable in item.usedFacilities"
                        :key="itemTable"
                        class=""
                      >
                        <td>{{ itemTable.facilityId }}</td>
                        <td>{{ itemTable.facilityName }}</td>
                        <td>
                          {{ $filters.formatAmount(itemTable.calculatedValue) }}
                          {{ item.currency.letterCode }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                v-if="dataRespArchive.content.length === 0"
                class="text-center fw-bold bg-info text-black"
              >
                {{ $t(`uiElements.text.noData`) }}
              </div>
            </div>
          </div>
          <div class="w-100 text-center my-3" v-else>
            <trn-loading></trn-loading>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="toast-container position-absolute p-3 end-0 z-index-1500"
    v-if="showToast"
  >
    <bs-toast v-for="toast in toastPropsList" :key="toast" :="toast"></bs-toast>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { actionTypes } from "@/store/modules/business-day-calculation";
import TrnLoading from "@/components/Loading";
import Datepicker from "vue3-date-time-picker";
import { stringify } from "query-string";
import businessDayCalculationApi from "@/api/business-day-calculation";
import BsToast from "@/components/UI-elements/BsToast";
import {maska} from "maska";

export default {
  name: "TrnBusinessDayCalculation",
  components: { TrnLoading, Datepicker, BsToast },
  directives: { maska },
  data() {
    return {
      getArchiveReq: {
        page: 0,
        size: 30,
        sort: {
          col: "createdDate",
          order: "asc",
        },
      },
      showModal: false,
      titleModal: "",
      typeModalForm: "",
      showToast: false,
      toastPropsList: [],
      formArchive: {
        date: null,
        billingCalculationNumber: null,
        startDate: null,
        endDate: null,
      },
      dateFormat: "dd/MM/yyyy",
    };
  },
  computed: {
    dataRespHome: {
      get() {
        return this.$store.state.businessDayCalculation.dataResp;
      },
      set(newValue) {
        this.$store.state.businessDayCalculation.dataResp = newValue;
      },
    },
    dataRespArchive: {
      get() {
        return this.$store.state.businessDayCalculation.dataRespArchive;
      },
      set(newValue) {
        this.$store.state.businessDayCalculation.dataRespArchive = newValue;
      },
    },
    ...mapState({
      isLoadingHome: (state) => state.businessDayCalculation.isLoading,
      isLoadingHomeScroll: (state) =>
        state.businessDayCalculation.isLoadingHome,
      errorResp: (state) => state.businessDayCalculation.errorResp,
      isLoadingArchive: (state) =>
        state.businessDayCalculation.isLoadingArchive,
      isLoadingScrollArchive: (state) =>
        state.businessDayCalculation.isLoadingArchive,
      errorRespArchive: (state) =>
        state.businessDayCalculation.errorRespArchive,
    }),
  },
  watch: {
    "formArchive.date"() {
      if (this.formArchive.date) {
        this.formArchive.startDate = new Date(this.formArchive.date)
          .toISOString()
          .split("T")[0];

        // this.formArchive.endDate = new Date(this.formArchive.date)
        //   .toISOString()
        //   .split("T")[0];
      } else {
        this.formArchive.startDate = "";
        // this.formArchive.endDate = "";
      }

      this.businessDayCalculationArchive();
    },
  },
  methods: {
    businessDayCalculation() {
      const stringifiedParams = "";
      this.$store.dispatch(actionTypes.getBusinessDayCalculation, {
        apiParams: stringifiedParams,
      });
    },
    businessDayCalculationArchive() {
      const stringifiedParams = stringify({
        page: this.getArchiveReq.page,
        size: this.getArchiveReq.size,
        sort: `${this.getArchiveReq.sort.col},${this.getArchiveReq.sort.order}`,
        start: this.formArchive.startDate,
        // end: this.formArchive.endDate,
        billingCalculationNumber: this.formArchive.billingCalculationNumber,
      });
      this.$store.dispatch(actionTypes.getBusinessDayCalculationArchive, {
        apiParams: stringifiedParams,
      });
    },
    scrollTableArchive(event) {
      const currentEl = event.target;
      let checkScrollEnd =
        currentEl.scrollTop + currentEl.clientHeight + 3 >= currentEl.scrollHeight;
      if (checkScrollEnd && this.isLoadingHomeScroll === false) {
        const currentPage = this.page + 1;
        const totalPages = this.dataRespHome.totalPages;
        if (currentPage < totalPages) this.loadMoreDataArchive();
      }
    },
    loadMoreDataArchive() {
      this.page += 1;
      const stringifiedParams = stringify({
        page: this.getArchiveReq.page,
        size: this.getArchiveReq.size,
        sort: `${this.getArchiveReq.sort.col},${this.getArchiveReq.sort.order}`,
        start: this.startDate,
        // end: this.endDate,
        billingCalculationNumber: this.formArchive.billingCalculationNumber,
      });
      this.$store.dispatch(
        actionTypes.getBusinessDayCalculationArchiveScrollPush,
        {
          apiParams: stringifiedParams,
        }
      );
    },
    reloadPage() {
      this.page = 0;
      this.businessDayCalculation();
    },
    clickGenerateCalculation() {
      businessDayCalculationApi
        .makeBillingForAll()
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          if (result.status === 200) {
            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.success`)}`,
              bgColor: "bg-success",
              textColor: "text-white",
            });
            this.showToast = true;

            this.businessDayCalculation();
          } else {
            console.log("err");
            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.err`)}`,
              bgColor: "bg-danger",
              textColor: "text-white",
            });
            this.showToast = true;
          }
        });
    },
  },
  mounted() {
    this.businessDayCalculation();
    this.businessDayCalculationArchive();
  },
};
</script>

<style scoped></style>
