import axios from "@/api/axios";

const getUsers = (apiParams) => {
  return axios.get("/users?" + apiParams);
};

const updateUser = (reqObj) => {
  return axios.put("/users", { ...reqObj });
};

const addUser = (reqObj) => {
  return axios.post("/users", { ...reqObj });
};

const changePasswordUser = (reqObj) => {
  return axios.put("/users/update-user-password", { ...reqObj });
};
const changeCurrentPasswordUser = (reqObj) => {
  return axios.put("/users/update-current-user-password", { ...reqObj });
};

export default {
  getUsers,
  updateUser,
  addUser,
  changePasswordUser,
  changeCurrentPasswordUser,
};
