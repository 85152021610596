<template>
  <div class="title-table mb-2 ps-3 py-1 fw-bold">
    {{ $t(`left-menu.monitoring`) }}
  </div>
  <div class="w-75">
    <div class="row g-0">
      <div class="col-md-3 px-3">
        <h5 class="fw-bold">{{ $t(`Monitoring.health.status`) }}</h5>
        <ul class="list-group list-group-flush" v-if="respDataHealth">
          <li class="list-group-item">
            {{ $t(`Monitoring.health.dbConnectionHealth`) }}:
            <span class="rounded p-1" :class="dbConnectionHealthClass">{{
              respDataHealth.dbConnectionHealth
            }}</span>
          </li>
          <li class="list-group-item">
            {{ $t(`Monitoring.health.hsmConnectionHealth`) }}:
            <span class="rounded p-1" :class="hsmConnectionHealthClass">{{
              respDataHealth.hsmConnectionHealth
            }}</span>
          </li>
          <li class="list-group-item">
            {{ $t(`Monitoring.health.isoAuthConnectionHealth`) }}:
            <span class="rounded p-1" :class="isoAuthConnectionHealthClass">{{
              respDataHealth.isoConnectionHealth
            }}</span>
          </li>
          <li class="list-group-item">
            {{ $t(`Monitoring.health.serverHealth`) }}:
            <span class="rounded p-1" :class="serverHealthClass">{{
              respDataHealth.serverHealth
            }}</span>
          </li>
        </ul>
        <template v-else>
          <div class="w-100 text-center">
            <trn-loading></trn-loading>
          </div>
        </template>
      </div>
      <div class="col-md-9 px-3">
        <template v-if="respDataAnalytics">
          <vue-apex-charts
            width="100%"
            type="line"
            :options="chartOptions"
            :series="series"
          ></vue-apex-charts>
        </template>
        <template v-else>
          <div class="w-100 text-center">
            <trn-loading></trn-loading>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import TrnLoading from "@/components/Loading";
import VueApexCharts from "vue3-apexcharts";
import monitoringApi from "@/api/monitoring";
import useVuelidate from "@vuelidate/core";
import { getItemLocalStorage } from "@/helpers/persistanceStorage";
import {stringify} from "query-string";

export default {
  name: "TrnMonitoring",
  components: {
    VueApexCharts,
    TrnLoading,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      series: [
        {
          name: this.$t("Monitoring.charts.success"),
          data: [],
        },
        {
          name: this.$t("Monitoring.charts.refusal"),
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 50,
            tools: {
              download: true,
              selection: false,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: false,
              reset: true,
              customIcons: [],
            },
          },
        },
        colors: ["#02b638", "#da1b1b"],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: this.$t("Analytics.charts.title.transactionActivity"),
          align: "left",
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: [],
          title: {
            text: this.$t("Monitoring.charts.time"),
          },
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
        zoom: {
          enabled: true,
          type: "x",
          autoScaleYaxis: false,
          zoomedArea: {
            fill: {
              color: "#90CAF9",
              opacity: 0.4,
            },
            stroke: {
              color: "#0D47A1",
              opacity: 0.4,
              width: 1,
            },
          },
        },
      },
      respDataAnalytics: null,
      respDataHealth: null,
      respDataAnalyticsWebSocket: null,
      connWS: null,
    };
  },
  computed: {
    dbConnectionHealthClass: function () {
      return {
        "bg-success": this.respDataHealth?.dbConnectionHealth === "OK",
        "bg-danger": this.respDataHealth?.dbConnectionHealth === "ERROR",
        "text-white": true,
      };
    },
    hsmConnectionHealthClass: function () {
      return {
        "bg-success": this.respDataHealth?.hsmConnectionHealth === "OK",
        "bg-danger": this.respDataHealth?.hsmConnectionHealth === "ERROR",
        "text-white": true,
      };
    },
    isoAuthConnectionHealthClass: function () {
      return {
        "bg-success": this.respDataHealth?.isoConnectionHealth === "OK",
        "bg-danger": this.respDataHealth?.isoConnectionHealth === "ERROR",
        "text-white": true,
      };
    },
    serverHealthClass: function () {
      return {
        "bg-success": this.respDataHealth?.serverHealth === "OK",
        "bg-danger": this.respDataHealth?.serverHealth === "ERROR",
        "text-white": true,
      };
    },
    smppConnectionHealthClass: function () {
      return {
        "bg-success": this.respDataHealth?.smppConnectionHealth === "OK",
        "bg-danger": this.respDataHealth?.smppConnectionHealth === "ERROR",
        "text-white": true,
      };
    },
  },
  methods: {
    fetchAnalytics() {
      let currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const stringifiedParams = stringify({
        startDate: currentDate.toISOString(),
        endDate: new Date().toISOString(),
      });
      monitoringApi
        .getTransactionAnalytics(stringifiedParams)
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          // console.log("result", result);
          if (result.status === 200) {
            this.respDataAnalytics = result.data;
            this.fillChartData();
          } else {
            console.log("err");
          }
        });
    },
    fetchHealth() {
      monitoringApi
        .getHealth()
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          // console.log("result", result);
          if (result.status === 200) {
            this.respDataHealth = result.data;
          } else {
            console.log("err");
          }
        });
    },
    fillChartData() {
      for (let key in this.respDataAnalytics.successfulHourlyAnalytics) {
        this.chartOptions.xaxis.categories.push(
          this.$filters.formatDateTime(
            this.respDataAnalytics.successfulHourlyAnalytics[key].period,
            "time"
          )
        );

        this.series[0].data.push(
          this.respDataAnalytics.successfulHourlyAnalytics[key].count
        );

        this.series[1].data.push(
          this.respDataAnalytics.declinedHourlyAnalytics[key].count
        );
      }
    },
    getSignals() {
      const token = getItemLocalStorage("accessToken");
      this.$cookies.set("Authorization", `Bearer ${token}`);

      this.connWS = new WebSocket(
        `${process.env.VUE_APP_API_HOST_WS}/transaction/analitics`
      );
      this.connWS.onmessage = (event) => {
        this.respDataAnalyticsWebSocket = JSON.parse(event.data);
        const indexXaxis = this.chartOptions.xaxis.categories.indexOf(
          this.$filters.formatDateTime(
            this.respDataAnalyticsWebSocket.timestamp,
            "time"
          )
        );
        if (indexXaxis === -1) {
          this.chartOptions.xaxis.categories.push(
            this.$filters.formatDateTime(
              this.respDataAnalyticsWebSocket.timestamp,
              "time"
            )
          );
          switch (this.respDataAnalyticsWebSocket.statusCode) {
            case "SUCCESS":
              this.series[0].data.push(1);
              break;
            case "DECLINED":
              this.series[1].data.push(1);
              break;
          }
        } else {
          switch (this.respDataAnalyticsWebSocket.statusCode) {
            case "SUCCESS":
              this.series[0].data[indexXaxis]++;
              break;
            case "DECLINED":
              this.series[1].data[indexXaxis]++;
              break;
          }
        }
      };
    },
  },
  mounted() {
    this.fetchAnalytics();
    this.fetchHealth();
    setInterval(this.fetchHealth, 50000);
    this.getSignals();
  },
  unmounted() {
    this.connWS.close();
  },
};
</script>

<style scoped></style>
