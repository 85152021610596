import productsApi from "@/api/settings-api/products";

const state = {
  dataResp: null,
  dataModalResp: null,

  isLoading: false,
  isLoadingScroll: false,
  isLoadingModal: false,

  errorResp: null,
};

export const mutationTypes = {
  getProductsStart: "[Products] getProducts start",
  getProductsSuccess: "[Products] getProducts success",
  getProductsFailure: "[Products] getProducts failure",

  getProductsScrollPushStart: "[Products] getProductsScrollPush start",
  getProductsScrollPushSuccess:
    "[Products] getProductsScrollPushSuccess success",
  getProductsScrollPushFailure: "[Products] getProductsScrollPush failure",
};

export const actionTypes = {
  getProducts: "[Products] getProducts",
  getProductsScrollPush: "[Products] getProductsScrollPush",
};

const mutations = {
  [mutationTypes.getProductsStart](state) {
    state.isLoading = true;
    state.data = null;
  },
  [mutationTypes.getProductsSuccess](state, payload) {
    state.isLoading = false;
    state.dataResp = payload;
  },
  [mutationTypes.getProductsFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
  [mutationTypes.getProductsScrollPushStart](state) {
    state.isLoadingScroll = true;
  },
  [mutationTypes.getProductsScrollPushSuccess](state, payload) {
    for (let item in payload.content) {
      state.dataResp.content.push(payload.content[item]);
    }
    state.isLoadingScroll = false;
  },
  [mutationTypes.getProductsScrollPushFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
};

const actions = {
  [actionTypes.getProducts](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getProductsStart, apiParams);
      productsApi
        .getProducts(apiParams)
        .then((response) => {
          context.commit(mutationTypes.getProductsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getProductsFailure,
            result.response.data.error
          );
        });
    });
  },
  [actionTypes.getProductsScrollPush](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getProductsScrollPushStart, apiParams);
      productsApi
        .getProducts(apiParams)
        .then((response) => {
          context.commit(
            mutationTypes.getProductsScrollPushSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getProductsScrollPushFailure,
            result.response.data
          );
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
