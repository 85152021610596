<template>
  <div class="title-table mb-2 ps-3 py-1 fw-bold">
    {{ $t(`settings-menu.ipsCardGroup`) }}
  </div>

  <!-- ToolBar -->
  <div class="toolbar">
    <div class="btn-group mb-2">
      <button
        class="btn btn-outline-primary btn-sm"
        type="button"
        @click="clickChangeGroupObj('add')"
      >
        <i class="bi bi-file-earmark-plus"></i>
        <span class="ms-1">{{ $t(`uiElements.btn.add`) }}</span>
      </button>
      <button
        class="btn btn-outline-primary btn-sm"
        :class="{ disabled: !selectedTableRow?.ipsCardGroupId }"
        type="button"
        @click="clickChangeGroupObj('update')"
      >
        <i class="bi bi-pencil-square"></i>
        <span class="ms-1">{{ $t(`uiElements.btn.change`) }}</span>
      </button>
      <button
        class="btn btn-outline-primary btn-sm"
        :class="{ disabled: !selectedTableRow?.ipsCardGroupId }"
        type="button"
        @click="clickChangeGroupObj('delete')"
      >
        <i class="bi bi-trash3"></i>
        <span class="ms-1">{{ $t(`uiElements.btn.delete`) }}</span>
      </button>
    </div>
  </div>

  <!-- ToolBar кінець -->

  <div
    class="table-responsive table-block overflow-auto"
    id="table-block"
    @scroll="scrollTable"
  >
    <table class="table table-sm table-light">
      <thead>
        <tr class="text-center">
          <th scope="col">
            {{ $t(`IpsCardGroups.table.ipsCardGroupId`) }}
          </th>
          <th scope="col">
            {{ $t(`IpsCardGroups.table.ipsName`) }}
          </th>
          <th scope="col">
            {{ $t(`IpsCardGroups.table.ipsSymbol`) }}
          </th>
          <th scope="col">
            {{ $t(`IpsCardGroups.table.firstCardNum`) }}
          </th>
          <th scope="col">
            {{ $t(`IpsCardGroups.table.noPinLimit`) }}
          </th>
          <th scope="col">
            {{ $t(`IpsCardGroups.table.opPin`) }}
          </th>
          <th scope="col">
            {{ $t(`IpsCardGroups.table.opManual`) }}
          </th>
          <th scope="col">
            {{ $t(`IpsCardGroups.table.opNfc`) }}
          </th>
        </tr>
      </thead>
      <tbody class="text-nowrap">
        <template v-if="isLoading">
          <tr>
            <td colspan="16">
              <div class="loading text-center">
                <trn-loading></trn-loading>
              </div>
            </td>
          </tr>
        </template>
        <template v-if="dataResp">
          <tr
            v-for="item in dataResp.content"
            :key="item"
            class="text-center cursor-pointer"
            :class="{
              'select-td':
                item.ipsCardGroupId === selectedTableRow?.ipsCardGroupId,
            }"
            @click="clickTableRow(item)"
          >
            <td>{{ item.ipsCardGroupId }}</td>
            <td>{{ item.ipsName }}</td>
            <td>{{ item.ipsSymbol }}</td>
            <td>{{ item.firstCardNum }}</td>
            <td>{{ item.noPinLimit }}</td>
            <td>{{ item.opPin }}</td>
            <td>{{ item.opManual }}</td>
            <td>{{ item.opNfc }}</td>
          </tr>
          <template v-if="isLoadingScroll">
            <tr>
              <td colspan="16">
                <div class="loading text-center">
                  <trn-loading></trn-loading>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>

  <bs-modal
    :modal-toggle-emit="showModal"
    :title="titleModal"
    @hideMainModal="showModal = false"
  >
    <template v-if="typeFormGroup === 'update' || typeFormGroup === 'add'">
      <form
        class="needs-validation"
        @submit.prevent="submitChangeGroupNumber"
        novalidate
      >
        <!--  input-ipsCardGroupId  -->
        <div class="row mb-3" v-if="selectedTableRow">
          <label for="input-ipsCardGroupId" class="col-sm-6 col-form-label">
            {{ $t(`IpsCardGroups.table.ipsCardGroupId`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="number"
              class="form-control"
              id="input-ipsCardGroupId"
              v-model="selectedTableRow.ipsCardGroupId"
              disabled
            />
          </div>
        </div>

        <!--  input-ipsName  -->
        <div class="row mb-3">
          <label for="input-ipsName" class="col-sm-6 col-form-label">
            {{ $t(`IpsCardGroups.table.ipsName`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              :class="addClassValidInput('ipsName')"
              id="input-ipsName"
              v-model="v$.formIpsGroup.ipsName.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formIpsGroup.ipsName.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formIpsGroup.ipsName.reTextAndNum.$invalid">
                {{ $t(`Validations.reTextAndNum`) }}
              </div>
              <div v-if="v$.formIpsGroup.ipsName.maxLength.$invalid">
                {{ $t(`Validations.maxLength`, { num: 255 }) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-ipsSymbol  -->
        <div class="row mb-3">
          <label for="input-ipsSymbol" class="col-sm-6 col-form-label">
            {{ $t(`IpsCardGroups.table.ipsSymbol`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              :class="addClassValidInput('ipsSymbol')"
              id="input-ipsSymbol"
              v-model="v$.formIpsGroup.ipsSymbol.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formIpsGroup.ipsSymbol.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formIpsGroup.ipsSymbol.reTextLatin.$invalid">
                {{ $t(`Validations.reTextLatin`) }}
              </div>
              <div v-if="v$.formIpsGroup.ipsSymbol.maxLength.$invalid">
                {{ $t(`Validations.maxLength`, { num: 1 }) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-firstCardNum  -->
        <div class="row mb-3">
          <label for="input-firstCardNum" class="col-sm-6 col-form-label">
            {{ $t(`IpsCardGroups.table.firstCardNum`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="number"
              class="form-control"
              :class="addClassValidInput('firstCardNum')"
              id="input-firstCardNum"
              v-model="v$.formIpsGroup.firstCardNum.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formIpsGroup.firstCardNum.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-noPinLimit  -->
        <div class="row mb-3">
          <label for="input-noPinLimit" class="col-sm-6 col-form-label">
            {{ $t(`IpsCardGroups.table.noPinLimit`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="number"
              class="form-control"
              :class="addClassValidInput('noPinLimit')"
              id="input-noPinLimit"
              v-model="v$.formIpsGroup.noPinLimit.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formIpsGroup.noPinLimit.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-opPin  -->
        <div class="row mb-3">
          <label for="input-opPin" class="col-sm-6 col-form-label">
            {{ $t(`IpsCardGroups.table.opPin`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              :class="addClassValidInput('opPin')"
              id="input-opPin"
              v-model="v$.formIpsGroup.opPin.$model"
            >
              <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
              <option value="N">{{ $t(`uiElements.text.no`) }}</option>
            </select>
            <div class="invalid-feedback">
              <div v-if="v$.formIpsGroup.opPin.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-opManual  -->
        <div class="row mb-3">
          <label for="input-opManual" class="col-sm-6 col-form-label">
            {{ $t(`IpsCardGroups.table.opManual`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              :class="addClassValidInput('opManual')"
              id="input-opManual"
              v-model="v$.formIpsGroup.opManual.$model"
            >
              <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
              <option value="N">{{ $t(`uiElements.text.no`) }}</option>
            </select>
            <div class="invalid-feedback">
              <div v-if="v$.formIpsGroup.opManual.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-opNfc  -->
        <div class="row mb-3">
          <label for="input-opNfc" class="col-sm-6 col-form-label">
            {{ $t(`IpsCardGroups.table.opNfc`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              :class="addClassValidInput('opNfc')"
              id="input-opNfc"
              v-model="v$.formIpsGroup.opNfc.$model"
            >
              <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
              <option value="N">{{ $t(`uiElements.text.no`) }}</option>
            </select>
            <div class="invalid-feedback">
              <div v-if="v$.formIpsGroup.opNfc.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  Buttons form  -->
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-outline-secondary me-3"
            @click="showModal = false"
          >
            {{ $t(`uiElements.btn.close`) }}
          </button>
          <button
            type="submit"
            class="btn btn-outline-primary"
            :disabled="v$.$invalid && v$.$dirty ? true : false"
          >
            {{ $t(`uiElements.btn.save`) }}
          </button>
        </div>
      </form>
    </template>
    <template v-if="typeFormGroup === 'delete'">
      <div class="text-center">
        <div class="mb-3">
          {{ $t(`IpsCardGroups.modal.deleteGroup`) }}
          <span class="fw-bold">{{ selectedTableRow.ipsCardGroupId }}</span
          >?
        </div>
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-outline-secondary me-3"
            @click="showModal = false"
          >
            {{ $t(`uiElements.btn.close`) }}
          </button>
          <button
            type="button"
            class="btn btn-danger"
            @click="submitDeleteGroupNumber"
          >
            {{ $t(`uiElements.btn.delete`) }}
          </button>
        </div>
      </div>
    </template>
  </bs-modal>
  <div
    class="toast-container position-absolute p-3 end-0 z-index-1500"
    v-if="showToast"
  >
    <bs-toast v-for="toast in toastPropsList" :key="toast" :="toast"></bs-toast>
  </div>
</template>

<script>
import TrnLoading from "@/components/Loading";
import BsModal from "@/components/UI-elements/BsModal";
import BsToast from "@/components/UI-elements/BsToast";
import useVuelidate from "@vuelidate/core";
import { helpers, maxLength, required } from "@vuelidate/validators";
import { mapState } from "vuex";
import { stringify } from "query-string";
import { actionTypes } from "@/store/modules/settings-store/ips-card-groups";
import ipsCardGroupsApi from "@/api/settings-api/ips-card-groups";

export default {
  name: "TrnIpsCardGroup",
  components: {
    TrnLoading,
    BsModal,
    BsToast,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      page: 0,
      size: 100,
      sort: {
        col: "ipsCardGroupId",
        order: "asc",
      },
      showModal: false,
      titleModal: "",
      typeFormGroup: "",
      formIpsGroup: {},
      formIpsGroupType: "",
      showToast: false,
      toastPropsList: [],
      selectedTableRow: null,
    };
  },
  validations() {
    return {
      formIpsGroup: {
        ipsName: {
          required,
          reTextAndNum: helpers.regex(/^[a-zA-Zа-яА-ЯіїґєІЇҐЄёЁ0-9\s]+$/u), // input expression is only Latin, Cyrillic and numbers.
          maxLength: maxLength(255),
        },
        ipsSymbol: {
          required,
          reTextLatin: helpers.regex(/^[a-zA-Z]+$/u),
          maxLength: maxLength(1),
        },
        firstCardNum: {
          required,
        },
        noPinLimit: {
          required,
        },
        opPin: {
          required,
        },
        opManual: {
          required,
        },
        opNfc: {
          required,
        },
      },
    };
  },
  computed: {
    dataResp: {
      get() {
        return this.$store.state.ipsCardGroups.dataResp;
      },
      set(newValue) {
        this.$store.state.ipsCardGroups.dataResp = newValue;
      },
    },
    ...mapState({
      isLoading: (state) => state.ipsCardGroups.isLoading,
      isLoadingScroll: (state) => state.ipsCardGroups.isLoadingScroll,
      dataModalResp: (state) => state.ipsCardGroups.dataModalResp,
      errorResp: (state) => state.ipsCardGroups.errorResp,
    }),
  },
  methods: {
    fetchIpsCardGroups() {
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
      });
      this.$store.dispatch(actionTypes.getIpsCardGroups, {
        apiParams: stringifiedParams,
      });
    },
    scrollTable(event) {
      const currentEl = event.target;
      let checkScrollEnd =
        currentEl.scrollTop + currentEl.clientHeight + 3 >=
        currentEl.scrollHeight;
      if (checkScrollEnd && this.isLoadingScroll === false) {
        const currentPage = this.page + 1;
        const totalPages = this.dataResp.totalPages;
        if (currentPage < totalPages) this.loadMoreData();
      }
    },
    loadMoreData() {
      this.page += 1;
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
      });
      this.$store.dispatch(actionTypes.getIpsCardGroupsScrollPush, {
        apiParams: stringifiedParams,
      });
    },
    reloadPage() {
      this.page = 0;
      this.fetchIpsCardGroups();
    },
    addClassValidInput(inputName) {
      return {
        "is-invalid":
          this.v$.formIpsGroup[inputName].$error === true &&
          this.v$.formIpsGroup[inputName].$dirty === true,
        "is-valid":
          this.v$.formIpsGroup[inputName].$error === false &&
          this.v$.formIpsGroup[inputName].$dirty === true,
      };
    },
    clickTableRow(rowItem) {
      this.selectedTableRow = rowItem;
    },
    clickChangeGroupObj(type) {
      this.formIpsGroupType = type;
      this.showModal = true;

      if (type === "add") {
        this.typeFormGroup = "add";
        this.selectedTableRow = null;
        this.formIpsGroup = {};
        this.titleModal = this.$t(`IpsCardGroups.modal.addNewGroup`);
      } else if (type === "delete") {
        this.titleModal =
          this.$t("IpsCardGroups.table.ipsCardGroupId") +
          ": " +
          this.selectedTableRow.ipsCardGroupId;
        this.typeFormGroup = "delete";
      } else if (type === "update") {
        this.titleModal =
          this.$t("IpsCardGroups.table.ipsCardGroupId") +
          ": " +
          this.selectedTableRow.ipsCardGroupId;
        this.typeFormGroup = "update";
        this.formIpsGroup.ipsCardGroupId = this.selectedTableRow.ipsCardGroupId;
        this.formIpsGroup.ipsName = this.selectedTableRow.ipsName;
        this.formIpsGroup.ipsSymbol = this.selectedTableRow.ipsSymbol;
        this.formIpsGroup.firstCardNum = this.selectedTableRow.firstCardNum;
        this.formIpsGroup.noPinLimit = this.selectedTableRow.noPinLimit;
        this.formIpsGroup.opPin = this.selectedTableRow.opPin;
        this.formIpsGroup.opManual = this.selectedTableRow.opManual;
        this.formIpsGroup.opNfc = this.selectedTableRow.opNfc;
      }
    },
    submitChangeGroupNumber() {
      this.v$.formIpsGroup.$touch();
      if (!this.v$.formIpsGroup.$error) {
        if (this.formIpsGroupType === "add") {
          ipsCardGroupsApi
            .addIpsCardGroup({ ...this.formIpsGroup })
            .then(function (result) {
              return result;
            })
            .catch(function (result) {
              return result;
            })
            .then((result) => {
              // console.log("result", result);
              if (result.status === 200) {
                this.showModal = false;
                this.selectedTableRow = null;
                this.formIpsGroup = {};

                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.successSave`)}`,
                  bgColor: "bg-success",
                  textColor: "text-white",
                });
                this.showToast = true;

                this.fetchIpsCardGroups();
              } else {
                console.log("err");
                this.toastPropsList.push({
                  title: `${this.$t(`uiElements.toast.errSave`)}`,
                  content: result.response.data.error.errorText,
                  bgColor: "bg-danger",
                  textColor: "text-white",
                });
                this.showToast = true;
              }
            });
        } else {
          ipsCardGroupsApi
            .updateIpsCardGroup(this.selectedTableRow.ipsCardGroupId, {
              ...this.formIpsGroup,
            })
            .then(function (result) {
              return result;
            })
            .catch(function (result) {
              return result;
            })
            .then((result) => {
              if (result.status === 200) {
                this.showModal = false;

                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.successSave`)}`,
                  bgColor: "bg-success",
                  textColor: "text-white",
                });
                this.showToast = true;

                this.fetchIpsCardGroups();
              } else {
                // console.log("err", result.response.data.error.errorText);
                this.toastPropsList.push({
                  title: `${this.$t(`uiElements.toast.errSave`)}`,
                  content: result.response.data.error.errorText,
                  bgColor: "bg-danger",
                  textColor: "text-white",
                });
                this.showToast = true;
              }
            });
        }
      }
    },
    submitDeleteGroupNumber() {
      ipsCardGroupsApi
        .deleteIpsCardGroup(this.selectedTableRow.ipsCardGroupId)
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          if (result.status === 200) {
            this.showModal = false;
            this.selectedTableRow = null;
            this.formIpsGroup = {};

            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.success`)}`,
              bgColor: "bg-success",
              textColor: "text-white",
            });
            this.showToast = true;

            this.fetchIpsCardGroups();
          } else {
            // console.log("err", result.response.data.error.errorText);
            this.toastPropsList.push({
              title: `${this.$t(`uiElements.toast.err`)}`,
              content: result.response.data.error.errorText,
              bgColor: "bg-danger",
              textColor: "text-white",
            });
            this.showToast = true;
          }
        });
    },
  },
  mounted() {
    this.fetchIpsCardGroups();
  },
};
</script>

<style scoped>
.select-td > td {
  background-color: #0d6efd !important;
  color: white !important;
}
</style>
