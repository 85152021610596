import terminalApi from "@/api/terminal";

const state = {
  dataResp: null,
  dataModalResp: null,

  isLoading: false,
  isLoadingScroll: false,
  isLoadingModal: false,

  errorResp: null,
};

export const mutationTypes = {
  getTerminalStart: "[Terminal] getTerminal start",
  getTerminalSuccess: "[Terminal] getTerminal success",
  getTerminalFailure: "[Terminal] getTerminal failure",

  getTerminalScrollPushStart: "[Terminal] getTerminalScrollPush start",
  getTerminalScrollPushSuccess:
    "[Terminal] getTerminalScrollPushSuccess success",
  getTerminalScrollPushFailure: "[Terminal] getTerminalScrollPush failure",
};

export const actionTypes = {
  getTerminal: "[Terminal] getTerminal",
  getTerminalScrollPush: "[Terminal] getTerminalScrollPush",
};

const mutations = {
  [mutationTypes.getTerminalStart](state) {
    state.isLoading = true;
    state.data = null;
  },
  [mutationTypes.getTerminalSuccess](state, payload) {
    state.isLoading = false;
    state.dataResp = payload;
  },
  [mutationTypes.getTerminalFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
  [mutationTypes.getTerminalScrollPushStart](state) {
    state.isLoadingScroll = true;
  },
  [mutationTypes.getTerminalScrollPushSuccess](state, payload) {
    for (let item in payload.page.content) {
      state.dataResp.page.content.push(payload.page.content[item]);
    }
    state.isLoadingScroll = false;
  },
  [mutationTypes.getTerminalScrollPushFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
};

const actions = {
  [actionTypes.getTerminal](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getTerminalStart, apiParams);
      terminalApi
        .getTerminals(apiParams)
        .then((response) => {
          context.commit(mutationTypes.getTerminalSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getTerminalFailure,
            result.response.data.error
          );
        });
    });
  },
  [actionTypes.getTerminalScrollPush](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getTerminalScrollPushStart, apiParams);
      terminalApi
        .getTerminals(apiParams)
        .then((response) => {
          context.commit(
            mutationTypes.getTerminalScrollPushSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getTerminalScrollPushFailure,
            result.response.data
          );
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
