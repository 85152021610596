import receiptTemplateApi from "@/api/settings-api/receipt-remplate";

const state = {
  dataResp: null,
  dataModalResp: null,

  isLoading: false,
  isLoadingScroll: false,
  isLoadingModal: false,

  errorResp: null,
};

export const mutationTypes = {
  getReceiptTemplateStart: "[ReceiptTemplate] getReceiptTemplate start",
  getReceiptTemplateSuccess: "[ReceiptTemplate] getReceiptTemplate success",
  getReceiptTemplateFailure: "[ReceiptTemplate] getReceiptTemplate failure",

  getReceiptTemplateScrollPushStart:
    "[ReceiptTemplate] getReceiptTemplateScrollPush start",
  getReceiptTemplateScrollPushSuccess:
    "[ReceiptTemplate] getReceiptTemplateScrollPushSuccess success",
  getReceiptTemplateScrollPushFailure:
    "[ReceiptTemplate] getReceiptTemplateScrollPush failure",
};

export const actionTypes = {
  getReceiptTemplate: "[ReceiptTemplate] getReceiptTemplate",
  getReceiptTemplateScrollPush:
    "[ReceiptTemplate] getReceiptTemplateScrollPush",
};

const mutations = {
  [mutationTypes.getReceiptTemplateStart](state) {
    state.isLoading = true;
    state.data = null;
  },
  [mutationTypes.getReceiptTemplateSuccess](state, payload) {
    state.isLoading = false;
    state.dataResp = payload;
  },
  [mutationTypes.getReceiptTemplateFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
  [mutationTypes.getReceiptTemplateScrollPushStart](state) {
    state.isLoadingScroll = true;
  },
  [mutationTypes.getReceiptTemplateScrollPushSuccess](state, payload) {
    for (let item in payload.content) {
      state.dataResp.content.push(payload.content[item]);
    }
    state.isLoadingScroll = false;
  },
  [mutationTypes.getReceiptTemplateScrollPushFailure](state, payload) {
    state.isLoading = false;
    state.errorResp = payload;
  },
};

const actions = {
  [actionTypes.getReceiptTemplate](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getReceiptTemplateStart, apiParams);
      receiptTemplateApi
        .getReceiptTemplate(apiParams)
        .then((response) => {
          context.commit(
            mutationTypes.getReceiptTemplateSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getReceiptTemplateFailure,
            result.response.data.error
          );
        });
    });
  },
  [actionTypes.getReceiptTemplateScrollPush](context, { apiParams }) {
    return new Promise((resolve) => {
      context.commit(
        mutationTypes.getReceiptTemplateScrollPushStart,
        apiParams
      );
      receiptTemplateApi
        .getReceiptTemplate(apiParams)
        .then((response) => {
          context.commit(
            mutationTypes.getReceiptTemplateScrollPushSuccess,
            response.data
          );
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getReceiptTemplateScrollPushFailure,
            result.response.data
          );
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
