<template>
  <div class="title-table mb-2 ps-3 py-1 fw-bold">
    {{ $t(`settings-menu.VendorRSO`) }}
  </div>

  <div
    class="table-responsive table-block overflow-auto"
    id="table-block"
    @scroll="scrollTable"
  >
    <table class="table table-sm table-light">
      <thead>
        <tr class="text-center">
          <th scope="col">
            {{ $t(`VendorRSO.table.vendorId`) }}
          </th>
          <th scope="col">
            {{ $t(`VendorRSO.table.packageName`) }}
          </th>
          <th scope="col">
            {{ $t(`VendorRSO.table.appName`) }}
          </th>
          <th scope="col">
            {{ $t(`VendorRSO.table.versionCode`) }}
          </th>
          <th scope="col">
            {{ $t(`VendorRSO.table.versionName`) }}
          </th>
          <th scope="col">
            {{ $t(`VendorRSO.table.aidlLibVersionCode`) }}
          </th>
          <th scope="col">
            {{ $t(`VendorRSO.table.createDate`) }}
          </th>
        </tr>
      </thead>
      <tbody class="text-nowrap">
        <template v-if="isLoading">
          <tr>
            <td colspan="16">
              <div class="loading text-center">
                <trn-loading></trn-loading>
              </div>
            </td>
          </tr>
        </template>
        <template v-if="dataResp">
          <tr
            v-for="item in dataResp.content"
            :key="item"
            class="text-center cursor-pointer"
            :class="{
              'select-td': item.id === selectedTableRow?.id,
            }"
            @click="clickTableRow(item)"
          >
            <td>{{ item.id }}</td>
            <td>{{ item.packageName }}</td>
            <td>{{ item.appName }}</td>
            <td>{{ item.versionCode }}</td>
            <td>{{ item.versionName }}</td>
            <td>{{ item.aidlLibVersionCode }}</td>
            <td>{{ $filters.formatDateTime(item.createDate, "date") }}</td>
          </tr>
          <template v-if="isLoadingScroll">
            <tr>
              <td colspan="16">
                <div class="loading text-center">
                  <trn-loading></trn-loading>
                </div>
              </td>
            </tr>
          </template>
        </template>
        <template v-if="dataResp?.content.length === 0">
          <tr>
            <td colspan="16">
              <div class="text-center fw-bold bg-info text-black">
                {{ $t(`uiElements.text.noData`) }}
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
  <div class="toast-container position-absolute p-3 end-0" v-if="showToast">
    <bs-toast v-for="toast in toastPropsList" :key="toast" :="toast"></bs-toast>
  </div>
</template>

<script>
import TrnLoading from "@/components/Loading";
import BsToast from "@/components/UI-elements/BsToast";
import useVuelidate from "@vuelidate/core";
import { mapState } from "vuex";
import { stringify } from "query-string";
import { actionTypes } from "@/store/modules/settings-store/vendor-aidl";

export default {
  name: "TrnVendorRSO",
  components: {
    TrnLoading,
    BsToast,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      page: 0,
      size: 100,
      sort: {
        col: "id",
        order: "asc",
      },
      showModal: false,
      titleModal: "",
      showFormGroup: false,
      formVendorRSO: {},
      formVendorRSOType: "",
      showToast: false,
      toastPropsList: [],
      selectedTableRow: null,
      dateFormat: "dd.MM.yyyy",
    };
  },
  computed: {
    dataResp: {
      get() {
        return this.$store.state.vendorRSO.dataResp;
      },
      set(newValue) {
        this.$store.state.vendorRSO.dataResp = newValue;
      },
    },
    ...mapState({
      isLoading: (state) => state.vendorRSO.isLoading,
      isLoadingScroll: (state) => state.vendorRSO.isLoadingScroll,
      dataModalResp: (state) => state.vendorRSO.dataModalResp,
      errorResp: (state) => state.vendorRSO.errorResp,
    }),
  },
  methods: {
    fetchVersion() {
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
      });
      this.$store.dispatch(actionTypes.getVendorRSO, {
        apiParams: stringifiedParams,
      });
    },
    scrollTable(event) {
      const currentEl = event.target;
      let checkScrollEnd =
        currentEl.scrollTop + currentEl.clientHeight + 3 >=
        currentEl.scrollHeight;
      if (checkScrollEnd && this.isLoadingScroll === false) {
        const currentPage = this.page + 1;
        const totalPages = this.dataResp.totalPages;
        if (currentPage < totalPages) this.loadMoreData();
      }
    },
    loadMoreData() {
      this.page += 1;
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
      });
      this.$store.dispatch(actionTypes.getVendorRSOScrollPush, {
        apiParams: stringifiedParams,
      });
    },
    reloadPage() {
      this.page = 0;
      this.fetchVersion();
    },
    addClassValidInput(inputName) {
      return {
        "is-invalid":
          this.v$.formVendorRSO[inputName].$error === true &&
          this.v$.formVendorRSO[inputName].$dirty === true,
        "is-valid":
          this.v$.formVendorRSO[inputName].$error === false &&
          this.v$.formVendorRSO[inputName].$dirty === true,
      };
    },
    clickTableRow(rowItem) {
      this.selectedTableRow = rowItem;
    },
  },
  mounted() {
    this.fetchVersion();
  },
};
</script>

<style scoped>
.select-td > td {
  background-color: #0d6efd !important;
  color: white !important;
}
</style>
