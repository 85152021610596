<template>
  <div class="title-table mb-2 ps-3 py-1 fw-bold">
    {{ $t(`settings-menu.version`) }}
  </div>

  <!-- ToolBar -->
  <div class="toolbar">
    <div class="btn-group mb-2">
      <button
        class="btn btn-outline-primary btn-sm"
        type="button"
        @click="clickChangeGroupObj('add')"
      >
        <i class="bi bi-file-earmark-plus"></i>
        <span class="ms-1">{{ $t(`uiElements.btn.add`) }}</span>
      </button>
      <button
        class="btn btn-outline-primary btn-sm"
        :class="{ disabled: !selectedTableRow?.id }"
        type="button"
        @click="clickChangeGroupObj('update')"
      >
        <i class="bi bi-pencil-square"></i>
        <span class="ms-1">{{ $t(`uiElements.btn.change`) }}</span>
      </button>
    </div>
  </div>

  <!-- ToolBar кінець -->

  <div
    class="table-responsive table-block overflow-auto col-md-4"
    id="table-block"
    @scroll="scrollTable"
  >
    <table class="table table-sm table-light">
      <thead>
        <tr class="text-center">
          <th scope="col">
            {{ $t(`Version.table.version`) }}
          </th>
          <th scope="col">
            {{ $t(`Version.table.expDate`) }}
          </th>
          <th scope="col">
            {{ $t(`Version.table.updateDate`) }}
          </th>
        </tr>
      </thead>
      <tbody class="text-nowrap">
        <template v-if="isLoading">
          <tr>
            <td colspan="16">
              <div class="loading text-center">
                <trn-loading></trn-loading>
              </div>
            </td>
          </tr>
        </template>
        <template v-if="dataResp">
          <tr
            v-for="item in dataResp.content"
            :key="item"
            class="text-center cursor-pointer"
            :class="{
              'select-td': item.id === selectedTableRow?.id,
            }"
            @click="clickTableRow(item)"
          >
            <td>{{ item.version }}</td>
            <td>{{ $filters.formatDateTime(item.expDate, "date") }}</td>
            <td>{{ $filters.formatDateTime(item.updateDate, "date") }}</td>
          </tr>
          <template v-if="isLoadingScroll">
            <tr>
              <td colspan="16">
                <div class="loading text-center">
                  <trn-loading></trn-loading>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>

  <bs-modal
    :modal-toggle-emit="showModal"
    :title="titleModal"
    @hideMainModal="showModal = false"
  >
    <template v-if="showFormGroup">
      <form
        class="needs-validation"
        @submit.prevent="submitChangeGroupNumber"
        novalidate
      >
        <!--  input-version  -->
        <div class="row mb-3">
          <label for="input-version" class="col-sm-6 col-form-label">
            {{ $t(`Version.table.version`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              :class="addClassValidInput('version')"
              id="input-version"
              v-model="v$.formVersion.version.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formVersion.version.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formVersion.version.reNumAndDot.$invalid">
                {{ $t(`Validations.reNumAndDot`) }}
              </div>
              <div v-if="v$.formVersion.version.maxLength.$invalid">
                {{ $t(`Validations.maxLength`, { num: 255 }) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-expDate  -->
        <div class="row mb-3">
          <label for="input-expDate" class="col-sm-6 col-form-label">
            {{ $t(`Version.table.expDate`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <Datepicker
              id="expDate-ff"
              :class="addClassValidInput('expDate')"
              v-model="v$.formVersion.expDate.$model"
              autoApply
              locale="en"
              :format="dateFormat"
              required
            />
            <div class="invalid-feedback">
              <div v-if="v$.formVersion.expDate.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  Buttons form  -->
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-outline-secondary me-3"
            @click="showModal = false"
          >
            {{ $t(`uiElements.btn.close`) }}
          </button>
          <button
            type="submit"
            class="btn btn-outline-primary"
            :disabled="v$.$invalid && v$.$dirty ? true : false"
          >
            {{ $t(`uiElements.btn.save`) }}
          </button>
        </div>
      </form>
    </template>
  </bs-modal>
  <div class="toast-container position-absolute p-3 end-0" v-if="showToast">
    <bs-toast v-for="toast in toastPropsList" :key="toast" :="toast"></bs-toast>
  </div>
</template>

<script>
import TrnLoading from "@/components/Loading";
import BsModal from "@/components/UI-elements/BsModal";
import BsToast from "@/components/UI-elements/BsToast";
import useVuelidate from "@vuelidate/core";
import {helpers, maxLength, required} from "@vuelidate/validators";
import { mapState } from "vuex";
import { stringify } from "query-string";
import { actionTypes } from "@/store/modules/settings-store/version";
import versionApi from "@/api/settings-api/version";
import Datepicker from "vue3-date-time-picker";

export default {
  name: "TrnVersion",
  components: {
    TrnLoading,
    BsModal,
    BsToast,
    Datepicker,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      page: 0,
      size: 100,
      sort: {
        col: "version",
        order: "asc",
      },
      showModal: false,
      titleModal: "",
      showFormGroup: false,
      formVersion: {},
      formVersionType: "",
      showToast: false,
      toastPropsList: [],
      selectedTableRow: null,
      dateFormat: "dd.MM.yyyy",
    };
  },
  validations() {
    return {
      formVersion: {
        version: {
          required,
          reNumAndDot: helpers.regex(/^[0-9.]+$/u), // input expression is only Latin, Cyrillic and numbers.
          maxLength: maxLength(255),
        },
        expDate: {
          required,
        },
      },
    };
  },
  computed: {
    dataResp: {
      get() {
        return this.$store.state.version.dataResp;
      },
      set(newValue) {
        this.$store.state.version.dataResp = newValue;
      },
    },
    ...mapState({
      isLoading: (state) => state.version.isLoading,
      isLoadingScroll: (state) => state.version.isLoadingScroll,
      dataModalResp: (state) => state.version.dataModalResp,
      errorResp: (state) => state.version.errorResp,
    }),
  },
  methods: {
    fetchVersion() {
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
      });
      this.$store.dispatch(actionTypes.getVersion, {
        apiParams: stringifiedParams,
      });
    },
    scrollTable(event) {
      const currentEl = event.target;
      let checkScrollEnd =
        currentEl.scrollTop + currentEl.clientHeight + 3 >= currentEl.scrollHeight;
      if (checkScrollEnd && this.isLoadingScroll === false) {
        const currentPage = this.page + 1;
        const totalPages = this.dataResp.totalPages;
        if (currentPage < totalPages) this.loadMoreData();
      }
    },
    loadMoreData() {
      this.page += 1;
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
      });
      this.$store.dispatch(actionTypes.getVersionScrollPush, {
        apiParams: stringifiedParams,
      });
    },
    reloadPage() {
      this.page = 0;
      this.fetchVersion();
    },
    addClassValidInput(inputName) {
      return {
        "is-invalid":
          this.v$.formVersion[inputName].$error === true &&
          this.v$.formVersion[inputName].$dirty === true,
        "is-valid":
          this.v$.formVersion[inputName].$error === false &&
          this.v$.formVersion[inputName].$dirty === true,
      };
    },
    clickTableRow(rowItem) {
      this.selectedTableRow = rowItem;
    },
    clickChangeGroupObj(type) {
      this.formVersionType = type;
      this.showModal = true;
      this.showFormGroup = true;
      if (type === "add") {
        this.selectedTableRow = null;
        this.formVersion = {};
        this.titleModal = this.$t(`Version.table.version`);
      } else {
        this.titleModal = this.selectedTableRow.version;
        this.formVersion.version = this.selectedTableRow.version;
        this.formVersion.expDate = this.selectedTableRow.expDate;
      }
    },
    submitChangeGroupNumber() {
      this.v$.formVersion.$touch();
      if (!this.v$.formVersion.$error) {
        this.formVersion.expDate = new Date(this.formVersion.expDate)
          .toISOString()
          .split("T")[0];
        console.log(this.formVersion.expDate)
        if (this.formVersionType === "add") {
          versionApi
            .addVersion({ ...this.formVersion })
            .then(function (result) {
              return result;
            })
            .catch(function (result) {
              return result;
            })
            .then((result) => {
              // console.log("result", result);
              if (result.status === 200) {
                this.showModal = false;
                this.selectedTableRow = null;
                this.formVersion = {};

                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.successSave`)}`,
                  bgColor: "bg-success",
                  textColor: "text-white",
                });
                this.showToast = true;

                this.fetchVersion();
              } else {
                this.showModal = false;
                this.selectedTableRow = null;
                console.log("err");
                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.errSave`)}`,
                  bgColor: "bg-danger",
                  textColor: "text-white",
                });
                this.showToast = true;
              }
            });
        } else {
          versionApi
            .updateVersion(this.selectedTableRow.id, {
              ...this.formVersion,
            })
            .then(function (result) {
              return result;
            })
            .catch(function (result) {
              return result;
            })
            .then((result) => {
              // console.log("result", result);
              if (result.status === 200) {
                this.showModal = false;

                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.successSave`)}`,
                  bgColor: "bg-success",
                  textColor: "text-white",
                });
                this.showToast = true;

                this.fetchVersion();
              } else {
                this.showModal = false;
                this.selectedTableRow = null;
                console.log("err");
                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.errSave`)}`,
                  bgColor: "bg-danger",
                  textColor: "text-white",
                });
                this.showToast = true;
              }
            });
        }
      }
    },
  },
  watch: {
    "formVersion.expDate"() {
      if (this.formVersion.expDate) {
        let startDate = new Date();
        let endDate = new Date(this.formVersion.expDate);
        if (startDate > endDate) {
          this.formVersion.expDate = startDate;
        }
      }
    },
  },
  mounted() {
    this.fetchVersion();
  },
};
</script>

<style scoped>
.select-td > td {
  background-color: #0d6efd !important;
  color: white !important;
}
</style>
