import axios from "@/api/axios";

const getTerminals = (apiParams) => {
  return axios.get("/terminals/with-dictionaries?" + apiParams);
};

const updateTerminal = (termId, termObj) => {
  return axios.put("/terminals/" + termId, { ...termObj });
};

const findTerminalByMerchantIds = (merchantObj) => {
  return axios.post("/terminals/get-by-merchants", { ...merchantObj });
};

const addSubordinationTerminalData = (termObj) => {
  return axios.post("/registration/add-subordinate-web", { ...termObj });
};

export default {
  getTerminals,
  updateTerminal,
  findTerminalByMerchantIds,
  addSubordinationTerminalData,
};
