<template>
  <template v-if="isPathNameLogin">
    <div class="position-absolute end-0">
      <trn-locale-switcher></trn-locale-switcher>
    </div>
    <router-view />
  </template>
  <template v-else>
    <div class="flex-wrapper">
      <div class="header">
        <trn-topbar v-if="!isPathNameLogin"></trn-topbar>
      </div>
      <div class="main-content">
        <div class="container-fluid mt-3">
          <div class="row">
            <div class="col-md-2 left-block">
              <trn-leftbar v-if="!isPathNameLogin"></trn-leftbar>
            </div>
            <div class="col-md-10">
              <div class="content d-flex flex-column overflow-auto">
                <router-view />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="d-flex align-items-center">
          <div class="container d-flex justify-content-between">
            <div class="text-white">{{ $t(`Footer.copyright`) }}</div>
            <div class="">{{ $t(`Footer.versionStr`) }}: {{ uiVersion }}</div>
          </div>
          <div v-if="isSuperAdmin" class="mx-2">
            <button
              @click="runStubEcho"
              type="button"
              class="btn btn-light btn-sm my-1"
            >
              <i class="bi bi-soundwave"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  <div
    class="toast-container position-absolute p-3 top-0 end-0 z-index-1500"
    v-if="showToast"
  >
    <bs-toast v-for="toast in toastPropsList" :key="toast" :="toast"></bs-toast>
  </div>
</template>

<script>
import TrnTopbar from "@/components/Topbar";
import TrnLeftbar from "@/components/Leftbar";
import TrnLocaleSwitcher from "@/components/LocaleSwitcher.vue";
import transactionApi from "@/api/transaciton";
import BsToast from "@/components/UI-elements/BsToast.vue";
import {getItemLocalStorage} from "@/helpers/persistanceStorage";

export default {
  data() {
    return {
      showToast: false,
      toastPropsList: [],
    };
  },
  components: { BsToast, TrnLocaleSwitcher, TrnLeftbar, TrnTopbar },
  computed: {
    isPathNameLogin() {
      const pathName = this.$router.currentRoute.value.meta.name;
      return pathName === "login";
    },
    uiVersion() {
      return process.env.VUE_APP_UI_VERSION;
    },
    isSuperAdmin() {
      const authorities = getItemLocalStorage("authorities");
      return authorities.includes("TRANSACTIONS_CREATE");
    },
  },
  methods: {
    runStubEcho() {
      transactionApi.runStubEcho().then((res) => {
        if (res.status === 200) {
          this.showModal = false;

          this.toastPropsList.push({
            content: `${this.$t(`uiElements.toast.success`)}`,
            bgColor: "bg-success",
            textColor: "text-white",
          });
          this.showToast = true;
        } else {
          console.log("err");
          this.toastPropsList.push({
            content: `${this.$t(`uiElements.toast.err`)}`,
            bgColor: "bg-danger",
            textColor: "text-white",
          });
          this.showToast = true;
        }
      });
    },
  },
};
</script>
<style scoped>
.left-block {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05), 0 2px 10px 0 rgba(0, 0, 0, 0.05);
}
.content {
  height: calc(100vh - 110px);
}
.footer {
}
</style>
